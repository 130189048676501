import React from "react";
import NoDataUI from "../../common/NoDataUI";
import Loader from "../../common/ui/Loader";

const PayrollHistory = ({ payroll, isLoading }) => { 
    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-lg font-semibold text-gray-800 border-b pb-4 mb-4">
                Payroll History
            </h2>
            <div className="overflow-x-auto">
                <table className="min-w-full text-sm text-left text-gray-500">
                    <thead className="bg-gray-100 text-primary font-semibold">
                        <tr>
                            <th className="px-6 py-3">MONTH</th>
                            <th className="px-6 py-3">BASE SALARY</th>
                            <th className="px-6 py-3">BONUS</th>
                            <th className="px-6 py-3">DEDUCTION</th>
                            <th className="px-6 py-3">TAX</th>
                            <th className="px-6 py-3">TOTAL PAYABLE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isLoading ? <Loader /> : (
                                payroll && payroll.length > 0 ? (
                                    payroll.map((item, index) => (
                                        <tr
                                            key={item.id || index}
                                            className="border-b hover:bg-gray-50"
                                        >
                                            <td className="px-6 py-3 text-gray-700">
                                                {item?.month}
                                            </td>
                                            <td className="px-6 py-3 text-gray-700">
                                                {item?.baseSalary || "-"}
                                            </td>
                                            <td className="px-6 py-3 text-gray-700">
                                                {item?.bonuses || "-"}
                                            </td>
                                            <td className="px-6 py-3 text-gray-700">
                                                {item?.deductions || "-"}
                                            </td>
                                            <td className="px-6 py-3 text-gray-700">
                                                {item?.tax || "-"}
                                            </td>
                                            <td className="px-6 py-3 text-gray-700 font-semibold">
                                                {item?.totalPayable || "-"}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="6"
                                            className="px-6 py-3 text-center text-gray-500 italic"
                                        >
                                            <NoDataUI />
                                        </td>
                                    </tr>
                                )
                            )
                        }

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PayrollHistory;
