import React from "react";
import LabelChart from "./LabelChart";
import API from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";

const TaskStatusSection = () => {
  const { user } = useAuth();
  const fetchWeeklyTask = async () => {
    try {
      const { data } = await API.get(`/auth/users/${user?._id}/weekly-tasks`);
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: weeklyTaskData, isLoading } = useQuery({
    queryKey: ["weekly-task"],
    queryFn: fetchWeeklyTask,
    enabled: !!user,
  });
  return (
    <div className="flex justify-center h-full bg-white p-4 pb-0 rounded-lg border border-secondary">
      <LabelChart data={weeklyTaskData} loading={isLoading} />
    </div>
  );
};

export default TaskStatusSection;
