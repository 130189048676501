import React, { useEffect, useState } from "react";
import { Button } from "../../common/ui/Button";
import axios from "axios";
import Buttonloader from "../../common/ui/ButtonLoader";
import Editor from "../editor/editor";
import Loader from "../../common/ui/Loader";
import API from "../../services/api";

const PanelSection = ({
  fetchProjectInfo,
  projectId,
  projectInfo,
  isLoading,
}) => {
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState(projectInfo?.panels || "");
  const [savingStatus, setSavingStatus] = useState('Saved');

  const url = process.env.REACT_APP_FASTAPI_URL;

  useEffect(() => {
    if (projectInfo?.panels) {
      setContent(projectInfo.panels);
      setSavingStatus("Saved");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!content) return;
    
    if (content === projectInfo?.panels) {
      setSavingStatus("Saved");
      return;
    }

    setSavingStatus('Unsaved');
    const debounceTimeout = setTimeout(async () => {
      try {
        const payload = { panels: content };
        await UpdatePanels(payload);
        setSavingStatus('Saved');
      } catch (error) {
        console.error("Error in Update", error);
      }
    }, 1500);
    return () => clearTimeout(debounceTimeout);
  }, [content]);

  const UpdatePanels = async (panels) => {
    try {
      await API.patch(`/project/${projectId}`, panels);
      fetchProjectInfo();
    } catch (error) {
      console.error("Error in Update", error);
    }
  };

  const moduleGenarate = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${url}/panels/`, {
        project_id: projectId,
      });
      if (data?.panels) {
        setContent(data.panels);
        fetchProjectInfo();
      }
      setLoader(false);
      return data;
    } catch (err) {
      console.error("Error in moduleGenarate:", err.message);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between pb-2.5">
        <h3 className="text-primary font-semibold">Panel</h3>
        {content && (
          <Button onClick={() => moduleGenarate()} disabled={loader}>
            {" "}
            {loader ? <Buttonloader /> : "Regenerate"}
          </Button>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="body flex-grow rounded-md border border-gray-100 bg-gray-50 p-4 px-0 w-full overflow-y-auto flex flex-col">
          {content && <div className="flex items-center justify-end px-4 mb-5">
              <div className="p-1.5 px-4 bg-primary/20 text-xs font-semibold rounded-md">
                {savingStatus}
              </div>
            </div>}
          <div className="h-full overflow-auto flex-grow">
            {content ? (
              <Editor key={content} initialValue={content ?? ""} onChange={setContent} />
            ) : (
              <div className="h-full flex items-center justify-center">
                <Button onClick={moduleGenarate} disabled={loader}>
                  {loader ? <Buttonloader /> : "Generate"}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PanelSection;
