import React from "react";
import IconRoundTick from "../../common/svgIcons/IconRoundTick";

const SteperItem = ({ id, icon, name, isLast, isActive, setStep, isStepClickable }) => {
  const { isGreen, isYellow } = isStepClickable;

  return (
    <li
      className={`py-2.5 px-1.5 flex items-center gap-1.5 rounded-md relative ${isActive === id ? "bg-primary/10 text-primary" : "text-gray-600"
        } ${isGreen || isYellow ? "cursor-pointer" : "cursor-not-allowed"}`}
      onClick={() => {
        if (isGreen || isYellow) {
          setStep(id);
        }
      }}
    >
      <span>
        {React.cloneElement(icon, {
          width: "20px",
          height: "20px",
          className: `${isActive === id ? "text-primary" : "text-gray-600"}`,
        })}
      </span>
      <p className="text-xs font-semibold">{name}</p>
      <div className="flex items-center flex-col gap-1 h-full absolute -start-8 top-2.5">
        <span>
          <IconRoundTick
            className={`h-5 w-5 ${isGreen
              ? "text-[#34C759]"
              : isYellow
                ? "text-[#e9d24e]"
                : "text-gray-600"
              }`}
          />
        </span>
        <div
          className={`h-[calc(50%+4px)] w-[1px] ${isGreen
            ? "bg-[#34C759]"
            : isYellow
              ? "bg-[#e9d24e]"
              : "bg-gray-600"
            } ${isLast && "hidden"}`}
        />
      </div>
    </li>
  );
};

export default SteperItem;
