import { Switch } from '@headlessui/react'
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import API from '../../services/api';

const DocsSwitch = ({ id, isInDocs, addUser, removeUser, role, userId, docs }) => {
    const queryClient = useQueryClient();
    const [status, setStatus] = useState(false)
    useEffect(() => {
        if (docs?.permissions?.some((permission) => permission.userId === userId)) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }, [docs, userId]);

    const { mutate: manageDocsPermission } = useMutation({
        mutationFn: async () => {
            if (status === true) {
                const response = await API.patch(`docs/${docs?._id}/permissions`, { permissions: [{ userId: userId }] });
                return response.data
            } else {
                const response = await API.delete(`docs/${docs?._id}/permissions/${userId}`, { permissions: [{ userId: userId }] });
                return response.data
            }
        },
        onSuccess: () => {
            toast.success(`Permission ${status ? 'assigned' : 'removed'} successfully`)
            queryClient.invalidateQueries({ queryKey: ["userDoc-list"] })
            queryClient.invalidateQueries({ queryKey: ["archive-list"] })
            queryClient.invalidateQueries({ queryKey: ["docs-list"] })
        },
        onError: (error) => {
            console.error("Error assigning Permissions", error);
            toast.error(error.response?.data?.message || "Error assigning Permissions")
        },
    });
    return (
        <Switch
            checked={status}
            onChange={() => {
                setStatus(!status)
                manageDocsPermission(status)
            }}
            className="group relative flex h-5 w-8 cursor-pointer rounded-full bg-[#98A1B2] p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary"
        >
            <span
                aria-hidden="true"
                className="pointer-events-none inline-block size-3 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-3"
            />
        </Switch>
    )
}

export default DocsSwitch