import { Activity } from 'lucide-react';
import IconColse from '../common/svgIcons/IconClose';
import IconComplete from '../common/svgIcons/IconComplete';
import IconDevelopment from '../common/svgIcons/IconDevelopment';
import IconDone from '../common/svgIcons/IconDone';
import IconFlag from '../common/svgIcons/IconFlag';
import IconHold from '../common/svgIcons/IconHold';
import IconInProgress from '../common/svgIcons/IconInProgress';
import IconReadyTesting from '../common/svgIcons/IconReadyTesting';
import IconReOpen from '../common/svgIcons/IconReOpen';
import IconReview from '../common/svgIcons/IconReview';
import IconTodo from '../common/svgIcons/IconTodo';
import IconHand from '../common/svgIcons/IconHand';
import IconBagTick from '../common/svgIcons/IconBagTick';
import IconSprintActive from '../common/svgIcons/IconSprintActive'
import IconSprintComplete from '../common/svgIcons/IconSprintComplete'
import IconSprintNotStarted from '../common/svgIcons/IconSprintNotStarted'

export const colorMap = {
    a: "#FF6F61",
    b: "#6B9F67",
    c: "#5A6F91",
    d: "#D8A02A",
    e: "#D76C9E",
    f: "#62C8C2",
    g: "#9933ff",
    h: "#5BFF82",
    i: "#9B7FCD",
    j: "#D96BE7",
    k: "#A3A600",
    l: "#70CFAE",
    m: "#68B9D6",
    n: "#7A9FD1",
    o: "#FF7D3A",
    p: "#67CC57",
    q: "#A4E37A",
    r: "#6DB4D2",
    s: "#F27D71",
    t: "#72F34F",
    u: "#F18BC2",
    v: "#F1E15D",
    w: "#F27A8F",
    x: "#6AE3E5",
    y: "#F1BC5D",
    z: "#7AEB71",
};

export const priorityTypes = [
    { id: 1, value: 'Urgent', name: 'Urgent', icon: <IconFlag className="text-[#B13A41] h-5 w-5" />, color: '#FF4D4D', bgColor: '#FF4D4D33' },
    { id: 2, value: 'High', name: 'High', icon: <IconFlag className="text-[#CF9409] h-5 w-5" />, color: '#FA9600', bgColor: '#FA960033' },
    { id: 3, value: 'Medium', name: 'Medium', icon: <IconFlag className="text-[#4366FF] h-5 w-5" />, color: '#FFC711', bgColor: '#FFCD2926' },
    { id: 4, value: 'Low', name: 'Low', icon: <IconFlag className="text-[#87909E] h-5 w-5" />, color: '#3B82F6', bgColor: '#3B82F633' },
]

export const statusType = [
    { id: 1, value: 'To Do', name: 'To Do', color: '#D18101', bgColor: '#D1810126', icon: <IconTodo className="h-4 w-4 text-[#D18101]" /> },
    { id: 1, value: 'Ready for Development', name: 'Ready for Development', color: '#01ABD1', bgColor: '#01ABD126', icon: <IconDevelopment className="h-4 w-4 text-[#01ABD1]" /> },
    { id: 1, value: 'In Progress', name: 'In Progress', color: '#004AEB', bgColor: '#004AEB26', icon: <IconInProgress className="h-4 w-4 text-[#004AEB]" /> },
    { id: 1, value: 'Hold', name: 'Hold', color: '#FA9600', bgColor: '#FA960026', icon: <IconHold className="h-4 w-4 text-[#FA9600]" /> },
    { id: 1, value: 'Done', name: 'Done', color: '#32ADE6', bgColor: '#32ADE626', icon: <IconDone className="h-4 w-4 text-[#32ADE6]" /> },
    { id: 1, value: 'Ready for Test', name: 'Ready for Test', color: '#AF52DE', bgColor: '#AF52DE26', icon: <IconReadyTesting className="h-4 w-4 text-[#AF52DE]" /> },
    { id: 1, value: 'Re Open', name: 'Re Open', color: '#FF5D7C', bgColor: '#FFE7EC', icon: <IconReOpen className="h-4 w-4 text-[#FF5D7C]" /> },
    { id: 1, value: 'In Review', name: 'In Review', color: '#00C7BE', bgColor: '#00C7BE26', icon: <IconReview className="h-4 w-4 text-[#00C7BE]" /> },
    { id: 1, value: 'Complete', name: 'Complete', color: '#34C759', bgColor: '#34C75926', icon: <IconComplete className="h-4 w-4 text-[#34C759]" /> },
    { id: 1, value: 'Closed', name: 'Closed', color: '#008748', bgColor: '#00AC4826', icon: <IconColse className="h-4 w-4 text-[#008748]" /> },
]

export const genderTypes = [
    { id: 1, value: 'male', name: 'Male' },
    { id: 2, value: 'female', name: 'Female' },
]
export const roleTypes = [
    { id: 1, value: 'admin', name: 'Admin' },
    { id: 2, value: 'human_resources', name: 'Human Resources' },
    { id: 3, value: 'project_manager', name: 'Project Manager' },
    { id: 4, value: 'developer', name: 'Developer' },
    { id: 5, value: 'quality_assurance', name: 'Quality Assurance' },
]

export const monthList = [
    { id: '01', value: 'Jan', name: 'Jan' },
    { id: '02', value: 'Feb', name: 'Feb' },
    { id: '03', value: 'Mar', name: 'Mar' },
    { id: '04', value: 'Apr', name: 'Apr' },
    { id: '05', value: 'May', name: 'May' },
    { id: '06', value: 'Jun', name: 'Jun' },
    { id: '07', value: 'Jul', name: 'Jul' },
    { id: '08', value: 'Aug', name: 'Aug' },
    { id: '09', value: 'Sep', name: 'Sep' },
    { id: '10', value: 'Oct', name: 'Oct' },
    { id: '11', value: 'Nov', name: 'Nov' },
    { id: '12', value: 'Dec', name: 'Dec' },
];
export const LeaveStatus = [
    { id: 1, value: "Pending" },
    { id: 2, value: "Approved" },
    { id: 3, value: "Rejected" },
];
export const LeaveType = [
    { id: 1, value: "Paid", name: "Paid" },
    { id: 2, value: "Unpaid", name: "Unpaid" },
    { id: 3, value: "CompsOff", name: "Comp Offs" },
    { id: 4, value: "Floater", name: "Floater" },
];
export const PayrollData = [
    { id: '1', name: 'sagar', basic: 2000 },
    { id: '2', name: 'suyog', basic: 3000 },
    { id: '3', name: 'sahil', basic: 1500 },
    { id: '4', name: 'nikunj', basic: 1000 },
    { id: '5', name: 'pratham', basic: 2000 },
];

export const statuses = [
    { status: 'Planned', icon: <IconSprintNotStarted className="w-4 h-4" /> },
    { status: 'Active', icon: <IconSprintActive className="w-4 h-4" /> },
    { status: 'Completed', icon: <IconSprintComplete className="w-4 h-4 text-[#34C759]" /> },
    { status: 'Closed', icon: <IconSprintComplete className="w-4 h-4 text-[#008748]" /> },
]

export const categories = [
    {
        name: 'Profile',
        children: [
            {
                name: 'Profiled',
                detail: 'Profile Profile'
            },
            {
                name: 'Profiles',
                detail: 'Profiles Profiles'
            },
        ],
    },
    {
        name: 'About',
        children: [
            {
                name: 'Summary',
                detail: 'Summary Summary'
            },
            {
                name: 'Timeline',
                detail: 'Timeline Timeline'
            },
        ],
    },
    {
        name: 'Tasks',
        children: [
            {
                name: 'Task',
                detail: 'Task Task'
            },
            {
                name: 'Tasked',
                detail: 'Tasks Tasks'
            },
        ],
    },
    {
        name: 'Leave',
        //   children: [
        //     {
        //         name: 'Leaved',
        //         detail: 'Leave Leave'
        //     },
        //     {
        //         name: 'Leaves',
        //         detail: 'Leaves Leaves'
        //     },
        //   ],
    },
    {
        name: 'Payroll',
        // children: [
        //     {
        //         name: 'Payrolled',
        //         detail: 'Payroll Payroll'
        //     },
        //     {
        //         name: 'Payrolls',
        //         detail: 'Payrolls Payrolls'
        //     },
        // ],
    },
    {
        name: 'Timesheet',
        //   children: [
        //     {
        //         name: 'Timesheeted',
        //         detail: 'Timesheet Timesheet'
        //     },
        //     {
        //         name: 'Timesheets',
        //         detail: 'Timesheets Timesheets'
        //     },
        //   ],
    },
]

export const TimelineData = [
    {
        events: [
            {
                id: 1,
                icon: <IconHand className='h-5 w-5 text-gray-500' />,
                name: 'Joined ELAUNCH SOLUTION PRIVATE LIMITED'
            },
            {
                id: 2,
                icon: <IconBagTick className='h-4 w-[18px] text-gray-500' />,
                name: 'Probation Completed'
            },
            {
                id: 3,
                icon: <IconBagTick className='h-4 w-[18px] text-gray-500' />,
                name: 'Permanent employee'
            },
            {
                id: 4,
                icon: <IconBagTick className='h-4 w-[18px] text-gray-500' />,
                name: 'Appraisal'
            },
            {
                id: 5,
                icon: <IconBagTick className='h-4 w-[18px] text-gray-500' />,
                name: 'Notice Period'
            },
            {
                id: 6,
                icon: <IconBagTick className='h-4 w-[18px] text-gray-500' />,
                name: 'Final Settlment'
            },
        ]
    },
]

export const TimelineActionToId = {
    5: "Notice Period Set",
    2: "Probation Updated",
    4: "Appraisal",
    3: "User Confirmed",
    6: "Final Settlement",
    1: "Joined ELAUNCH SOLUTION PRIVATE LIMITED",
}

export const DocsList = [
    { id: 1, name: 'nexus Local' },
    { id: 2, name: 'nexus Beta' },
    { id: 3, name: 'nexus Prod' },
    { id: 4, name: 'playage gaming' },
    { id: 5, name: 'elaunch' },
]

export const employeeData = [
    {
        name: "Sagar Kachariya",
        designation: "Sr. Front End Developer",
        location: "Katargam",
        department: "Designing",
        email: "sagar.k@elaunchinfotech.in",
        mobile: "8141829527"
    },
    {
        name: "Virat Kohli",
        designation: "Unity Developer",
        location: "Katargam",
        department: "Game Development",
        email: "virat.k@elaunchinfotech.in",
        mobile: "9929915214"
    },
    {
        name: "Nayan Variya",
        designation: "Chief Technology Officer",
        location: "Katargam",
        department: "Android/Flutter",
        email: "nayan.v@elaunchinfotech.in",
        mobile: "8856732195"
    },
    {
        name: "Suyog Bora",
        designation: "Jr. Software Engineer",
        location: "Katargam",
        department: "Designing",
        email: "suyog.b@elaunchinfotech.in",
        mobile: "8895527510"
    }
]

