import React, { useState } from "react";
import IconBoardView from "../../common/svgIcons/IconBoardView";
import IconSummary from "../../common/svgIcons/IconSummary";
import IconListView from "../../common/svgIcons/IconListView";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import DocumentsList from "./DocumentsList";
import { Button } from "../../common/ui/Button";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import Loader from "../../common/ui/Loader";

const DocumentList = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true)
  const fetchDocs = async () => {
    try {
      const { data } = await API.get(`/docs/all-docs`);
      return data?.list
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false)
    }
  };

  const { data: DocsList } = useQuery({
    queryKey: ['docs-list'], queryFn: fetchDocs,
  });

  const fetchArchiveDocs = async () => {
    try {
      const { data } = await API.get(`/docs/all-docs/archive`);
      return data?.list
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false)
    }
  };

  const { data: ArchiveDocsList } = useQuery({
    queryKey: ['archive-list'], queryFn: fetchArchiveDocs,
  });
  const fetchUsersDocs = async () => {
    try {
      const { data } = await API.get(`/docs/user-docs`);
      return data?.list
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false)
    }
  };

  const { data: UsersDocsList } = useQuery({
    queryKey: ['userDoc-list'], queryFn: fetchUsersDocs,
  });
  return (
    <div className="task-list-wrapper">
      <div className="xxs:p-5 p-2.5 bg-white rounded-lg h-full overflow-hidden">
        <TabGroup className="h-full">
          <div className="flex items-center gap-2.5">
            <TabList className="flex gap-1 border-b border-gray-200 overflow-auto flex-grow">
              {["All", "My Docs", "Archived"].map((tab, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    clsx(
                      "outline-none px-3 flex items-center gap-1 pb-1.5 pt-2 text-sm leading-[1] font-semibold rounded-t-md transition-all duration-200 ease-in-out border-b-2",
                      selected
                        ? "bg-gray-100 text-primary border-gray-200"
                        : "text-gray-400 hover:bg-gray-100 hover:text-primary border-transparent"
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </TabList>
            <Button
              size="sm"
              className={"font-semibold max-sm:h-6"}
              onClick={() => navigate(`/docs/create`)}
            >
              Create Docs
            </Button>
          </div>

          <TabPanels className="mt-4">
            {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
              <Loader />
            </div> :
              <>
                <TabPanel key="All" className="rounded-xl bg-gray-50 p-4 shadow">
                  <DocumentsList docsdata={DocsList} />
                </TabPanel>
                <TabPanel
                  key="My Docs"
                  className="rounded-xl bg-gray-50 p-4 shadow"
                >
                  <DocumentsList docsdata={UsersDocsList} />
                </TabPanel>
                <TabPanel
                  key="Archived"
                  className="rounded-xl bg-gray-50 p-4 shadow"
                >
                  <DocumentsList docsdata={ArchiveDocsList} />
                </TabPanel>
              </>
            }
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default DocumentList;
