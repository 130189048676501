import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { generateYearList } from '../../lib/utils'
import { Button } from '../../common/ui/Button'
import { CheckIcon, ChevronDownIcon } from 'lucide-react'
import { monthList } from '../../staticData/data'
import PayrolListItem from './PayrolListItem'
import NoDataUI from '../../common/NoDataUI'
import Buttonloader from '../../common/ui/ButtonLoader'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import API from '../../services/api'
import { toast } from 'react-toastify'
import Loader from '../../common/ui/Loader'

const PayrollList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const lastMonthIndex = (currentMonth - 1 + 12) % 12;
    const lastMonth = monthList[lastMonthIndex]?.id;
    const yearList = generateYearList(currentYear - 10, currentYear);

    const [selectYear, setSelectYear] = useState(currentYear.toString())
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [loadingButton, setLoadingButton] = useState(null);
    const [payrollData, setPayrollData] = useState();
    const [noData, setNoData] = useState(false);
    const [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     if (selectYear === currentYear.toString()) {
    //         setSelectedMonth(lastMonth);
    //     } else {
    //         setSelectedMonth(monthList[0]?.id);
    //     }
    // }, [selectYear, currentYear, lastMonth]); 

    const fetchPayroll = async (month, year) => {
        try {
            setLoader(true)
            const { data } = await API.get(`/payroll/list?month=${year}-${month}`);
            setPayrollData(data?.data || []);
            setNoData(!data?.data?.length);
            setLoader(false)
        } catch (err) {
            console.error(err.message);
            setPayrollData([]);
            setNoData(true);
        }
    };

    useEffect(() => {
        fetchPayroll(selectedMonth, selectYear);
    }, [selectedMonth, selectYear]);

    const handleGenerate = (buttonId) => {
        setLoadingButton(buttonId);
        GeneratePayroll();
    };

    const { mutate: GeneratePayroll } = useMutation({
        mutationFn: async () => {
            const response = await API.post(`/payroll/generate`, { month: `${selectYear}-${selectedMonth}` });
            return response.data;
        },
        onSuccess: (data, variables) => {
            toast.success('Payroll Generated successfully');
            fetchPayroll(selectedMonth, selectYear);
            setLoadingButton(null);
        },
        onError: (error) => {
            console.error('Failed to Generate Payroll:', error);
            toast.error('Failed to Generate Payroll');
            setLoadingButton(null);
        }
    });

    return (
        <>
            <div className='xxs:p-5 p-2.5 flex flex-col h-full overflow-hidden'>
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                        {
                            monthList?.map((month) =>
                                <Button className={` text-primary hover:text-white ${selectedMonth == month?.id ? 'bg-primary text-white' : 'bg-[#e3e4f5] text-primary'}`} onClick={() => setSelectedMonth(month?.id)}>{month?.name}</Button>
                            )
                        }
                        <div className=""></div>
                    </div>
                    <div className="flex items-center gap-2">
                        <Button
                            onClick={() => handleGenerate('regenerate')}
                            disabled={loadingButton === 'regenerate' || !payrollData || payrollData.length === 0}
                        >
                            {loadingButton === 'regenerate' ? <Buttonloader loaderClass="text-white bg-white" /> : 'Re-Generate'}
                        </Button>
                        <Listbox
                            value={selectYear}
                            onChange={(newStatus) => setSelectYear(newStatus)}
                        >
                            <ListboxButton
                                className={clsx(
                                    `relative block rounded-lg bg-white/5 border border-border text-left text-sm/6 w-[150px] p-2 py-1.5`,
                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                )}
                            >
                                {selectYear}
                                <span className='absolute right-3 top-1/2 -translate-y-1/2'>
                                    <ChevronDownIcon className="size-4" />
                                </span>
                            </ListboxButton>
                            <ListboxOptions
                                anchor="bottom"
                                transition
                                className={clsx(
                                    'w-[150px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                    'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                )}
                            >
                                {yearList?.map((year) => (
                                    <ListboxOption
                                        key={year.id}
                                        value={year.value}
                                        className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5"
                                    >
                                        <div className="flex items-center gap-1.5">
                                            <div className="text-xs font-medium">{year.name}</div>
                                        </div>
                                        <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Listbox>
                    </div>
                </div>
                <div className="flex-grow overflow-hidden">
                    <div className="h-full">
                        <div className="h-full flex flex-col">
                            <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                <div className="w-[50%] px-3.5">Employee Name</div>
                                <div className="w-[10%]">Basic</div>
                                <div className="w-[10%]">Bonus</div>
                                <div className="w-[10%]">Deduction</div>
                                <div className="w-[10%]">Tax</div>
                                <div className="w-[10%]">Total</div>
                            </div>
                            <div className="h-full flex-grow overflow-y-auto">
                                {loader ? <Loader /> : (
                                    payrollData?.length ?
                                        payrollData?.map((payroll) =>
                                            <PayrolListItem payroll={payroll} />
                                        ) :
                                        <div className="h-full flex items-center justify-center flex-col gap-2">
                                            <NoDataUI subtitle={"No Payroll Data Found for this month and year."} title={"Oops, No Payroll Data Found!"} />
                                            <Button onClick={() => handleGenerate('generate')} disabled={loadingButton === 'generate'} >
                                                {loadingButton === 'generate' ? (<Buttonloader loaderClass="text-white bg-white" />) : ('Generate')}
                                            </Button>
                                        </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayrollList