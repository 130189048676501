import React from 'react'

const IconHoliday = (props) => {
    return (
        <div>
            <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9843 7.95312H6.39404V13.3536H10.9843V7.95312Z" fill="#5E6A7A" fill-opacity="0.2" />
                <path d="M8.01426 13.6229H6.12402V7.68262H11.2543V8.22262H6.66402V13.0829H8.01426V13.6229Z" fill="currentColor" />
                <path d="M18.5449 10V6.87246C18.5449 6.12678 17.9406 5.52246 17.1949 5.52246H13.4231C12.6729 5.52246 12.0645 6.13062 12.0645 6.8811V8.4927V10H18.5449Z" fill="white" />
                <path d="M18.8147 12.8132H18.2747V6.87269C18.2747 6.27701 17.7901 5.79269 17.1944 5.79269H13.4228C12.8228 5.79269 12.3344 6.28109 12.3344 6.88109V12.8129H11.7944V6.88133C11.7944 5.98349 12.525 5.25293 13.4228 5.25293H17.1947C18.088 5.25293 18.8149 5.97965 18.8149 6.87293V12.8132H18.8147Z" fill="currentColor" />
                <path d="M16.6548 5.79254H13.9546V3.90254C13.9546 3.6047 14.197 3.3623 14.4948 3.3623H16.1148C16.4124 3.3623 16.6548 3.6047 16.6548 3.90254V5.79254ZM14.4946 5.25254H16.1146V3.90254H14.4946V5.25254Z" fill="currentColor" />
                <path d="M14.3145 13.6233H18.5447V10.9233C18.5447 10.4759 18.1821 10.1133 17.7347 10.1133H12.8745C12.4271 10.1133 12.0645 10.4759 12.0645 10.9233V12.5433L14.3145 13.6233Z" fill="currentColor" fill-opacity="0.2" />
                <path d="M18.8147 13.893H14.0444V13.353H18.2747V10.923C18.2747 10.6252 18.0323 10.3828 17.7347 10.3828H12.8744C12.5768 10.3828 12.3344 10.6252 12.3344 10.923V12.813H11.7944V10.923C11.7944 10.3273 12.2788 9.84277 12.8744 9.84277H17.7349C18.3306 9.84277 18.8149 10.3271 18.8149 10.923V13.893H18.8147Z" fill="currentColor" />
                <path d="M17.2248 6.60254H13.392C13.2554 6.60254 13.1445 6.71342 13.1445 6.84998V9.03278H17.4648V6.84254C17.4648 6.71006 17.3573 6.60254 17.2248 6.60254Z" fill="#5E6A7A" fill-opacity="0.2" />
                <path d="M17.7348 9.30301H12.8745V6.88141C12.8745 6.57901 13.1205 6.33301 13.4229 6.33301H17.1948C17.4926 6.33301 17.735 6.57541 17.735 6.87301V9.30301H17.7348ZM13.4155 8.76301H17.1948V6.87277H13.4229L13.4155 8.76301Z" fill="currentColor" />
                <path d="M14.0369 14.7584C14.0369 14.7584 12.2515 13.6427 10.8293 13.0904C10.7052 13.0487 9.7315 12.8125 9.18262 12.8125L11.7041 14.7584C12.3609 14.7584 13.3449 14.7584 14.0369 14.7584Z" fill="#5E6A7A" fill-opacity="0.2" />
                <path d="M14.9791 15.0291H11.6124L8.39111 12.543H9.18287C9.78575 12.543 10.7962 12.7947 10.9154 12.8348C12.3564 13.3942 14.1065 14.4841 14.1804 14.5302L14.9791 15.0291ZM11.7965 14.4889H13.0495C12.409 14.125 11.5097 13.6448 10.7316 13.3426C10.6778 13.3246 10.4302 13.2637 10.133 13.2049L11.7965 14.4889Z" fill="currentColor" />
                <path d="M16.7285 14.7588C15.8909 14.7588 6.11593 14.7588 5.91457 14.7588L5.29201 13.8574C4.80409 13.0908 3.69385 13.0908 3.69385 13.0908L4.80433 15.8707L6.70057 16.662C7.64785 17.0573 9.76153 17.2608 10.788 17.2608H16.451C19.3238 17.2608 20.0698 16.6654 20.3623 16.3157C20.4674 16.1899 20.4586 16.0078 20.3369 15.8981C19.8929 15.498 18.918 14.7588 16.7285 14.7588Z" fill="#5E6A7A" fill-opacity="0.2" />
                <path d="M16.451 17.5315H10.7882C9.68229 17.5315 7.55325 17.3107 6.59685 16.9118L4.59597 16.0766L3.29541 12.8213H3.69405C3.74541 12.8213 4.95909 12.8311 5.51997 13.7126L6.05637 14.4893H16.7287C19.1767 14.4893 20.1861 15.3991 20.5178 15.6979C20.7456 15.9038 20.7681 16.2511 20.5694 16.4892C20.1957 16.9366 19.362 17.5315 16.451 17.5315ZM5.01309 15.666L6.80469 16.4136C7.66317 16.7717 9.70341 16.9918 10.7882 16.9918H16.4512C18.4387 16.9918 19.6848 16.7062 20.1552 16.1434C20.1652 16.1318 20.1713 16.1134 20.1561 16.0995C19.8626 15.835 18.9691 15.0298 16.7287 15.0298H5.77341L5.07021 14.0117C4.83933 13.6486 4.42821 13.4882 4.11525 13.4177L5.01309 15.666Z" fill="currentColor" />
                <path d="M15.2207 16.9832C15.2207 16.9832 9.45733 20.8753 8.08525 20.3192L7.53613 20.0413L12.4761 16.4273C12.4761 16.4273 13.0249 16.1494 13.8484 16.1494L15.2207 16.9832Z" fill="#5E6A7A" fill-opacity="0.2" />
                <path d="M8.39548 20.6379C8.2378 20.6379 8.09932 20.6163 7.98388 20.5695L7.02148 20.0832L12.354 16.1863C12.3788 16.1736 12.9708 15.8789 13.8483 15.8789H14.1183V16.4189H13.8483C13.1835 16.4189 12.7095 16.6171 12.6149 16.6601L8.05108 19.9992L8.20732 20.0779C9.08836 20.4358 12.8873 18.2331 15.0694 16.759L15.2933 16.6078L15.5955 17.0556L15.3716 17.2068C14.4684 17.8164 10.1928 20.6379 8.39548 20.6379Z" fill="currentColor" />
                <path d="M5.06159 14.7666H3.42383V15.3066H5.06159V14.7666Z" fill="currentColor" />
                <path d="M10.782 16.1425V15.6025H11.322V16.1425C10.782 16.1425 11.322 16.1425 10.782 16.1425ZM9.70199 16.1425V15.6025H10.2394V16.1425C9.70199 16.1425 10.2394 16.1425 9.70199 16.1425ZM8.61935 16.1425V15.6025H9.16223V16.1425C8.61935 16.1425 9.16199 16.1425 8.61935 16.1425ZM7.54199 16.1425V15.6025H8.08199V16.1425C7.54199 16.1425 8.08199 16.1425 7.54199 16.1425Z" fill="currentColor" />
                <path d="M17.3844 16.1425V15.6025H17.9244V16.1425C17.3844 16.1425 17.9244 16.1425 17.3844 16.1425ZM16.3041 16.1425V15.6025H16.8444V16.1425C16.3041 16.1425 16.8444 16.1425 16.3041 16.1425ZM15.2241 16.1425V15.6025H15.7641V16.1425C15.2241 16.1425 15.7641 16.1425 15.2241 16.1425Z" fill="currentColor" />
                <path d="M19.9185 15.3894L18.9951 15.7012L19.1679 16.213L20.0914 15.9012L19.9185 15.3894Z" fill="currentColor" />
                <path d="M17.7348 10.9229H12.8745V11.4629H17.7348V10.9229Z" fill="currentColor" />
                <path d="M10.9842 8.22254H8.82422V7.41254C8.82422 6.9659 9.18758 6.60254 9.63422 6.60254H10.1742C10.6209 6.60254 10.9842 6.9659 10.9842 7.41254V8.22254ZM9.36422 7.68254H10.4442V7.41254C10.4442 7.2635 10.3233 7.14254 10.1742 7.14254H9.63422C9.48542 7.14254 9.36422 7.2635 9.36422 7.41254V7.68254Z" fill="currentColor" />
                <path d="M7.7441 8.49219H7.2041V12.5424H7.7441V8.49219Z" fill="currentColor" />
                <path d="M4.49429 6.38953L4.25928 6.87598L5.23195 7.34589L5.46696 6.85945L4.49429 6.38953Z" fill="currentColor" />
                <path d="M5.27166 8.16156L4.12793 8.17773L4.13556 8.71768L5.27929 8.70151L5.27166 8.16156Z" fill="currentColor" />
                <path d="M5.85965 5.14765L5.43213 5.47754L6.13091 6.38312L6.55843 6.05323L5.85965 5.14765Z" fill="CurrentColor" />
            </svg>

        </div>
    )
}

export default IconHoliday
