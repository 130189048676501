import { createImageUpload } from "novel/plugins";
import { toast } from "react-toastify";
import API from "../../services/api";

const onUpload = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const promise = API.post("/auth/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Ensure the correct content type
    },
  });

  return new Promise((resolve, reject) => {
    toast.promise(
      promise.then(async (res) => {
        if (res.status === 200) {
          const url = res.data?.file?.path;
          const image = new Image();
          image.src = url;
          image.onload = () => {
            resolve(url);
          };
        } else if (res.status === 401) {
          resolve(file);
          toast.error("`BLOB_READ_WRITE_TOKEN` environment variable not found, reading image locally instead.");
        } else {
          const errorMessage = res.data?.message || "Error uploading image. Please try again.";
          toast.error(errorMessage);
          reject(new Error(errorMessage)); 
        }
      }),
      {
        loading: "Uploading image...",
        success: "Image uploaded successfully.",
        error: (e) => {
          reject(e); 
          toast.error(e.message || "Error uploading image. Please try again.");
          return e.message;
        },
      }
    ).catch((error) => {
      toast.error("Error uploading image. Please try again.");
    });
  });
};

export const uploadFn = createImageUpload({
  onUpload,
  validateFn: (file) => {
    if (!file.type.includes("image/")) {
      toast.error("File type not supported.");
      return false;
    }
    if (file.size / 1024 / 1024 > 20) {
      toast.error("File size too big (max 20MB).");
      return false;
    }
    return true;
  },
});
