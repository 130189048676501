import React from "react";
import Kite from "../../assets/json/kite.json";
import flag from "../../assets/json/flag/flag_loader.json";
import Lottie from "react-lottie-player";

const Loader = () => {
  return process.env.REACT_APP_THEAM === "kite" ? (
    <div className="h-full flex items-center justify-center">
      <Lottie
        loop
        animationData={Kite}
        play
        style={{ width: 80, height: 80 }}
      />
    </div>
  ) : process.env.REACT_APP_THEAM === "flag" ? (
    <div className="h-full flex items-center justify-center">
      <Lottie
        loop
        animationData={flag}
        play
        style={{ width: 100, height: 100 }}
      />
    </div>
  ) : (
    <div className="h-full flex items-center justify-center">
      <span class="loader"></span>
    </div>
  );
};

export default Loader;
