import React from "react";
import IconDocs from "../../common/svgIcons/IconDocs";
import { Pencil, Trash2 } from "lucide-react";
import { Button } from "../../common/ui/Button";

const PageListItem = ({ index, page, hoverId }) => {
  return (
    // <div className="realtive">
      <div key={index} className="cursor-pointer flex items-center gap-1.5 pe-11">
        <span>
          <IconDocs className="h-4 w-4" />
        </span>
        <p className="pt-1 truncate">
          {page?.title}
        </p>
      </div>
      /* {hoverId === index && (
        <div className="absolute right-0 top-1/2 -translate-y-1/2 z-[9999] flex items-center gap-1.5">
          <Button
            className="border border-border p-1 rounded-md bg-black h-auto text-dark/50 hover:bg-white"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
        </div>
      )} */
    // </div>
  );
};

export default PageListItem;
