import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ChevronRightIcon, MenuIcon, Square } from "lucide-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LiveTimer from "../common/LiveTimer";
import ProfileDropdown from "../common/ProfileDropdown";
import { Button } from "../common/ui/Button";
import Tooltip from "../common/ui/Tooltip";
import { useAuth } from "../context/AuthContext";
import { getIcon, groupTasksByStatus } from "../lib/utils";
import API from "../services/api";
import AddProjectModal from "./modal/AddProjectModal";
import ChangePasswordModal from "./modal/ChangePasswordModal";
import SelectSprintStatus from "../common/SelectSprintStatus";
import TodayTaskDropdown from "../common/TodayTaskDropdown";
import { statuses } from "../staticData/data";
import IconCheckIn from "../common/svgIcons/IconCheckIn";
import IconCheckOut from "../common/svgIcons/IconCheckOut";
import ConfirmationModal from "./modal/ConfirmationModal";
import IconBack from "../common/svgIcons/IconBack";

const Header = ({ openSidebar, setOpenSidebar }) => {
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { projectId, sprintId } = useParams();
  const childRef = useRef();
  const [open, setOpen] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [timers, setTimers] = useState([]);
  const [passModal, setPassModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    createdBy: "",
    team: [],
  });
  const [attendanceData, setAttendanceData] = useState([]);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const { docsId } = useParams();
  const navigate = useNavigate()

  const handleAddProject = async (data) => {
    try {
      await API.post("/project", data);
      setNewProject({
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        createdBy: "",
        team: [],
      });
      setOpen(false);
      toast.success("Project added successfully");
      queryClient.invalidateQueries({ queryKey: ["projectList"] });
    } catch (err) {
      if (err.status === 401) {
        logout();
      } else {
        const errorMessage = Object.entries(err.response?.data?.errors)[0][1];
        toast.error(errorMessage);

      }
    }
  };

  let headerTitle;

  switch (true) {
    case pathname.includes("/home"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Home</span>
      );
      break;
    case pathname.includes("/attendance"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          Attendance Stats
        </span>
      );
      break;
    case pathname.includes("/docs"):
      headerTitle = (
        docsId ?
          <span className="flex items-center cursor-pointer" onClick={() => navigate('/docs')}>
            <IconBack className="h-6 w-6 text-gray-600" />
          </span> :
          <span className="flex items-center gap-1 text-[#475467]">
            Docs
          </span>
      );
      break;
    case pathname.includes("/leave-request"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          Leave Request
        </span>
      );
      break;
    case pathname.includes("/leave"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Leave</span>
      );
      break;
    case pathname.includes("/task-analytics"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          Task Analytics
        </span>
      );
      break;
    case pathname.includes("/task-tracker"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          Task Tracker
        </span>
      );
      break;
    case pathname.includes("/user-detail"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          User Profile
        </span>
      );
      break;
    case pathname.includes("/user"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Team</span>
      );
      break;
    case pathname.includes("/timesheet"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          Timesheet
        </span>
      );
      break;
    case pathname.includes("/tags"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Tags</span>
      );
      break;
    case pathname.includes("/department"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">
          Department
        </span>
      );
      break;
    case pathname.includes("/job-title"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Job List</span>
      );
      break;
    case pathname.includes("/archive"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Archive</span>
      );
      break;
    case pathname.includes("/payroll"):
      headerTitle = (
        <span className="flex items-center gap-1 text-[#475467]">Payroll</span>
      );
      break;
    default:
      headerTitle = null;
      break;
  }

  const fetchProjectDetails = async () => {
    const { data } = await API.get(`project/${projectId}`);
    return data;
  };
  const fetchSprintDetails = async () => {
    const { data } = await API.get(`sprint/${sprintId}`);
    return data;
  };
  const { data: projectDetails } = useQuery({
    queryKey: ["project", projectId],
    queryFn: fetchProjectDetails,
    enabled: !!projectId,
  });

  const { data: sprintDetails } = useQuery({
    queryKey: ["sprint", sprintId],
    queryFn: fetchSprintDetails,
    enabled: !!sprintId,
  });

  const handleStopTimer = async (timerId) => {
    try {
      let data = await API.post("/timer/stop", { timerId });
      fetchTimersAndTasks();
      // fetchCurrentTask();
      if (childRef.current) {
        childRef.current.fetchTimersAndTasks();
      }
      queryClient.invalidateQueries({ queryKey: ["todays-hour"] });
      setActiveTask(null);
    } catch (err) {
      if (err.status === 401) {
        logout();
      }
    }
  };

  const fetchTodaysHour = async () => {
    try {
      const { data } = await API.get("/auth/user/today-hours");
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: activeTaskData } = useQuery({
    queryKey: ["todays-hour"],
    queryFn: fetchTodaysHour,
  });

  const fetchTimersAndTasks = async () => {
    try {
      const [timersRes] = await Promise.all([API.get("/timer")]);
      setTimers(timersRes.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchTimersAndTasks();
  }, []);

  const isActiveTimer = activeTaskData?.data?.activeTimer;

  useEffect(() => {
    fetchAttendanceInfo();
  }, []);

  const fetchAttendanceInfo = async () => {
    try {
      const response = await API.get("/timer/clock-in-info");
      if (response.data?.data) {
        const todayRecords = response.data?.data;
        const lastRecord = todayRecords[todayRecords.length - 1];
        queryClient.invalidateQueries(['todays-checkIn']);
        setAttendanceData(todayRecords);
        setIsCheckedIn(lastRecord && !lastRecord.checkOutTime);
      }
    } catch (error) {
      console.error("Error fetching attendance info:", error);
    }
  };
  const { data: AttendanceData } = useQuery({
    queryKey: ["Check-info"],
    queryFn: fetchAttendanceInfo,
  });
  const handleAttendanceAction = async (isCheckIn) => {
    try {
      const endpoint = isCheckIn ? "/timer/check-in" : "/timer/check-out";
      const response = await API.post(endpoint);

      if (response?.data) {
        fetchAttendanceInfo();
        queryClient.invalidateQueries(['attendance']);
      }
    } catch (error) {
      console.error(`Error during ${isCheckIn ? "check-in" : "check-out"}:`, error);
      toast.error(error?.response?.data?.message || `Error during ${isCheckIn ? "check-in" : "check-out"}`)
    }
  };


  const handleAttandance = async () => {
    handleAttendanceAction(!isCheckedIn)
  }

  return (
    <>
      <div className="xxs:px-5 px-2.5 border-b border-border py-1.5">
        <div className="flex items-center justify-between gap-1">
          <h2 className="sm:py-2 py-2 text-[#98A1B2] flex items-center gap-1 text-sm">
            {pathname.includes("/projectDetail") ? (
              <div className="flex items-center gap-2">
                <span className="flex items-center gap-1 text-[#475467] max-lg:hidden">
                  Project{" "}
                  <span className="max-lg:hidden">
                    <ChevronRightIcon className="w-4 h-4" />
                  </span>
                </span>
                <span className="max-w-[100px] truncate">
                  {projectDetails?.name}
                </span>
              </div>
            ) : pathname.includes("/project") ? (
              <div className="flex items-center gap-2">
                <span className="flex items-center gap-1 text-[#475467] max-lg:hidden">
                  Project{" "}
                  <span className="max-lg:hidden">
                    <ChevronRightIcon className="w-4 h-4" />
                  </span>
                </span>
                <span className="max-w-[100px] truncate">
                  {projectDetails?.name}
                </span>
                <span className="max-lg:hidden">
                  <ChevronRightIcon className="w-4 h-4" />
                </span>
                <div className="flex items-center gap-2 max-lg:hidden">
                  <span>{getIcon(sprintDetails?.status)}</span>{" "}
                  <span className="truncate">
                    {sprintDetails?.name} -{" "}
                    {moment(sprintDetails?.startDate).format("DD/MM/YYYY")} -{" "}
                    {moment(sprintDetails?.endDate).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="border-s border-[#ECEDFF] ps-2 max-lg:hidden">
                  {(sprintDetails?.userRoleFlag === 'Admin' || sprintDetails?.userRoleFlag === 'Project Manager' || sprintDetails?.userRoleFlag === 'Team Lead' || sprintDetails?.userRoleFlag === 'Tester/QA') ?
                    <SelectSprintStatus sprint={sprintDetails} />
                    : <p className="relative w-[110px] rounded-lg flex items-center gap-2 bg-primary/10 py-1.5 px-3  font-semibold text-dark text-[12px]',
                     'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'">
                      <span>{statuses.find((stutusEle) => stutusEle.status === sprintDetails?.status)?.icon}</span>  {statuses.find((stutusEle) => stutusEle.status === sprintDetails?.status)?.status}</p>
                  }
                </div>

              </div>
            ) : (
              headerTitle
            )}
          </h2>
          <div className="flex items-center sm:gap-4 gap-2">
            {/* <div
              className="cusorr-pointer"
              onClick={() => handleAttendanceAction(!isCheckedIn)}
            >
              {isCheckedIn ? (
                <Tooltip text="Clock-Out"
                  position="bottom">
                  <IconCheckOut className="h-5 w-5 mt-2 text-red-500" />
                </Tooltip>
              ) : (
               
                  <Tooltip text="Clock-In"
                    position="bottom">
                    <IconCheckIn className="h-5 w-5 mt-2 text-green-500" />
                  </Tooltip>
               
              )}
            </div> */}
            <Button size='sm' className={`font-semibold max-sm:h-6 ${isCheckedIn && 'bg-red-500 hover:bg-red-500'}`} onClick={() => setModelOpen(true)} >{isCheckedIn ? 'Check Out' : 'Check In'}</Button>

            {!isActiveTimer && <TodayTaskDropdown />}
            {activeTaskData?.data?.activeTimer && (
              <Tooltip
                detailClass="top-12 right-0 left-[unset] translate-x-0 bg-[#eeeffb] text-[#475467] p-2 cursor-pointer"
                arrowClass="rotate-180 -top-1 left-[unset] translate-x-0 right-20"
                borderColor="border-t-[#eeeffb]"
                text={
                  <div className="flex items-center flex-wrap max-lg:gap-y-1">
                    <span className="flex items-center gap-1 text-[#475467]">
                      {activeTaskData?.data.activeTimer.taskId.projectId.name}{" "}
                      <span>
                        <ChevronRightIcon className="w-4 h-4" />
                      </span>
                    </span>{" "}
                    <span className="truncate">
                      {activeTaskData?.data.activeTimer.taskId?.sprintId?.name}{" "}
                      -{" "}
                      {moment(
                        activeTaskData?.data.activeTimer.taskId?.sprintId
                          ?.startDate
                      ).format("DD/MM/YYYY")}{" "}
                      -{" "}
                      {moment(
                        activeTaskData?.data.activeTimer.taskId?.sprintId
                          ?.endDate
                      ).format("DD/MM/YYYY")}
                    </span>{" "}
                    <span>
                      <ChevronRightIcon className="w4 h-4" />
                    </span>{" "}
                    <span className="font-semibold text-primary">
                      {activeTaskData?.data.activeTimer.taskId?.title}{" "}
                    </span>
                  </div>
                }
              >
                <div className="flex items-center sm:gap-2.5 gap-1 whitespace-nowrap cursor-pointer bg-[#FCFCFD] border border-[#EAECF0] p-2 max-xxs:py-1.5 rounded-lg">
                  {isActiveTimer === null ? (
                    <TodayTaskDropdown></TodayTaskDropdown>
                  ) : (
                    <LiveTimer
                      initialElapsedSeconds={
                        activeTaskData?.data?.activeTimer?.elapsedTime
                      }
                      className={"max-xxs:text-sm"}
                    />
                  )}
                  <Button
                    size={"icon"}
                    className={
                      "h-6 w-6 rounded-full bg-red-500 flex items-center justify-center"
                    }
                    onClick={() =>
                      handleStopTimer(activeTaskData?.data.activeTimer._id)
                    }
                  >
                    <Square className="text-white fill-white h-3 w-3" />
                  </Button>
                </div>
              </Tooltip>
            )}
            <ProfileDropdown setOpen={setOpen} setPassModal={setPassModal} />
            <MenuIcon
              className="lg:hidden"
              onClick={() => setOpenSidebar((prev) => !prev)}
            />
          </div>
        </div>
      </div>
      <AddProjectModal
        open={open}
        setOpen={setOpen}
        newProject={newProject}
        setNewProject={setNewProject}
        handleAddProject={handleAddProject}
      />
      <ChangePasswordModal passModal={passModal} setPassModal={setPassModal} />
      <ConfirmationModal
        title={`Are you sure you want to ${isCheckedIn ? 'Check Out' : 'Check In'}?`}
        open={modelOpen}
        setOpen={setModelOpen}
        handleConfirm={handleAttandance}
      />
    </>
  );
};

export default Header;
