import { Eye } from "lucide-react";
import moment from "moment";
import React, { useState } from "react";
import LeaveDetails from "../modal/LeaveDetails";
import { Button } from "../../common/ui/Button";

const LeaveListItem = ({ leaveList }) => {
  const [leaveDetailsOpen, setLeaveDetailsOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);

  const calculateDuration = (date, totalDays) => {
    if (date && totalDays) {
      const startDate = moment(date?.date).format("YYYY-MM-DD");
      const endDate = moment(date?.date)
        .add(totalDays - 1, "day")
        .format("YYYY-MM-DD");
      if (totalDays === 0.5) {
        return `${startDate}  (${date.leaveType})`;
      }
      return `${startDate} - ${endDate}`;
    }
    return "N/A";
  };

  return (
    <div className="w-full">
      {leaveList.map((leave, index) => (
        <div
          key={leave.id || index}
          className="flex items-center gap-2 text-gray-700 border-b border-gray-200 py-2 hover:bg-gray-50 cursor-pointer text-sm font-semibold last:border-0"
        >
          <div className="w-[20%] px-3 text-gray-800">
            <div className="text-sm font-medium flex flex-col gap-1 flex-wrap">
              <p className="text-dark">
                {leave?.leaveDays?.length < 2
                  ? moment(leave?.leaveDays[0]?.date).format("MMM DD, YYYY")
                  : moment(leave?.leaveDays[0]?.date).format("MMM DD, YYYY") +
                  " - " +
                  moment(
                    leave?.leaveDays[leave?.leaveDays?.length - 1]?.date
                  ).format("MMM DD, YYYY")}
              </p>
              <span className="text-gary-400">({leave?.totalDays} {leave?.totalDays === 1 ? 'Day' : 'Days'})</span>
            </div>
          </div>
          <div className="w-[20%]">
            <div className="">
              <p>{leave?.type}</p>
              <p className="text-gray-500 font-normal">
                Requested on {moment(leave?.appliedAt).format("MMM DD, YYYY")}
              </p>
            </div>
          </div>
          <div className="w-[20%]">{leave.status}</div>
          <div className="w-[20%]">{leave?.approvedBy?.name}</div>
          {/* <div className="w-[20%]">{leave?.adminRequest && leave?.adminRequest?.name ? leave?.adminRequest?.name : leave?.user?.name}</div> */}
          <div className="w-[20%]">{leave?.actionAt ? moment(leave?.actionAt).format("MMM DD, YYYY") : "-"}</div>
          <div className="w-[20%] truncate pe-2.5">{leave?.reason}</div>
          <div className="w-[10%]">
            <Button
              className="p-0 h-6 w-6 shadow-none rounded-md bg-transparent border border-primary hover:bg-primary group"
              onClick={() => {
                setLeaveDetailsOpen(true);
                setSelectedLeave(leave);
              }}
            >
              <Eye className="h-4 w-4 text-primary group-hover:text-white" />
            </Button>
          </div>
          {/* <div className="w-[60%] px-3 text-gray-800">{leave.user?.name || "N/A"}</div>
                    <div className="w-[10%] text-gray-600 text-center">{leave.type || "N/A"}</div>
                    <div className="w-[10%] text-gray-600 text-center truncate">{leave.reason || "N/A"}</div>
                    <div className="w-[10%] text-gray-600 text-center">{leave.totalDays || 0}</div>
                    <div className="w-[20%] text-gray-600 text-center">
                    {calculateDuration(leave.leaveDays[0], leave.totalDays)}
                    </div>
                    <div className="w-[10%] text-center">
                        <span
                            className={`px-2 py-1 rounded text-white text-xs ${leave.status === "Approved"
                            ? "bg-green-500"
                                : leave.status === "Rejected"
                                    ? "bg-red-500"
                                    : "bg-yellow-500"
                                    }`}
                        >
                            {leave.status || "Pending"}
                        </span>
                    </div> */}
        </div>
      ))}
      {selectedLeave && leaveDetailsOpen && <LeaveDetails leaveDetails={selectedLeave} setOpen={setLeaveDetailsOpen} isOpen={leaveDetailsOpen} />}
    </div>
  );
};

export default LeaveListItem;
