import React from "react";
import ProjectData from "../components/projectDetail/ProjectData";
import { useParams } from "react-router-dom";

const ProjectDetails = () => {
  const { projectId } = useParams();  
  return (
    <>
      <ProjectData projectId={projectId} />
    </>
  )
};

export default ProjectDetails;
