import React from 'react'
import AttendanceList from '../components/attendance/AttendanceList'

const Attendance = () => {
  return (
    <AttendanceList />
  )
}

export default Attendance
