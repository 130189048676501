import { Calendar, CheckIcon, ChevronDown } from "lucide-react";
import React, { useRef, useState } from "react";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import clsx from "clsx";
import { LeaveStatus, LeaveType, monthList } from "../staticData/data";
import DatePicker from "react-datepicker";

const FilterItem = ({ setStatus, status, type, setType, showDatePicker, setMonth, month, setStartDate, startDate, endDate, setEndDate, showPending }) => {
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);
    return (
        <div className="border border-gray-200 p-3 mb-4 text-gray-700 flex space-x-4">
            <div className="flex items-center border-r border-gray-300 pr-4">
                <Listbox value={status} onChange={(newStatus) => setStatus(newStatus)}>
                    <ListboxButton
                        className={clsx(
                            `relative flex items-center space-x-32 rounded-lg bg-white/5 text-left text-sm/6`,
                            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                    >
                        <p>{status === "Except Pending" ? "Select Status" : status || "status"}</p>
                        <ChevronDown />
                    </ListboxButton>
                    <ListboxOptions
                        anchor="bottom"
                        transition
                        className={clsx(
                            "w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                        )}
                    >
                        {LeaveStatus?.filter(status => showPending !== false || status.value !== "Pending")?.map((status) => (
                            <ListboxOption
                                key={status.id}
                                value={status.value}
                                className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                            >
                                <div className="flex items-center gap-1.5">
                                    <div className="text-xs font-medium">{status.value}</div>
                                </div>
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </Listbox>
            </div>

            <div className="flex items-center border-r border-gray-300 pr-4">
                <Listbox value={type} onChange={(newType) => setType(newType)}>
                    <ListboxButton
                        className={clsx(
                            `relative flex items-center space-x-32 rounded-lg bg-white/5 text-left text-sm/6`,
                            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                        )}
                    >
                        <p className="ml-4">{type || "Leave Type"}</p>
                        <ChevronDown />
                    </ListboxButton>
                    <ListboxOptions
                        anchor="bottom"
                        transition
                        className={clsx(
                            "w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                        )}
                    >
                        {LeaveType?.map((type) => (
                            <ListboxOption
                                key={type.id}
                                value={type.value}
                                className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                            >
                                <div className="flex items-center gap-1.5">
                                    <div className="text-xs font-medium">{type?.name}</div>
                                </div>
                                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </Listbox>
                {showDatePicker &&
                    <>
                        <div className="flex items-center border-r border-gray-300 pr-4 ml-2">
                            <DatePicker
                                ref={startDatePickerRef}
                                placeholderText="Start Date"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="w-full"
                            />
                            <Calendar
                                className="h-5 w-5 font-medium text-gray-500 cursor-pointer"
                                onClick={() => startDatePickerRef.current.setFocus()}
                            />
                        </div>

                        {/* End Date Picker */}
                        <div className="flex items-center ml-2">
                            <DatePicker
                                ref={endDatePickerRef}
                                placeholderText="End Date"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="w-full"
                            />
                            <Calendar
                                className="h-5 w-5 font-medium text-gray-500 cursor-pointer"
                                onClick={() => endDatePickerRef.current.setFocus()}
                            />
                        </div>
                    </>
                }
            </div>
            {!showDatePicker &&
                <div className="relative w-full max-w-xs mx-auto">
                    <DatePicker
                        selected={month}
                        onChange={(date) => setMonth(date)}
                        dateFormat="YYYY-MM" // Only show month and year
                        showMonthYearPicker // Show only month and year
                        showFullMonthYearPicker
                        placeholderText="Select a month"
                        className="w-full px-4 py-2 text-sm rounded-lg  focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary placeholder-gray-600"
                    />
                </div>

            }
        </div>
    );
};

export default FilterItem;
