import React, { useEffect, useState } from 'react';
import HorizontalScrollContainer from '../../common/HorizontalScrollContainer';
import API from '../../services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TaskListBoardItem from './TaskListBoardItem';
import NoDataUI from '../../common/NoDataUI';
import IconCicularPlus from '../../common/svgIcons/IconCicularPlus';
import { cn } from '../../lib/utils';
import QuickAddCard from '../../common/QuickAddCard';

const TaskBoardList = ({ sprintId, sortedTasks, statusType, projectDetails, fetchTasks, sprintDetails, handleQuickAdd, selectedValues }) => {
    const queryClient = useQueryClient();
    const [tasks, setTasks] = useState(sortedTasks?.flatMap((group) => group.tasks)); // Flatten tasks into a single array
    const [draggingTaskId, setDraggingTaskId] = useState(null);
    const [activeQuickAdd, setActiveQuickAdd] = useState(null);

    const onDragStart = (taskId) => {
        setDraggingTaskId(taskId);
    };

    useEffect(() => {
        if (sortedTasks) {
            const updatedTasks = sortedTasks?.flatMap((group) => group.tasks);
            setTasks(updatedTasks);
        }
    }, [sortedTasks]);

    const onDrop = (newStatus) => {
        if (!draggingTaskId) return;

        const updatedTasks = tasks.map((task) =>
            task._id === draggingTaskId ? { ...task, status: newStatus } : task
        );

        setTasks(updatedTasks);
        updateTaskStatus({ taskId: draggingTaskId, newStatus });
        setDraggingTaskId(null); // Reset dragging task
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const { mutate: updateTaskStatus } = useMutation({
        mutationFn: async ({ taskId, newStatus }) => {
            const response = await API.patch(`/task/${taskId}`, { status: newStatus });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["tasks", sprintId] });
        },
        onError: (error) => {
            console.error("Error updating task status:", error.message);
        },
    });

    const filteredTaskList = () => {
        return tasks?.filter((sortedTask) => {
            if (!selectedValues?.taskStatuses.some(status => status?.name === "Closed") && sortedTask.status === "Closed") {
                return false;
            }
            return true;
        });
    };

    return (
        <div className="flex space-x-4">
            <HorizontalScrollContainer>
                {statusType?.map((status) => {
                    const tasksForStatus = filteredTaskList()?.filter((task) => task.status === status.value);

                    return (
                        <div
                            key={status.value}
                            className="rounded-lg shadow bg-gray-50 flex flex-col h-full max-h-[calc(100vh-240px)] overflow-hidden"
                            onDragOver={onDragOver}
                            onDrop={() => onDrop(status.value)}
                            style={{
                                minWidth: '280px',
                                maxWidth: '280px',
                            }}
                        >
                            {/* Status Header */}
                            <div className="header p-3 flex items-center">
                                <h2
                                    className="text-[10px] font-semibold flex items-center gap-1 px-1.5 py-0.5 rounded-lg"
                                    style={{
                                        color: status.color,
                                        border: `1px solid ${status.color}`,
                                        backgroundColor: `${status.color}20`,
                                    }}
                                >
                                    <span>{status.icon}</span>
                                    {status.name}
                                </h2>
                            </div>

                            {/* Tasks */}
                            <div className="body p-3 pt-0 flex flex-col gap-2 max-h-[calc(100%-46px)] overflow-auto">
                                {tasksForStatus && tasksForStatus?.length > 0 && tasksForStatus?.map((task) => (
                                    <div
                                        key={task._id}
                                        className="task-item bg-white p-2 shadow flex justify-between hover:shadow-lg transition-transform transform hover:scale-105 rounded-lg"
                                        draggable
                                        onDragStart={() => onDragStart(task._id)}
                                    >
                                        <TaskListBoardItem
                                            fetchCurrentTask={fetchTasks}
                                            tag={task.tags}
                                            task={task}
                                            sprintDetails={sprintDetails}
                                            projectDetails={projectDetails}
                                        />
                                    </div>
                                ))}
                                <div
                                    className={cn('bg-white p-2 shadow flex items-center gap-2 rounded-lg text-sm')}
                                >
                                    <QuickAddCard
                                        status={status.name}
                                        addTask={handleQuickAdd}
                                        isEditing={activeQuickAdd === status.name}
                                        setActiveQuickAdd={setActiveQuickAdd}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </HorizontalScrollContainer>
        </div>
    );
};

export default TaskBoardList;
