import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { Pencil, Reply, Tag, Trash2, GitBranch, Bug } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { cn } from '../../lib/utils';
import { Input } from '../Input';
import { Separator } from '../separator';
import IconReply from '../svgIcons/IconReply';
import TagItem from '../TagItem';
import { Button, buttonVariants } from './Button';
import Tooltip from './Tooltip';
import IconSubtask from '../svgIcons/IconSubtask';
import IconBug from '../svgIcons/IconBug';


const Title = ({ userRol, task, setRestoreOpen, updateTitle, hoverId, handleOpenDetails, setHoverId, setDeleteOpen, tags, setSelectedTags, selectedTags, isArchive, handleRetrieve, taskTags, handleRemoveTag, handleSelectTag, isReport }) => {
    const { user } = useAuth()
    const inputRef = useRef();
    const [localTitle, setLocalTitle] = useState();
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        setLocalTitle(task?.title);
    }, [task]);

    const handleTitleChange = (e) => {
        setLocalTitle(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleUpdateTitle(localTitle);
        }
        if (e.key === 'Escape') {
            handleCancelEdit();
        }
    };

    const handleUpdateTitle = async (newTitle) => {
        updateTitle(newTitle);
        setIsEditMode(false);
        setHoverId(null);
    };

    const handleCancelEdit = () => {
        setLocalTitle(task?.title);
        setIsEditMode(false);
        setHoverId(null);
    };

    const handleOnBlur = () => {
        if (isEditMode) {
            setIsEditMode(false);
            setHoverId(null);
        } else {
            setHoverId(null);
        }
    };


    const [filterText, setFilterText] = useState('');
    const filteredTags = tags?.filter(tag =>
        tag.name.toLowerCase().includes(filterText.toLowerCase()) && !taskTags?.some((selectedTag) => selectedTag._id === tag._id)
    );
    const handleInputChange = (e) => {
        setFilterText(e.target.value);
    };
    return (
        <div className="relative">
            <div className="flex items-center gap-2">
                {isEditMode ? (
                    <Input
                        ref={inputRef}
                        id={`edit-title-${task?._id}`}
                        labelClass="!hidden"
                        className="border-0 bg-transparent p-0 outline-none !ring-0"
                        value={localTitle}
                        onChange={handleTitleChange}
                        onKeyPress={handleKeyPress}
                        onBlur={handleOnBlur}
                        autoFocus
                    />
                ) : (
                    <p onClick={handleOpenDetails} className='truncate max-w-[280px]'>
                        {task.title}
                    </p>

                )}

                <div className="flex items-center gap-2.5">
                    {task?.subtaskCount > 0 && (
                        <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Subtask' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                            <div className="flex items-center rounded-full text-sm">
                                <IconSubtask className='h-4 w-4 text-[#667085]' />
                                {task.subtaskCount}
                            </div>
                        </Tooltip>
                    )}

                    {task?.bugCount > 0 && (
                        <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='bug' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                            <div className="flex items-center rounded-full text-sm">
                                <IconBug className='h-4 w-4 text-[#667085]' />
                                {task.bugCount}
                            </div>
                        </Tooltip>
                    )}
                </div>

                <div className="">
                    <ul className='list-none flex items-center gap-0.5 flex-wrap'>
                        {taskTags?.map(tag => (
                            <TagItem
                                tagClassName='text-[10px]'
                                className="!border-0 py-0"
                                key={tag._id}
                                tag={tag}
                                isSelected={taskTags.some((selectedTag) => selectedTag._id === tag._id)}
                                isListMode={false}
                                onClick={handleRemoveTag}
                                close={true}
                                userRol={userRol}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            {hoverId === task?._id && !isEditMode && (
                <div className="absolute right-0 top-1/2 -translate-y-1/2 z-[9] flex items-center gap-1.5">
                    <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            inputRef.current?.focus();
                            setIsEditMode(true);
                        }}
                    >
                        <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Edit' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                            <div className="flex items-center rounded-full text-sm">
                                <Pencil className="h-4 w-4" />
                            </div>
                        </Tooltip>

                    </Button>
                    {
                        (userRol === 'Admin' || userRol === 'Project Manager' || userRol === 'Team Lead' || userRol === 'Tester/QA') &&
                        <Button
                            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDeleteOpen(true);
                            }}
                        >
                            <Trash2 className='h-4 w-4 text-red-500' />
                        </Button>
                    }
                    {
                        isArchive &&
                        <Button
                            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setRestoreOpen(true)
                            }}
                        >
                            <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Restore' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                                <div className="flex items-center rounded-full text-sm">
                                    <IconReply className='h-4 w-4 text-dark/80' />
                                </div>
                            </Tooltip>
                        </Button>
                    }
                    {
                        (userRol === 'Admin' || userRol === 'Project Manager' || userRol === 'Team Lead' || userRol === 'Tester/QA') &&
                        <div className="">
                            <Menu >
                                <MenuButton className={cn(buttonVariants({ className: "border border-border  p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white" }))}>
                                    <Tag className="w-4 h-4 fill-white/60" />
                                </MenuButton>
                                <MenuItems
                                    transition
                                    anchor="bottom end"
                                    className="w-[280px] origin-top-right mt-1 rounded-xl z-[999] border-2 border-border shadow-lg  bg-white p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                >
                                    <div className="p-1">
                                        <Separator className="bg-[#ECEDFF] mt-1" />
                                        <div className="">
                                            <Input
                                                type="text"
                                                className="border-0 py-2 px-1 text-xs"
                                                placeholder="Search Tag Here"
                                                value={filterText}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <Separator className="bg-[#ECEDFF]" />
                                        <div>
                                            {
                                                filteredTags?.length > 0 ? (<ul>
                                                    {filteredTags?.map((tag) => {
                                                        // const isSelected = selectedTags.some((selectedTag) => selectedTag._id === tag._id);
                                                        // const isDisabled = taskTags.some((existingTag) => existingTag._id === tag._id);
                                                        return (
                                                            <TagItem
                                                                key={tag._id}
                                                                tag={tag}
                                                                isListMode={true}
                                                                onClick={handleSelectTag}
                                                            />
                                                        );
                                                    })}
                                                </ul>) : (
                                                    <div className='mt-3 border-border border p-6 text-center text-xs font-medium text-muted'>All Tags Has Been Attached</div>
                                                )
                                            }
                                        </div >
                                    </div >
                                </MenuItems >
                            </Menu >
                        </div >
                    }
                </div >
            )}
        </div >
    );
};

export default Title;
