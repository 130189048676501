import React, { useState } from "react";
import Drawer from "./Drawer";

const ArrivalsCount = ({ title, count, data }) => {
  const [open, setOpen] = useState(false);
  const showTime = title === 'Late Arrivals today' ? true : false;
  return (
    <div className="ps-4 relative after:absolute after:h-full after:w-0.5 after:bg-primary after:top-0 after:left-1">
      <p className="text-sm">{title}</p>
      <div className="flex items-end justify-between">
        <h3 className="text-3xl font-semibold">{count}</h3>
        {count > 0 &&
          <button className="text-sm text-primary" onClick={() => setOpen(true)}>View All</button>
        }
      </div>
      <Drawer open={open} setOpen={setOpen} userData={data} showTime={showTime} />
    </div>
  );
};

export default ArrivalsCount;
