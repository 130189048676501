import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';

const leaveTypes = ["First Half", "Second Half", "Full Day"];

const DateItem = ({ date, setDaysDifference, setLeaveData }) => {
    const [selections, setSelections] = useState(
        date.map((date) => ({ date, leaveType: "Full Day" })) // Initialize with default type as "Full day"
    );
    
    useEffect(() => {
        setSelections((prevSelections) => {
            const newSelections = date.map((currentDate) => {
                const existing = prevSelections.find((item) => item.date === currentDate);
                return existing || { date: currentDate, leaveType: "Full Day" }; // Default to "Full Day"
            });
            return newSelections;
        });
    }, [date]);

    const handleLeaveTypeChange = (index, leaveType) => {
        setSelections((prev) =>
            prev.map((item, i) =>
                i === index ? { ...item, leaveType } : item // Update the type for the corresponding date
            )
        );
    };

    const totalDays = useMemo(() => {
        setLeaveData(selections)
        return selections.reduce((total, item) => {
            const value = item.leaveType === "Full Day" ? 1 : 0.5;
            return total + value;
        }, 0);
    }, [selections]);

    setDaysDifference(totalDays)

    return (
        <ul className="border border-secondary bg-[#fbfbff] rounded-md py-1.5 flex flex-col gap-1">
            {date.map((currentDate, index) => (
                <li key={index} className="flex items-center justify-between gap-1.5 px-2">
                    <label htmlFor={`day${index + 1}`} className="text-sm text-gray-500">
                        {currentDate}
                    </label>
                    <div className="">
                        <Listbox
                            value={selections[index]?.leaveType}
                            onChange={(value) => handleLeaveTypeChange(index, value)}
                        >
                            <div className="relative">
                                <ListboxButton
                                    className={clsx(
                                        "relative block w-[135px] rounded-md bg-[#FBFBFF] text-left text-sm border p-1 px-1.5 focus:outline-none"
                                    )}
                                >
                                    {selections[index]?.leaveType || <span className="text-gray-400">Casual</span>}
                                    <span className="absolute right-3 top-1/2 -translate-y-1/2">
                                        <ChevronDownIcon className="size-4" />
                                    </span>
                                </ListboxButton>
                                <ListboxOptions className="absolute z-10 w-[135px] mt-1 rounded-lg bg-white shadow-lg border border-gray-200 max-h-40 overflow-auto p-1.5">
                                    {leaveTypes.map((type) => (
                                        <ListboxOption
                                            key={type}
                                            value={type}
                                            className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md p-1 select-none hover:bg-dark/5"
                                        >
                                            <div className="flex items-center gap-1.5">
                                                <div className="text-xs font-medium">{type}</div>
                                            </div>
                                            <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                        </ListboxOption>
                                    ))}
                                </ListboxOptions>
                            </div>
                        </Listbox>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default DateItem;
