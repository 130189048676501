import { Dialog, DialogPanel } from '@headlessui/react';
import React from 'react';
import { X } from 'lucide-react';
import moment from 'moment';
import clsx from 'clsx'; // Import clsx
import { getColorFromName } from '../../lib/utils';

const FestivalListModal = ({ open, setOpen, list }) => {
    return (
        <Dialog open={open} as="div" className="relative z-10 focus:outline-none" onClose={() => setOpen(false)}>
            {/* Overlay */}
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex items-start justify-center p-4 mt-10">
                    {/* Modal Panel */}
                    <DialogPanel
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out p-6 relative transform transition-all shadow-lg"
                    >
                        {/* Close Button */}
                        <button
                            onClick={() => setOpen(false)}
                            className="absolute top-3 right-3 text-gray-500 hover:text-gray-800 focus:outline-none"
                        >
                            <X className="h-4 w-4" />
                        </button>

                        {/* Modal Content */}
                        <div>
                            <h2 className="text-lg font-semibold mb-4">
                                Holidays ({moment(new Date()).format('YYYY')})
                            </h2>

                            {/* Holiday List */}
                            {list && list.length > 0 ? (
                                <div className="grid grid-cols-2 gap-4">
                                    {list.map((festival, index) => (
                                        <div key={index} className="rounded-md">
                                            <div className="flex items-center">
                                                {/* Date Box */}
                                                <div
                                                    className={clsx(
                                                        'h-auto w-16 rounded-md border flex flex-col justify-center items-center',
                                                    )}
                                                    style={{
                                                        border: `2px solid ${getColorFromName(festival?.name)}`,
                                                    }}
                                                >
                                                    <div className="w-full text-white" style={{
                                                        background: `${getColorFromName(festival?.name)}`,
                                                    }}>
                                                    <p className="text-sm py-1 text-center font-medium">
                                                        {moment(festival?.date).format('MMM')}
                                                    </p>
                                                    </div>
                                                    <p className="text-lg font-medium">
                                                        {moment(festival?.date).format('DD')}
                                                    </p>
                                                </div>
                                                {/* Details */}
                                                <div className="flex flex-col ml-2">
                                                    <p className="font-medium text-gray-800">{festival?.name}</p>
                                                    <p className="text-sm text-gray-700">
                                                        {moment(festival?.date).format('dddd')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-gray-600">No holidays available.</p>
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default FestivalListModal;
