import { useState } from 'react'

import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorContent,
  EditorRoot,
} from 'novel'

import { ImageResizer, handleCommandNavigation } from 'novel/extensions'
import { handleImageDrop, handleImagePaste } from 'novel/plugins'



import { NodeSelector } from './selectors/node-selector'
import { LinkSelector } from './selectors/link-selector'
import { MathSelector } from './selectors/math-selector'
import { TextButtons } from './selectors/text-buttons'
import { ColorSelector } from './selectors/color-selector'
import { defaultExtensions } from './extensions'
import { uploadFn } from './image-upload'
import EditorMenu from './editor-menu'
import { slashCommand, suggestionItems } from './slash-command'
import { Separator } from '../../common/separator'
import { useAuth } from '../../context/AuthContext'

const hljs = require('highlight.js')

const extensions = [...defaultExtensions, slashCommand]


export default function Editor({ initialValue, onChange }) {

  const [openNode, setOpenNode] = useState(false)
  const [openColor, setOpenColor] = useState(false)
  const [openLink, setOpenLink] = useState(false)
  const [openAI, setOpenAI] = useState(false)
  const { isAuthenticated } = useAuth();

  const highlightCodeblocks = (content) => {
    const doc = new DOMParser().parseFromString(content, 'text/html')
    doc.querySelectorAll('pre code').forEach(el => {
      hljs.highlightElement(el)
    })
    return new XMLSerializer().serializeToString(doc)
  }

  return (
    <div className='relative w-full h-full'>
      <EditorRoot className='abc'>
        <EditorContent
          immediatelyRender={false}
          initialContent={initialValue}
          extensions={extensions}
          editable={isAuthenticated}
          className='min-h-96 p-4 h-full'
          editorProps={{
            handleDOMEvents: {
              keydown: (_view, event) => handleCommandNavigation(event)
            },
            handlePaste: (view, event) =>
              handleImagePaste(view, event, uploadFn),
            handleDrop: (view, event, _slice, moved) =>
              handleImageDrop(view, event, moved, uploadFn),
            attributes: {
              class:
                'prose dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full h-full'
            }
          }}
          onUpdate={({ editor }) => {
            onChange(editor.getJSON())
          }}
          slotAfter={<ImageResizer />}
        >
          <EditorCommand className='z-50 h-auto max-h-[330px]  overflow-y-auto border-none rounded-none bg-background px-1 py-2 shadow-md transition-all'>
            <EditorCommandEmpty className='px-2 text-muted-foreground'>
              No results
            </EditorCommandEmpty>
            <EditorCommandList className='bg-white'>
              {suggestionItems.map(item => (
                <EditorCommandItem
                  value={item.title}
                  onCommand={val => item.command?.(val)}
                  className='flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-accent aria-selected:bg-accent'
                  key={item.title}
                >
                  <div className='flex h-10 w-10 items-center justify-center  border-none rounded-none bg-background'>
                    {item.icon}
                  </div>
                  <div>
                    <p className='font-medium'>{item.title}</p>
                    <p className='text-xs text-muted-foreground'>
                      {item.description}
                    </p>
                  </div>
                </EditorCommandItem>
              ))}
            </EditorCommandList>
          </EditorCommand>

          <EditorMenu className='abc' open={openAI} onOpenChange={setOpenAI}>
            <NodeSelector className='abc' open={openNode} onOpenChange={setOpenNode} />

            <Separator orientation='vertical' className="h-8" />
            <LinkSelector open={openLink} onOpenChange={setOpenLink} />

            <Separator orientation='vertical' className="h-8" />
            <MathSelector />

            <Separator orientation='vertical' className="h-8" />
            <TextButtons />

            <Separator orientation='vertical' className="h-8" />
            <ColorSelector open={openColor} onOpenChange={setOpenColor} />
          </EditorMenu>
        </EditorContent>
      </EditorRoot>
    </div>
  )
}
