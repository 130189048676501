import { clsx } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import IconSprintActive from "../common/svgIcons/IconSprintActive";
import IconSprintComplete from "../common/svgIcons/IconSprintComplete";
import IconSprintNotStarted from "../common/svgIcons/IconSprintNotStarted";
import { statusType } from "../staticData/data";

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const getIcon = (key) => {
  switch (key) {
    case 'Active':
      return <IconSprintActive className="w-4 h-4 text-[#1290E0]" />;

    case 'Completed':
      return <IconSprintComplete className="w-4 h-4 text-[#008844]" />;

    case 'Planned':
      return <IconSprintNotStarted className="w-4 h-4 text-[#ACAEB1]" />;

    default:
      return null;
  }
}


export function getHumanReadableTimeDifference(startTime) {
  const startDate = moment(startTime);
  const currentDate = moment();

  const duration = moment.duration(currentDate.diff(startDate));

  let result = '';
  if (duration.hours() > 0) {
    result += `${duration.hours()} hour${duration.hours() > 1 ? 's' : ''}`;
  }
  if (duration.minutes() > 0) {
    if (result) result += ' ';
    result += `${duration.minutes()} minute${duration.minutes() > 1 ? 's' : ''}`;
  }

  return result || 'Less than a minute ago';
}

export const groupTasksByStatus = (tasks) => {
  const statusOrder = statusType.map((status) => status.value);
  const groupedTasks = tasks.reduce((acc, task) => {
    let statusGroup = acc.find((group) => group.status === task.status);
    if (!statusGroup) {
      acc.push({
        status: task.status,
        tasks: [task],
      });
    } else {
      statusGroup.tasks.push(task);
    }

    return acc;
  }, []);
  return groupedTasks.sort(
    (a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
  );
};

// Hash function to generate a numeric hash from a string
const stringToHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

// Convert hash to HSL color
const hashToColor = (hash) => {
  const hue = Math.abs(hash) % 360; // Limit hue to [0, 360]
  const saturation = 60 + (Math.abs(hash) % 40); // Saturation [60-100%]
  const lightness = 50 + (Math.abs(hash) % 20); // Lightness [50-70%]
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Convert hash to a professional HSL color
const hashToProfessionalColor = (hash) => {
  const hue = Math.abs(hash) % 360;
  const saturation = 50 + (Math.abs(hash) % 20);
  const lightness = 50 + (Math.abs(hash) % 15);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Generate a color based on the first and last name
export const getColorFromName = (name) => {

  const combinedHash = stringToHash(name);
  return hashToProfessionalColor(combinedHash);
};

// generate year options
export const generateYearList = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
      years.push({ id: year, value: year.toString(), name: year.toString() });
  }
  return years;
};

export const formatRole = (str) => {
  return str
    .split('_') // Split the string into words at the underscore
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back together with a space
};