import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Check, Plus, X } from 'lucide-react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Input } from '../common/Input';
import TagListItem from '../common/TagListItem';
import { Button } from '../common/ui/Button';
import API from '../services/api';
import Loader from '../common/ui/Loader';

const Tags = () => {
  const [loader, setLoader] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const queryClient = useQueryClient();

  const { data: tags = [], isLoading } = useQuery(
    {
      queryKey: ['tags'],
      queryFn: async () => {
        const response = await API.get('/tag/list');
        return response.data || [];
      },
      enabled: true,
    }
  );

  const { mutate: updateTagMutation } = useMutation({
    mutationFn: async ({ id, name }) => {
      const response = await API.patch(`/tag/${id}/update`, { name });
      return response.data;
    },
    onMutate: async (updatedTag) => {
      await queryClient.cancelQueries({ queryKey: ['tags'] });
      const previousTags = queryClient.getQueryData(['tags']);
      queryClient.setQueryData(['tags'], (oldTags) =>
        oldTags.map((tag) =>
          tag._id === updatedTag.id ? { ...tag, name: updatedTag.name } : tag
        )
      );
      return { previousTags };
    },
    onError: (error, updatedTag, context) => {
      queryClient.setQueryData(['tags'], context.previousTags);
      toast.error(error.response?.data?.message || 'Failed to update tag');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      toast.success('Tag Updated Successfully');
    },
  });

  const { mutate: deleteTagMutation } = useMutation({
    mutationFn: async (id) => {
      const response = await API.delete(`/tag/${id}/delete`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      toast.success('Tag Deleted Successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to delete tag');
    },
  });

  const { mutate: addTagMutation } = useMutation({
    mutationFn: async (name) => {
      const response = await API.post(`/tag/create`, { name });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      setNewTagName('');
      setIsAdding(false);
      toast.success('Tag Added Successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Failed to add tag');
    },
  });

  const addNewTag = () => {
    if (newTagName.trim()) {
      addTagMutation(newTagName.trim());
    }
  };

  const cancelAdd = () => {
    setNewTagName('');
    setIsAdding(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addNewTag();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewTag();
    }
  };
  return (
    isLoading ? <Loader /> : (
      <section className="xxs:p-5 p-2.5">
        <div className="grid grid-cols-1 gap-2">
          <div>
            <div className="">
              <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                <div className="w-1/2 px-3.5">Label</div>
              </div>
              <ul>
                {tags
                  .map((tag) => (
                    <TagListItem
                      key={tag._id}
                      tag={tag}
                      onUpdate={updateTagMutation}
                      onDelete={deleteTagMutation}
                    />
                  ))}
                {!isAdding ? (
                  <li
                    className="border-t border-border py-1 cursor-pointer hover:bg-gray-100"
                    onClick={() => setIsAdding(true)}
                  >
                    <div className="flex items-center gap-2 py-1.5 px-3 rounded-md">
                      <Plus className="w-4 h-4" />
                      <span className="text-sm text-muted">Add Tag</span>
                    </div>
                  </li>
                ) : (
                  <li className="py-1">
                    <form onSubmit={handleSubmit} className="flex items-center gap-2">
                      <Input
                        type="text"
                        rootClass="w-full"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter tag label"
                        className="py-1 px-2 border rounded-md flex-grow"
                      />
                      <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        type="submit"
                      >
                        <Check className="h-4 w-4 text-green-500" />
                      </Button>
                      <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={cancelAdd}
                      >
                        <X className="h-4 w-4 text-red-500" />
                      </Button>
                    </form>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>

    )

  );
};

export default Tags;
