import React from 'react'
import IconCelebration from '../../common/svgIcons/IconCelebration'
import IconAroplan from '../../common/svgIcons/IconAroplan'
import moment from 'moment'

const PendingLeave = ({ leaveData }) => {
    return (
        <div className="col-span-5 bg-white p-4 border border-secondary rounded-md">
            {
                !leaveData?.length ?
                    <div className="flex items-center gap-3">
                        <div className="bg-primary/10 flex items-center justify-center p-3 rounded-full">
                            <IconCelebration className='h-5 w-5 text-primary' />
                        </div>
                        <div className="">
                            <div className=""></div>
                            <h3 className="text-sm" >Huuary! No pending leave requests</h3>
                            <p className="text-sm text-gray-600">Request leave on the right!</p>
                        </div>
                    </div> :
                    leaveData.map((leave) => (
                        <div className="flex items-start gap-4 border-b last:border-0 border-secondary py-2.5 last:pb-0 first:pt-0">
                            <div className="h-10 w-10 rounded-full flex items-center justify-center bg-primary"><IconAroplan className='h-5 w-5 text-white' /></div>
                            <div className="grid grid-cols-4 gap-2 flex-grow">
                                <div className="">
                                    <label className='text-sm text-gray-500 '>Past Leave</label>
                                    <p className='text-sm font-medium text-dark flex items-center gap-1 flex-wrap'>
                                        {
                                            leave?.leaveDays?.length < 2 ?
                                                moment(leave?.leaveDays[0]?.date).format('MMM DD, YYYY') :
                                                moment(leave?.leaveDays[0]?.date).format('MMM DD, YYYY') + ' - ' + moment(leave?.leaveDays[leave?.leaveDays?.length - 1]?.date).format('MMM DD, YYYY')
                                        }
                                        <span>({leave?.totalDays} {leave?.totalDays ===1 ? 'Day' : 'Days'})</span></p>
                                </div>
                                <div className="">
                                    <label className='text-sm text-gray-500 '>Leave Type</label>
                                    <p className='text-sm font-medium text-dark'>{leave?.type}</p>
                                </div>
                                <div className="">
                                    <label className='text-sm text-gray-500 '>Requested On</label>
                                    <p className='text-sm font-medium text-dark'>{moment(leave?.appliedAt).format('MMM DD, YYYY')}</p>
                                </div>
                                <div className="">
                                    <label className='text-sm text-gray-500 '>Status</label>
                                    <p className='text-sm font-medium text-dark'>{leave?.status}</p>
                                </div>
                                <div className="col-span-full flex gap-1.5">
                                    <label className='text-sm text-dark shrink-0'>leave Note: </label>
                                    <p className='text-sm font-medium text-gray-500 break-all'>{leave?.reason}</p>
                                </div>
                            </div>
                        </div>
                    ))
            }
        </div>
    )
}

export default PendingLeave