import React, { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle, Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { Calendar, CheckIcon, ChevronDownIcon, Infinity, X } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../../common/ui/Button";
import ButtonLoader from "../../common/ui/ButtonLoader";
import moment from "moment";
import IconTime from "../../common/svgIcons/IconTime";
import DateItem from "../leave/DateItem";
import API from "../../services/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";

const leaveTypes = ["Paid", "Unpaid", "Floater", "CompsOff"];

const ApplyLeaveModel = ({ isOpen, setIsOpen, loader, userData }) => {

    const location = useLocation();

    const userId = location?.state?.userId;

    const queryClient = useQueryClient();
    const { control, register, handleSubmit, reset, formState: { errors }, clearErrors } = useForm({
        defaultValues: {
            leaveType: "",
            reason: "",
            leaveDateRange: [null, null],
        },
    });
    const { user } = useAuth();
    const [status, setStatus] = useState('Fullday')
    const [leaveType, setLeaveType] = useState("");
    const [loading, setLoading] = useState(false);
    const [leaveData, setLeaveData] = useState([]);
    const [startsDate, setStartsDate] = useState();
    const [endsDate, setEndsDate] = useState();
    const [daysDifference, setDaysDifference] = useState(0);
    const [datesRange, setDatesRange] = useState([])

    const startDateChange = (date) => {
        const formattedDate = moment(date).format("DD MMM, yyyy");
        setStartsDate(formattedDate);
        if (endsDate) {
            calculateDaysDifference(date, endsDate);
        }
        if (endsDate && moment(date).isAfter(moment(endsDate))) {
            const adjustedEndDate = moment(date).format("DD MMM, yyyy");
            setEndsDate(adjustedEndDate);
            calculateDaysDifference(formattedDate, adjustedEndDate);
        }
    };

    const endDateChange = (date) => {
        const formattedDate = moment(date).format("DD MMM, yyyy");
        setEndsDate(formattedDate);

        if (startsDate) {
            calculateDaysDifference(startsDate, date);
        }
    };

    const calculateDaysDifference = (start, end) => {
        const startMoment = moment(start, "DD MMM, yyyy");
        const endMoment = moment(end, "DD MMM, yyyy");
    
        const dates = [];
        const leaveData = [];
        let currentDate = startMoment.clone();
        let workDaysCount = 0;
    
        while (currentDate.isSameOrBefore(endMoment)) {
            const dayOfWeek = currentDate.day();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                workDaysCount += 1
                dates.push(currentDate.format("YYYY-MM-DD"));
                leaveData.push({ date: currentDate.format("YYYY-MM-DD"), leaveType: "Full Day" });
            }
            currentDate.add(1, "days");
        }
        setDaysDifference(workDaysCount);
        setLeaveData(leaveData);
        setDatesRange(dates);
    };
    const { mutate: applyLeaveMutation } = useMutation({
        mutationFn: async (data) => {
            setLoading(true)
            const response = await API.post("/leaves/apply", { type: leaveType, reason: data.reason, leaveDays: leaveData, userId })
            return response.data;
        },
        onSuccess: () => {
            setIsOpen(false)
            queryClient.invalidateQueries({ queryKey: ['leave'] });
            queryClient.invalidateQueries({ queryKey: ['userLeave'] });
            queryClient.invalidateQueries({ queryKey: ['monthLeaveData'] });
            queryClient.invalidateQueries({ queryKey: ['weakLeaveData'] });
            queryClient.invalidateQueries({ queryKey: ["pendingLeaves", user?._id] });
            queryClient.invalidateQueries({ queryKey: ["typeSummary", user?._id] });
            queryClient.invalidateQueries({ queryKey: ['userProfile', user?._id] });
            setLeaveData([]);
            setLeaveType("");
            setDatesRange([]);
            setStartsDate("")
            setEndsDate("")
            setDaysDifference(0)
            toast.success('Leave Applied successfully');
            setLoading(false)
            reset({
                leaveType: "",
                reason: "",
                leaveDateRange: [null, null],
            });
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to Apply Leave');
            setLoading(false)
        }
    });
    const resetForm = () => {
        reset({
            leaveType: "",
            reason: "",
            leaveDateRange: [null, null],
        })
        setLeaveData([]);
        setLeaveType("");
        setDatesRange([]);
        setStartsDate("");
        setEndsDate("");
        setDaysDifference(0);
        setIsOpen(false);
    }


    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => {
            setIsOpen(false)
            resetForm()
        }}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl">
                        <DialogTitle as="h3" className="text-base rounded-t-xl font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border">
                            <p className="text-xl font-bold py-1 px-1">Apply Leave</p>
                            <span className="cursor-pointer" onClick={() => {
                                setIsOpen(false)
                                resetForm()
                            }}>
                                <X className="text-dark/50" />
                            </span>
                        </DialogTitle>

                        <div>
                            <form onSubmit={handleSubmit(applyLeaveMutation)}>
                                <div className="p-8 py-5 max-h-[500px] overflow-auto">
                                    <div className="w-full flex items-center justify-between bg-[#fbfbff] gap-2.5 border border-secondary rounded-lg p-2 mb-2.5">
                                        <div className="flex items-start flex-col">
                                            <lable className="text-sm text-gray-500">From</lable>
                                            <DatePicker
                                                value={startsDate}
                                                selected={startsDate}
                                                onKeyDown={(e) => {
                                                    e.preventDefault(); // Prevent manual input
                                                }}
                                                onChange={startDateChange}
                                                placeholderText="Select Date"
                                                dateFormat="dd MMM yyyy"
                                                minDate={moment(Date.now()).toDate()}
                                                className="w-full text-sm"
                                                filterDate={(date) => {
                                                    const day = date.getDay();
                                                    const isSaturday = day === 6;
                                                    const isSunday = day === 0;

                                                    if (isSaturday) {
                                                        // Allow the fifth Saturday
                                                        const monthStart = moment(date).startOf('month');
                                                        const saturdaysInMonth = [];
                                                        for (let d = 0; d < monthStart.daysInMonth(); d++) {
                                                            const tempDate = monthStart.clone().add(d, 'days');
                                                            if (tempDate.day() === 6) saturdaysInMonth.push(tempDate);
                                                        }
                                                        // Check if the current date matches the fifth Saturday
                                                        const formattedDate = moment(date).format('YYYY-MM-DD');
                                                        return saturdaysInMonth.length === 5 && formattedDate === saturdaysInMonth[4].format('YYYY-MM-DD');
                                                    }
                                                    return !isSunday;
                                                }}
                                            />
                                        </div>
                                        <p className="bg-[#EAECF0] text-xs py-1 px-1.5 rounded-md inline-block flex-shrink-0">{daysDifference} days</p>
                                        <div className="flex items-end flex-col">
                                            <lable className="text-sm text-gray-500">To</lable>
                                            <DatePicker
                                                value={endsDate}
                                                selected={endsDate}
                                                onKeyDown={(e) => {
                                                    e.preventDefault(); // Prevent manual input
                                                }}
                                                onChange={endDateChange}
                                                placeholderText="Select Date"
                                                dateFormat="dd MMM yyyy"
                                                minDate={startsDate ? moment(startsDate).toDate() : moment(Date.now()).toDate()}
                                                className="w-full text-end text-sm"
                                                filterDate={(date) => {
                                                    const day = date.getDay();
                                                    const isSaturday = day === 6;
                                                    const isSunday = day === 0;
                                                    if (isSaturday) {
                                                        const monthStart = moment(date).startOf('month');
                                                        const saturdaysInMonth = [];
                                                        for (let d = 0; d < monthStart.daysInMonth(); d++) {
                                                            const tempDate = monthStart.clone().add(d, 'days');
                                                            if (tempDate.day() === 6) saturdaysInMonth.push(tempDate);
                                                        }
                                                        // Check if the current date matches the fifth Saturday
                                                        const formattedDate = moment(date).format('YYYY-MM-DD');
                                                        return saturdaysInMonth.length === 5 && formattedDate === saturdaysInMonth[4].format('YYYY-MM-DD');
                                                    }
                                                    return !isSunday; 
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-2.5">
                                        <Controller
                                            name="leaveType"
                                            control={control}
                                            rules={{
                                                required: 'Please select a leave type.'
                                            }}
                                            render={({ field }) => (
                                                <Listbox onChange={(value) => { field.onChange(value); setLeaveType(value); clearErrors('leaveType'); }}>
                                                    <div className="relative">
                                                        <ListboxButton
                                                            className={clsx(
                                                                "relative block w-full rounded-lg bg-[#FBFBFF] text-left text-sm border py-3.5 px-4 focus:outline-none",
                                                                errors.leaveType && "border-red-500"
                                                            )}
                                                        >
                                                            {leaveType || <span className="text-gray-400">Select Leave Type</span>}
                                                            <span className="absolute right-3 top-1/2 -translate-y-1/2">
                                                                <ChevronDownIcon className="h-4 w-4" />
                                                            </span>
                                                        </ListboxButton>
                                                        <ListboxOptions
                                                            anchor="bottom"
                                                            transition
                                                            className="absolute z-10 w-[380px] mt-1 rounded-lg bg-white shadow-lg border border-gray-200 max-h-40 overflow-auto p-1.5"
                                                        >
                                                            {leaveTypes.map((type) => {
                                                                const isDisabled =
                                                                    (type === "Paid" && daysDifference > (userData?.leaveBalance || 0)) ||
                                                                    (type === "Floater" && daysDifference > (userData?.floaterLeave || 0)) ||
                                                                    (type === "CompsOff" && daysDifference > (userData?.compOffs || 0));

                                                                const availableBalance =
                                                                    type === "Paid"
                                                                        ? userData?.leaveBalance || 0
                                                                        : type === "Floater"
                                                                            ? userData?.floaterLeave || 0
                                                                            : type === "CompsOff"
                                                                                ? userData?.compOffs || 0
                                                                                : <Infinity className="h-4 w-4 mr-1" />

                                                                return (
                                                                    <ListboxOption
                                                                        key={type}
                                                                        value={type}
                                                                        disabled={isDisabled}
                                                                        className={clsx(
                                                                            "group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5",
                                                                            isDisabled && "opacity-50 cursor-not-allowed"
                                                                        )}
                                                                    >
                                                                        <div className="flex items-center gap-1.5 w-full justify-between">
                                                                            <div className="text-xs font-medium">{type}</div>
                                                                            <div className="flex text-xs font-medium text-gray-500">
                                                                                {availableBalance}{" "}
                                                                                {type === "Unpaid" ? " infinite balance" : "days available"}
                                                                            </div>
                                                                        </div>
                                                                        <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                                                    </ListboxOption>
                                                                );
                                                            })}
                                                        </ListboxOptions>
                                                    </div>
                                                </Listbox>
                                            )}
                                        />
                                        {errors.leaveType && (
                                            <p className="text-xs text-red-500 mt-1">{errors.leaveType.message}</p>
                                        )}
                                    </div>
                                    {
                                        daysDifference > 0 &&
                                        <div className="mb-2.5">
                                            <div className="bg-[#EAECF0] rounded-md inline-flex gap-1 p-0.5">
                                                <Button type='button' className={`p-1 rounded-md text-xs h-auto text-gray-500 shadow-none hover:bg-white ${status === 'Fullday' ? 'bg-white' : 'bg-transparent'}`} onClick={() => setStatus('Fullday')}>Full days</Button>
                                                <Button type='button' className={`p-1 rounded-md text-xs h-auto text-gray-500 shadow-none hover:bg-white ${status === 'Custom' ? 'bg-white' : 'bg-transparent'}`} onClick={() => setStatus('Custom')}>Custom</Button>
                                            </div>
                                            <div className="mt-1.5">
                                                {
                                                    status === 'Custom' &&
                                                    <ul className="border border-secondary bg-[#fbfbff] rounded-md py-1.5 flex flex-col gap-1">
                                                        <DateItem date={datesRange} setLeaveData={setLeaveData} setDaysDifference={setDaysDifference} />
                                                    </ul>
                                                }
                                                <div className="flex items-center gap-1 pt-1.5">
                                                    <span><IconTime className='h-5 w-5 text-gray-500' /></span>
                                                    <p className="text-sm text-gray-500">You are requesting for {daysDifference} days of leave</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="mb-3">
                                        <textarea
                                            rows={3}
                                            placeholder="Reason for Leave"
                                            className={clsx(
                                                "block w-full rounded-md border px-4 py-3.5 text-sm bg-[#FBFBFF] focus:outline-none",
                                                errors.reason && "border-red-500"
                                            )}
                                            {...register("reason", {
                                                required: "Reason is required", minLength: {
                                                    value: 10,
                                                    message: "Reason must be at least 10 characters long",
                                                },
                                                maxLength: {
                                                    value: 250,
                                                    message: "Reason cannot exceed 250 characters",
                                                },
                                            })}
                                        />
                                        {errors.reason && <p className="text-red-500 text-xs mt-1">{errors.reason.message}</p>}
                                    </div>
                                </div>

                                {/* Action Buttons */}
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button
                                        variant="outline"
                                        type="button"
                                        onClick={() => {
                                            resetForm()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit">
                                        {loading ? <ButtonLoader /> : "Apply Leave"}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default ApplyLeaveModel;
