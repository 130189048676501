import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import TodayLeave from "../../common/TodayLeave";
import NotInToday from "./NotInToday";
import ArrivalsCount from "./ArrivalsCount";
import EmployeeCard from "./EmployeeCard";
import { useAuth } from "../../context/AuthContext";
import { employeeData } from "../../staticData/data";
import TeamCalendar from "./TeamCalendar";
import Loader from "../../common/ui/Loader";

const teamMembers = [
  { name: "Aditya Rajput", initials: "AR", color: "bg-teal-500", dates: [4, 5, 17] },
  { name: "Ashishkumar Singh", initials: "AS", color: "bg-blue-500", dates: [5, 6, 14, 15] },
  { name: "Bhavin Sangani", initials: "BS", color: "bg-orange-500", dates: [7, 11, 14] },
  { name: "Diya Patel", initials: "DP", color: "bg-pink-500", dates: [3, 5, 8, 10] },
  { name: "Gaurav Shingala", initials: "GS", color: "bg-gray-700", dates: [4, 5, 7] },
  { name: "Sagar Kachariya", initials: "GS", color: "bg-gray-700", dates: [10, 13, 14, 15,  16, 17, 18, 19, 20] },
];
const weeklyOffDays = [0, 6];

const SummaryDetail = () => {
  const [loader, setLoader] = useState(true);
  
  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentYear, setCurrentYear] = useState(2025);
  const [userData, setUserdata] = useState();
  const fetchLateArrivalsList = async ({ }) => {
    try {
      const { data } = await API.get(`/timer/attendance/late-arrivals`);
      return data;
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false)
    }
  };
  const fetchUsers = async () => {
    try {
      await API.get(`/department/users/list`).then((res) => {
        setUserdata(res.data?.data);
      })

    } catch (err) {
      console.error(err.message);
    } finally {
      setLoader(false)
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const { data: LateArrivals } = useQuery({
    queryKey: ["late-arrival"],
    queryFn: fetchLateArrivalsList,
    keepPreviousData: true, // Retain previous data while fetching new data
  });

  const fetchOnTimeList = async ({ }) => {
    try {
      const { data } = await API.get(`/timer/attendance/on-time`);
      return data;
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
      setLoader(false)
    }
  };

  const { data: OnTimeArrivals } = useQuery({
    queryKey: ["on-time"],
    queryFn: fetchOnTimeList,
    keepPreviousData: true, // Retain previous data while fetching new data
  });

  const LeaveData = async ({ }) => {
    try {
      const { data, holidays } = await API.get(`/leaves/attendance/calendar?month=${currentMonth + 1}&year=${currentYear}`);
      return data;
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
    }
  };

  const { data: leaveList } = useQuery({
    queryKey: ["leaveList", {currentMonth: currentMonth}],
    queryFn: LeaveData,
    keepPreviousData: true,
  });

  const filterLeaveData = leaveList?.data?.filter((data) => data?.calendar?.length > 0);

  const perfectData = (input_data) => {
    return {
      "userId": input_data?.userId,
      "name": input_data?.name,
      "dates": input_data?.calendar?.map(entry => ({
        "date": entry?.date,
        "status": entry?.status,
        "leaveType": entry?.leaveType
      }))
    };
  };

  const formattedResults = filterLeaveData?.map(perfectData);  

  return (
    <div className="bg-[#F9FAFB] rounded-lg border border-secondary 2xl:p-5 p-3 h-full overflow-auto">
      {loader ?
        <div className="h-full flex items-center justify-center"><Loader /></div>
        :
        <>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6 bg-white border border-secondary rounded-lg p-3">
              <TodayLeave title="Who is off today" />
            </div>
            <div className="col-span-6 bg-white border border-secondary rounded-lg overflow-auto">
              <NotInToday title="Not in yet today" />
            </div>
            <div className="col-span-3 bg-white border border-secondary rounded-lg p-3">
              <ArrivalsCount title="Employees On Time today" count={OnTimeArrivals?.count || 0} data={OnTimeArrivals?.data} />
            </div>
            <div className="col-span-3 bg-white border border-secondary rounded-lg p-3">
              <ArrivalsCount title="Late Arrivals today" count={LateArrivals?.count || 0} data={LateArrivals?.data} />
            </div>
          </div>
          <div className="pt-5">
            <p className="text-lg pb-2.5">Team calendar</p>
            <TeamCalendar userData={formattedResults} teamMembers={teamMembers} currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} currentYear={currentYear} setCurrentYear={setCurrentYear} weeklyOffDays={weeklyOffDays} holidays={leaveList?.holidays} />
          </div>
          <div className="pt-5">
            <p className="text-lg pb-2.5">Peers ( {userData?.length} )</p>
            <div className="grid grid-cols-4 gap-4">
              {userData?.map((employee) => (
                <EmployeeCard employee={employee} />
              ))}
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default SummaryDetail;
