import React from 'react'

const IconAroplan = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M18.7498 10.625V12.5H4.77014C4.23254 12.5 3.70926 12.3267 3.27792 12.0059C2.84658 11.685 2.53016 11.2336 2.37561 10.7188L1.27639 7.05469C1.24838 6.96137 1.24257 6.86281 1.25943 6.76685C1.27629 6.67089 1.31535 6.58021 1.37349 6.50203C1.43162 6.42385 1.50724 6.36035 1.59428 6.31659C1.68133 6.27283 1.7774 6.25003 1.87482 6.25H2.49982L4.37482 8.125H7.25842L6.28264 5.19766C6.25134 5.10376 6.24278 5.00378 6.25768 4.90594C6.27257 4.80809 6.31049 4.71518 6.36831 4.63486C6.42614 4.55454 6.50221 4.48909 6.59027 4.44391C6.67832 4.39874 6.77585 4.37512 6.87482 4.375H7.49982L11.2498 8.125H16.2498C16.9129 8.125 17.5487 8.38839 18.0176 8.85723C18.4864 9.32607 18.7498 9.96196 18.7498 10.625Z" fill="currentColor" />
            <path d="M17.4998 16.875C17.4998 17.0408 17.434 17.1997 17.3168 17.3169C17.1996 17.4342 17.0406 17.5 16.8748 17.5H5.62484C5.45908 17.5 5.30011 17.4342 5.1829 17.3169C5.06569 17.1997 4.99984 17.0408 4.99984 16.875C4.99984 16.7092 5.06569 16.5503 5.1829 16.4331C5.30011 16.3158 5.45908 16.25 5.62484 16.25H16.8748C17.0406 16.25 17.1996 16.3158 17.3168 16.4331C17.434 16.5503 17.4998 16.7092 17.4998 16.875ZM19.3748 10.625V12.5C19.3748 12.6658 19.309 12.8247 19.1918 12.9419C19.0746 13.0592 18.9156 13.125 18.7498 13.125H4.77094C4.09811 13.1281 3.44247 12.9126 2.9027 12.5109C2.36293 12.1092 1.96822 11.543 1.77797 10.8977L0.678751 7.23438C0.62275 7.04781 0.611124 6.85074 0.6448 6.65889C0.678476 6.46704 0.756522 6.28571 0.872713 6.12937C0.988904 5.97303 1.14002 5.84601 1.31402 5.75844C1.48801 5.67087 1.68006 5.62518 1.87484 5.625H2.49984C2.66538 5.62508 2.82413 5.69082 2.94125 5.80781L4.63422 7.5H6.39125L5.68813 5.39531C5.62546 5.20734 5.60838 5.00717 5.6383 4.8113C5.66821 4.61542 5.74426 4.42947 5.86017 4.26877C5.97607 4.10807 6.12853 3.97722 6.30495 3.88703C6.48137 3.79683 6.6767 3.74986 6.87484 3.75H7.49984C7.58194 3.74994 7.66325 3.76605 7.73913 3.79741C7.815 3.82878 7.88395 3.87479 7.94203 3.93281L11.5092 7.5H16.2498C17.0786 7.5 17.8735 7.82924 18.4596 8.41529C19.0456 9.00134 19.3748 9.7962 19.3748 10.625ZM18.1248 10.625C18.1248 10.1277 17.9273 9.65081 17.5757 9.29918C17.224 8.94754 16.7471 8.75 16.2498 8.75H11.2498C11.0843 8.74992 10.9256 8.68418 10.8084 8.56719L7.24125 5H6.87484L7.85063 7.92734C7.88193 8.02124 7.89048 8.12122 7.87558 8.21906C7.86069 8.31691 7.82277 8.40982 7.76495 8.49014C7.70713 8.57046 7.63106 8.63591 7.543 8.68109C7.45494 8.72626 7.35741 8.74988 7.25844 8.75H4.37484C4.29274 8.75007 4.21144 8.73395 4.13556 8.70259C4.05969 8.67122 3.99074 8.62521 3.93266 8.56719L2.24125 6.875H1.87484L2.97406 10.5391C3.08835 10.9264 3.32538 11.2661 3.64945 11.507C3.97353 11.748 4.36711 11.8771 4.77094 11.875H18.1248V10.625Z" fill="currentColor" />
        </svg>

    )
}

export default IconAroplan