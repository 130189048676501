import React, { useState } from 'react';
import { Check, Pencil, Trash2 } from 'lucide-react';
import { Button } from './Button';
import { Input } from '../Input';
import DeleteModal from '../../components/modal/DeleteModal';
import { getColorFromName } from '../../lib/utils';
import Tooltip from './Tooltip';

const DepartmentList = ({ dept, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(dept?.name);
  const [deleteOpen, setDeleteOpen] = useState(false);


  const handleEdit = () => setIsEditing(true);

  const handleSave = () => {
    if (newName.trim() === dept?.name.trim()) {
      setIsEditing(false);
      return;
    }
    if (newName && newName?.trim !== "") {
      onUpdate({ id: dept?._id, name: newName });
    }
    setNewName(dept?.name)
    setIsEditing(false);

  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const handleDelete = () => {
    onDelete(dept?._id);
  };

  return (
    <li className="border-b border-border py-1 px-3">
      <div className="flex items-center justify-between">
        <div>
          {isEditing ? (
            <Input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onKeyDown={handleKeyPress}
              className="py-1 px-2 border text-xs border-primary rounded-md"
            />
          ) : (
            <div className="flex items-center gap-2">
              <span className='w-4 h-4 rounded-full' style={{ backgroundColor: getColorFromName(dept?.name.slice(0, 2)) }}></span>
              <span
                className="inline-flex text-[13px] rounded-md text-muted  font-medium capitalize"
              >
                {dept?.name}
              </span>
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {isEditing ? (
            <Button
              className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
              onClick={handleSave}
            >
              <Check className="h-4 w-4 text-green-500" />
            </Button>
          ) : (
            <Button
              className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
              onClick={handleEdit}
            >
              <Tooltip detailClass='top-8 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Edit' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                <div className="flex items-center rounded-full text-sm">
                  <Pencil className="h-4 w-4" />
                </div>
              </Tooltip>

            </Button>
          )}
          <Button
            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
            onClick={() => setDeleteOpen(true)}
          >
            <Tooltip detailClass='top-8 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Delete' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
              <div className="flex items-center rounded-full text-sm">
                <Trash2 className="h-4 w-4 text-red-500" />
              </div>
            </Tooltip>
          </Button>
        </div>
      </div>
      <DeleteModal
        title={'Are you sure you want to delete this dept?'}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleDeleteTask={handleDelete}
      />
    </li>
  );
};

export default DepartmentList;
