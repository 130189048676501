import React, { useEffect } from 'react'
import { useState } from 'react';   
import { QueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import API from '../../services/api';
import DeleteModal from '../modal/DeleteModal';
import { Button } from '@headlessui/react';
import { Pencil, Trash, Trash2 } from 'lucide-react';
import EditUserModal from '../modal/EditUserModal';
import Tooltip from '../../common/ui/Tooltip';
import moment from 'moment';
import EditHolidayModal from '../modal/EditHolidayModal';

const HolidayListItem = ({ holidayData, fetchHolidays, newHoliday, setNewHoliday }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [selectedHoliday, setSelectedHoliday] = useState();
    const [loader, setLoader] = useState(false);

    const { mutate: deleteHolidayMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.delete(`/holiday/${holidayData?._id}`);
            return response.data;
        },
        onSuccess: () => {
            toast.success('Holiday deleted successfully');
            setDeleteOpen(false)
            fetchHolidays();
        },
        onError: (error) => {
            console.error('Holiday deletion failed:', error);
            toast.error('Failed to delete Holiday');
            setDeleteOpen(false)
        },
    });

    const handleEdit = async (e) => {
        e.preventDefault();
        setLoader(true)
        try {
            await API.put(`/holiday/${holidayData._id}`, newHoliday);
            setShowAddEditModal(false);
            fetchHolidays();
            toast.success('User updated successfully');
        } catch (err) {
            // console.error(err.message);
            toast.error(err.response.data.message);
        } finally {
            setLoader(false)
        }
    }


    return (
        <>
            <div className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-xs hover:bg-primary/5">
                <div className="w-1/2 flex gap-2 items-center border-e border-border p-1" >
                    <div className="flex items-center gap-2 ml-4">
                        {holidayData?.name}
                    </div>
                </div>
                <div className="w-[30%] flex items-center font-medium border-e border-border">
                    {moment(holidayData?.date).format('DD-MM-YYYY')}
                </div>

                <div className="w-[15%] flex items-center gap-2">
                    <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={(e) => {
                            setShowAddEditModal(true);
                            setSelectedHoliday(holidayData);
                        }}
                    >
                        <Tooltip detailClass='top-8 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Edit' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                            <div className="flex items-center rounded-full text-sm">
                                <Pencil className="h-4 w-4" />
                            </div>
                        </Tooltip>

                    </Button>
                    <Button
                        className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                        onClick={(e) => {
                            setDeleteOpen(true);
                        }}
                    >
                        <Tooltip detailClass='top-8 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Delete' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                            <div className="flex items-center rounded-full text-sm">
                                <Trash2 className='h-4 w-4 text-red-500' />
                            </div>
                        </Tooltip>
                    </Button>
                </div>
            </div>
            <EditHolidayModal isOpen={showAddEditModal} setIsOpen={setShowAddEditModal} newHoliday={newHoliday} setNewHoliday={setNewHoliday} holidayData={selectedHoliday} setSelectedHoliday={setSelectedHoliday} handleSubmit={handleEdit} loader={loader} />
            <DeleteModal title={'Are you sure you want to delete this holiday?'} open={deleteOpen} setOpen={setDeleteOpen} id={holidayData?._id} handleDeleteTask={deleteHolidayMutation} />
        </>
    )
}

export default HolidayListItem