import React from "react";

const IconArrowExit = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M16.875 10L13.75 13.125V6.875L16.875 10Z"
        fill="currentColor"
      />
      <path
        d="M8.75 16.875C8.75 17.0408 8.68415 17.1997 8.56694 17.3169C8.44973 17.4342 8.29076 17.5 8.125 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H8.125C8.29076 2.5 8.44973 2.56585 8.56694 2.68306C8.68415 2.80027 8.75 2.95924 8.75 3.125C8.75 3.29076 8.68415 3.44973 8.56694 3.56694C8.44973 3.68415 8.29076 3.75 8.125 3.75H3.75V16.25H8.125C8.29076 16.25 8.44973 16.3158 8.56694 16.4331C8.68415 16.5503 8.75 16.7092 8.75 16.875ZM17.3172 10.4422L14.1922 13.5672C14.1048 13.6547 13.9934 13.7143 13.8721 13.7385C13.7508 13.7626 13.625 13.7502 13.5108 13.7029C13.3965 13.6555 13.2988 13.5754 13.2302 13.4725C13.1615 13.3696 13.1249 13.2487 13.125 13.125V10.625H8.125C7.95924 10.625 7.80027 10.5592 7.68306 10.4419C7.56585 10.3247 7.5 10.1658 7.5 10C7.5 9.83424 7.56585 9.67527 7.68306 9.55806C7.80027 9.44085 7.95924 9.375 8.125 9.375H13.125V6.875C13.1249 6.75132 13.1615 6.63038 13.2302 6.52751C13.2988 6.42464 13.3965 6.34446 13.5108 6.29711C13.625 6.24977 13.7508 6.23739 13.8721 6.26154C13.9934 6.2857 14.1048 6.34531 14.1922 6.43281L17.3172 9.55781C17.3753 9.61586 17.4214 9.68479 17.4529 9.76066C17.4843 9.83654 17.5005 9.91787 17.5005 10C17.5005 10.0821 17.4843 10.1635 17.4529 10.2393C17.4214 10.3152 17.3753 10.3841 17.3172 10.4422ZM15.9891 10L14.375 8.38359V11.6164L15.9891 10Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconArrowExit;
