import React, { useState, useEffect } from "react";
import { Button } from "../../common/ui/Button";
import axios from "axios";
import Buttonloader from "../../common/ui/ButtonLoader";
import Loader from "../../common/ui/Loader";
import API from "../../services/api";
import Editor from "../editor/editor";

const KickOffSection = ({
  fetchProjectInfo,
  projectId,
  projectInfo,
  isLoading,
}) => {
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState(projectInfo?.kickoff || "");
  const [userInput, setUserInput] = useState("");
  const [showInput, setShowInput] = useState(false)
  const [savingStatus, setSavingStatus] = useState("Unsaved");

  const url = process.env.REACT_APP_FASTAPI_URL;

  useEffect(() => {
    if (projectInfo?.kickoff) {
      setContent(projectInfo.kickoff);
      setSavingStatus("Saved");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!content) return;

    if (content === projectInfo?.kickoff) {
      setSavingStatus("Saved"); 
      return;
    }
    setSavingStatus("Unsaved");
    const debounceTimeout = setTimeout(async () => {
      try {
        const payload = { kickoff: content };
        await UpdateKickOff(payload);
        setSavingStatus("Saved");
      } catch (error) {
        console.error("Error in Update", error);
        setSavingStatus("Error in Saved");
      }
    }, 1500);
    return () => clearTimeout(debounceTimeout);
  }, [content]);

  const UpdateKickOff = async (kickoff) => {
    try {
      await API.patch(`/project/${projectId}`, kickoff);
      fetchProjectInfo();
    } catch (error) {
      console.error("Error in Update", error);
    }
  };

  const moduleGenerate = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${url}/kickoff/`, {
        project_id: projectId,
        user_input: userInput,
      });
      setContent(data.kickoff || "");
      fetchProjectInfo();
      setLoader(false);
      setShowInput(false);
    } catch (err) {
      console.error("Error in moduleGenerate:", err.message);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 h-full">
        {(!content || showInput) && (
          <div className="border p-4 rounded-md bg-gray-50 shadow-sm">
            <h4 className="text-base font-semibold text-gray-700 mb-2">
              Enter Input for Project Kick Off
            </h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                moduleGenerate();
              }}
              className="flex items-center gap-2"
            >
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Enter input"
                className="flex-grow py-2 px-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              />
              <button
                type="submit"
                className={`p-2 text-white rounded-md ${!userInput.trim()
                    ? "bg-blue-200 cursor-not-allowed"
                    : "bg-primary"
                  }`}
                disabled={!userInput.trim()}
              >
                {loader ? <Buttonloader /> : "Generate"}
              </button>
            </form>
          </div>
        )}

        {content && !showInput && (
          <div className="flex items-center justify-between">
            <h3 className="text-primary font-semibold text-lg">Project KickOff</h3>
            <Button
              onClick={() => {
                setShowInput(true);
                setUserInput("");
              }}
              disabled={loader}
            >
              {loader ? <Buttonloader /> : "Regenerate"}
            </Button>
          </div>
        )}

        {isLoading ? (
          <Loader />
        ) : (
          content && (
            <div className="body rounded-md border border-gray-100 bg-gray-50 h-[calc(100vh-200px)] relative overflow-y-auto p-4">
              <div className="sticky top-0 right-0 flex items-center justify-end z-10">
                <div className="p-1.5 px-4 bg-primary/20 text-xs font-semibold rounded-md inline-block shadow">
                  {savingStatus}
                </div>
              </div>
              <div className="mt-8">
                {content ? (
                  <Editor key={content} initialValue={content ?? ""} onChange={setContent} />
                ) : (
                  <Button onClick={() => moduleGenerate()} disabled={loader}>
                    {loader ? <Buttonloader /> : "Generate"}
                  </Button>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );

};

export default KickOffSection;
