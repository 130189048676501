import React, { useEffect, useState } from 'react';
import LabelChart from './LabelChart';
import TimeSheetChart from './TimeSheetChart';
import LiveTimer from '../../common/LiveTimer';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import AssigneePopover from '../../common/ui/AssigneePopover';
import { getColorFromName } from '../../lib/utils';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../common/ui/Button';
import ConfirmationModal from '../modal/ConfirmationModal';
import { toast } from 'react-toastify';
import Tooltip from '../../common/ui/Tooltip';
import CurrentTimmer from '../../common/ui/CurrentTimmer';
import Holiday from './Holiday';
import BirthdayAndWorkAnniversaries from './BirthdayAndWorkAnniversaries';
import ApplyLeaveModel from '../modal/ApplyLeaveModel';
import TodayLeave from '../../common/TodayLeave';

const SecondWelcomeBanner = () => {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const [modelOpen, setModelOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();

    const handleAttendanceAction = async (isCheckIn) => {
        try {
            const endpoint = isCheckIn ? "/timer/check-in" : "/timer/check-out";
            const response = await API.post(endpoint);

            if (response?.data) {
                fetchAttendanceInfo();
                queryClient.invalidateQueries(['attendance']);
                queryClient.invalidateQueries(['Check-info']);
            }
        } catch (error) {
            console.error(`Error during ${isCheckIn ? "check-in" : "check-out"}:`, error);
            toast.error(error?.response?.data?.message || `Error during ${isCheckIn ? "check-in" : "check-out"}`)
        }
    };

    const fetchWeeklyTask = async () => {
        try {
            const { data } = await API.get(`/auth/users/${user?._id}/weekly-tasks`);
            return data;
        } catch (err) {
            console.error(err.message);
        }
    };

    const fetchTodayLeaveList = async () => {
        try {
            const { data, loading } = await API.get(`/leaves/today`);
            return data?.data;
        } catch (err) {
            console.error(err.message);
        }
    };

    const { data: todayLeave } = useQuery({
        queryKey: ['todays-leave'], queryFn: fetchTodayLeaveList
    });

    useEffect(() => {
        fetchAttendanceInfo();
    }, []);

    const fetchAttendanceInfo = async () => {
        try {
            const response = await API.get("/timer/clock-in-info");
            if (response.data?.data) {
                const todayRecords = response.data?.data;
                const lastRecord = todayRecords[todayRecords.length - 1];
                setIsCheckedIn(lastRecord && !lastRecord.checkOutTime);
            }
        } catch (error) {
            console.error("Error fetching attendance info:", error);
        }
    };
    const { data: info } = useQuery({
        queryKey: ['todays-checkIn'], queryFn: fetchAttendanceInfo
    });

    const handleAttandance = async () => {
        handleAttendanceAction(!isCheckedIn)
    }
    const GraphPlaceholderPie = ({ data }) => {
        const labelColorMapping = {
            Available: '#98B433',
        };

        const [hasData, setHasData] = useState(false);
        const [series, setSeries] = useState([]);
        const [options, setOptions] = useState({
            chart: {
                width: 120,
                type: "donut",
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: false
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: "85%",
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: "",
                                fontSize: "16px", 
                                fontWeight: "medium",
                                color: "#1F2937",
                                formatter: function() {
                                    return data;
                                },
                            },
                            value: {
                                offsetY: 0,
                                fontSize: '22px'
                            }
                        },
                    },
                },
            },
            labels: [],
            states: {
                hover: {
                    filter: {
                        type: "none", // Disable hover effect
                    },
                    borderWidth: 0,
                    borderColor: "transparent",
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 120,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        });

        // Inside useEffect where you're setting the options:
        useEffect(() => {
            if (data) {
                let mappedSeries = [];
                let mappedColors = [];
                let totalCount = 0;

                if (data > 0) {
                    mappedSeries = [data];
                    mappedColors = [labelColorMapping["Available"]];
                    totalCount = data;
                }

                setHasData(totalCount > 0);
                setSeries(mappedSeries);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    colors: mappedColors,
                }));
            }
        }, [data]);


        return (
            <div className="flex items-center justify-center">
                {hasData ? (
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        width={120}
                    />
                ) : (
                    <div className="h-full flex items-end">
                        <p className="text-center text-gray-500">No leave data available.</p>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div>
            <div className="task-list-wrapper pt-7">
                <div className="grid md:grid-cols-12 gap-4">
                    <CurrentTimmer setModelOpen={setModelOpen} isCheckedIn={isCheckedIn} />
                    <div className="2xl:col-span-4 md:col-span-6 col-span-1 bg-white p-4 rounded-lg border border-secondary">
                        {/* <div className="flex items-center justify-between">
                            <h2 className='text-base'>On Leave Today</h2>
                            {todayLeave?.length > 8 && <p>View All</p>}
                        </div>
                        <div className="time mt-7">
                            {todayLeave?.length > 0 ? (
                                <div className="flex gap-3.5">
                                    {todayLeave.slice(0, 8).map((user, index) => (
                                        <div className="flex-col" key={index}>
                                            <Tooltip position="top" toolTipClass="first:ms-0 -ms-2.5" text={user?.name + ' - ' + user?.leaveType}>
                                                <div className="relative">
                                                    <span
                                                        className="relative font-medium uppercase h-10 w-10 p-2 text-white rounded-full flex items-center justify-center text-sm first:ms-0 -ms-2.5 hover:z-[9] border border-border"
                                                        style={{ background: `${getColorFromName(user?.name)}` }}
                                                    >
                                                        {user?.name
                                                            ?.split(' ')
                                                            .slice(0, 2)
                                                            .map((name) => name.slice(0, 1))}
                                                    </span>
                                                    <div
                                                        className={`absolute -bottom-1 left-6 w-4 h-4 rounded-full border z-10 overflow-hidden bg-white after:absolute after:h-full after:bottom-0 after:bg-primary ${user?.leaveType === 'First Half'
                                                            ? 'after:w-1/2 after:left-0'
                                                            : user?.leaveType === 'Second Half'
                                                                ? 'after:w-1/2 after:right-0'
                                                                : 'after:w-full'
                                                            }`}
                                                    ></div>
                                                </div>
                                                <span className="text-sm truncate">{user?.name?.split(' ')[0]}</span>
                                            </Tooltip>
                                        </div>
                                    ))}

                                    {todayLeave.length > 8 && (
                                        <div className="flex-col">
                                            <div className="relative">
                                                <span className="relative font-medium uppercase h-10 w-10 p-2 text-dark rounded-full flex items-center justify-center text-sm first:ms-0 -ms-2.5 hover:z-[9] border border-border bg-gray-300">
                                                    +{todayLeave.length - 8}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <p>Everyone is working today!</p>
                            )}
                        </div> */}
                        <TodayLeave title="On Leave Today" />
                    </div>
                    <div className="2xl:col-span-4 md:col-span-6 col-span-1 bg-white p-4 pb-0 rounded-lg border border-secondary">
                        <h1 className='text-base'>Paid Leave Balance</h1>
                        <div className="flex justify-between">
                            <GraphPlaceholderPie data={user?.leaveBalance} />
                            <div className="flex flex-col items-center justify-center gap-y-3">
                                <button onClick={() => setIsOpen(true)} className='text-primary font-semibold'>Request Leave</button>
                                <button onClick={() => navigate('/leave')} className='text-primary font-semibold' >View All Balances</button>
                            </div>
                        </div>
                    </div>
                    <BirthdayAndWorkAnniversaries />
                    <Holiday />
                </div>
            </div>
            <ConfirmationModal
                title={`Are you sure you want to ${isCheckedIn ? 'Check Out' : 'Check In'}?`}
                open={modelOpen}
                setOpen={setModelOpen}
                handleConfirm={handleAttandance}
            />
            <ApplyLeaveModel isOpen={isOpen} setIsOpen={setIsOpen} userData={user}></ApplyLeaveModel>
        </div >
    )
}

export default SecondWelcomeBanner
