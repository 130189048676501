import React, { useEffect, useState } from 'react';
import welcomebanner from "../../../src/assets/images/welcome_banner.png";
import LabelChart from './LabelChart';
import TimeSheetChart from './TimeSheetChart';
import LiveTimer from '../../common/LiveTimer';
import { useQuery } from '@tanstack/react-query';
import API from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';

const WelcomeBanner = () => {
  const [loader, setLoader] = useState(false)

  const { user } = useAuth();

  const fetchTodaysHour = async () => {
    try {
      const { data } = await API.get('/auth/user/today-hours');
      return data
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchWeeklyTask = async () => {
    try {
      const { data } = await API.get(`/auth/users/${user?._id}/weekly-tasks`);
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchWeeklyHours = async () => {
    try {
      const { data, loading } = await API.get(`/auth/users/${user?._id}/weekly-hours`);
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data } = useQuery({
    queryKey: ['todays-hour'], queryFn: fetchTodaysHour
  });

  const { data: weeklyTaskData, isLoading } = useQuery({
    queryKey: ['weekly-task'],
    queryFn: fetchWeeklyTask,
    // queryFn: async () => {
    //   setLoader(true);
    //   const data = await fetchWeeklyTask();
    //   setLoader(false);
    //   return data;
    // },
    enabled: !!user,
  });

  const { data: weeklyHourData, isLoading: isLoadingWeeklyHours } = useQuery({
    queryKey: ['weekly-hours'],
    queryFn: fetchWeeklyHours,
    // queryFn: async () => {
    //   setLoader(true);
    //   const data = await fetchWeeklyHours();
    //   setLoader(false);
    //   return data;
    // },
    enabled: !!user,
  });

  const isActiveTimer = data?.data?.activeTimer
  const totalSeconds = data?.data?.totalSeconds
  const duration = moment.duration(totalSeconds, 'seconds');
  const formattedDuration = `${duration.hours()}h : ${duration.minutes()}m : ${duration.seconds()}s`;
  

  return (
    <div>
      <div className="task-list-wrapper">
        <div className="grid md:grid-cols-12 grid-cols-1 gap-4">
          <div className='2xl:col-span-5 md:col-span-12 col-span-1 rounded-lg bg-primary/80 text-white '>
            <div className="flex justify-between h-full banner-bg">
              <div className='3xl:p-8 p-6'>
                <h2 className='text-xl font-bold mb-2'>Welcome {user?.name} 🎉</h2>
                <p className='xl:text-base text-sm font-medium text-gray-50'>Welcome to the Elaunch Nexus, We are delighted that you have visited our dashboard.</p>
                <div className="time mt-8">
                  <h4 className='max-xl:text-sm sm:mb-2 mb-1'>Time Today</h4>
                  {
                    isActiveTimer ? (
                      <LiveTimer className="text-white 3xl:text-4xl text-3xl cursor-default whitespace-nowrap" initialElapsedSeconds={totalSeconds} />
                    ) : <h6 className={'text-white 3xl:text-4xl text-3xl font-semibold cursor-default whitespace-nowrap'}>{formattedDuration}</h6>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="2xl:col-span-4 md:col-span-6 col-span-1 bg-white p-4 pb-0 rounded-lg border border-secondary">
            <TimeSheetChart data={weeklyHourData} loading={isLoadingWeeklyHours} />
          </div>
          <div className="2xl:col-span-3 md:col-span-6 col-span-1 bg-white p-4 pb-0 rounded-lg border border-secondary">
            <div className="flex justify-center h-full">
              <LabelChart data={weeklyTaskData} loading={isLoading}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeBanner
