import React, { useState } from 'react'
import IconInfo from '../../common/svgIcons/IconInfo'
import LeaveDetails from './LeaveDetails'
import LeaveHistory from '../modal/LeaveHistory'

const PaidLeave = ({ userData , leaveData}) => {
    const [open, setOpen] = useState(false);
    const data = {
        Consumed: leaveData?.Paid?.count || 0,
        Available: userData?.leaveBalance || 0
      }
    return (
        <div className=" bg-white border border-secondary rounded-md">
            <div className="card-header p-4 pb-2 border-b border-secondary flex items-center justify-between">
                <h4 className="text-sm">Paid Leave</h4>
                <span onClick={() => setOpen(true)} className='cursor-pointer text-sm'>View Details</span>
            </div>
            <LeaveDetails showAccured={true} data={data} paid={true} userData={userData} color={'#98B433'} consumedColor={'#C9E07A'} />
            {open && <LeaveHistory isOpen={open} setIsOpen={setOpen} defaultTab={"Paid"} />}
        </div>
    )
}

export default PaidLeave