import React from 'react'

const IconTrack = (props) => {
  return (
  <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M16.875 10.625C16.875 11.9847 16.4718 13.314 15.7164 14.4445C14.9609 15.5751 13.8872 16.4563 12.631 16.9767C11.3747 17.497 9.99238 17.6332 8.65876 17.3679C7.32514 17.1026 6.10013 16.4478 5.13864 15.4864C4.17716 14.5249 3.52238 13.2999 3.2571 11.9662C2.99183 10.6326 3.12798 9.25029 3.64833 7.99405C4.16868 6.73781 5.04987 5.66408 6.18046 4.90865C7.31105 4.15321 8.64026 3.75 10 3.75C11.8234 3.75 13.5721 4.47433 14.8614 5.76364C16.1507 7.05295 16.875 8.80164 16.875 10.625Z" fill="currentColor"/>
    <path d="M10 3.125C8.51664 3.125 7.0666 3.56487 5.83323 4.38898C4.59986 5.21309 3.63856 6.38443 3.07091 7.75487C2.50325 9.12532 2.35472 10.6333 2.64411 12.0882C2.9335 13.543 3.64781 14.8794 4.6967 15.9283C5.7456 16.9772 7.08197 17.6915 8.53683 17.9809C9.99168 18.2703 11.4997 18.1218 12.8701 17.5541C14.2406 16.9864 15.4119 16.0251 16.236 14.7918C17.0601 13.5584 17.5 12.1084 17.5 10.625C17.4977 8.63658 16.7068 6.73024 15.3008 5.32421C13.8948 3.91818 11.9884 3.12727 10 3.125ZM10 16.875C8.76387 16.875 7.5555 16.5084 6.52769 15.8217C5.49988 15.1349 4.6988 14.1588 4.22576 13.0168C3.75271 11.8747 3.62894 10.6181 3.8701 9.40569C4.11125 8.19331 4.70651 7.07966 5.58059 6.20558C6.45466 5.3315 7.56831 4.73625 8.78069 4.49509C9.99307 4.25393 11.2497 4.37771 12.3918 4.85075C13.5338 5.3238 14.5099 6.12488 15.1967 7.15269C15.8834 8.18049 16.25 9.38887 16.25 10.625C16.2481 12.282 15.5891 13.8707 14.4174 15.0424C13.2457 16.2141 11.657 16.8731 10 16.875ZM7.5 1.25C7.5 1.08424 7.56585 0.925269 7.68306 0.808058C7.80027 0.690848 7.95924 0.625 8.125 0.625H11.875C12.0408 0.625 12.1997 0.690848 12.3169 0.808058C12.4342 0.925269 12.5 1.08424 12.5 1.25C12.5 1.41576 12.4342 1.57473 12.3169 1.69194C12.1997 1.80915 12.0408 1.875 11.875 1.875H8.125C7.95924 1.875 7.80027 1.80915 7.68306 1.69194C7.56585 1.57473 7.5 1.41576 7.5 1.25Z" fill="currentColor"/>
    <path d="M12.7219 9.60509L8.97188 7.10509C8.87773 7.04228 8.76829 7.00621 8.65524 7.00073C8.5422 6.99526 8.42979 7.02058 8.33001 7.074C8.23023 7.12741 8.14683 7.20692 8.08871 7.30404C8.03059 7.40115 7.99993 7.51223 8 7.62541V12.6254C7.99993 12.7386 8.03059 12.8497 8.08871 12.9468C8.14683 13.0439 8.23023 13.1234 8.33001 13.1768C8.42979 13.2302 8.5422 13.2556 8.65524 13.2501C8.76829 13.2446 8.87773 13.2085 8.97188 13.1457L12.7219 10.6457C12.8076 10.5887 12.8779 10.5113 12.9265 10.4205C12.9752 10.3298 13.0006 10.2284 13.0006 10.1254C13.0006 10.0224 12.9752 9.92105 12.9265 9.83027C12.8779 9.7395 12.8076 9.66215 12.7219 9.60509ZM9.25 11.4574V8.79728L11.2484 10.1254L9.25 11.4574Z" fill="currentColor"/>
    </svg>
    
  )
}

export default IconTrack
