import React from "react";
import IconInfo from "../../common/svgIcons/IconInfo";
import ReactApexChart from "react-apexcharts";
import API from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import Tooltip from "../../common/ui/Tooltip";
import { useLocation } from "react-router-dom";

const Monthly = () => {
  const location = useLocation()

  const userId = location?.state?.userId;
  const fetchMonthData = async () => {
    try {
      const { data } = await API.get(`/leaves/monthly-count?userId=${userId}`);
      return data?.data || [];
    } catch (err) {
      console.error(err.message);
      return [];
    }
  };

  const { data: monthlyLeaveData } = useQuery(
    { queryKey: ['monthLeaveData'], queryFn: fetchMonthData }
  );

  const monthOrder = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  // Function to organize the data correctly
  const getSortedMonthlyData = () => {
    if (!monthlyLeaveData) return Array(12).fill(0);

    const sortedData = Array(12).fill(0); // Initialize an array of 12 months with default value 0

    // Loop through the months in the correct order and fill the data
    monthOrder.forEach((month, index) => {
      if (monthlyLeaveData[month] !== undefined) {
        sortedData[index] = monthlyLeaveData[month]; // Assign the value for the specific month
      }
    });

    return sortedData;
  };
  const barOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
        endingShape: "rounded",
        borderRadius: 5,
        borderRadiusApplication: 'end'
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      lines: { show: false },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          fontSize: "12px",
          colors: "#1F2937",
        },
      },
    },
    yaxis: {
      show: false,
    },
    colors: ["#5461D6"],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const barSeries = [
    {
      name: "Monthly Stats",
      data: getSortedMonthlyData()
    },
  ];
  return (
    <div className="col-span-6 bg-white p-4 border border-secondary rounded-md flex flex-col justify-between">
      <div className="card-header pb-2 border-b border-secondary flex items-center justify-between">
        <h4 className="text-sm">Monthly Status</h4>
        <Tooltip
          position="left"
          toolTipClass="cursor-pointer"
          text="This graph indicates your month-over-month leave utilization during the entire year. You can get an overview of number of leave taken in any month during the year."
          bgColor="bg-white"
          arrowbg="#5461d6"
          borderColor="border-t-[#5461d6]"
          detailClass="text-black max-w-80"
        >
          <IconInfo className="h-5 w-5 text-gray-500" />
        </Tooltip>
      </div>
      <div className="card-body">
        <ReactApexChart
          options={barOptions}
          series={barSeries}
          type="bar"
          height="120"
          className="monthly_chart"
        />
      </div>
    </div>
  );
};

export default Monthly;
