import React, { useEffect, useState } from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import LeaveListItem from "../leave/LeaveListItem";
import API from "../../services/api";
import Loader from "../../common/ui/Loader";
import NoDataUI from "../../common/NoDataUI";
import LeaveRequestItem from "./LeaveRequestItem";
import FilterItem from "../../common/FilterItem";
import moment from "moment";

const LeaveRequestList = () => {
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = useState("Pending");
    const [type, setType] = useState("");
    const [month, setMonth] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const [totalPages, setTotalPages] = useState(1); // Track the total pages

    const fetchAllLeaves = async ({ queryKey }) => {
        const [_key, status, type, month, page] = queryKey;
        try {
            setLoader(true);
            const Month = month ? moment(month).format("YYYY-MM") : "";
            const { data } = await API.get(
                `/leaves/leaves?status=${status}&type=${type}&month=${Month}&page=${page}&limit=10`
            ); // Fetch 10 items per page
            setTotalPages(data?.totalPages || 1); // Set total pages from response
            return data?.data;
        } catch (err) {
            setLoader(false);
            console.error(err.message);
            return [];
        } finally {
            setLoader(false);
        }
    };

    const { data: leaveData, isFetching } = useQuery({
        queryKey: ["leave", status, type, month, currentPage], // Include page in queryKey
        queryFn: fetchAllLeaves,
        keepPreviousData: true, // Retain previous data while fetching new data
    });

    const handlePageChange = (direction) => {
        if (direction === "next" && currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        } else if (direction === "prev" && currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    return (
        <div className="xxs:p-5 p-2.5 flex flex-col h-full overflow-hidden">
            <FilterItem
                setStatus={setStatus}
                status={status}
                setType={setType}
                type={type}
                showDatePicker={false}
                setMonth={setMonth}
                month={month}
            />
            <div className="mt-3 flex-grow overflow-hidden border border-gray-200">
                {loader || isFetching ? <Loader /> : ""}
                <div className="h-full overflow-x-auto">
                    <div className={`h-full flex flex-col`}>
                        <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-2">
                            {/* <div className="w-[55%] px-3.5">Name</div>
                            <div className="w-[10%] text-center">Leave Type</div>
                            <div className="w-[15%] text-center">Reason</div>
                            <div className="w-[10%] text-center">Total Days</div>
                            <div className="w-[20%] text-center">Duration</div>
                            <div className="w-[10%] text-center">Action</div> */}
                            <div className="w-[20%] px-3.5">LEAVE DATES</div>
                            <div className="w-[20%]">LEAVE TYPE</div>
                            <div className="w-[15%]">STATUS</div>
                            <div className="w-[20%]">REQUESTED BY</div>
                            <div className="w-[20%]">ACTION TAKEN ON</div>
                            <div className="w-[20%]">LEAVE NOTE</div>
                            <div className="w-[15%]">ACTIONS</div>
                        </div>
                        <div className="flex-grow overflow-y-auto">
                            {leaveData && leaveData.length > 0 ? (
                                <LeaveRequestItem leaveList={leaveData} />
                            ) : (
                                <NoDataUI />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Pagination */}
            <div className="mt-4 flex justify-between items-center">
                <button
                    className={`px-4 py-2 rounded-md text-sm ${currentPage === 1 ? "bg-gray-300 text-gray-500 " : "bg-gray-200 hover:bg-gray-300"
                        }`}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange("prev")}
                >
                    Previous
                </button>
                <p className="text-sm">
                    Page {currentPage} of {totalPages}
                </p>
                <button
                    className={`px-4 py-2 rounded-md text-sm ${currentPage === totalPages ? "bg-gray-300 text-gray-500 " : "bg-gray-200 hover:bg-gray-300"
                        }`}
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange("next")}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default LeaveRequestList;
