import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import newLogingif from '../assets/gif/Newlogin.gif';
import bgImg from '../assets/images/bg.jpg';
import bgFestival from '../assets/images/kite-bg.png';
import newLogo from '../assets/images/New_Logo.png';
import { Input } from '../common/Input';
import PasswordInput from '../common/PasswordInput';
import { Button } from '../common/ui/Button';
import { useAuth } from '../context/AuthContext';
import API from '../services/api';
import Buttonloader from '../common/ui/ButtonLoader';
import { requestNotificationPermission } from '../firebase-notifications';
import { useForm, Controller } from 'react-hook-form';
import Lottie from 'react-lottie-player';
import LoginLottie from '../assets/json/project-management.json'
import flag from '../assets/json/flag/indian-independence-celebration.json'
import indianFlag from "../assets/json/flag/indianFlag.json";

const Login = ({ onLogin }) => {
  const { setUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState(false);

  const { control, handleSubmit, formState: { errors }, reset, watch } = useForm()

  const navigate = useNavigate();

  const getToken = async () => {
    const tokens = await requestNotificationPermission()
    if(tokens){
      await API.post('/auth/users/save-push-token', { pushToken: tokens })
    }
  }

  const onSubmit = async (datas) => {
    setLoader(true);
    try {
      const { data } = await API.post('/auth/login', { email: datas.email, password: datas.password });
      const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(data?.data), process.env.REACT_APP_SECRET_KEY).toString();
      localStorage.setItem('token', data?.token);
      localStorage.setItem('user', encryptedUserData);
      setUser(data?.data);
      toast.success('Login successful!');
      onLogin();
      navigate('/home');
      await getToken()
    } catch (err) {
      toast.error("Invalid login details");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="auth-wrapper">
      <main>
        <section className="h-screen overflow-hidden">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 h-full">
            <div className={`flex flex-col items-center justify-center bg-cover bg-center xl:col-span-6 bg-blend-overlay max-lg:hidden`} style={{ backgroundImage: `url(${bgImg})` }}>
              <div className="pt-16">
                {
                  process.env.REACT_APP_THEAM === "flag" ?
                    <div className='relative z-[1]'>
                      <img src={newLogo} alt="Logo" className='max-w-[200px]' />
                      <Lottie
                        loop
                        animationData={indianFlag}
                        play
                        style={{ width: 110, height: 110 }}
                        className="absolute -top-[58px] -left-[14px] -z-[1]"
                      />
                    </div>
                  :
                <img src={newLogo} alt="Logo" />
                }
              </div>
              <Lottie
                loop
                animationData={LoginLottie}
                play
                style={{ width: 800, height: 800 }}
              />
            </div>
            <div className="h-screen relative xl:col-span-6 overflow-auto bg-white/80 bg-blend-hue">
            {
              process.env.REACT_APP_THEAM === "kite" && 
              <div className="absolute top-0 left-0 -z-[1] h-full w-full">
                <img src={bgFestival} alt='' className='h-full w-full object-cover' />
              </div>
            }
            {
              process.env.REACT_APP_THEAM === "flag" && 
                <Lottie
                    loop
                    animationData={flag}
                    play
                    // style={{ width: 180, height: 100 }}
                    className="absolute top-0 left-0 h-full w-full -z-[1]"
                />
            }
              <div className="flex items-center justify-center px-6 lg:px-10 2xl:px-[100px] py-6 lg:py-10 min-h-screen">
                <div className={`w-full max-w-[600px] ${process.env.REACT_APP_THEAM && 'bg-white p-10 rounded-xl shadow-lg border border-secondary'}`}>
                  <div className="mb-10">
                    <h1 className="text-dark text-4xl font-semibold">Welcome back !!!</h1>
                    <p className="text-dark/80 py-1.5 text-lg font-normal">Login your Account</p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className="mb-3">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Email is Required',
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Invalid Email Address'
                          }
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            lable="Your Email"
                            placeholder="Enter your Email Address"
                          />
                        )}
                      />
                      {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                    </div>
                    <div className="mb-3">
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: 'Password is Required',
                          // minLength: {
                          //   value: 6,
                          //   message: 'Password must be at least 6 characters'
                          // }
                        }}
                        render={({ field }) => (
                          <PasswordInput
                            {...field}
                            lable="Your Password"
                            placeholder="Enter your password"
                            type="password"
                          />
                        )}
                      />
                      {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
                    </div>

                    <Button type="submit" className="w-full p-4 h-auto font-semibold mt-10" disabled={loader}>
                      {loader ? <Buttonloader /> : 'Login'}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Login;