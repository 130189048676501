import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { categories } from "../../staticData/data";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import SummarySection from "./SummarySection";
import TimelineSection from "./TimelineSection";
import LeaveHistory from "../leave/LeaveHistory";
import API from "../../services/api";
import PayrollHistory from "./PayrollHistory";
import { useAuth } from "../../context/AuthContext";

const InformationSection = () => {
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const {user} = useAuth();
   
  // Fetch leaves with pagination
  const fetchAllLeaves = async () => {
    try {
      setLoader(true);
      const { data } = await API.get(
        `/leaves/leaves/${user?._id}?status=${status}&type=${type}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=5`
      );
      setTotalPages(data?.totalPages || 1); // Set total pages for pagination
      return data?.data;
    } catch (err) {
      setLoader(false);
      console.error(err);
      return [];
    } finally {
      setLoader(false);
    }
  };

  const { data: leaveData } = useQuery({
    queryKey: ['userLeave', status, type, startDate, endDate, currentPage],
    queryFn: fetchAllLeaves,
    keepPreviousData: true,
  });

  // Filter out pending leaves
  const OtherLeaves = () => {
    if (leaveData && leaveData.length > 0) {
      return leaveData.filter((leave) => leave?.status !== "Pending");
    }
  };

  // Fetch payrolls with pagination
  const fetchPayrolls = async () => {
    try {
      setLoader(true);
      const { data } = await API.get(
        `/payroll/${user?._id}/history?page=${currentPage}&limit=5`
      );
      setTotalPages(data?.totalPages || 1); // Set total pages for pagination
      return data?.data;
    } catch (err) {
      setLoader(false);
      console.error(err.message);
      return [];
    } finally {
      setLoader(false);
    }
  };

  const { data: payrollData, isLoading } = useQuery({
    queryKey: ["UserPayroll", currentPage],
    queryFn: fetchPayrolls,
    keepPreviousData: true,
  });

  // Handle pagination button clicks
  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div>
      <TabGroup>
        <TabList className="flex gap-4 bg-[#F2F4F7] p-3 rounded-md border border-border">
          {categories.map(({ name }) => (
            <Tab
              key={name}
              className="rounded-md w-[90px] py-1 px-3 text-sm/6 font-semibold text-gray-500 data-[selected]:bg-primary/10 data-[selected]:text-primary data-[hover]:bg-primary/5 data-[selected]:data-[hover]:bg-primary/10 ring-0 outline-none"
            >
              {name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {categories.map(({ name, children }) =>
            children?.length ? (
              <TabPanel key={name}>
                <TabGroup>
                  <TabList className="flex gap-4 bg-[#F2F4F7] p-2 px-3 rounded-b-md border border-t-0 border-border -mt-1">
                    <div className="bg-white rounded-md border border-secondary overflow-hidden">
                      {children?.map(({ name }) => (
                        <Tab
                          key={name}
                          className="w-[90px] py-1 px-3 text-sm/6 font-semibold text-gray-500 border-b border-transparent data-[selected]:border-primary data-[selected]:text-primary ring-0 outline-none"
                        >
                          {name}
                        </Tab>
                      ))}
                    </div>
                  </TabList>
                  <TabPanels className="mt-3">
                    {children?.map(({ name }) => (
                      <TabPanel key={name} className="rounded-xl bg-white/5">
                        {name === "Summary" && <SummarySection />}
                        {name === "Timeline" && <TimelineSection />}
                      </TabPanel>
                    ))}
                  </TabPanels>
                </TabGroup>
              </TabPanel>
            ) : (
              <TabPanel key={name} className="rounded-xl bg-white/5">
                {name === "Leave" && (
                  <div className="mt-3 border border-border p-2.5 rounded-lg">
                    <LeaveHistory
                      endDate={endDate}
                      setEndDate={setEndDate}
                      setType={setType}
                      status={status}
                      type={type}
                      setStartDate={setStartDate}
                      startDate={startDate}
                      setStatus={setStatus}
                      leaves={OtherLeaves()}
                    />
                    {/* Pagination Controls for Leave */}
                    <div className="flex justify-between mt-4">
                      <button
                        className="px-4 py-2 bg-gray-200 rounded-md text-sm"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange("prev")}
                      >
                        Previous
                      </button>
                      <p className="text-sm">Page {currentPage} of {totalPages}</p>
                      <button
                        className="px-4 py-2 bg-gray-200 rounded-md text-sm"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange("next")}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {name === "Timesheet" && name}
                {name === "Payroll" && (
                  <div>
                    <PayrollHistory payroll={payrollData || []} />
                    {/* Pagination Controls for Payroll */}
                    <div className="flex justify-between mt-4">
                      <button
                        className="px-4 py-2 bg-gray-200 rounded-md text-sm"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange("prev")}
                      >
                        Previous
                      </button>
                      <p className="text-sm">Page {currentPage} of {totalPages}</p>
                      <button
                        className="px-4 py-2 bg-gray-200 rounded-md text-sm"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange("next")}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
                {/* {name === "Payroll" &&
                  <PayrollHistory payroll={payrollData || []} isLoading={isLoading} />
                } */}
              </TabPanel>
            )
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default InformationSection;
