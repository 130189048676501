import React, { useEffect, useState } from 'react'
import UserListItem from './UserListItem'
import API from '../../services/api';
import AddEditModal from '../modal/AddUserModal';
import { Button } from '../../common/ui/Button';
import AddUserModal from '../modal/AddUserModal';
import { toast } from 'react-toastify';
import NoDataUI from '../../common/NoDataUI';
import Loader from '../../common/ui/Loader';
import { useMutation } from '@tanstack/react-query';
import Buttonloader from '../../common/ui/ButtonLoader';

const UserList = () => {
const [showAddEditModal, setShowAddEditModal] = useState(false);
const [modalType, setModalType] = useState('');
const [userData, setUserdata] = useState();
const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    role: '',
    password: ''
});
const [loader, setLoader] = useState(false);
const [buttonLoader, setButtonLoader] = useState(false);

const fetchUsers = async () => {
    if (!userData || userData.length < 0) {
        setLoader(true)
    }
    try {
        await API.get(`/department/users/list`).then((res) => {
            setUserdata(res.data?.data);
        })

    } catch (err) {
        console.error(err.message);
    } finally {
        setLoader(false)
    }
};

useEffect(() => {
    fetchUsers();
}, []);

const handleAdd = async (data) => {
    // e.preventDefault();
    setLoader(true)
    try {
        await API.post('/auth/register', data);
        setShowAddEditModal(false);
        fetchUsers();
        toast.success('User added successfully');
    } catch (err) {
        // console.error(err.message);
        toast.error(err.response.data.message);
    } finally {
        setLoader(false)
    }
}
const { mutate: calculatePerfomance } = useMutation({
    mutationFn: async () => {
        setButtonLoader(true)
        const response = await API.post(`/reward/assign`);
        return response.data;
    },
    onSuccess: () => {
        setButtonLoader(false)
        toast.success('User Perfomance Calculated Successfully');
    },
    onError: (error) => {
        setButtonLoader(false)
        console.error('User Perfomance Calculation failed:', error?.response?.data?.message);
        toast.error(error?.response?.data?.message || 'Failed to  Calculate Perfomance');
    },
});


return (
    <>
        <div className='xxs:p-5 p-2.5 flex flex-col h-full overflow-hidden'>
            <div className="flex items-center justify-between">
                <h2 className='sm:text-lg text-base font-semibold flex-grow'>Employee List</h2>
                <div className="flex gap-2">
                    {buttonLoader ? <Buttonloader /> : <Button size='sm' className={"font-semibold"} onClick={() => calculatePerfomance()}>
                        Calculate Performance
                    </Button>}
                    <Button size='sm' className={"font-semibold"} onClick={() => setShowAddEditModal(true)}>
                        Add Employee
                    </Button>
                </div>
            </div>
            <div className="mt-3 flex-grow overflow-hidden">
                {loader ? <Loader /> : ""}
                <div className='h-full overflow-x-auto'>
                    <div className={`h-full ${userData?.length ? "min-w-[750px]" : ""}  flex flex-col`}>
                        <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                            <div className="w-1/2 px-3.5">Name ({userData?.length})</div>
                            <div className="w-[30%]">Email</div>
                            <div className="w-[25%]">Role</div>
                            <div className="w-[25%]">Department</div>
                            <div className="w-[25%]">JobTitle</div>
                            <div className="w-[15%]">Action</div>
                        </div>
                        <div className="flex-grow overflow-y-auto">
                            {
                                userData?.length ? userData?.map((user) => (
                                    <UserListItem key={user.id} userData={user} fetchUsers={fetchUsers} showAddEditModal={showAddEditModal} setShowAddEditModal={setShowAddEditModal} newUser={newUser} setNewUser={setNewUser} />
                                )) :
                                    <div className="h-full flex items-center justify-center">
                                        <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddUserModal isOpen={showAddEditModal} setIsOpen={setShowAddEditModal} newUser={newUser} setNewUser={setNewUser} handleSubmit={handleAdd} loader={loader} />
    </>
)
}

export default UserList