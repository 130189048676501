import React, { useEffect, useState } from "react";
import { Copy, Ellipsis, Info, LassoSelect, Pencil, Plus, Trash2 } from "lucide-react";
import { Button, buttonVariants } from "../../common/ui/Button";
import PageListItem from "./PageListItem";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import clsx from "clsx";
import { cn, getColorFromName } from "../../lib/utils";
import IconLink from "../../common/svgIcons/IconLink";
import { Input } from "../../common/Input";
import { useAuth } from "../../context/AuthContext";
import Editor from "../editor/editor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../common/ui/Loader";

const DocumentDetail = () => {
  const { user } = useAuth();
  const { docsId, pageId } = useParams()
  const navigate = useNavigate()
  const [hoverId, setHoverId] = useState(1);
  const [title, setTitle] = useState();
  const [loader, setLoader] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [saving, setSaving] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pages, setPages] = useState([
    { title: "Untitled", content: "Untitled" }
  ])
  const queryClient = useQueryClient();

  const showMenu = (id) => setHoverId(id);
  const hideMenu = () => setHoverId("");

  const { mutate: addNewPage } = useMutation({
    mutationFn: async () => {
      if (docsId) {
        const response = await API.post(`docs/${docsId}/pages`, { title: "Untitled", content: "Untitled" });
        return response.data;
      } else {
        setPages([...pages, { title: "Untitled", content: "Untitled" }]);
      }
    },
    onSuccess: () => {
      if (docsId) {
        queryClient.invalidateQueries({ queryKey: ["document-Info"] });
      }
    },
    onError: (error) => {
      console.error("Error Adding Page", error);
      toast.error(error.response?.data?.message || "Error Adding Page");
    },
  });

  const { mutate: createDocument } = useMutation({
    mutationFn: async () => {
      if (docsId) {
        const response = await API.put(`docs/${docsId}/${pageId}/title`, { title });
        return response.data;
      } else {
        const title = pages[0].title;
        const response = await API.post(`/docs/main-doc`, { title: title || 'docs', pages: pages });
        return response.data;
      }
    },
    onSuccess: () => {
      if (docsId) {
        toast.success('title updated successfully')
      }
      else {
        toast.success('docs created successfully')
        navigate('/docs')
      }
      queryClient.invalidateQueries({ queryKey: ["document-Info"] })
    },
    onError: (error) => {
      console.error("Error Creating Document", error);
      toast.error(error.response?.data?.message || "Error Creating Document")
    },
  });

  const fetchDocumentInfo = async () => {
    if (docsId) {
      setLoader(true);
      try {
        const { data } = await API.get(`/docs/main-doc/${docsId}`);
        setPages(data?.document?.pages || [])
        return data?.document
      } catch (error) {
        console.error(error.message);
        toast.error(error.response?.data?.message || "Error in watching Document")
        return [];
      } finally {
        setLoader(false)
      }
    }
  };

  const { data: DocumentInfo } = useQuery({
    queryKey: ['document-Info'], queryFn: fetchDocumentInfo,
  });


  useEffect(() => {
    if (pages && pageId) {
      const index = pages.findIndex(page => page._id === pageId);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  }, [pages, pageId]);

  const { mutate: deletePage } = useMutation({
    mutationFn: async ({ index, page }) => {
      if (docsId) {
        await API.delete(`docs/${docsId}/pages/${page?._id}`);
        return { page, index };
      } else {
        const updatedPages = pages.filter((_, i) => i !== index);
        setPages(updatedPages);
        hideMenu();
      }
    },
    onSuccess: ({ index, page }) => {
      if (docsId) {
        const remainingPages = DocumentInfo?.pages.filter(p => p._id !== page?._id);

        if (remainingPages.length === 0) {
          navigate('/docs');
        } else {
          const currentIndex = DocumentInfo?.pages.findIndex(p => p._id === page?._id);
          const targetIndex = currentIndex === remainingPages.length ? currentIndex - 1 : Math.min(currentIndex, remainingPages.length - 1);
          const targetPageId = remainingPages[targetIndex]?._id;

          // Set the selected index before navigation
          setSelectedIndex(targetIndex);
          handlePageClick(targetPageId);
          queryClient.invalidateQueries({ queryKey: ["document-Info"] });
        }
      }
    },
    onError: (error) => {
      console.error("Error Deleting Page", error);
      toast.error(error.response?.data?.message || "Error Deleting Page");
    },
  });

  const { mutate: duplicatePage } = useMutation({
    mutationFn: async ({ index, page }) => {
      if (docsId) {
        const response = await API.post(`docs/${docsId}/pages`, { title: page?.title + ' (Copy)', content: page?.content });
        return response.data;
      } else {
        const newPage = `${pages[index]} Copy`;
        setPages([...pages, newPage]);
        hideMenu();
      }
    },
    onSuccess: () => {
      if (docsId) {
        queryClient.invalidateQueries({ queryKey: ["document-Info"] });
      }
    },
    onError: (error) => {
      console.error("Error Duplicating Page", error);
      toast.error(error.response?.data?.message || "Error Duplicating Page");
    },
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      createDocument()
    }
  };

  const handlePageClick = (pageId) => {
    if (docsId) {
      const index = pages.findIndex(page => page._id === pageId);
      setSelectedIndex(index);
      navigate(`/docs/${docsId}/${pageId}`);
    }
  };

  const { mutate: SaveEditorChange } = useMutation({
    mutationFn: ({ newContent, index }) => {
      setSaving("Saving");
      return new Promise((resolve) => {
        setTimeout(async () => {
          setPages((prevPages) =>
            prevPages.map((page, i) =>
              i === index ? { ...page, content: newContent } : page
            )
          );
          if (docsId && pageId) {
            API.put(`/docs/${docsId}/${pageId}/content`, { content: newContent })
              .then(() => {
                setSaving(false)
              })
              .catch((error) => {
                setSaving(false)
                toast.error(error.response?.data?.message || 'Failed to save content');
                console.error(error);
              });
          } else {
            setSaving(false);
          }
        }, 3000);
      })
    },
    onSuccess: () => {
      setSaving("Saved");
    },
    onError: (error) => {
      console.error("Request failed:", error?.response?.data?.message);
    },
  });
  const handleEditorChange = ({ newContent, index }) => {
    setSaving("Saving");
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeoutId = setTimeout(() => {
      SaveEditorChange({ newContent, index });
    }, 2000);
    setDebounceTimeout(timeoutId);
  };


  return (
    <div className="h-full">
      <TabGroup className="h-full flex" selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <div className="px-3.5 pt-2 h-full border-e border-secondary w-[320px]">
          <TabList className="flex gap-1 flex-col gap-y-1 mb-1">
            {pages.map((page, index) => (
              <Tab
                key={index}
                onClick={() => handlePageClick(page?._id)}
                className={({ selected }) =>
                  clsx(
                    "outline-none p-3 CompsOff flex items-center gap-1.5 text-xs leading-[1] font-semibold rounded-lg ps-5 relative overflow-hidden truncate",
                    selected
                      ? "bg-primary/10 text-primary"
                      : "text-gray-500 hover:bg-gray-100"
                  )
                }
                onMouseEnter={() => showMenu(index)}
                onMouseLeave={hideMenu}
              >
                <PageListItem index={index} page={page} hoverId={hoverId} />
                {hoverId === index && (
                  <div className="menu-action absolute top-1/2 -translate-y-1/2 right-2 mt-[2.5px]">
                    <Menu>
                      <MenuButton
                        onClick={(e) => e.stopPropagation()}
                        className={cn(
                          buttonVariants({
                            className:
                              "w-5 h-5 items-center justify-center p-0 ml-auto flex-shrink-0 border-none shadow-none",
                            variant: "outline",
                            size: "icon",
                          })
                        )}
                      >
                        <Ellipsis className="w-4 h-4" />
                      </MenuButton>
                      <MenuItems
                        transition
                        anchor="bottom end"
                        className="w-[150px] origin-top-right rounded-xl border-2 border-border bg-white shadow-lg p-1 text-xs transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-[999]"
                      >
                        <MenuItem className="mb-1">
                          <button
                            className="group flex w-full items-center gap-1.5 rounded-lg py-1.5 px-2 data-[focus]:bg-primary data-[focus]:text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              duplicatePage({ index, page });
                            }}
                          >
                            <Copy className="size-3.5 " />
                            Duplicate
                          </button>
                        </MenuItem>
                        <MenuItem className="mb-1">
                          <button className="group flex w-full items-center gap-1.5 rounded-lg py-1.5 px-2 data-[focus]:bg-primary data-[focus]:text-white "
                            onClick={async (e) => {
                              await window.navigator.clipboard.writeText(process.env.REACT_APP_COPY_LINK +`/docs/${docsId}/${pageId}`)
                            }}>
                            <IconLink className="h-4 w-4 text-dark group-hover:text-white" />
                            Copy Link
                          </button>
                        </MenuItem>
                        <MenuItem className="mb-1">
                          <button
                            className="group flex w-full items-center gap-1.5 rounded-lg py-1.5 px-2 data-[focus]:bg-primary data-[focus]:text-white"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              deletePage({ index, page });
                            }}
                          >
                            <Trash2 className="size-3.5 " />
                            Delete
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                )}
              </Tab>
            ))}
          </TabList>
          <Button
            onClick={() => addNewPage()}
            className={
              "w-full bg-transparent hover:bg-gray-100 text-gray-500 shadow-none flax items-center gap-2.5 justify-start px-2"
            }
          >
            <Plus className="h-4 w-4" />
            Add Page
          </Button>
        </div>
        <TabPanels className="w-full h-full p-6 overflow-y-auto">
          {pages.map((page, index) => (
            <TabPanel key={index} className='h-full'>
              {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
                <Loader />
              </div> :
                <div className="w-[60%] h-full mx-auto flex flex-col">
                  <div className="">
                    <Input
                      onChange={(e) => {
                        if (docsId) {
                          setTitle(e.target.value);
                        } else {
                          const updatedTitle = e.target.value;
                          setPages((prevPages) =>
                            prevPages.map((p, i) =>
                              i === index ? { ...p, title: updatedTitle } : p
                            )
                          )
                        }
                      }}
                      onKeyPress={handleKeyPress}
                      className="border-0 bg-transparent text-4xl font-bold px-0 pt-0"
                      placeholder={`${page?.title} `}
                      defaultValue={`${page?.title} `}
                    />
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2.5">
                        <div className="flex items-center gap-1.5">
                          <div
                            className="h-6 w-6 text-white rounded-full flex items-center justify-center font-medium uppercase text-xs"
                            style={{
                              background: `${getColorFromName(user.name)}`,
                            }}
                          >
                            {user.name
                              .split(" ")
                              .slice(0, 2)
                              .map((name) => name.slice(0, 1).toUpperCase())
                              .join("")}
                          </div>
                          <p className="text-dark text-sm">{user.name}</p>
                        </div>
                        <div className="flex items-center gap-1.5">
                          <p className="text-gray-500 text-sm">Last Updated:</p>
                          <p className="text-gray-500 font-semibold text-sm">
                            {moment(page?.updatedAt).format('DD-MMM-YYYY hh:mm A') || 'None'}
                          </p>
                        </div>
                      </div>
                      {page?.content &&
                        <div className="p-1.5 px-4 bg-primary/20 text-xs font-semibold rounded-md inline-block">
                          {saving ? 'Saving..' : "Saved"}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="relative flex-grow overflow-auto flex flex-col mt-2.5">
                    <div className="flex-grow overflow-auto">
                      <Editor
                        key={"content"}
                        initialValue={page?.content || ""}
                        onChange={(newContent) => handleEditorChange({ newContent, index })}
                      />
                    </div>
                  </div>
                </div>
              }
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default DocumentDetail;
