import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Login from "./components/Login";
import { useAuth } from "./context/AuthContext";
import Dashboard from "./pages/Dashboard";
import TimeSheet from "./pages/TimeSheet";
import DashboardLayout from "./layoutes/DashboardLayout";
import Home from "./pages/Home";
import Users from "./pages/Users";
import Tags from "./pages/Tags";
import Archive from "./pages/Archive";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase-config";
import { requestNotificationPermission } from "./firebase-notifications";
import TaskAnalytics from "./pages/TaskAnalytics";
import TaskTracker from "./pages/TaskTracker";
import Department from "./pages/Department";
import JobTitle from "./pages/JobTitle";
import UserProfile from "./pages/UserProfile";
import Payroll from "./pages/Payroll";
import Leave from "./pages/Leave";
import ProtectedRoute from "./services/ProtectedRoute";
import LeaveRequest from "./pages/LeaveRequest";
import ProjectDetails from "./pages/ProjectDetails";
import Nosprint from "./pages/Nosprint";
import Attendance from "./pages/Attendance";
import Documents from "./pages/Documents";
import DocumentsDetails from "./pages/DocumentsDetails";
import AttandanceList from "./components/attendance/UserAttandanceList";
import UserAttandanceList from "./components/attendance/UserAttandanceList";
import PublicDocument from "./components/documents/PublicDocument";
import HolidayList from "./components/holiday/HolidayList";
import TeamSummary from "./pages/TeamSummary";

function App() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  // // Create the navigate function inside App, where it is properly inside the Router context
  // const navigate = useNavigate()

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/home" replace />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route path="doc/:docsId/:pageId" element={<PublicDocument />} />
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/" element={<DashboardLayout />}>
                <Route
                  path="home"
                  element={<Home setIsAuthenticated={setIsAuthenticated} />}
                />
                <Route
                  path="attendance"
                  element={<ProtectedRoute auth={false}><Attendance setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                {/* <Route
                  path="attendance-list"
                  element={<ProtectedRoute auth={false}><UserAttandanceList setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                /> */}
                <Route
                  path="attendance-list"
                  element={<ProtectedRoute auth={false}><UserAttandanceList setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="leave"
                  element={<ProtectedRoute auth={false}><Leave setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="/leave-request"
                  element={<ProtectedRoute auth={false}><LeaveRequest setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="team-summary"
                  element={<ProtectedRoute auth={false}><TeamSummary setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="docs"
                  element={<ProtectedRoute auth={false}><Documents setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="docs/create"
                  element={<ProtectedRoute auth={false}><DocumentsDetails setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="docs/:docsId/:pageId"
                  element={<ProtectedRoute auth={false}><DocumentsDetails setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="tags"
                  element={<ProtectedRoute auth={true}><Tags setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="archive"
                  element={<ProtectedRoute auth={true}><Archive setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="task-analytics"
                  element={<ProtectedRoute auth={true}><TaskAnalytics setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="task-tracker"
                  element={<ProtectedRoute auth={true}><TaskTracker setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="holiday"
                  element={<ProtectedRoute auth={true}><HolidayList setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="tags"
                  element={<ProtectedRoute auth={true}><Tags setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="no-sprint"
                  element={<ProtectedRoute auth={true}><Nosprint setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="department"
                  element={<ProtectedRoute auth={true}><Department setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="job-title"
                  element={<ProtectedRoute auth={true}><JobTitle setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="project/:projectId/:sprintId"
                  element={
                    <ProtectedRoute auth={false} >
                      <Dashboard setIsAuthenticated={setIsAuthenticated} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="timesheet"
                  element={
                    <ProtectedRoute auth={true}><TimeSheet setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>
                  }
                />
                <Route
                  path="user"
                  element={<ProtectedRoute auth={true}><Users setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="user-detail"
                  element={<ProtectedRoute auth={false}><UserProfile setIsAuthenticated={setIsAuthenticated} /></ProtectedRoute>}
                />
                <Route
                  path="payroll"
                  element={<ProtectedRoute auth={true}><Payroll setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
                <Route
                  path="projectDetail/:projectId"
                  element={<ProtectedRoute auth={false}><ProjectDetails setIsAuthenticated={setIsAuthenticated} /> </ProtectedRoute>}
                />
              </Route>
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" replace />} />
          )}
        </Routes>
      </div>
      <ToastContainer autoClose={2000} pauseOnHover={false} />
    </Router>
  );
}

export default App;
