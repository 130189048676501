import React from 'react'

const IconBag = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M17.5 9.24292V15.625C17.5 15.7907 17.4342 15.9497 17.3169 16.0669C17.1997 16.1841 17.0408 16.25 16.875 16.25H3.125C2.95924 16.25 2.80027 16.1841 2.68306 16.0669C2.56585 15.9497 2.5 15.7907 2.5 15.625V9.24292C4.77931 10.5615 7.36677 11.2539 10 11.25C12.6333 11.2541 15.2208 10.5617 17.5 9.24292Z" fill="currentColor" />
            <path d="M8.125 8.75C8.125 8.58424 8.19085 8.42527 8.30806 8.30806C8.42527 8.19085 8.58424 8.125 8.75 8.125H11.25C11.4158 8.125 11.5747 8.19085 11.6919 8.30806C11.8092 8.42527 11.875 8.58424 11.875 8.75C11.875 8.91576 11.8092 9.07473 11.6919 9.19194C11.5747 9.30915 11.4158 9.375 11.25 9.375H8.75C8.58424 9.375 8.42527 9.30915 8.30806 9.19194C8.19085 9.07473 8.125 8.91576 8.125 8.75ZM18.125 5.625V15.625C18.125 15.9565 17.9933 16.2745 17.7589 16.5089C17.5245 16.7433 17.2065 16.875 16.875 16.875H3.125C2.79348 16.875 2.47554 16.7433 2.24112 16.5089C2.0067 16.2745 1.875 15.9565 1.875 15.625V5.625C1.875 5.29348 2.0067 4.97554 2.24112 4.74112C2.47554 4.5067 2.79348 4.375 3.125 4.375H6.25V3.75C6.25 3.25272 6.44754 2.77581 6.79917 2.42417C7.15081 2.07254 7.62772 1.875 8.125 1.875H11.875C12.3723 1.875 12.8492 2.07254 13.2008 2.42417C13.5525 2.77581 13.75 3.25272 13.75 3.75V4.375H16.875C17.2065 4.375 17.5245 4.5067 17.7589 4.74112C17.9933 4.97554 18.125 5.29348 18.125 5.625ZM7.5 4.375H12.5V3.75C12.5 3.58424 12.4342 3.42527 12.3169 3.30806C12.1997 3.19085 12.0408 3.125 11.875 3.125H8.125C7.95924 3.125 7.80027 3.19085 7.68306 3.30806C7.56585 3.42527 7.5 3.58424 7.5 3.75V4.375ZM3.125 5.625V8.87656C5.23471 10.0243 7.59828 10.6254 10 10.625C12.4018 10.6254 14.7655 10.024 16.875 8.87578V5.625H3.125ZM16.875 15.625V10.2836C14.7344 11.3307 12.383 11.8751 10 11.875C7.61708 11.8755 5.26557 11.3314 3.125 10.2844V15.625H16.875Z" fill="currentColor" />
        </svg>
    )
}

export default IconBag