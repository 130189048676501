import React from "react";
import DocumentsListItem from "./DocumentsListItem";
import NoDataUI from "../../common/NoDataUI";

const DocumentsList = ({ docsdata }) => {
  return (
    <div className={`h-[calc(100vh-258px)] overflow-auto flex flex-col mt-2`}>
      <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
        <div className="w-[60%] px-3.5">Name</div>
        <div className="w-[15%]">Date Updated</div>
        <div className="w-[15%]">Date Viewed</div>
        <div className="w-[10%]">Sharing</div>
      </div>
      <div className="flex-grow overflow-auto">
        {docsdata?.length ? (
          docsdata?.map((docs) => <DocumentsListItem docs={docs} />)
        ) : (
          <div className="h-full flex items-center justify-center">
            <NoDataUI
              subtitle={"Tasks and Reminders assigned to you will show here."}
              title={"Oops, No Tasks Found!"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsList;
