import React from 'react'

const PayrolListItem = ({ payroll }) => {
    // const bonus = (payroll?.bonuses * 20) / 100
    // const Tax = (payroll?.tax * 5) / 100
    // const Total = payroll?.baseSalary + bonus + Tax
    return (
        <div className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm font-medium hover:bg-primary/5">
            <div className="w-[50%] flex gap-2 items-center border-e border-border p-1">
                {payroll?.user?.name}
            </div>
            <div className="w-[10%] flex gap-2 items-center border-e border-border p-1">
                {payroll?.baseSalary}
            </div>
            <div className="w-[10%] flex gap-2 items-center border-e border-border p-1">
                {payroll?.bonuses}
            </div>
            <div className="w-[10%] flex gap-2 items-center border-e border-border p-1">
                {payroll?.deductions}
            </div>
            <div className="w-[10%] flex gap-2 items-center border-e border-border p-1">
                {payroll?.tax}
            </div>
            <div className="w-[10%] flex gap-2 items-center border-e border-border p-1">
                {payroll?.totalPayable}
            </div>
        </div>
    )
}

export default PayrolListItem