import React, { createContext, useContext, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import API from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [user, setUser] = useState();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            try {
                const bytes = CryptoJS.AES.decrypt(userData, process.env.REACT_APP_SECRET_KEY);
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setUser(decryptedData);
            } catch (error) {
                console.error("Failed to decrypt user data:", error.message);
                setUser(null);
            }
        }
    }, []);
    
    const logout = async () => {
        try {
            // await API.post('/auth/logout');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            toast.success('Log out successful!');
            window.location.pathname = '/login';
        } catch (err) {
            toast.error("Invalid login details");
        }
    };
    
    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout, user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
