import { Dialog, DialogPanel, DialogTitle, Select } from '@headlessui/react';
import { X } from 'lucide-react';
import React from 'react';
import { Input } from '../../common/Input';
import { Button } from '../../common/ui/Button';
import { roleTypes } from '../../staticData/data';
import { genderTypes } from '../../staticData/data';
import Buttonloader from '../../common/ui/ButtonLoader';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';

const AddUserModal = ({ isOpen, setIsOpen, newUser, setNewUser, handleSubmit, loader }) => {
    const {
        register,
        handleSubmit: handleFormSubmit,
        setValue,
        watch,
        formState: { errors },
        reset,
    } = useForm();

    const close = () => {
        setIsOpen(false);
        setNewUser({ name: '', email: '', role: '' });
        reset({
            name: '',
            email: '',
            role: '',
            birthday: null,
            join: null,
            password: '',
            baseSalary: '',
            gender: '',
            mobileNumber: '',
        });
    };

    const onSubmit = (data) => {
        handleSubmit({
            ...data,
            birthday: watch('birthday'),
            joinday: watch('join')
        });
        reset({
            name: '',
            email: '',
            role: '',
            birthday: null,
            join: null,
            password: '',
            baseSalary: '',
            gender: '',
            mobileNumber: '',
        });
    };

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => close()}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle
                            as="h3"
                            className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border"
                        >
                            <div className="">
                                <p className="text-xl font-bold">Add Employee</p>
                            </div>
                            <span className="cursor-pointer" onClick={() => close()}>
                                <X className="text-dark/50" />
                            </span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleFormSubmit(onSubmit)}>
                                <div className="p-8 py-5 max-h-[400px] overflow-auto">
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Name</label>
                                        <Input
                                            placeholder="User Name"
                                            {...register('name', { required: 'User Name is required' })}
                                            className={`${errors.name ? 'border-red-500' : ''}`}
                                        />
                                        {errors.name && (
                                            <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>
                                        )}
                                    </div>
                                    <label className="text-sm ml-1">Birthday</label>
                                    <div className={`custome_date border mb-3 border-border bg-[#FBFBFF] rounded-md w-full relative ${errors.birthday ? 'border-red-500' : 'border-border'}`}>
                                        <DatePicker
                                            placeholderText="User Birthday"
                                            {...register('birthday', { required: 'Birthday is required' })}
                                            maxDate={new Date()}
                                            selected={watch('birthday')}
                                            onChange={(date) => setValue('birthday', date)}
                                            className="w-full rounded-md border px-4 py-3.5 text-sm"
                                        />
                                    </div>
                                    {errors.birthday && (
                                        <p className="text-red-500 text-xs mt-1">{errors.birthday.message}</p>
                                    )}
                                    <label className="text-sm ml-1">Joining Date</label>
                                    <div className={`custome_date border mb-3 border-border bg-[#FBFBFF] rounded-md w-full relative ${errors.join ? 'border-red-500' : 'border-border'}`}>
                                        <DatePicker
                                            {...register('join', { required: 'Joining Date is required' })}
                                            placeholderText="User Joining Date"
                                            selected={watch('join')}
                                            onChange={(date) => setValue('join', date)}
                                            className="w-full rounded-md border px-4 py-3.5 text-sm"
                                        />
                                    </div>
                                    {errors.join && (
                                        <p className="text-red-500 text-xs mt-1">{errors.join.message}</p>
                                    )}
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Email</label>
                                        <Input
                                            placeholder="User Email"
                                            {...register('email', {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                    message: 'Invalid email address',
                                                },
                                            })}
                                            className={`${errors.email ? 'border-red-500' : ''}`}
                                        />
                                        {errors.email && (
                                            <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Gender</label>
                                        <Select
                                            className={`w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors ${errors.role ? 'border-red-500' : ''}`}
                                            {...register('gender', { required: 'gender is required' })}
                                        >
                                            <option value="">Select Gender</option>
                                            {genderTypes?.map((gender) => (
                                                <option key={gender.id} value={gender.value}>
                                                    {gender.name}
                                                </option>
                                            ))}
                                        </Select>
                                        {errors.gender && (
                                            <p className="text-red-500 text-xs mt-1">{errors.gender.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Mobile Number</label>
                                        <Input
                                            placeholder="Mobile Number"
                                            maxLength="10"
                                            type="tel"
                                            {...register('mobileNumber', {
                                                required: 'Mobile Number is required',
                                                pattern: {
                                                    value: /^[0-9]{10}$/,
                                                    message: 'Mobile Number must be exactly 10 digits',
                                                },
                                            })}
                                            className={`${errors.mobileNumber ? 'border-red-500' : ''}`}
                                        />
                                        {errors.mobileNumber && (
                                            <p className="text-red-500 text-xs mt-1">{errors.mobileNumber.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Password</label>
                                        <Input
                                            placeholder="User Password"
                                            type="password"
                                            {...register('password', {
                                                required: 'Password is required',
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                                    message: 'New password must be at least 8 characters long, with 1 uppercase letter and 1 number.',
                                                },
                                            })}
                                            className={`${errors.password ? 'border-red-500' : ''}`}
                                        />
                                        {errors.password && (
                                            <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Role</label>
                                        <Select
                                            className={`w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors ${errors.role ? 'border-red-500' : ''}`}
                                            {...register('role', { required: 'Role is required' })}
                                        >
                                            <option value="">Select Role</option>
                                            {roleTypes?.map((user) => (
                                                <option key={user.id} value={user.value}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </Select>
                                        {errors.role && (
                                            <p className="text-red-500 text-xs mt-1">{errors.role.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                    <label className="text-sm ml-1">Base Salary</label>
                                        <Input
                                            type="number"
                                            placeholder="Base Salary"
                                            {...register('baseSalary', {
                                                required: 'Base Salary is required',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Only numeric values are allowed',
                                                },
                                            })}
                                            className={`${errors.baseSalary ? 'border-red-500' : ''}`}
                                        />
                                        {errors.baseSalary && (
                                            <p className="text-red-500 text-xs mt-1">{errors.baseSalary.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant="outline" type="button" onClick={() => close()}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Add Employee'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default AddUserModal;
