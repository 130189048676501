import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/New_Logo_1.png";
import FlagLogo from "../../assets/images/blank_logo.png";

const LogoPart = () => {
  return (
    <div className="lg:py-5 lg:pt-9 py-4 px-4 pt-1 z-[1]">
      <Link to={"/"}>
        {/* {process.env.REACT_APP_THEAM === "flag" ? (
          <img
            src={FlagLogo}
            alt=""
            className="lg:w-[80%] w-[60%] lg:mx-auto"
          />
        ) : (
        )} */}
        <img src={logo} alt="" className="lg:w-[80%] w-[60%] lg:mx-auto" />
      </Link>
    </div>
  );
};

export default LogoPart;
