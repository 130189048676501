import React from "react";
import NoDataUI from "../common/NoDataUI";
import { ChevronRightIcon } from "lucide-react";
import { useLocation } from "react-router-dom";

const Nosprint = () => {
  const data = useLocation();
  const projectName = data?.state?.name || "Project";

  return (
    <div className=" flex flex-col h-full">
      {/* Header Section */}
      <div className="border-b border-border p-2 bg-white">
        <div className="flex flex-col items-start gap-2">
          <span className="flex items-center text-base ml-2 font-semibold text-[#475467]">
            <span>Project</span>
            <ChevronRightIcon className="w-5 h-5 mx-2" />
            <span className="truncate">{projectName}</span>
          </span>
        </div>
      </div>
      <div className="flex-grow flex flex-col">
        <h2 className="text-xl font-medium text-black p-5">My Tasks</h2>
        <div className="flex items-center justify-center flex-grow ">
          <NoDataUI title={"Oops, No Sprint Found!"} />
        </div>
      </div>
    </div>
  );
};

export default Nosprint;
