import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import NoDataUI from '../../common/NoDataUI';
import { Button } from '../../common/ui/Button';
import { useAuth } from '../../context/AuthContext';
import { groupTasksByStatus } from '../../lib/utils';
import API from '../../services/api';
import { statusType } from '../../staticData/data';
import AddTaskModal from '../modal/AddTaskModal';
import TaskListItem from './TaskListItem';
import { CheckIcon, X } from "lucide-react";
import IconColse from '../../common/svgIcons/IconClose';
import Tooltip from '../../common/ui/Tooltip';
import QuickAddTaks from '../../common/QuickAddTaks';
import Loader from '../../common/ui/Loader';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import TaskBoardList from './TaskBoardList';
import IconListView from '../../common/svgIcons/IconListView';
import IconBoardView from '../../common/svgIcons/IconBoardView';
import IconSummary from '../../common/svgIcons/IconSummary';
import FilterDropdown from '../../common/ui/FilterDropdown';

const leaveTypes = ["Comp Offs", "Floater Leave", "Paid Leave", "Unpaid Leave"];

const TaskList = () => {
    const queryClient = useQueryClient()
    const { projectId, sprintId } = useParams();
    const childRef = useRef();
    const { user, logout } = useAuth();
    const [isOpen, setIsOpen] = useState(false)
    const [taskDetails, setTaskDetails] = useState();
    const [activeTask, setActiveTask] = useState();
    const [timers, setTimers] = useState([]);
    const [timer, setTimer] = useState(false);
    const [tags, setTags] = useState();
    const [allTask, setAllTask] = useState()
    const [isSelected, setIsSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userRol, setUserRol] = useState();
    const [openFilterDropdown, setOpenFilterDropdown] = useState(null);
    const [selectTab, setSelectTab] = useState('Board');

    const handleToggle = (index) => {
        setOpenFilterDropdown(openFilterDropdown === index ? null : index);
    };

    const [selectedValues, setSelectedValues] = useState({
        users: [],
        taskStatuses: [],
        tags: [],
    });

    const handleSelectionChange = (key, values) => {
        setSelectedValues((prev) => ({ ...prev, [key]: values }));
    };

    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        projectId: '',
        assignedTo: '',
        dueDate: new Date().toISOString().split('T')[0],
        priority: 'Medium',
        sprintId: ''
    });

    const fetchProjectDetails = async () => {
        const { data } = await API.get(`project/${projectId}`);
        return data;
    };
    const fetchSprintDetails = async () => {
        const { data } = await API.get(`sprint/${sprintId}`);
        return data;
    };

    const fetchTasks = async () => {
        if (!filteredTaskList() || filteredTaskList().length <= 0) {
            setLoader(true);
        }
        try {
            const { data } = await API.get(`/task/sprints/${sprintId}/tasks`);
            setAllTask(data?.tasks);
            setUserRol(data?.userRoleFlag)
            return groupTasksByStatus(data?.tasks);
        } catch (err) {
            console.error(err.message);
            return [];
        } finally {
            setLoader(false)
        }
    };
    const { data: projectDetails } = useQuery({
        queryKey: ['project', projectId], queryFn: fetchProjectDetails, enabled: !!projectId
    });
    const { data: sprintDetails } = useQuery(
        { queryKey: ['sprint', sprintId], queryFn: fetchSprintDetails, enabled: !!sprintId }
    );
    const { data: sortedTasks } = useQuery(
        { queryKey: ['tasks', sprintId], queryFn: fetchTasks, enabled: !!sprintId }
    );

    const { mutate: addTaskMutation } = useMutation({
        mutationFn: async (data) => {
            setLoading(true);
            const response = await API.post('/task', data);
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Task added successfully');
            setNewTask({
                title: '',
                description: '',
                projectId: '',
                assignedTo: '',
                dueDate: new Date().toISOString().split('T')[0],
                priority: 'Medium',
            });
            setIsOpen(false);
            setLoading(false);
            fetchTasks();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message ||
                Object.entries(error.response?.data?.errors || {})[0]?.[1] ||
                'Failed to add task';
            toast.error(errorMessage);
            setLoading(false);
        },
    });

    const handleAddTask = (data) => {
        const obj = {
            ...data,
            projectId: projectId,
            sprintId: sprintId
        };
        addTaskMutation(obj);
    };

    const { mutate: addQuickTaskMutation } = useMutation({
        mutationFn: async (newTaskData) => {
            const response = await API.post('/task', newTaskData);
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            toast.success('Task added successfully');
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message ||
                Object.entries(error.response?.data?.errors || {})[0]?.[1] ||
                'Failed to add task';
            toast.error(errorMessage);
        },
    });

    const handleQuickAdd = async (newTitle, newStatus) => {
        const obj = {
            title: newTitle,
            status: newStatus,
            projectId: projectId,
            sprintId: sprintId
        };
        addQuickTaskMutation(obj);

    }

    const [taskTimer, setTaskTimer] = useState();

    const fetchTimersAndTasks = async () => {
        try {
            const [timersRes] = await Promise.all([API.get('/timer')]);
            setTimers(timersRes.data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleStopTimer = async (timerId) => {
        try {
            let data = await API.post('/timer/stop', { timerId });
            setTimer(false);
            fetchTimersAndTasks();
            fetchCurrentTask();
            if (childRef.current) {
                childRef.current.fetchTimersAndTasks();
            }
            setActiveTask(null)
            setTaskTimer(timers.filter((time) => time.taskId._id == taskDetails._id))
        } catch (err) {
            if (err.status === 401) {
                logout()
            }
        }
    };
    const fetchCurrentTask = async () => {
        try {
            const response = await API.get('/timer/active');
            setActiveTask(response.data)
        } catch (error) {
            console.error(error.message);
            setActiveTask(null)
        }
    }
    const fetchTagList = async () => {
        try {
            const tagList = await API.get("/tag/list")
            setTags(tagList?.data)
        } catch (error) {
            toast.error("Failed To Get Tag List")
        }
    }
    const { mutate: statusChangeTaskMutation } = useMutation({
        mutationFn: async ({ newStatus, sortedTask }) => {
            // setLoader(true)
            let Tasksid = []
            if (sortedTask?.tasks && sortedTask?.tasks?.length > 0) {
                sortedTask?.tasks?.map((task) =>
                    Tasksid.push(task?._id)
                )
            }

            const response = await API.put(`/task/status/batch`, { status: newStatus, taskIds: Tasksid });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] })
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            // setLoader(false)
            toast.success('Status Changed successfully');
        },
        onError: (error) => {
            setLoader(false)
            toast.error(error?.response?.data?.message || 'Failed to Changed Stutus');
        },
    });

    useEffect(() => {
        fetchTimersAndTasks();
        fetchCurrentTask()
        fetchTagList();
    }, []);

    const tagNames = sortedTasks?.reduce((acc, status) => {
        status?.tasks?.forEach((task) => {
            task?.tags?.forEach((tag) => {
                if (!acc.some(existingTag => existingTag._id === tag._id)) {
                    acc.push(tag);
                }
            });
        });
        return acc;
    }, []);

    const StatusNames = sortedTasks
        ?.filter((status) => status.tasks.length > 0)
        .map((status, index) => ({
            _id: index + 1,
            name: status.status,
        }));
        
    const filteredTaskList = () => {
        if (selectedValues?.tags?.length > 0 || selectedValues?.users?.length > 0 || selectedValues?.taskStatuses?.length > 0) {
            return sortedTasks
                ?.map((status) => ({
                    ...status,
                    tasks: status.tasks.filter((task) => {
                        const hasMatchingTag = selectedValues?.tags?.length > 0 ?
                            task.tags.some((tag) =>
                                selectedValues?.tags.some((selectedTag) => selectedTag?._id === tag?._id)
                            ) : true;

                        const hasMatchingUser = selectedValues?.users?.length > 0 ?
                            task.assignedTo.some((assignedUserId) =>
                                selectedValues?.users.some((selectedUser) => selectedUser?._id === assignedUserId?._id)
                            ) : true;
                        const hasMatchingStatus = selectedValues?.taskStatuses?.length > 0
                            ? selectedValues.taskStatuses.some((selectedStatus) => selectedStatus?.name === task?.status)
                            : true;

                        return hasMatchingTag && hasMatchingUser && hasMatchingStatus;
                    }),
                }))
                .filter((status) => status.tasks.length > 0);
        }
        return sortedTasks || [];
    };

    const FilterUser = allTask?.map((tasked) => tasked?.assignedTo)?.flat()?.reduce((acc, assignee) => {
        if (!acc.some((item) => item._id === assignee._id)) {
            acc.push(assignee);
        }
        return acc;
    }, []);


    return (
        <div className="task-list-wrapper">
            <div className='xxs:p-5 p-2.5 bg-white rounded-lg h-full overflow-hidden'>
                <div className="">
                    {/* <div className="flex items-center justify-between mb-2">
                        <h2 className='sm:text-lg text-base font-semibold ml-4'>My Tasks</h2>
                        <div className="flex items-center gap-2.5">
                            <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass="sm:h-8 h-6" text='Quickly show closed tasks' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                                <Button
                                    size="sm"
                                    variant="default"
                                    onClick={() => setIsSelected(!isSelected)}
                                    className={`px-1 gap-1.5 font-semibold max-sm:h-6 border border-[#008748] hover:bg-[#008748] hover:text-white group ${isSelected ? "bg-[#008748] text-white " : "bg-[#00AC4826] text-[#008748]"}`}
                                >
                                    <IconColse className={`sm:h-5 h-4 sm:w-5 w-4 group-hover:text-white ${isSelected ? "text-white " : "text-[#008748]"}`} />
                                    Closed
                                </Button>
                            </Tooltip>
                            <Button size='sm' className={"font-semibold max-sm:h-6"} onClick={() => setIsOpen(true)}>Add Task</Button>
                        </div>
                    </div> */}
                    <TabGroup className="h-full">
                        <div className="flex items-center gap-2.5">
                            <TabList className="flex gap-1 border-b border-gray-200 overflow-auto flex-grow">
                                {['Board', 'List', 'Summary'].map((tab, index) => (
                                    <Tab
                                        key={index}
                                        className={({ selected }) =>
                                            clsx(
                                                "outline-none px-3 flex items-center gap-1 pb-1.5 pt-2 text-sm leading-[1] font-semibold rounded-t-md transition-all duration-200 ease-in-out",
                                                selected
                                                    ? "bg-gray-100 text-primary border-b-2 border-gray-200"
                                                    : "text-gray-400 hover:bg-gray-100 hover:text-primary"
                                            )
                                        }
                                        onClick={()=>setSelectTab(tab)}
                                    >
                                        <span>{tab === "Board" ? <IconBoardView className="w-4 h-4" /> : tab === "Summary" ? <IconSummary className="h-4 w-4" /> : <IconListView className="w-4 h-4" />}</span>   {tab}
                                    </Tab>
                                ))}
                            </TabList>
                            {
                               (selectTab === 'Board' || selectTab === 'List') &&
                                <div className="flex items-center gap-2.5">
                                    <FilterDropdown
                                        list={FilterUser}
                                        label="User"
                                        isOpen={openFilterDropdown === 0}
                                        toggleDropdown={() => handleToggle(0)}
                                        selectedValues={selectedValues.users}
                                        onSelectionChange={(values) => handleSelectionChange("users", values)}
                                    />
                                    <FilterDropdown
                                        list={StatusNames}
                                        label="Task Status"
                                        isOpen={openFilterDropdown === 1}
                                        toggleDropdown={() => handleToggle(1)}
                                        selectedValues={selectedValues.taskStatuses}
                                        onSelectionChange={(values) => handleSelectionChange("taskStatuses", values)}
                                    />
                                    <FilterDropdown
                                        list={tagNames}
                                        label="Tags"
                                        isOpen={openFilterDropdown === 2}
                                        toggleDropdown={() => handleToggle(2)}
                                        selectedValues={selectedValues.tags}
                                        onSelectionChange={(values) => handleSelectionChange("tags", values)}
                                    />
                                    <Button size='sm' className={"font-semibold max-sm:h-6"} onClick={() => setIsOpen(true)}>Add Task</Button>
                                </div>
                            }
                        </div>

                        <TabPanels className="mt-4">
                            <TabPanel key="Board" className="max-h-[calc(100vh-80px)] ">
                                {loader ? (
                                    <div className="flex justify-center items-center h-96">
                                        <Loader />
                                    </div>
                                ) : (
                                    <TaskBoardList selectedValues={selectedValues}
                                        fetchTasks={fetchTasks} projectDetails={projectDetails} sortedTasks={filteredTaskList()}
                                        sprintDetails={sprintDetails} sprintId={sprintId} statusType={statusType}
                                        handleQuickAdd={handleQuickAdd} 
                                    ></TaskBoardList>
                                )}
                            </TabPanel>
                            <TabPanel key="List" className="rounded-xl bg-gray-50 p-4 shadow">
                                {loader ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
                                    <Loader />
                                </div>) : (
                                    <div className={`h-[calc(100vh-258px)] overflow-auto mt-2`}>
                                        {
                                            filteredTaskList()?.length ? (
                                                filteredTaskList()
                                                    .filter((sortedTask) => {
                                                        if (!selectedValues.taskStatuses.some(status => status?.name === "Closed") && sortedTask.status === "Closed") {
                                                            return false;
                                                        }
                                                        return true;
                                                    })
                                                    .map((sortedTask) => {
                                                        const theme = statusType.find((status) => status.name === sortedTask.status);
                                                        return (
                                                            <div className='mb-2.5' key={sortedTask.status}>
                                                                <div className="">
                                                                    <button
                                                                        className={clsx(
                                                                            `relative text-white capitalize font-semibold inline-block text-xs px-1 py-0.5 rounded-md border`,
                                                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                                        )}
                                                                        style={{ background: `${theme?.bgColor}`, color: `${theme?.color}`, borderColor: `${theme?.color}` }}
                                                                    ><Listbox
                                                                        value={sortedTask.status}
                                                                        onChange={(newStatus) => statusChangeTaskMutation({ newStatus, sortedTask })}
                                                                    >
                                                                            <ListboxButton
                                                                                className={clsx(
                                                                                    `relative block w-full rounded-lg bg-white/5 text-left text-[10px] `,
                                                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                                                )}
                                                                            >
                                                                                <div className='flex items-center gap-1'>
                                                                                    {theme?.icon}
                                                                                    {sortedTask.status}
                                                                                </div>
                                                                            </ListboxButton>
                                                                            <ListboxOptions
                                                                                anchor="bottom"
                                                                                transition
                                                                                className={clsx(
                                                                                    'w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                                                    'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                                                )}
                                                                            >
                                                                                {statusType.map((status) => (
                                                                                    <ListboxOption
                                                                                        key={status.id}
                                                                                        value={status.value}
                                                                                        className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                                                                                    >
                                                                                        <div className="flex items-center gap-1.5">
                                                                                            {status?.icon}
                                                                                            <div className="text-xs font-medium">{status.name}</div>
                                                                                        </div>
                                                                                        <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                                                                                    </ListboxOption>
                                                                                ))}
                                                                            </ListboxOptions>
                                                                        </Listbox>
                                                                    </button>
                                                                    <div className="overflow-x-auto">
                                                                        <div className='min-w-[992px]'>
                                                                            <div className="mt-2">
                                                                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                                                    <div className="w-[60%] px-3.5">Name</div>
                                                                                    <div className="w-[10%]">Assignee</div>
                                                                                    <div className="w-[10%]">Due Date</div>
                                                                                    <div className="w-[10%] whitespace-nowrap">Time Estimate</div>
                                                                                    <div className="w-[10%]">Priority</div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                sortedTask?.tasks.map((task) => (
                                                                                    <TaskListItem userRol={userRol} tags={tags} fetchCurrentTask={fetchCurrentTask} user={user} tag={task?.tags} task={task} key={task._id} sprintDetails={sprintDetails} projectDetails={projectDetails} />
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <QuickAddTaks className='py-2 px-1.5 text-sm' task={'task'} status={sortedTask?.status} addTask={handleQuickAdd} />
                                                            </div>
                                                        );
                                                    })
                                            ) : (
                                                <div className="h-full flex items-center justify-center">
                                                    <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                                                </div>
                                            )
                                        }
                                    </div>

                                )}
                            </TabPanel>
                            <TabPanel key="summary" className="rounded-xl bg-gray-50 p-4 shadow">
                                <p className="text-sm text-gray-600">Summary content goes here.</p>
                            </TabPanel>
                        </TabPanels>
                    </TabGroup>
                </div >
                <AddTaskModal activeSprint={sprintDetails} selectedProject={projectDetails} isOpen={isOpen} setIsOpen={setIsOpen} newTask={newTask} setNewTask={setNewTask} handleAddTask={handleAddTask} loader={loading} />
            </div >
        </div >
    );
};

export default TaskList;
