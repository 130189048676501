import React, { useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Cake, ChevronUp, PartyPopper, UserPlus } from "lucide-react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { getColorFromName } from "../../lib/utils";
import { useAuth } from "../../context/AuthContext";
import UserProfile from "../../common/UserProfile";
import { useQuery } from "@tanstack/react-query";
import API from "../../services/api";
import moment from "moment";

const BirthdayAndWorkAnniversaries = () => {
  const [view, setView] = useState(true);
  const { user } = useAuth();

  const fetchTodaySpecialEvents = async () => {
    try {
      const data = await API.get(`/auth/users/today-special-events`);
      return data?.data?.data
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: todayEvents } = useQuery({
    queryKey: ['todays-events'], queryFn: fetchTodaySpecialEvents
  });
  const fetchUpcomingSpecialEvents = async () => {
    try {
      const data = await API.get(`/auth/users/upcoming-events`);
      return data?.data?.data
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: upcomingEvents } = useQuery({
    queryKey: ['coming-events'], queryFn: fetchUpcomingSpecialEvents
  });

  return (
    <div className="2xl:col-span-5 md:col-span-6 col-span-1 mt-3">
      <TabGroup className="h-full flex flex-col">
        <div className="flex items-center pe-3 border border-b-0 border-secondary bg-white rounded-t-lg">
          <TabList
            className="flex gap-1 overflow-auto flex-grow"
            onClick={() => setView(true)}
          >
            {["Birthday", "Work Anniversaries", "New joinees"].map(
              (tab, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    clsx(
                      "outline-none px-3 flex items-center gap-1 pb-2.5 pt-3 text-sm leading-[1] font-semibold rounded-t-md border-b-2  transition-all duration-200 ease-in-out",
                      selected
                        ? "text-primary border-primary"
                        : "text-gray-400 hover:text-primary border-transparent"
                    )
                  }
                >
                  <span>
                    {tab === "Birthday" ? (
                      <Cake className="w-4 h-4" />
                    ) : tab === "New joinees" ? (
                      <UserPlus className="h-4 w-4" />
                    ) : (
                      <PartyPopper className="w-4 h-4" />
                    )}
                  </span>{" "}
                  {tab}
                </Tab>
              )
            )}
          </TabList>
          <span onClick={() => setView(!view)} className="cursor-pointer">
            <ChevronUp className="w-4 h-4" />
          </span>
        </div>
        <motion.div
          initial={{ height: 0, overflow: "hidden" }}
          animate={{
            height: view ? "100%" : 0,
            overflow: "hidden",
          }}
          exit={{ height: 0, overflow: "hidden" }}
          transition={{ duration: 0.15 }}
          className="body border border-secondary bg-white rounded-b-lg"
        >
          <TabPanels className="">
            <TabPanel key="Birthday" className="p-3">
              <div className="flex flex-col gap-3.5">
                <div className="">
                  <p className="pb-1">Today's Birthdays</p>
                  <div className="flex gap-2">
                    {todayEvents?.birthdays?.length > 0 ?
                      todayEvents?.birthdays?.map((event) => (
                        <UserProfile
                          userName={event?.name}
                          tooltipText={`${event?.name}'s birthday Today`}
                        />
                      ))
                      :<p className="py-1 text-gray-600 text-xs">No birthdays Today</p>
                    }
                  </div>
                </div>
                <div className="">
                  <p className="pb-1">
                    Upcoming Birthdays (Next 7 days)
                  </p>
                  <div className="flex gap-2">
                    {upcomingEvents?.birthdaysLength > 0 ?
                      upcomingEvents?.birthdays?.map((event) => (
                        <UserProfile
                          userName={event?.name}
                          tooltipText={`${event?.name}'s birthday on ${moment(event?.birthday).format('DD-MMM')}`}
                        />
                      ))
                      : <p className="py-1 text-gray-600 text-xs">No Upcoming birthdays.</p>
                    }
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel key="Work Anniversaries" className="p-3">
              <div className="flex flex-col gap-3.5">
                <div className="">
                  <p className="pb-1">Work Anniversaries</p>
                  <div className="flex gap-2">
                    {todayEvents?.anniversaries?.length > 0 ?
                      todayEvents?.anniversaries?.map((event) => (
                        <UserProfile
                          userName={event?.name}
                          tooltipText={`${event?.name} completed ${event?.years} years`}
                        />
                      ))
                      : <p className="py-1 text-gray-600 text-xs">No Work Anniversaries today</p>
                    }
                  </div>
                </div>
                <div className="">
                  <p className="pb-1">
                    Upcoming Work Anniversaries (Next 7 days)
                  </p>
                  <div className="flex gap-2">
                    {upcomingEvents?.workAnniversariesLength > 0 ?
                      upcomingEvents?.workAnniversaries?.map((event) => (
                        <UserProfile
                          userName={event?.name}
                          tooltipText={`${event?.name}'s is completing ${event?.yearsCompleted + 1} years on ${moment(event?.joinday).format('DD-MMM')}`}
                        />
                      ))
                      : <p className="py-1 text-gray-600 text-xs">No Upcoming workAnniversaries.</p>
                    }
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel key="New joinees" className="p-3">
              <div className="flex flex-col gap-3.5">
                <div className="">
                  <p className="pb-1">New joinees today</p>
                  <div className="flex gap-2">
                    {todayEvents?.newJoinees?.length > 0 ?
                      todayEvents?.newJoinees?.map((event) => (
                        <UserProfile
                          userName={event?.name}
                          tooltipText={`${event?.name}'s joined today`}
                        />
                      ))
                      : <p className="py-1 text-gray-600 text-xs">No new joinees today.</p>
                    }
                  </div>
                </div>
                <div className="">
                  <p className="pb-1">
                    Recent joinees
                  </p>
                  <div className="flex gap-2">
                    {upcomingEvents?.newJoinersLength > 0 ?
                      upcomingEvents?.newJoiners?.map((event) => (
                        <UserProfile
                          userName={event?.name}
                          tooltipText={`${event?.name}'s will join on ${moment(event?.joinday).format('DD-MMM')}`}
                        />
                      ))
                      : <p className="py-1 text-gray-600 text-xs">No new joinees.</p>
                    }
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </motion.div>
      </TabGroup>
    </div>
  );
};

export default BirthdayAndWorkAnniversaries;
