import React from "react";

const IconBag2 = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M18.125 9.375V15C18.125 15.1658 18.0592 15.3247 17.9419 15.4419C17.8247 15.5592 17.6658 15.625 17.5 15.625H2.5C2.33424 15.625 2.17527 15.5592 2.05806 15.4419C1.94085 15.3247 1.875 15.1658 1.875 15V9.375H18.125Z"
        fill="currentColor"
      />
      <path
        d="M17.5 5H13.75V4.375C13.75 3.87772 13.5525 3.40081 13.2008 3.04917C12.8492 2.69754 12.3723 2.5 11.875 2.5H8.125C7.62772 2.5 7.15081 2.69754 6.79917 3.04917C6.44754 3.40081 6.25 3.87772 6.25 4.375V5H2.5C2.16848 5 1.85054 5.1317 1.61612 5.36612C1.3817 5.60054 1.25 5.91848 1.25 6.25V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H17.5C17.8315 16.25 18.1495 16.1183 18.3839 15.8839C18.6183 15.6495 18.75 15.3315 18.75 15V6.25C18.75 5.91848 18.6183 5.60054 18.3839 5.36612C18.1495 5.1317 17.8315 5 17.5 5ZM7.5 4.375C7.5 4.20924 7.56585 4.05027 7.68306 3.93306C7.80027 3.81585 7.95924 3.75 8.125 3.75H11.875C12.0408 3.75 12.1997 3.81585 12.3169 3.93306C12.4342 4.05027 12.5 4.20924 12.5 4.375V5H7.5V4.375ZM2.5 6.25H17.5V8.75H15V8.125C15 7.95924 14.9342 7.80027 14.8169 7.68306C14.6997 7.56585 14.5408 7.5 14.375 7.5C14.2092 7.5 14.0503 7.56585 13.9331 7.68306C13.8158 7.80027 13.75 7.95924 13.75 8.125V8.75H6.25V8.125C6.25 7.95924 6.18415 7.80027 6.06694 7.68306C5.94973 7.56585 5.79076 7.5 5.625 7.5C5.45924 7.5 5.30027 7.56585 5.18306 7.68306C5.06585 7.80027 5 7.95924 5 8.125V8.75H2.5V6.25ZM17.5 15H2.5V10H5V10.625C5 10.7908 5.06585 10.9497 5.18306 11.0669C5.30027 11.1842 5.45924 11.25 5.625 11.25C5.79076 11.25 5.94973 11.1842 6.06694 11.0669C6.18415 10.9497 6.25 10.7908 6.25 10.625V10H13.75V10.625C13.75 10.7908 13.8158 10.9497 13.9331 11.0669C14.0503 11.1842 14.2092 11.25 14.375 11.25C14.5408 11.25 14.6997 11.1842 14.8169 11.0669C14.9342 10.9497 15 10.7908 15 10.625V10H17.5V15Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconBag2;
