import { Check, Eye, X } from "lucide-react";
import moment from "moment";
import React, { useState } from "react";
import { Button } from "../../common/ui/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import { toast } from "react-toastify";
import Buttonloader from "../../common/ui/ButtonLoader";
import LeaveDetails from "../modal/LeaveDetails";
import IconEye from "../../common/svgIcons/IconEye";
import Tooltip from "../../common/ui/Tooltip";

const LeaveRequestItem = ({ leaveList }) => {
  const queryClient = useQueryClient();
  const [leaveDetailsOpen, setLeaveDetailsOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [processingLeaveId, setProcessingLeaveId] = useState(null);

  const calculateDuration = (date, totalDays) => {
    if (date && totalDays) {
      const startDate = moment(date?.date).format("YYYY-MM-DD");
      const endDate = moment(date?.date)
        .add(totalDays - 1, "day")
        .format("YYYY-MM-DD");
      if (totalDays === 0.5) {
        return `${startDate}  (${date.leaveType})`;
      }
      return `${startDate} - ${endDate}`;
    }
    return "N/A";
  };

  const { mutate: ApproveLeave } = useMutation({
    mutationFn: async ({ leave, status }) => {
      setProcessingLeaveId(leave._id);
      const response = await API.put(`/leaves/${leave._id}/approve`, {
        status: status,
      });
      return response.data;
    },
    onSuccess: (_, { status }) => {
      setProcessingLeaveId(null);
      queryClient.invalidateQueries({ queryKey: ["leave"] });
      toast.success(`Leave ${status || "request procced"} successfully`);
    },
    onError: (error) => {
      setProcessingLeaveId(null);
      console.error(`request failed:`, error?.response?.data?.message);
      toast.error(
        error?.response?.data?.message || `Failed to proceed request`
      );
    },
  });

  return (
    <div className="w-full bg-white rounded-lg shadow-sm">
      {leaveList.map((leave, index) => (
        <div
          key={leave.id || index}
          className="flex items-center gap-2 text-gray-700 border-b border-gray-200 py-2 hover:bg-gray-50 cursor-pointer text-sm font-semibold last:border-0"
        >
          <div className="w-[20%] px-3 text-gray-800">
            <div className="text-sm font-medium flex flex-col gap-1 flex-wrap">
              <p className="text-dark">
                {leave?.leaveDays?.length < 2
                  ? moment(leave?.leaveDays[0]?.date).format("MMM DD, YYYY")
                  : moment(leave?.leaveDays[0]?.date).format("MMM DD, YYYY") +
                  " - " +
                  moment(
                    leave?.leaveDays[leave?.leaveDays?.length - 1]?.date
                  ).format("MMM DD, YYYY")}
              </p>
              <span className="text-gary-400">( {leave?.totalDays} {leave?.totalDays === 1 ? 'Day' : 'Days'} )</span>
            </div>
          </div>
          <div className="w-[20%]">
            <div className="">
              <p>{leave?.type}</p>
              <p className="text-gray-500 font-normal">
                Requested on {moment(leave?.appliedAt).format("MMM DD, YYYY")}
              </p>
            </div>
          </div>
          <div className="w-[15%]">{leave.status}</div>
          {/* <div className="w-[20%]">{leave?.user?.name}</div> */}
          <div className="w-[20%]">{leave?.adminRequest && leave?.adminRequest?.name ? leave?.adminRequest?.name : leave?.user?.name}</div>
          <div className="w-[20%]">{leave?.actionAt ? moment(leave?.actionAt).format("MMM DD, YYYY") : "-"}</div>
          <div className="w-[20%] truncate pe-2.5">{leave?.reason}</div>
          <div className="w-[15%] flex items-center gap-1.5">
            {leave?.status === "Pending" ?
              processingLeaveId === leave._id ? (
                <Buttonloader />
              ) : (
                <>
                  <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Accept' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                    <Button
                      className="p-0 h-6 w-6 shadow-none rounded-md bg-transparent border border-green-600 hover:bg-green-600 group"
                      onClick={() => {
                        ApproveLeave({ leave: leave, status: "Approved" });
                      }}
                    >
                      <Check className="h-4 w-4 text-green-600 group-hover:text-white" />
                    </Button>
                  </Tooltip>
                  <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Reject' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                    <Button
                      className="p-0 h-6 w-6 shadow-none rounded-md bg-transparent border border-red-600 hover:bg-red-600 group"
                      onClick={() => {
                        ApproveLeave({ leave: leave, status: "Rejected" });
                      }}
                    >
                      <X className="h-4 w-4 text-red-600 group-hover:text-white" />
                    </Button>
                  </Tooltip>
                  <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='View' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                    <Button
                      className="p-0 h-6 w-6 shadow-none rounded-md bg-transparent border border-primary hover:bg-primary group"
                      onClick={() => {
                        setLeaveDetailsOpen(true);
                        setSelectedLeave(leave);
                      }}
                    >
                      <IconEye className="h-4 w-4 text-primary group-hover:text-white" />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='View' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                  <Button
                    className="p-0 h-6 w-6 shadow-none rounded-md bg-transparent border border-primary hover:bg-primary group"
                    onClick={() => {
                      setLeaveDetailsOpen(true);
                      setSelectedLeave(leave);
                    }}
                  >
                    <IconEye className="h-4 w-4 text-primary group-hover:text-white" />
                  </Button>
                </Tooltip>
              )}
          </div>
        </div>
      ))}

      {/* Leave Details Modal */}
      {selectedLeave && leaveDetailsOpen && (
        <LeaveDetails
          leaveDetails={selectedLeave}
          setOpen={setLeaveDetailsOpen}
          isOpen={leaveDetailsOpen}
        />
      )}
    </div>
  );
};

export default LeaveRequestItem;
