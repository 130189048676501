import React from "react";

const IconProjectSetup = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M14.3996 16.875H5.59961L9.99961 10L14.3996 16.875Z"
        fill="currentColor"
      />
      <path
        d="M18.6516 16.5382L10.7422 4.17961L12.4016 1.58664C12.4458 1.51749 12.476 1.44031 12.4904 1.3595C12.5047 1.2787 12.5031 1.19585 12.4855 1.11568C12.4678 1.03552 12.4346 0.959614 12.3876 0.892299C12.3407 0.824984 12.2809 0.767579 12.2117 0.72336C12.1426 0.679142 12.0654 0.648976 11.9846 0.634586C11.9038 0.620195 11.8209 0.621862 11.7408 0.639491C11.6606 0.657119 11.5847 0.690365 11.5174 0.737328C11.4501 0.784292 11.3927 0.844055 11.3485 0.913204L10 3.02024L8.65158 0.91086C8.56228 0.771207 8.42116 0.67275 8.25926 0.637147C8.09736 0.601544 7.92796 0.631713 7.7883 0.721016C7.64865 0.81032 7.55019 0.951443 7.51459 1.11334C7.47899 1.27524 7.50916 1.44464 7.59846 1.5843L9.25783 4.17961L1.34846 16.5382C1.28806 16.6327 1.25417 16.7416 1.25036 16.8537C1.24655 16.9657 1.27295 17.0767 1.32679 17.1751C1.38064 17.2734 1.45994 17.3555 1.5564 17.4126C1.65285 17.4698 1.76291 17.4999 1.87502 17.4999H18.125C18.2371 17.4999 18.3472 17.4698 18.4436 17.4126C18.5401 17.3555 18.6194 17.2734 18.6732 17.1751C18.7271 17.0767 18.7535 16.9657 18.7497 16.8537C18.7459 16.7416 18.712 16.6327 18.6516 16.5382ZM6.74221 16.2499L10 11.1593L13.2578 16.2499H6.74221ZM14.7422 16.2499L10.5235 9.6632C10.4669 9.57481 10.3891 9.50207 10.2971 9.45169C10.205 9.4013 10.1018 9.37489 9.9969 9.37489C9.89198 9.37489 9.78876 9.4013 9.69673 9.45169C9.60471 9.50207 9.52685 9.57481 9.47033 9.6632L5.25783 16.2499H3.01721L10 5.33898L16.9828 16.2499H14.7422Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconProjectSetup;
