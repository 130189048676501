import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import API from "../../services/api";
import UserProfile from "../../common/UserProfile";
import { useAuth } from "../../context/AuthContext";

const NotInToday = ({ title }) => {

  const fetchAllUserList = async ({ }) => {
    try {
      const { data } = await API.get(`/timer/attendance/not-clocked-in`);
      return data?.data;
    } catch (err) {
      console.error(err.message);
      return [];
    } finally {
    }
  };

  const { data: userData } = useQuery({
    queryKey: ["not-in"], // Include page in queryKey
    queryFn: fetchAllUserList,
    keepPreviousData: true, // Retain previous data while fetching new data
  });


  return (
    <>
      <div className="flex items-center justify-between p-3 pb-0">
        <h2 className="text-base">{title}</h2>
      </div>
      <div className="time mt-6 me-3 ms-3 pb-3 overflow-auto">
        <div className="flex gap-3.5">
          {userData?.length > 0 ?
            userData?.map((user) => (
              <UserProfile
                userName={user?.name}
                tooltipText={user?.name}
              />
            ))
            : <p>All employees are already in.</p>
          }
        </div>
      </div>
    </>
  );
};

export default NotInToday;
