import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { getColorFromName } from "../lib/utils";

const UserProfile = ({ userName, tooltipText, leave, leaveType }) => {
  
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild>
          <div className="flex flex-col items-center w-fit text-center">
            <div className="relative">
              <span
                className="font-medium uppercase h-10 w-10 p-2 text-white rounded-full flex items-center justify-center text-sm first:ms-0 -ms-2.5 hover:z-[9] border border-border"
                style={{
                  background: `${getColorFromName(userName)}`,
                }}
              >
                {userName
                  ?.split(" ")
                  .slice(0, 2)
                  .map((name) => name.slice(0, 1))}
              </span>
              {leave && (
                <div
                  className={`absolute -bottom-1 left-6 w-4 h-4 rounded-full border z-10 overflow-hidden bg-white after:absolute after:h-full after:bottom-0 after:bg-primary ${
                    leaveType === 'First Half'
                      ? "after:w-1/2 after:left-0"
                      : leaveType === 'Second Half'
                      ? "after:w-1/2 after:right-0"
                      : "after:w-full after:left-0"
                  }`}
                ></div>
              )}
            </div>
            <span className="text-sm truncate">{userName?.split(" ")[0]}</span>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            align="center"
            side="bottom"
            className="bg-muted  rounded-md p-1 text-xs font-semibold text-white max-sm:hidden"
            sideOffset={5}
          >
            <span className="me-1">{tooltipText}</span>
            <Tooltip.Arrow className="TooltipArrow fill-muted" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default UserProfile;
