import React, { useState } from 'react'
import API from '../../services/api';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import FestivalListModal from '../modal/FestivalListModal';

const Holiday = () => {
    const [open, setOpen] = useState(false)
    const fetchHolidayList = async () => {
        try {
            const { data, loading } = await API.get(`/holiday/list`);
            return data?.holidays;
        } catch (err) {
            console.error(err.message);
        }
    };

    const { data: holidayList } = useQuery({
        queryKey: ['holiday'], queryFn: fetchHolidayList
    });

    const upcomingHoliday = holidayList
        ?.filter((holiday) => moment(holiday.date).isAfter(moment()))
        ?.sort((a, b) => moment(a.date).diff(moment(b.date)))
        ?.[0];
    return (
        <div className='xl:col-span-3 md:col-span-6 row-span-1 rounded-lg bg-white text-gray-700 border border-secondary p-3'>
                <div className='flex justify-between items-center'>
                    <h2 className='font-medium'>Holiday</h2>
                    <button
                        className="text-sm underline"
                        onClick={() => setOpen(true)}
                    >
                        View All
                    </button>
                </div>
                {upcomingHoliday ? (
                    <div className='mt-4'>
                        <h2 className='text-2xl mt-1.5'>{upcomingHoliday.name}</h2>
                        <h2 className='text-base'>
                            {moment(upcomingHoliday.date).format('ddd, DD MMMM, YYYY')}
                        </h2>
                    </div>
                ) : (
                
                    <p className='text-lg'>No upcoming holidays.</p>
                )}
            {open && <FestivalListModal open={open} setOpen={setOpen} list={holidayList} />}
        </div>
    )
}

export default Holiday
