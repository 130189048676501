import { Dialog, DialogPanel, DialogTitle, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'
import { X } from 'lucide-react'
import React, { useState } from 'react'
import Loader from '../../common/ui/Loader'
import Buttonloader from '../../common/ui/ButtonLoader'
import LeavePolicyDetails from '../../common/LeavePolicyDetails'

const LeavePolicy = ({ setIsOpen, isOpen }) => {
    const [selectedTab, setSelectedTab] = useState();
    const date = new Date().getFullYear();
    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen h-screen bg-black/30">
                <div className="flex min-h-full w-full items-center justify-center">
                    <DialogPanel className="w-screen h-screen bg-white">
                        <DialogTitle
                            as="h3"
                            className="text-base font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border"
                        >
                            <p className="text-xl font-bold py-1 px-1">Leave Policy Explanation</p>
                            <span className="cursor-pointer" onClick={() => setIsOpen(false)}>
                                <X className="text-dark/50" />
                            </span>
                        </DialogTitle>
                        <TabGroup className="h-full flex flex-col">
                            <TabList className="flex gap-1 p-4">
                                <Tab
                                    onClick={() => {
                                        setSelectedTab('CompsOff');
                                    }}
                                    key={'CompsOff'}
                                    className={({ selected }) =>
                                        clsx(
                                            "outline-none px-3 h-[30px] flex items-center gap-2.5 pb-1.5 pt-2 text-sm leading-[1] font-semibold rounded-t-md transition-all duration-200 ease-in-out",
                                            selected
                                                ? "bg-gray-100 text-primary border-b-2 border-gray-200"
                                                : "text-gray-400 hover:bg-gray-100 hover:text-primary"
                                        )
                                    }
                                >
                                    CompsOff
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setSelectedTab('Floater');
                                    }}
                                    key={'Floater'}
                                    className={({ selected }) =>
                                        clsx(
                                            "outline-none detail-section px-3 h-[30px] flex items-center gap-2.5 pb-1.5 pt-2 text-sm leading-[1] font-semibold rounded-t-md transition-all duration-200 ease-in-out",
                                            selected
                                                ? "bg-gray-100 text-primary border-b-2 border-gray-200"
                                                : "text-gray-400 hover:bg-gray-100 hover:text-primary"
                                        )
                                    }
                                >
                                    Floater Leave
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setSelectedTab('Paid');
                                    }}
                                    key={'Paid'}
                                    className={({ selected }) =>
                                        clsx(
                                            "outline-none px-3 h-[30px] flex items-center gap-1 pb-1.5 pt-2 text-sm leading-[1] font-semibold rounded-t-md transition-all duration-200 ease-in-out",
                                            selected
                                                ? "bg-gray-100 text-primary border-b-2 border-gray-200"
                                                : "text-gray-400 hover:bg-gray-100 hover:text-primary"
                                        )
                                    }
                                >
                                    Paid Leave
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setSelectedTab('Unpaid');
                                    }}
                                    key={'Unpaid'}
                                    className={({ selected }) =>
                                        clsx(
                                            "outline-none px-3 h-[30px] flex items-center gap-1 pb-1.5 pt-2 text-sm leading-[1] font-semibold rounded-t-md transition-all duration-200 ease-in-out",
                                            selected
                                                ? "bg-gray-100 text-primary border-b-2 border-gray-200"
                                                : "text-gray-400 hover:bg-gray-100 hover:text-primary"
                                        )
                                    }
                                >
                                    Unpaid Leave
                                </Tab>
                            </TabList>
                            <TabPanels className="p-6 flex-grow overflow-y-auto max-h-screen mb-20">
                                <TabPanel key={'CompsOff'} className="h-full">
                                    <LeavePolicyDetails
                                        heading={'CompsOff'}
                                        detail={
                                            'This leave type is used to provide additional leave for the work done by employee on off/extrawork/ etc'
                                        }
                                        leaveQuota={
                                            <p className='p-4 border border-primary text-primary'>
                                                You are allocated a total of 0 days of leave in a year beginning January {date} till December {date}. You can consume this leave in the same year they are accrued/credited.
                                            </p>
                                        }
                                        explanation={
                                            <>
                                                <p className='mb-2'>You are allowed to have more than annual quota of leave, if you are granted additional leave manually by your management</p>
                                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                    <div className="w-full px-3.5">Date</div>
                                                    <div className="w-[40%]">Change (days)</div>
                                                    <div className="w-[40%]">Balance (days)</div>
                                                </div>
                                                {[
                                                    { date: `01-Jan-${date}`, change: "+2", balance: "5" },
                                                ].map((row, index) => (
                                                    <div
                                                        key={index}
                                                        className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm hover:bg-primary/5 last:border-0"
                                                    >
                                                        <div className="w-full flex gap-2 items-center border-e border-border p-2">
                                                            <p>{row.date}</p>
                                                        </div>
                                                        <div className="w-[40%] p-2">
                                                            <p>{row.change}</p>
                                                        </div>
                                                        <div className="w-[40%] p-2">
                                                            <p>{row.balance}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="flex items-center mt-4">
                                                    <p>*- Consumed Leave Balance imported from Excel</p>
                                                    <p>#- Carryover Leave Balance imported from Excel</p>
                                                    <p>##- Carryover Leave Balance from year end processing</p>
                                                    <p>%- Carryover Leave Balance from another leave type</p>
                                                    <p>^- Leave expired because of expiration policy</p>
                                                    <p>@- Earned Leave Carryover imported from Excel</p>
                                                    <p>$- Earned Leave Accrual Imported using Excel</p>
                                                </div>
                                                <h1 className='font-semibold text-2xl mt-4'>Applying for a Leave</h1>
                                                <p className='mt-4 mb-2'>Comp Offs can be applied by you on your own or a manager can apply on your behalf.</p>
                                                <p className='p-4 border border-primary text-primary'>
                                                    You can apply for full day, as well as half-day of Comp Offs.
                                                </p>
                                                <h1 className='font-semibold text-2xl mt-4'>Restriction for Applying Leave</h1>
                                                <p className='mt-2 mb-2'>The following restrictions are enforced that can prevent you from applying a leave</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>
                                                        For applying a leave duration of 1 or more days, you need to apply at least 3 calendar days before of which there have to be at least 3 working days
                                                    </li>
                                                    <li>
                                                        You are not allowed to apply past day leave. Your manager or HR manager can apply on your behalf
                                                    </li>
                                                    <li>Comp Offs requires comment while applying leave</li>
                                                    <li>
                                                        There has to be a gap of 2 calendar days between two leave requests
                                                    </li>
                                                    <li>Comp Offs cannot be applied when in notice period</li>
                                                </ul>
                                                <h1 className='font-semibold text-2xl mt-4'>Restrictions after joining</h1>
                                                <p className='py-2'>You are able to consume leave 0 days after probation period. While you may not eligible to consume your leave during this period, your leave accrual happens as described in the previous section.</p>
                                                <h1 className='font-semibold text-2xl mt-4'>Holidays during leave</h1>
                                                <p className='py-2'>Holiday accompanying leave is treated as leave when total number of leave exceed 0 calendar days and Holiday is right between two leave days (Holiday is sandwiched between leave days).</p>
                                                <h1 className='font-semibold text-2xl mt-4'>Weekly Offs during leave</h1>
                                                <p className='py-2'>Weekly off day accompanying leave is treated as leave when total number of leave exceed 0 calendar days and Weekly off day is right between two leave days (Weekly off day is sandwiched between leave days).</p>
                                                <h1 className='font-semibold text-2xl mt-4'>Leave balances at the end of the year (Dec {date})</h1>
                                                <p className='py-2'>At the end of leave calendar year which is (Dec {date}), the following policies apply.</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>If the leave balance is more than 0 days, upto 3 days will be carried forward, and 0 days will be encashed.</li>
                                                </ul>
                                                <p className='py-2'>Negative leave balances that may have accumulated for employees who have taken leave beyond their annual quota gets nullified and balance is reset to zero</p>
                                            </>
                                        }
                                    />
                                </TabPanel>
                                <TabPanel key={'Floater'} className="h-full">
                                    <LeavePolicyDetails
                                        heading={'Floater Leave'}
                                        detail={
                                            'A floating holiday provides an employee with paid time off to observe a holiday'
                                        }
                                        leaveQuota={
                                            <p className='p-4 border border-primary text-primary'>
                                                You are allocated a total of 0 days of leave in a year beginning January {date} till December {date}. You can consume this leave in the same year they are accrued/credited.
                                            </p>
                                        }
                                        explanation={
                                            <>
                                                <p className='mb-2'>You are allowed to have more than annual quota of leave, if you are granted additional leave manually by your management</p>
                                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                    <div className="w-full px-3.5">Date</div>
                                                    <div className="w-[40%]">Change (days)</div>
                                                    <div className="w-[40%]">Balance (days)</div>
                                                </div>
                                                {[
                                                    { date: `01-Jan-${date}`, change: "+2", balance: "5" },
                                                ].map((row, index) => (
                                                    <div
                                                        key={index}
                                                        className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm hover:bg-primary/5 last:border-0"
                                                    >
                                                        <div className="w-full flex gap-2 items-center border-e border-border p-2">
                                                            <p>{row.date}</p>
                                                        </div>
                                                        <div className="w-[40%] p-2">
                                                            <p>{row.change}</p>
                                                        </div>
                                                        <div className="w-[40%] p-2">
                                                            <p>{row.balance}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="flex items-center mt-4">
                                                    <p>*- Consumed Leave Balance imported from Excel</p>
                                                    <p>#- Carryover Leave Balance imported from Excel</p>
                                                    <p>##- Carryover Leave Balance from year end processing</p>
                                                    <p>%- Carryover Leave Balance from another leave type</p>
                                                    <p>^- Leave expired because of expiration policy</p>
                                                    <p>@- Earned Leave Carryover imported from Excel</p>
                                                    <p>$- Earned Leave Accrual Imported using Excel</p>
                                                </div>
                                                <h1 className='font-semibold text-2xl mt-4'>Applying for a Leave</h1>
                                                <p className='mt-4 mb-2'>Floater Leave can be applied by you on your own or a manager can apply on your behalf.</p>
                                                <p className='p-4 border border-primary text-primary'>
                                                    You can apply for only full day of Floater Leave.
                                                </p>
                                                <h1 className='font-semibold text-2xl mt-4'>Restriction for Applying Leave</h1>
                                                <p className='mt-2 mb-2'>The following restrictions are enforced that can prevent you from applying a leave</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>
                                                        For applying a leave duration of 1 or more days, you need to apply at least 3 calendar days before of which there have to be at least 3 working days
                                                    </li>
                                                    <li>
                                                        You can apply leave for a past day, but not beyond than 10 calendar days back.
                                                    </li>
                                                    <li>Floater Leave requires comment while applying leave</li>
                                                    <li>
                                                        Floater Leave cannot be applied when in notice period
                                                    </li>
                                                </ul>
                                                <h1 className='font-semibold text-2xl mt-4'>Restrictions after joining</h1>
                                                <p className='py-2'>You are able to consume leave 0 days after probation period. While you may not eligible to consume your leave during this period, your leave accrual happens as described in the previous section.</p>
                                                <h1 className='font-semibold text-2xl mt-4'>Leave balances at the end of the year (Dec {date})</h1>
                                                <p className='py-2'>At the end of leave calendar year which is (Dec {date}), the following policies apply.</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>All Leave balances expire or get reset.</li>
                                                </ul>
                                                <p className='py-2'>Negative leave balances that may have accumulated for employees who have taken leave beyond their annual quota gets nullified and balance is reset to zero</p>
                                            </>
                                        }
                                    />
                                </TabPanel>
                                <TabPanel key={'Paid'} className="h-full">
                                    <LeavePolicyDetails
                                        heading={'Paid Leave'}
                                        detail={
                                            'This leave will be paid when not used'
                                        }
                                        leaveQuota={
                                            <p className='p-4 border border-primary text-primary'>
                                                You are allocated a total of 10 days of leave in a year beginning January {date} till December {date}. You can consume this leave in the same year they are accrued/credited.
                                            </p>
                                        }
                                        explanation={
                                            <>
                                                <p className='mb-2'>You are allowed to have more than annual quota of leave, if you are granted additional leave manually by your management.</p>
                                                <h1 className='font-semibold text-2xl mt-4'>Leave Accrual</h1>
                                                <p className='p-4 border border-primary text-primary mb-4'>
                                                    While your annual quota is 10, you are not eligible to consume all of those leave right away. Leave accrue at regular intervals as defined below. Accrued leave balance are the leave available for you to consume at any time.
                                                </p>
                                                <p className='p-4 border border-primary text-primary'>
                                                    As per your leave plan, Paid Leave accrues once every month on 1 st.
                                                </p>
                                                <p className='mt-4'>Leave accrual starts 0 day(s) after probation ends. The leave accrual rate varies based on your tenure in the organization as defined below</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8 mb-6'>
                                                    <li>After 10 years from joining, 1.5 days are accrued Once every month</li>
                                                </ul>
                                                <p>At any given time, you are limited to apply for those many leave that got accrued as of the date when a leave request is made and not on future day for which leave is applied.</p>
                                                <p>Ex:- If you are applying for a leave on March 01 for a leave on April 12th, leave accrued as of March 01 are considered.</p>
                                                <h1 className='font-semibold text-2xl mt-4 mb-3'>Applying for a Leave</h1>
                                                <p>Paid Leave can be applied by you on your own or a manager can apply on your behalf.</p>
                                                <p className='p-4 border border-primary text-primary'>
                                                    You can apply for full day, as well as half-day of Paid Leave.
                                                </p>
                                                <h1 className='font-semibold text-2xl mt-4'>Restriction for Applying Leave</h1>
                                                <p className='mt-4 mb-2'>Comp Offs can be applied by you on your own or a manager can apply on your behalf.</p>
                                                <p className='p-4 border border-primary text-primary'>
                                                    You can apply for full day, as well as half-day of Comp Offs.
                                                </p>
                                                <h1 className='font-semibold text-2xl mt-4'>Restriction for Applying Leave</h1>
                                                <p className='mt-2 mb-2'>The following restrictions are enforced that can prevent you from applying a leave</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>
                                                        For applying a leave duration of 1 or more days, you need to apply at least 1 calendar days before of which there have to be at least 1 working days
                                                    </li>
                                                    <li>
                                                        You can apply leave for a past day, but not beyond than 3 calendar days back.
                                                    </li>
                                                    <li>Paid Leave requires comment while applying leave</li>
                                                    <li>
                                                        Paid Leave requires document proof, if leave request exceeds 1 calendar days
                                                    </li>
                                                </ul>
                                                <h1 className='font-semibold text-2xl mt-4'>Restrictions after joining</h1>
                                                <p className='py-2'>You are able to consume leave 0 days after probation period. While you may not eligible to consume your leave during this period, your leave accrual happens as described in the previous section.</p>
                                                <h1 className='font-semibold text-2xl mt-4'>Leave balances at the end of the year (Dec {date})</h1>
                                                <p className='py-2'>At the end of leave calendar year which is (Dec {date}), the following policies apply.</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>If the leave balance is more than 0 days, upto 3 days will be carried forward, and 0 days will be encashed.</li>
                                                </ul>
                                                <p className='py-2'>Negative leave balances that may have accumulated for employees who have taken leave beyond their annual quota gets deducted from salary</p>
                                            </>
                                        }
                                    />
                                </TabPanel>
                                <TabPanel key={'Unpaid'} className="h-full">
                                    <LeavePolicyDetails
                                        heading={'Unpaid Leave'}
                                        detail={
                                            'Loss of pay'
                                        }
                                        leaveQuota={
                                            <p className=''>
                                                You have no upper limit on available annual leave quota
                                            </p>
                                        }
                                        explanation={
                                            <>
                                                <h1 className='font-semibold text-2xl mt-4 mb-3'>Applying for a Leave</h1>
                                                <p>Unpaid Leave can be applied by you on your own or a manager can apply on your behalf.</p>
                                                <p className='p-4 border border-primary text-primary'>
                                                    You can apply for full day, as well as half-day of Unpaid Leave.
                                                </p>
                                                <h1 className='font-semibold text-2xl mt-4'>Restriction for Applying Leave</h1>
                                                <p className='mt-2 mb-2'>The following restrictions are enforced that can prevent you from applying a leave</p>
                                                <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                                                    <li>
                                                        For applying a leave duration of 1 or more days, you need to apply at least 0 calendar days before of which there have to be at least 0 working days
                                                    </li>
                                                    <li>
                                                        You can apply leave for a past day, but not beyond than 10 calendar days back.
                                                    </li>
                                                    <li>Paid Leave requires comment while applying leave</li>
                                                </ul>
                                                <h1 className='font-semibold text-2xl mt-4'>Restrictions after joining</h1>
                                                <p className='py-2'>You are able to consume leave 0 days after joining date.</p>
                                            </>
                                        }
                                    />
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default LeavePolicy
