import { ArrowDownLeft, ArrowUpRight, Eye } from "lucide-react";
import moment from "moment/moment";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../common/ui/Button";
import Tooltip from "../../common/ui/Tooltip";
import RenderTimelin from "./RenderTimelin";

const Holidays = [
  { date: '2025-01-14', name: 'Makar Sankranti' },
  { date: '2025-01-15', name: 'Makar Sankranti' },
  { date: '2025-01-26', name: 'Republic Day' },
  { date: '2025-08-15', name: 'Independence Day' },
  { date: '2024-12-31', name: 'New Year' },
  { date: '2024-12-25', name: 'Christmas' },
];

const AttendanceListItem = ({ attendanceData }) => {
  const { date, arriveTime, effectiveHours, grossHours, isOnLeave, sessions, leaveInfo } = attendanceData;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const popupRef = useRef(null);

  const momentDate = moment(date, "YYYY-MM-DD");
  const isWeekend = momentDate.isValid() && (momentDate.day() === 0 || momentDate.day() === 6);

  const togglePopup = () => setIsPopupOpen((prev) => !prev);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const holiday = Holidays.find((holiday) => holiday.date === date);
  const isHoliday = holiday !== undefined;
  const holidayName = holiday?.name || holiday

  const calculateBreakDuration = (effectiveHours, grossHours) => {
    const toMilliseconds = (time) => {
      const [h, m, s] = (time.match(/(\d+)h\s*(\d+)m\s*(\d+)s/) || []).slice(1).map(Number);
      return (h || 0) * 3600000 + (m || 0) * 60000 + (s || 0) * 1000;
    };

    const effectiveMs = toMilliseconds(effectiveHours);
    const grossMs = toMilliseconds(grossHours);

    if (grossMs > effectiveMs) {
      const diffMs = grossMs - effectiveMs;
      const hours = Math.floor(diffMs / 3600000);
      const minutes = Math.floor((diffMs % 3600000) / 60000);
      const seconds = Math.floor((diffMs % 60000) / 1000);

      return `${hours.toString().padStart(2, "0")}h ${minutes
        .toString()
        .padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`;
    } else {
      return "-";
    }
  };

  return (
    <div onClick={() => togglePopup()} className={`body w-full flex text-muted border-b border-border cursor-default text-sm font-medium relative ${!isWeekend && isOnLeave && leaveInfo?.[0]?.type == "Full Day" ? "bg-[#E6E6FA]" : isWeekend && !sessions.length ? "bg-[#c2b89d33]" : isHoliday && "bg-[#e0bf6333]"}`}>
      <div className="w-[15%] p-1 py-3 flex items-center">
        {momentDate.isValid() ? (
          <span className="flex items-center gap-2 relative">
            {momentDate.format("MMM DD, ddd")}
            <span
              className={`text-[10px] font-medium px-1 py-[3px] rounded-sm leading-none ${isOnLeave && !isWeekend
                ? "bg-primary/70 text-white"
                : isWeekend && !sessions.length
                  ? "bg-[#f5deb3] text-[#6C6C80]"
                  : isHoliday
                    ? "bg-[#bd901433] text-[#6C6C80]"
                    : ""
                }`}
            >
              {!isWeekend && isOnLeave ? (
                <div className="relative">
                  <Tooltip
                    detailClass='top-5 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Leave' bgColor={'bg-primary'} borderColor={'border-t-primary'}
                  >
                    <span>LEAVE</span>
                  </Tooltip>
                </div>
              ) : isWeekend && !sessions.length ? (
                <div className="relative">
                  <Tooltip
                    detailClass='top-5 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Weekly-Off' bgColor={'bg-primary'} borderColor={'border-t-primary'}
                  >
                    <span>W-OFF</span>
                  </Tooltip>
                </div>
              ) : isHoliday ? (
                <div className="relative">
                  <Tooltip
                    detailClass='top-5 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text={`${holidayName}`} bgColor={'bg-primary'} borderColor={'border-t-primary'}
                  >
                    <span>HLDY</span>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </span>
          </span>
        ) : (
          "Invalid Date"
        )}
      </div>
      {!isWeekend && !sessions.length && isOnLeave && leaveInfo?.[0]?.type == "Full Day" ? (
        <div className="w-full py-3 flex items-center justify-center">
          <p className="text-[#6C6C80] font-semibold">Leave</p>
        </div>
      ) : isWeekend && !sessions.length ? (
        <div className="w-full py-3 flex items-center justify-center">
          <p className="text-[#6C6C80] font-semibold">Full Day Weekly-Off</p>
        </div>
      ) : isHoliday ? (
        <div className="w-full py-3 flex items-center justify-center">
          <p className="text-[#6C6C80] font-semibold">Holiday</p>
        </div>
      ) : (
        <>
          <div className="w-[40%] py-3 flex items-center">
            <RenderTimelin
              sessions={sessions}
              leaveInfo={leaveInfo?.[0]}
              date={date}
            />
          </div>

          <div className="w-[15%] py-3 flex items-center">{effectiveHours}</div>
          <div className="w-[15%] py-3 flex items-center">{grossHours}</div>
          <div className="w-[15%] py-3 flex items-center">
            {grossHours && effectiveHours
              ? calculateBreakDuration(effectiveHours, grossHours)
              : "-"}
          </div>
          <div className="w-[10%] py-3 flex items-center">{arriveTime ? moment(arriveTime, "HH:mm:ss").format("hh[h] mm[m] ss[s] A") : ' - '} </div>
          <div className="w-[5%] py-3 flex items-center">
            <Button
              className="p-0 h-6 w-6 shadow-none rounded-md bg-transparent border border-primary hover:bg-primary group"
              onClick={(e) => {
                e.stopPropagation();
                togglePopup();
              }}
            >
              <Tooltip text="Attandance Log"
                detailClass='top-8 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                <Eye className="h-4 w-4 text-primary group-hover:text-white" />
              </Tooltip>
            </Button>
          </div>
        </>
      )}

      {isPopupOpen && !isWeekend && !isHoliday && (
        <div
          ref={popupRef}
          className="absolute right-0 top-full mt-0 w-56 bg-white shadow-custom rounded-lg p-2.5 z-10 border border-secondary"
        >
          <div className="bg-gray-200 p-2 rounded-t-lg">
            <p className="text-sm text-gray-700 font-semibold">
              General Shift ({momentDate.format("MMM DD, ddd")})
            </p>
            <p className="text-sm text-gray-500">
              9:00 AM - 6:00 PM
            </p>
          </div>

          <div className="border-b border-gray-300 my-2"></div>

          {sessions.length > 0 ? (
            <ul className="mt-2 flex flex-col gap-1.5">
              {sessions.map((entry, index) => (
                <li key={index} className="flex items-center justify-between">
                  {entry.checkInTime && (
                    <span className="flex items-center gap-1">
                      <ArrowDownLeft className="text-green-400 h-4 w-4" />
                      {entry.checkInTime}
                    </span>
                  )}
                  {entry.checkOutTime && (
                    <span className="flex items-center gap-1">
                      <ArrowUpRight className="text-red-400 h-4 w-4" />
                      {entry.checkOutTime}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-gray-400 mt-2">No Attendance Found</p>
          )}
        </div>
      )}
    </div>

  );
};

export default AttendanceListItem;


