import React, { useMemo } from 'react'
import AssigneePopover from './ui/AssigneePopover'
import moment from 'moment'
import { getColorFromName } from '../lib/utils'
import { priorityTypes } from '../staticData/data'

const TaskReportCard = ({ report, title }) => {
    
  const backgroundColor = getColorFromName(report?.name)
  const renderIcon = (icon, label) => (
    <span className="flex items-center gap-1.5" aria-label={label}>
      {icon}
    </span>
  );

  const priorityColor = useMemo(
    () => priorityTypes.find((status) => status.name === report.tasks[0]?.priority),
    [report.tasks[0]?.priority]
  );

  return (
    <div className={`card flex flex-col gap-1.5 ${title === "Free" ? "px-2 p-1" : "p-2.5"} rounded-md bg-gray-50 border border-border`}>
      <div className="flex items-center gap-2 my-1">
        <div className="w-6 h-6 rounded-full flex items-center capitalize justify-center text-white text-[10px]" style={{ backgroundColor: `${backgroundColor}` }}>
          {report.name
            ?.split(' ')
            .slice(0, 2)
            .map((name) => name.slice(0, 1).toUpperCase())}
        </div>
        <div>
          <h1 className="font-medium text-sm text-gray-800">{report.name}</h1>
        </div>
      </div>
      <div className={`${title === "Free" ? "hidden" : ""} flex flex-col gap-1.5`}>
        <div className="flex gap-2 text-sm">
          <h4 className='min-w-[100px] whitespace-nowrap'>Task :</h4>
          <h2 className='text-muted'>{report.tasks[0]?.title}</h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <h4 className='min-w-[100px]'>Project :</h4>
          <h2 className='text-muted'>{report.tasks[0]?.projectId.name}</h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <h4 className='min-w-[100px]'>Assignee :</h4>
          <h2 className='text-muted'>
            <AssigneePopover
              isReport={true} reportedTo={report?.tasks[0]?.reportTo} showReportTo={true}
              assignedTo={report?.tasks[0]?.assignedTo } taskId={undefined} onAssigneeUpdate={undefined} isArchive={undefined} />
          </h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <h4 className='min-w-[100px]'>Sprint :</h4>
          <h2 className='text-muted'>{report.tasks[0]?.sprintId.name} - {moment(report.tasks[0]?.sprintId.startDate).format('DD/MM/YYYY')} - {moment(report.tasks[0]?.sprintId.endDate).format('DD/MM/YYYY')}</h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <h4 className='min-w-[100px]'>Due Date :</h4>
          <h2 className='text-muted'>{moment(report.tasks[0]?.dueDate).format('DD MMM YYYY')}</h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <h4 className='min-w-[100px]'>Time Estimate :</h4>
          <h2 className='text-muted'>{report.tasks[0]?.estimatedHours ? `${report.tasks[0]?.estimatedHours}hr` : "-"}</h2>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <h4 className='min-w-[100px]'>Priority :</h4>
          <div className='flex gap-1 text-muted'>{renderIcon(priorityColor?.icon, `${report.tasks[0]?.priority} icon`)} {report.tasks[0]?.priority}</div>
        </div>
      </div>
    </div>
  )
}

export default TaskReportCard
