import React, { useEffect, useState } from 'react'
import API from '../../services/api';
import AddEditModal from '../modal/AddUserModal';
import { Button } from '../../common/ui/Button';
import AddUserModal from '../modal/AddUserModal';
import { toast } from 'react-toastify';
import NoDataUI from '../../common/NoDataUI';
import Loader from '../../common/ui/Loader';
import { useMutation } from '@tanstack/react-query';
import Buttonloader from '../../common/ui/ButtonLoader';
import UserAttendanceItemList from './UserAttendanceItemList';
import { useQuery } from '@tanstack/react-query';
import { Calendar } from 'lucide-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const UserAttandanceList = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const fetchUsersAttendance = async (date) => {
        try {
            const formattedDate = date.toISOString().slice(0, 10);

            const { data } = await API.get(`/timer/report/user-summary?date=${formattedDate}`);
            return data?.data;
        } catch (err) {
            console.error(err.response?.data?.message);
        }
    };

    const { data: userAttandanceData, isLoading } = useQuery({
        queryKey: ["UserattendanceData", selectedDate],
        queryFn: () => fetchUsersAttendance(selectedDate),
        keepPreviousData: true,
    });
   

    return (
        <>
            <div className='xxs:p-5 p-2.5  flex flex-col h-full overflow-hidden'>
                <div className="flex items-center justify-between">
                    <h2 className='sm:text-lg text-base font-semibold flex-grow'>Attendance List</h2>
                </div>
                <div className="mt-2 flex items-center gap-4 pt-2">
                    <label htmlFor="datePicker" className="font-medium text-sm text-gray-700">
                        Select Date:
                    </label>
                    <div className='custome_date border border-secondary rounded-md relative'>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            onKeyDown={(e) => e.preventDefault()}
                            dateFormat="dd-MM-yyyy"
                            className="w-full rounded-md border px-4 py-3.5 text-sm"
                            placeholderText="Select start date"
                            maxDate={new Date()}
                            showPopperArrow={false}
                        />
                        <span
                            className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'
                            onClick={() => document.querySelector(".react-datepicker__input-container input").focus()}
                        >
                            <Calendar className='h-4 w-4' />
                        </span>
                    </div>
                </div>
                <div className="mt-3 flex-grow overflow-hidden">
                    <div className='h-full overflow-x-auto'>
                        <div className={`h-full ${userAttandanceData?.length ? "min-w-[750px]" : ""}  flex flex-col`}>
                            <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                <div className="w-[25%] px-3.5">Name ({userAttandanceData?.length})</div>
                                <div className="w-[40%]"> Attendance Visual </div>
                                <div className="w-[15%]">Effective Hours</div>
                                <div className="w-[15%]">Gross Hours</div>
                                <div className="w-[15%]">Break Duration</div>
                                <div className="w-[10%]">Arrival</div>
                                <div className="w-[5%]">Log</div>
                            </div>
                            <div className="flex-grow overflow-y-auto">
                                {
                                    userAttandanceData?.length ? userAttandanceData?.map((user) => (
                                        <UserAttendanceItemList key={user.id} userData={user} date={selectedDate} fetchUsersAttendance={fetchUsersAttendance} />
                                    )) :
                                        <div className="h-full flex items-center justify-center">
                                            <NoDataUI title={"Oops, No Attendance Found!"} />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAttandanceList