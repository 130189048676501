import React, { useState } from "react";
import DocsTitle from "./DocsTitle";
import { cn } from "../../lib/utils";
import ShareDocsModal from "../modal/ShareDocsModal";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import { Share, Share2 } from "lucide-react";

const DocumentsListItem = ({ docs }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [hoverId, setHoverId] = useState();
  const showMenu = (id) => setHoverId(id);
  const hideMenu = () => setHoverId("");

  const lastView = docs?.viewHistory?.find(
    (view) => view.userId.toString() === user?._id.toString()
  );
  ;

  return (
    <>
      <div
        key={docs._id}
        className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm font-medium hover:bg-primary/5"
        onMouseEnter={() => showMenu(docs?._id)}
        onMouseLeave={hideMenu}
      >
        <div
          className={cn(
            "w-[60%] flex gap-2 items-center border-e border-border p-2 px-1.5"
          )}
        >
          <div className="w-full relative">
            <DocsTitle docs={docs} hoverId={hoverId} setHoverId={setHoverId} />
          </div>
        </div>
        <div className="w-[15%] mt-2">{moment(docs?.updatedAt).format('DD-MMM-YYYY hh:mm A') || 'None'}</div>
        <div className="w-[15%] mt-2">  {
          lastView?.viewedAt
            ? moment(lastView.viewedAt).format("DD-MMM-YYYY hh:mm A")
            : "None"}
        </div>
        <div className="w-[10%]" onClick={() => setIsOpen(true)}><Share2 className="h-4 w-4 ml-2 mt-2" /></div>
      </div>
      <ShareDocsModal isOpen={isOpen} setIsOpen={setIsOpen} docs={docs} />
    </>
  );
};

export default DocumentsListItem;
