import React, { useState } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';

const Dropdown = ({ label, dataList, selectedValue, onChange }) => {

    const [selectedName, setSelectedName] = useState('');

    const handleSelect = (name) => {
        setSelectedName(name);
    };

    return (
        <div>
            <Listbox value={selectedValue} onChange={onChange}>
                <ListboxButton
                    className={clsx(
                        `w-[180px] rounded-lg py-1 pe-2 text-xs font-medium flex items-center justify-between`,
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                >
                    {selectedName ? <span>{selectedName}</span> : <span>{selectedValue || `Select`}</span>}
                    <ChevronDown className="text-dark/70 h-4 w-4" />
                </ListboxButton>
                <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                        'w-[210px] rounded-xl shadow-custom border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                    )}
                >
                    {dataList.map((item) => (
                        <ListboxOption
                            key={item._id}
                            value={item._id}
                            className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-lg py-1.5 px-2 select-none hover:bg-dark/5"
                            onClick={() => handleSelect(item.name)}
                        >
                            <div className="text-sm font-medium">{item.name}</div>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </div>
    );
};

export default Dropdown;
