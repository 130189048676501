import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";

const CurrentTimmer = ({ setModelOpen, isCheckedIn }) => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const formattedDate = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className="2xl:col-span-4 row-span-1 bg-white text-dark rounded-lg p-4 border border-secondary">
      <div className="flex justify-between items-center">
        <span className="font-medium">Time Today - {formattedDate}</span>
        <button
          className="text-sm underline"
          onClick={() => navigate("/attendance")}
        >
          View All
        </button>
      </div>
      <div className="mt-4">
        <p className="text-sm font-light">CURRENT TIME</p>
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-semibold mt-1">{formattedTime}</h1>
          <Button
            size="sm"
            className={`font-semibold ${
              isCheckedIn && "bg-red-500 hover:bg-red-500"
            }`}
            onClick={() => setModelOpen(true)}
          >
            {isCheckedIn ? "Check Out" : "Check In"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrentTimmer;