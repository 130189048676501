import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Lock, LogOut, Plus, UserRound } from 'lucide-react';
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Button } from './ui/Button';
import { useNavigate } from 'react-router-dom';


export default function ProfileDropdown({ setOpen, setPassModal }) {
  const navigate = useNavigate()
  const { logout, user } = useAuth();
  return (
    <Menu>
      <MenuButton className="flex justify-center items-center sm:w-8 w-6 sm:h-8 h-6 max-sm:text-xs bg-primary rounded-full text-white">
        {user?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase())}
      </MenuButton>
      <MenuItems
        transition
        anchor="bottom end"
        className="w-[180px] flex flex-col gap-1 origin-top-right rounded-xl border-2 border-border  font-semibold bg-white shadow-lg p-1 text-xs transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {/* <MenuItem>
          <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={() => navigate('/user-detail')}>
            <UserRound className="h-3.5 w-3.5" />
            Profile
          </button>
        </MenuItem> */}
        {
          user?.role === "admin" &&
          <MenuItem>
            <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={() => setOpen(true)}>
              <Plus className="h-3.5 w-3.5" />
              Add Project
            </button>
          </MenuItem>
        }
        <MenuItem>
          <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={() => setPassModal(true)}>
            <Lock className="h-3.5 w-3.5" />
            Change Password
          </button>
        </MenuItem>
        <MenuItem>
          <button className="group flex w-full items-center gap-2 text-xs text-dark/60 rounded-lg py-1.5 px-2.5 data-[focus]:bg-primary data-[focus]:text-white " onClick={logout}>
            <LogOut className="h-3.5 w-3.5" /> Logout
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  )
}
