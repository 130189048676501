import React, { useEffect, useState } from "react";
import { Copy, Ellipsis, Info, LassoSelect, Pencil, Plus, Trash2 } from "lucide-react";
import { Button, buttonVariants } from "../../common/ui/Button";
import PageListItem from "./PageListItem";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
} from "@headlessui/react";
import clsx from "clsx";
import { cn, getColorFromName } from "../../lib/utils";
import IconLink from "../../common/svgIcons/IconLink";
import { Input } from "../../common/Input";
import { useAuth } from "../../context/AuthContext";
import Editor from "../editor/editor";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../common/ui/Loader";

const PublicDocument = () => {
    const { user } = useAuth();
    const { docsId, pageId } = useParams()
    const navigate = useNavigate()
    const [hoverId, setHoverId] = useState(1);
    const [loader, setLoader] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [pages, setPages] = useState([
        { title: "Untitled", content: "Untitled" }
    ])

    const fetchDocumentInfo = async () => {
        if (docsId) {
            setLoader(true);
            try {
                const { data } = await API.get(`/docs/public-doc/${docsId}`);
                setPages(data?.document?.pages || [])
                return data?.document
            } catch (error) {
                console.error(error.message);
                toast.error(error.response?.data?.message || "Error in watching Document")
                return [];
            } finally {
                setLoader(false)
            }
        }
    };

    const { data: DocumentInfo } = useQuery({
        queryKey: ['document-Info'], queryFn: fetchDocumentInfo,
    });


    useEffect(() => {
        if (pages && pageId) {
            const index = pages.findIndex(page => page._id === pageId);
            if (index !== -1) {
                setSelectedIndex(index);
            }
        }
    }, [pages, pageId]);

    const handlePageClick = (pageId) => {
        if (docsId) {
            const index = pages.findIndex(page => page._id === pageId);
            setSelectedIndex(index);
            navigate(`/doc/${docsId}/${pageId}`);
        }
    };
    return (
        <div className="h-full mt-4">
            <TabGroup className="h-full flex" selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <div className="px-3.5 pt-2 h-full border-e border-secondary w-[320px]">
                    <TabList className="flex gap-1 flex-col gap-y-1 mb-1">
                        {pages.map((page, index) => (
                            <Tab
                                key={index}
                                onClick={() => handlePageClick(page?._id)}
                                className={({ selected }) =>
                                    clsx(
                                        "outline-none p-3 CompsOff flex items-center gap-1.5 text-xs leading-[1] font-semibold rounded-lg ps-5 relative overflow-hidden truncate",
                                        selected
                                            ? "bg-primary/10 text-primary"
                                            : "text-gray-500 hover:bg-gray-100"
                                    )
                                }
                            >
                                <PageListItem index={index} page={page} hoverId={hoverId} />
                            </Tab>
                        ))}
                    </TabList>
                </div>
                <TabPanels className="w-full h-full p-6 overflow-y-auto">
                    <div className="flex items-end justify-end">
                        <button onClick={() => navigate("/login")} className="text-center px-4 py-2 rounded-lg bg-primary text-white">Log In</button>
                    </div>
                    {pages.map((page, index) => (
                        <TabPanel key={index} className='h-full'>
                            {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
                                <Loader />
                            </div> :
                                <div className="w-[60%] h-full mx-auto flex flex-col">
                                    <div className="">
                                        <p className="border-0 bg-transparent text-4xl font-bold px-0 pt-0">{page?.title}</p>
                                    </div>
                                    <div className="relative flex-grow overflow-auto flex flex-col mt-2.5">
                                        <div className="flex-grow overflow-auto">
                                            <Editor
                                                key={"content"}
                                                initialValue={page?.content || ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </TabPanel>
                    ))}
                </TabPanels>
            </TabGroup>
        </div>
    );
};

export default PublicDocument;
