import * as Tooltip from "@radix-ui/react-tooltip";
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import NoProjectGif from "../assets/gif/no-projects.gif";
import Kites from '../assets/json/kites.json';
import flag from '../assets/json/flag/indianFlag.json';
import flagBaloon from '../assets/json/flag/india-flag-balloon.json';
// import handflag from '../assets/json/flag/hand_flag.json';
// import flagX from '../assets/json/flag/india-flag.json';
import Kite from '../assets/json/kite.json';
import ListItem from '../common/ListItem';
import NoDataUI from '../common/NoDataUI';
import IconArchive from '../common/svgIcons/IconArchive';
import IconBuilding from '../common/svgIcons/IconBuilding';
import IconDashboard from '../common/svgIcons/IconDashboard';
import IconPeoples from '../common/svgIcons/IconPeoples';
import IconTag from '../common/svgIcons/IconTag';
import IconWatch from '../common/svgIcons/IconWatch';
import { useAuth } from '../context/AuthContext';
import { cn } from '../lib/utils';
import API from '../services/api';
import IconAnalytics from "../common/svgIcons/IconAnalytics";
import IconTimesheet from "../common/svgIcons/IconTimesheet";
import IconDepartment from "../common/svgIcons/IconDepartment";
import IconBag from "../common/svgIcons/IconBag";
import IconPayroll from "../common/svgIcons/IconPayroll";
import { ChevronDown, ChevronUp } from "lucide-react";
import IconLeave from "../common/svgIcons/IconLeave";
import IconLeaveRequest from "../common/svgIcons/IconLeaveRequest";
import IconArrowExit from "../common/svgIcons/IconArrowExit";
import Lottie from "react-lottie-player";
import LogoPart from "../common/theme/LogoPart";
import IconAttendance from "../common/svgIcons/IconAttendance";
import IconDocs from "../common/svgIcons/IconDocs";
import IconLeaveCalander from "../common/svgIcons/IconLeaveCalander";
import IconHoliday from "../common/svgIcons/IconHoliday";

const Sidebar = ({ single = true, openSidebar, setOpenSidebar }) => {
    const navigate = useNavigate()
    const { sprintId } = useParams();
    const [expandedProjects, setExpandedProjects] = useState([]);
    // const [projects, setProjects] = useState([]);
    const [loader, setLoader] = useState(false);
    const { pathname } = useLocation();
    const { user } = useAuth()
    const [isLeaveOpen, setIsLeaveOpen] = useState(false);
    const [isAttendance, setIsAttendance] = useState(false);

    useEffect(() => {
        if (pathname === '/attendance' || pathname === '/attendance-list') {
            setIsAttendance(true);
        }
        if (pathname === '/leave' || pathname === '/leave-request') {
            setIsLeaveOpen(true);
        }
    }, [pathname]);

    const toggleProject = (projectId) => {
        if (single) {
            setExpandedProjects(expandedProjects[0] === projectId ? [] : [projectId]);
        } else {
            setExpandedProjects((prev) =>
                prev.includes(projectId)
                    ? prev.filter((id) => id !== projectId)
                    : [...prev, projectId]
            );
        }
    };

    const handleParentClick = (project) => {
        const firstSprint = project.sprints[0];
        toggleProject(project._id)
        if (project?.sprints?.length > 0) navigate(`/project/${project._id}/${firstSprint._id}`)
    };


    const fetchProjectsList = async () => {
        setLoader(true)
        try {
            const { data } = await API.get('/project/my-project-list');
            setLoader(false)
            return data
        } catch (err) {
            console.error(err.message);
        }
    };

    const { data: projects } = useQuery({
        queryKey: ['all-project'], queryFn: fetchProjectsList
    });

    useEffect(() => {
        if (sprintId && projects?.length > 0) {
            const matchingProject = projects?.find((project) =>
                project.sprints.some((sprint) => sprint._id === sprintId)
            );
            if (matchingProject && !expandedProjects.includes(matchingProject._id)) {
                setExpandedProjects((prev) => [...prev, matchingProject._id]);
            }
        }
    }, [sprintId, projects]);

    return (
        <>
            {openSidebar && <div className="lg:hidden fixed inset-0 bg-black/50 z-10" onClick={() => setOpenSidebar(false)}></div>}
            <div className={`bg-[#F3F3F8] w-[250px] p-4 px-0 pt-0 flex flex-col fixed top-0 max-lg:z-[999] transition-all duration-500 lg:!left-0 ${openSidebar ? "left-0" : "-left-full transition-all duration-500"} h-screen`}>
                <div className="relative overflow-hidden overflow-x-hidden h-screen">
                    {/* <div className="lg:py-5 py-4 px-4 pt-1 relative z-[1]">
                            <Link to={"/"}>
                                <img src={logo} alt="" className='lg:w-[80%] w-[60%] lg:mx-auto' />
                            </Link>
                        </div> */}
                    <LogoPart />
                    <div className="flex-grow flex flex-col overflow-auto h-[calc(100vh-123px)]">
                        <div className="px-3 pb-3 border-b border-secondary flex flex-col gap-1">
                            <Link
                                to={`/home`}
                                className={cn(
                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/home' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                    { "text-primary": '' }
                                )}
                            >
                                <IconDashboard className={`h-5 w-5 group-hover:text-primary ${pathname === '/home' ? 'text-primary ' : 'text-[#475467]'}`} />
                                Dashboard
                            </Link>
                            {/* <Link
                                to={`/attendance`}
                                className={cn(
                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/attendance' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                    { "text-primary": '' }
                                )}
                            >
                                <IconAttendance className={`h-5 w-5 group-hover:text-primary ${pathname === '/attendance' ? 'text-primary ' : 'text-[#475467]'}`} />
                                Attendance
                            </Link> */}
                            <div className="relative">
                                {(user?.role === "admin" || user?.role === "human_resources") ? (
                                    <div className="space-y-1">
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsAttendance((prev) => !prev);
                                                setIsLeaveOpen(false);
                                            }}
                                            className="group text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary"
                                        >
                                            <IconAttendance className="h-5 w-5 ml-0.5 text-gray-700 group-hover:text-primary" />
                                            Attendance
                                            {(user?.role === "admin" || user?.role === "human_resources") && (
                                                isAttendance ? <ChevronUp className="ml-auto w-4 h-4 group-hover:bg-primary group-hover:text-white group-hover:rounded-md" /> :
                                                    <ChevronDown className="ml-auto w-4 h-4 group-hover:bg-primary group-hover:text-white group-hover:rounded-md" />
                                            )}
                                        </div>
                                        {isAttendance && (
                                            <div className="space-y-1">
                                                <Link
                                                    to="/attendance"
                                                    className={`px-4 py-1 rounded-lg flex items-center text-sm hover:bg-primary/10 hover:text-primary ${pathname === "/attendance"
                                                        ? "text-primary bg-primary/10"
                                                        : "text-[#25272D]"
                                                        }`}
                                                >
                                                    <IconAttendance className={`h-5 w-5 mr-2 group-hover:text-primary ${pathname === '/attendance' ? 'text-primary ' : 'text-[#475467]'}`} />
                                                    My Attendance
                                                </Link>
                                                <Link
                                                    to="/attendance-list"
                                                    className={`px-4 py-1 rounded-lg flex items-center text-sm hover:bg-primary/10 hover:text-primary ${pathname === "/attendance-list"
                                                        ? "text-primary bg-primary/10"
                                                        : "text-[#25272D]"
                                                        }`}
                                                >
                                                    <IconAttendance className={`h-5 w-5 mr-2 group-hover:text-primary ${pathname === '/attendance-list' ? 'text-primary ' : 'text-[#475467]'}`} />
                                                    Attendance List
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <Link
                                        to="/attendance"
                                        className={`text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary ${pathname === "/attendance"
                                            ? "text-primary bg-primary/10"
                                            : "text-[#25272D]"
                                            }`}
                                    >
                                        <IconAttendance
                                            className={`h-5 w-5 ${pathname === "/attendance" ? "text-primary" : "text-[#475467]"}`}
                                        />
                                        My Attendance
                                    </Link>
                                )}
                            </div>

                            <div className="relative">
                                {(user?.role === "admin" || user?.role === "human_resources") ? (
                                    <div className="space-y-1">
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsLeaveOpen((prev) => !prev);
                                                setIsAttendance(false);
                                            }}
                                            className="group text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary"
                                        >
                                            <IconLeaveCalander className="h-5 w-5 ml-0.5 text-gray-700 group-hover:text-primary" />
                                            Leave
                                            {(user?.role === "admin" || user?.role === "human_resources") && (
                                                isLeaveOpen ? <ChevronUp className="ml-auto w-4 h-4 group-hover:bg-primary group-hover:text-white group-hover:rounded-md" /> :
                                                    <ChevronDown className="ml-auto w-4 h-4 group-hover:bg-primary group-hover:text-white group-hover:rounded-md" />
                                            )}
                                        </div>
                                        {isLeaveOpen && (
                                            <div className="space-y-1">
                                                <Link
                                                    to="/leave"
                                                    className={`px-4 py-1 rounded-lg flex items-center text-sm hover:bg-primary/10 hover:text-primary ${pathname === "/leave"
                                                        ? "text-primary bg-primary/10"
                                                        : "text-[#25272D]"
                                                        }`}
                                                >
                                                    <IconLeaveCalander className={`h-4 w-4 mr-2 ${pathname === "/leave" && "text-primary"}`} />
                                                    Leave
                                                </Link>
                                                <Link
                                                    to="/leave-request"
                                                    className={`px-4 py-1 rounded-lg flex items-center text-sm hover:bg-primary/10 hover:text-primary ${pathname === "/leave-request"
                                                        ? "text-primary bg-primary/10"
                                                        : "text-[#25272D]"
                                                        }`}
                                                >
                                                    <IconLeaveRequest className={`h-4 w-4 mr-2 ${pathname === "/leave-request" && "text-primary"}`} />
                                                    Leave Request
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <Link
                                        to="/leave"
                                        className={`text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary ${pathname === "/leave"
                                            ? "text-primary bg-primary/10"
                                            : "text-[#25272D]"
                                            }`}
                                    >
                                        <IconArrowExit
                                            className={`h-5 w-5 ${pathname === "/leave" ? "text-primary" : "text-[#475467]"}`}
                                        />
                                        Leave
                                    </Link>
                                )}
                            </div>
                            <Link
                                to={`/team-summary`}
                                className={cn(
                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/team-summary' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                    { "text-primary": '' }
                                )}
                            >
                                <IconDocs className={`h-5 w-5 group-hover:text-primary ${pathname === '/team-summary' ? 'text-primary ' : 'text-[#475467]'}`} />
                                Team Summary
                            </Link>
                            <Link

                                to={`/docs`}
                                className={cn(
                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/docs' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                    { "text-primary": '' }
                                )}
                            >
                                <IconDocs className={`h-5 w-5 group-hover:text-primary ${pathname === '/docs' ? 'text-primary ' : 'text-[#475467]'}`} />
                                Docs
                            </Link>
                            {
                                (user?.role === 'admin' || user?.role === "human_resources") &&
                                <>
                                    {
                                        user?.role !== "human_resources" &&
                                        <>
                                            <Link
                                                to={`/task-analytics`}
                                                className={cn(
                                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/task-analytics' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                                    { "text-primary": '' }
                                                )}
                                            >
                                                <IconAnalytics className={`h-5 w-5 group-hover:text-primary ${pathname === '/task-analytics' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                                Task Analytics
                                            </Link>
                                            <Link
                                                to={`/task-tracker`}
                                                className={cn(
                                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/task-tracker' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                                    { "text-primary": '' }
                                                )}
                                            >
                                                <IconWatch className={`h-5 w-5 group-hover:text-primary ${pathname === '/task-tracker' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                                Task Tracker
                                            </Link>
                                        </>
                                    }
                                    <Link
                                        to={`/timesheet`}
                                        className={cn(
                                            `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/timesheet' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                            { "text-primary": '' }
                                        )}
                                    >
                                        <IconTimesheet className={`h-5 w-5 group-hover:text-primary ${pathname === '/timesheet' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                        Timesheets
                                    </Link>
                                    <Link
                                        to={`/user`}
                                        className={cn(
                                            `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                            { "text-primary": '' }
                                        )}
                                    >
                                        <IconPeoples className={`h-5 w-5 group-hover:text-primary ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                        Team
                                    </Link>
                                    {(user?.role === 'admin' || user?.role === "human_resources") &&
                                        <Link
                                            to={`/holiday`}
                                            className={cn(
                                                `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/holiday' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                                { "text-primary": '' }
                                            )}
                                        >
                                            <IconHoliday className={`h-6 w-6 group-hover:text-primary ${pathname === '/holiday' ? 'text-primary' : 'text-[#475467]'}`} />
                                            Holiday
                                        </Link>
                                    }
                                    <Link
                                        to={`/department`}
                                        className={cn(
                                            `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/department' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                            { "text-primary": '' }
                                        )}
                                    >
                                        <IconDepartment className={`h-5 w-5 group-hover:text-primary ${pathname === '/department' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                        Department
                                    </Link>
                                    <Link
                                        to={`/job-title`}
                                        className={cn(
                                            `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/job-title' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                            { "text-primary": '' }
                                        )}
                                    >
                                        <IconBag className={`h-5 w-5 group-hover:text-primary ${pathname === '/job-title' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                        Job Title
                                    </Link>
                                    {
                                        user?.role !== "human_resources" &&
                                        <>
                                            <Link
                                                to={`/tags`}
                                                className={cn(
                                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/tags' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                                    { "text-primary": '' }
                                                )}
                                            >
                                                <IconTag className={`h-5 w-5 group-hover:text-primary ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                                Tags
                                            </Link>
                                            <Link
                                                to={`/archive`}
                                                className={cn(
                                                    `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/archive' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                                    { "text-primary": '' }
                                                )}
                                            >
                                                <IconArchive className={`h-5 w-5 group-hover:text-primary ${pathname === '/user' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                                Archive
                                            </Link>
                                        </>
                                    }
                                    <Link
                                        to={`/payroll`}
                                        className={cn(
                                            `text-sm p-1.5 py-1 rounded-lg cursor-pointer font-medium overflow-hidden whitespace-nowrap flex items-center gap-2 text-ellipsis hover:bg-primary/10 hover:text-primary group ${pathname === '/payroll' ? 'text-primary bg-primary/10' : 'text-[#25272D]'}`,
                                            { "text-primary": '' }
                                        )}
                                    >
                                        <IconPayroll className={`h-5 w-5 group-hover:text-primary ${pathname === '/payroll' ? 'text-primary bg-primary/10' : 'text-[#475467]'}`} />
                                        Payroll
                                    </Link>
                                </>
                            }
                        </div>
                        {
                            user?.role !== "human_resources" &&
                            <div className="px-4 pt-3 pe-0">
                                <p className='text-dark/40 pb-3 text-sm'>Project List</p>
                                <h3 className='flex items-center gap-2'>
                                    <IconBuilding className='w-5 h-5 text-[#475467]' />
                                    <p className='text-sm font-medium text-[#25272D]'>Elaunch Projects</p>
                                </h3>
                                {projects?.length ? (
                                    <ul className="flex flex-col mt-3 pe-4">
                                        <Tooltip.Provider>
                                            {projects?.map((project) => (
                                                <ListItem expandedProjects={expandedProjects} handleParentClick={handleParentClick} project={project} sprintId={sprintId} toggleProject={toggleProject} key={project._id} />
                                            ))}
                                        </Tooltip.Provider>
                                    </ul>
                                ) : (
                                    <div className="flex-grow flex items-center justify-center">
                                        <NoDataUI img={NoProjectGif} subtitle={"Project assigned to you will appear here."} subtitleClassName="italic" />
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    {
                        process.env.REACT_APP_THEAM === 'kite' &&
                        <div className="w-full flex">
                            <Lottie
                                loop
                                animationData={Kites}
                                play
                                style={{ width: 180, height: 100 }}
                                className="absolute -top-[15px] -left-[50px] -z-[1]"
                            />
                            <Lottie
                                loop
                                animationData={Kite}
                                play
                                style={{ width: 180, height: 60 }}
                                className="absolute top-0 -right-[30px] -z-[1]"
                            />
                        </div>
                    }
                    {
                        process.env.REACT_APP_THEAM === 'flag' &&
                        <>
                            <div className="w-full flex">
                                <Lottie
                                    loop
                                    animationData={flag}
                                    play
                                    style={{ width: 80, height: 80 }}
                                    className="absolute -top-[3px] left-[22px] -z-[1]"
                                />
                                {/* <Lottie
                                    loop
                                    animationData={flagBaloon}
                                    play
                                    style={{ width: 80, height: 80 }}
                                    className="absolute -top-[6px] right-0 -z-[1]"
                                /> */}
                            </div>
                            {/* <Lottie
                                loop
                                animationData={flag}
                                play
                                className="bloone_lottie absolute bottom-0 -z-[1] h-screen w-full opacity-20"
                            /> */}
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default Sidebar;
