import { Dialog, DialogPanel, DialogTitle, Select } from '@headlessui/react'
import { X } from 'lucide-react'
import React, { useEffect } from 'react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import { roleTypes } from '../../staticData/data'
import Buttonloader from '../../common/ui/ButtonLoader'
import DatePicker from 'react-datepicker'

const EditHolidayModal = ({ isOpen, setIsOpen, newHoliday, setNewHoliday, holidayData, setSelectedHoliday, handleSubmit, loader }) => {
    useEffect(() => {
        setNewHoliday({ name: holidayData?.name, date: holidayData?.date, description: holidayData?.description });
    }, [holidayData])


    const close = () => {
        setIsOpen(false);
        setSelectedHoliday();
        setNewHoliday({ name: '', date: '', description: '' })
    }

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => close()}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-visible"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border">
                            <div className="">
                                <p className='text-xl font-bold'>Edit Holiday</p>
                            </div>
                            <span className='cursor-pointer' onClick={() => close()}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Input placeholder='Name'
                                            value={newHoliday?.name}
                                            onChange={(e) => setNewHoliday({ ...newHoliday, name: e.target.value })} />
                                    </div>
                                    <div className={`custome_date border mb-3 bg-[#FBFBFF] rounded-md w-full relative border-border`}>
                                        <DatePicker
                                            placeholderText="date"
                                            onChange={(date) => setNewHoliday({ ...newHoliday, date: date })}
                                            selected={newHoliday.date}
                                            className="w-full rounded-md border px-4 py-3.5 text-sm"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Input placeholder='description'
                                            value={newHoliday?.description}
                                            onChange={(e) => setNewHoliday({ ...newHoliday, description: e.target.value })} />
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant='outline' type="button" onClick={() => close()}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Update Holiday'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default EditHolidayModal