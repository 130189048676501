import React, { useEffect, useState } from 'react'
import API from '../../services/api';
import { Button } from '../../common/ui/Button';
import { toast } from 'react-toastify';
import NoDataUI from '../../common/NoDataUI';
import Loader from '../../common/ui/Loader';
import HolidayListItem from './HolidayListItem';
import AddHolidayModal from '../modal/AddHolidayModal';

const HolidayList = () => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [holidayData, setHolidaydata] = useState();
    const [newHoliday, setNewHoliday] = useState({
        name: '',
        date: '',
        description: '',
    });
    const [loader, setLoader] = useState(true);

    const fetchHolidays = async () => {
        try {
            await API.get(`/holiday/list`).then((res) => {
                setHolidaydata(res.data?.holidays);
            })  
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoader(false)
        }
    };

    useEffect(() => {
        fetchHolidays();
    }, []);

    const handleAdd = async (data) => {
        // e.preventDefault();
        setLoader(true)
        try {
            await API.post('/holiday/add', data);
            setShowAddEditModal(false);
            fetchHolidays();
            toast.success('holiday added successfully');
        } catch (err) {
            // console.error(err.message);
            toast.error(err.response.data.message);
        } finally {
            setLoader(false)
        }
    }

    return (
        <>
            {loader ? <Loader /> :
                <div className='xxs:p-5 p-2.5 flex flex-col h-full overflow-hidden'>
                    <div className="flex items-center justify-between">
                        <h2 className='sm:text-lg text-base font-semibold flex-grow'>Holiday List</h2>
                        <div className="flex gap-2">
                            <Button size='sm' className={"font-semibold"} onClick={() => setShowAddEditModal(true)}>
                                Add Holiday
                            </Button>
                        </div>
                    </div>
                    <div className="mt-3 flex-grow overflow-hidden">
                        {loader ? <Loader /> : ""}
                        <div className='h-full overflow-x-auto'>
                            <div className={`h-full ${holidayData?.length ? "min-w-[750px]" : ""}  flex flex-col`}>
                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                    <div className="w-1/2 px-3.5">Name</div>
                                    <div className="w-[30%]">Date</div>
                                    <div className="w-[15%]">Action</div>
                                </div>
                                <div className="flex-grow overflow-y-auto">
                                    {
                                        holidayData?.length ? holidayData?.map((holiday) => (
                                            <HolidayListItem key={holiday?._id} holidayData={holiday} fetchHolidays={fetchHolidays} showAddEditModal={showAddEditModal} setShowAddEditModal={setShowAddEditModal} newHoliday={newHoliday} setNewHoliday={setNewHoliday} />
                                        )) :
                                            <div className="h-full flex items-center justify-center">
                                                <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <AddHolidayModal isOpen={showAddEditModal} setIsOpen={setShowAddEditModal} newHoliday={newHoliday} setNewHoliday={setNewHoliday} handleSubmit={handleAdd} loader={loader} />
        </>
    )
}


export default HolidayList
