import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../services/api';
import Loader from '../common/ui/Loader';
import { Check, Plus, X } from 'lucide-react';
import { Button } from '../common/ui/Button';
import { Input } from '../common/Input';
import DepartmentList from '../common/ui/DepartmentList';
import { toast } from 'react-toastify';

function JobTitle() {
    const [isAdding, setIsAdding] = useState(false);
    const [newJobTitle, setNewJobTitle] = useState('')
    const queryClient = useQueryClient();

    const { data: jobTitles = [], isLoading } = useQuery(
        {
            queryKey: ['jobTitles'],
            queryFn: async () => {
                const response = await API.get('/job-title/list')
                return response.data?.jobTitles || [];
            },
            enabled: true,
        }
    );

    const { mutate: addJobTitleMutation } = useMutation({
        mutationFn: async (name) => {
            const response = await API.post(`/job-title/create`, { name });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobTitles'] });
            setNewJobTitle('');
            setIsAdding(false);
            toast.success('jobTitle Added Successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to add jobTitle');
        },
    });

    const { mutate: updateTitleMutation } = useMutation({
        mutationFn: async ({ id, name }) => {
            const response = await API.put(`/job-title/update/${id}`, { name });
            return response.data;
        },
        onMutate: async (updatedTitle) => {
            await queryClient.cancelQueries({ queryKey: ['jobTitles'] });
            const previousTitle = queryClient.getQueryData(['jobTitles']);
            queryClient.setQueryData(['jobTitles'], (oldTitles) =>
                oldTitles.map((title) =>
                    title._id === updatedTitle.id ? { ...title, name: updatedTitle.name } : title
                )
            );
            return { previousTitle };
        },
        onError: (error, context) => {
            queryClient.setQueryData(['jobTitles'], context.previousTitle);
            toast.error(error.response?.data?.message || 'Failed to update jobTitle');
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobTitles'] });
            toast.success('jobTitles Updated Successfully');
        },
    });

    const { mutate: deleteDeptMutation } = useMutation({
        mutationFn: async (id) => {
            const response = await API.delete(`/job-title/delete/${id}`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobTitles'] });
            toast.success('jobTitle Deleted Successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to delete jobTitles');
        },
    });


    const addNewTitle = () => {
        if ( newJobTitle.trim()) {
            addJobTitleMutation(newJobTitle.trim());
        }
    };

    const cancelAdd = () => {
        setNewJobTitle('');
        setIsAdding(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addNewTitle();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addNewTitle();
        }
    };
    return (
        <div>
            {isLoading ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
                <Loader />
            </div>) : (
                <section className="xxs:p-5 p-2.5">
                    <div className="grid grid-cols-1 gap-2">
                        <div>
                            <div className="">
                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                    <div className="w-1/2 px-3.5">Label</div>
                                </div>
                                <ul>
                                    {jobTitles.length > 0 && jobTitles.map((title) => (
                                        <DepartmentList
                                            key={title._id}
                                            dept={title}
                                            onUpdate={updateTitleMutation}
                                            onDelete={deleteDeptMutation}
                                        />
                                    ))}
                                    {!isAdding ? (
                                        <li
                                            className="border-t border-border py-1 cursor-pointer hover:bg-gray-100"
                                            onClick={() => setIsAdding(true)}
                                        >
                                            <div className="flex items-center gap-2 py-1.5 px-3 rounded-md">
                                                <Plus className="w-4 h-4" />
                                                <span className="text-sm text-muted">Add JobTitle</span>
                                            </div>
                                        </li>
                                    ) : (
                                        <li className="py-1">
                                            <form onSubmit={handleSubmit} className="flex items-center gap-2">
                                                <Input
                                                    type="text"
                                                    rootClass="w-full"
                                                    value={newJobTitle}
                                                    onChange={(e) => setNewJobTitle(e.target.value)}
                                                    onKeyPress={handleKeyPress}
                                                    placeholder="Enter tag label"
                                                    className="py-1 px-2 border rounded-md flex-grow"
                                                />
                                                <Button
                                                    className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                                                    type="submit"
                                                >
                                                    <Check className="h-4 w-4 text-green-500" />
                                                </Button>
                                                <Button
                                                    className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                                                    onClick={cancelAdd}
                                                >
                                                    <X className="h-4 w-4 text-red-500" />
                                                </Button>
                                            </form>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}

export default JobTitle
