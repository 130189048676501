import { Dialog, DialogPanel } from '@headlessui/react'
import React from 'react'
import { Button } from '../../common/ui/Button'

const ConfirmationModal = ({ open, setOpen, handleConfirm, title, message, confirmText = "Yes", cancelText = "No" }) => {
    return (
        <Dialog open={open} as="div" className="relative z-10 focus:outline-none" onClose={() => setOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 p-10 flex flex-col items-center justify-center gap-4"
                    >
                        <div className="">
                            <h2>{title}</h2>
                            <p>{message}</p>
                        </div>
                        <div className="flex items-center gap-3">
                            <Button variant='outline' onClick={() => setOpen(false)}>{cancelText}</Button>
                            <Button onClick={() => {handleConfirm(); setOpen(false)}}>{confirmText}</Button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
    </Dialog>
    )
}

export default ConfirmationModal
