import React, { useEffect } from 'react'
import { useAuth } from '../context/AuthContext'
import { getColorFromName } from '../lib/utils';
import { Input } from '../common/Input';
import { Button } from '../common/ui/Button';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import API from '../services/api';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InformationSection from '../components/userDetails/InformationSection';

const UserProfile = () => {

    const queryClient = useQueryClient();

    const { handleSubmit, register, reset, setValue } = useForm()
    const { user } = useAuth()
    const userName = user?.name

    const fetchUser = async () => {
        try {
            const { data } = await API.get(`/auth/get-profile`);
            return data;
        } catch (err) { 
            console.error(err.message);
            return [];
        }
    };

    const { data: userData } = useQuery(
        { queryKey: ['userProfile', user?._id], queryFn: fetchUser, enabled: !!user?._id, }
    );

    useEffect(() => {
        if (userData) {
            setValue('tax', userData.tax || '');
            setValue('bonuses', userData.bonuses || '');
            setValue('deductions', userData.deductions || '');
        }
    }, [userData, setValue]);

    const { mutate: updatePayroll } = useMutation({
        mutationFn: async (data) => {
            const userId = user?._id;
            const { bonuses, deductions, tax } = data
            const response = await API.put(`/payroll/${userId}`, { bonuses, deductions, tax });
            return response.data;
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['userProfile'] });
            toast.success('Payroll Updated successfully');
            reset();
        },
        onError: (error) => {
            console.error('Failed to update Payroll:', error);
            toast.error('Failed to update Payroll');
        }
    });  

    const onsubmit = (data) => {
        updatePayroll(data);
    }

    return (
        <div className='xxs:p-5 p-2.5 flex flex-col h-full'>
            <div className="bg-[#F2F4F7] rounded-lg border border-border 2xl:p-5 p-3 mb-2">
                <div className="flex items-center 2xl:gap-10 gap-6 pb-5 border-b border-secondary">
                    <div className="2xl:h-36 h-28 2xl:w-36 w-28 flex items-center justify-center rounded-full bg-secondary flex-shrink-0">
                        <div
                            className="h-full w-full rounded-full flex items-center justify-center relative"
                            style={{
                                background: "conic-gradient(#6366F1 75%, #E5E7EB 75%)",
                            }}
                        >
                            <div className="bg-white h-[calc(100%-4px)] w-[calc(100%-4px)] rounded-full flex items-center justify-center">
                                <div className="h-[calc(100%-20px)] w-[calc(100%-20px)] rounded-full flex items-center justify-center text-white text-3xl font-semibold" style={{ background: getColorFromName(userName) }}>
                                    {userName
                                        ?.split(' ')
                                        .slice(0, 2)
                                        .map((name) => name.slice(0, 1).toUpperCase())}
                                </div>
                            </div>
                            <span className='h-5 w-5 rounded-full bg-white flex items-center justify-center absolute right-5 bottom-1.5 shadow-custom'>
                                <div className={`h-3 w-3 rounded-full ${userName === user?.name ? 'bg-[#24b04b]' : 'border-2 border-gray-500'}`} />
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 flex-grow">
                        <h2 className='text-xl font-semibold text-gray-600'>{userName}</h2>
                        <div className="grid 3xl:grid-cols-6 2xl:grid-cols-5 grid-cols-4 divide-x">
                            <div className="pe-5">
                                <label className='text-[#667085] text-sm'>Designation</label>
                                <p className='text-dark/90 text-base'>{userData?.jobTitle?.name}</p>
                            </div>
                            <div className="px-5">
                                <label className='text-[#667085] text-sm'>Mail</label>
                                <p className='text-dark/90 text-base'>{userData?.email}</p>
                            </div>
                            <div className="px-5">
                                <label className='text-[#667085] text-sm'>Number</label>
                                <p className='text-dark/90 text-base'>-</p>
                            </div>
                            <div className="px-5">
                                <label className='text-[#667085] text-sm'>Branch</label>
                                <p className='text-dark/90 text-base'>Katargam</p>
                            </div>
                            <div className="px-5">
                                <label className='text-[#667085] text-sm'>Id</label>
                                <p className='text-dark/90 text-base'>-</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-5 gap-5 pt-5">
                    <div className="">
                        <label className='text-[#667085] text-sm'>Business Unit</label>
                        <p className='text-dark/90 text-base'>Elaunch Private ltd</p>
                    </div>
                    <div className="">
                        <label className='text-[#667085] text-sm'>Department</label>
                        <p className='text-dark/90 text-base'>{userData?.department?.name}</p>
                    </div>
                    <div className="">
                        <label className='text-[#667085] text-sm'>Reporting Manager</label>
                        <p className='text-dark/90 text-base'>-</p>
                    </div>
                </div>
            </div>
            <InformationSection />
            {/* <form onSubmit={handleSubmit(onsubmit)} className="rounded-lg border border-secondary 2xl:p-5 p-3 w-1/3 mt-4">
                <div className="mb-2">
                    <label className='text-[#667085] text-sm'>Tax</label>
                    <Input type="number" {...register('tax')} />
                </div>
                <div className="mb-2">
                    <label className='text-[#667085] text-sm'>Bonus</label>
                    <Input type="number" {...register('bonuses')} />
                </div>
                <div className="">
                    <label className='text-[#667085] text-sm'>Deducation</label>
                    <Input type="number" {...register('deductions')} />
                </div>
                <div className="text-end mt-6">
                    <Button type="submit">Save</Button>
                </div>
            </form> */}
        </div>
    )
}

export default UserProfile