import moment from "moment";
import React, { useState } from "react";

const calculateTimelineSegments = (sessions, date) => {
    const dayStart = moment(date).startOf("day");
    const dayEnd = moment(date).endOf("day");
    const dayDuration = dayEnd.diff(dayStart);

    const validSessions = [];
    let hasMissingCheckOut = false;

    for (let i = 0; i < sessions?.length; i++) {
        const session = sessions[i];
        const checkInMoment = moment(`${date} ${session.checkInTime}`, "YYYY-MM-DD h:mm:ss A");
        const checkOutMoment = session.checkOutTime ? moment(`${date} ${session.checkOutTime}`, "YYYY-MM-DD h:mm:ss A") : null;

        if (session.checkOutTime === null) {
            hasMissingCheckOut = true;
            break;
        }

        if (checkInMoment.isValid() && checkOutMoment?.isValid()) {
            validSessions.push(session);
        }
    }

    if (validSessions?.length === 0) {
        return [];
    }

    const firstCheckInMoment = moment(`${date} ${validSessions[0]?.checkInTime}`, "YYYY-MM-DD h:mm:ss A");
    const lastCheckOutMoment = moment(`${date} ${validSessions[validSessions?.length - 1]?.checkOutTime}`, "YYYY-MM-DD h:mm:ss A");

    const timelineStartPercentage = 0;
    const timelineEndPercentage = 100;
    const totalSessionDuration = lastCheckOutMoment.diff(firstCheckInMoment);

    return validSessions.reduce((segments, session, index) => {
        const checkInMoment = moment(`${date} ${session.checkInTime}`, "YYYY-MM-DD h:mm:ss A");
        const checkOutMoment = session.checkOutTime ? moment(`${date} ${session.checkOutTime}`, "YYYY-MM-DD h:mm:ss A") : null;

        if (checkOutMoment === null) return segments;

        const startPercentage = ((checkInMoment.diff(firstCheckInMoment) / totalSessionDuration) * (timelineEndPercentage - timelineStartPercentage)).toFixed(2);
        const durationPercentage = ((checkOutMoment.diff(checkInMoment) / totalSessionDuration) * (timelineEndPercentage - timelineStartPercentage)).toFixed(2);

        segments.push({
            startPercentage: parseFloat(startPercentage),
            durationPercentage: parseFloat(durationPercentage),
            checkInTime: session.checkInTime,
            checkOutTime: session.checkOutTime,
        });

        return segments;
    }, []);
};

const RenderTimelin = ({ sessions, leaveInfo, date }) => {
    const [hoveredSegment, setHoveredSegment] = useState(null);
    const timelineSegments = calculateTimelineSegments(sessions, date);
    const isFirstHalfLeave = leaveInfo?.type === "First Half";
    const isSecondHalfLeave = leaveInfo?.type === "Second Half";
    const isFullDayLeave = leaveInfo?.type === "Full Day";


    const segmentsWithBreaks = timelineSegments.reduce((acc, segment, index) => {
        if (index > 0) {
            const prevSegment = acc[acc.length - 1];
            const gapStart = prevSegment.startPercentage + prevSegment.durationPercentage;
            const gapEnd = segment.startPercentage;

            if (gapEnd > gapStart) {
                acc.push({
                    type: 'break',
                    gapStart,
                    gapEnd,
                    gapPercentage: gapEnd - gapStart,
                    startTime: prevSegment.checkOutTime,
                    endTime: segment.checkInTime,
                });
            }
        }

        acc.push(segment);
        return acc;
    }, []);

    const segmentsWithStaticLeave = [];
    let leaveAdjusted = false;
    let previousEnd = 0;

    timelineSegments.forEach((segment, index) => {
        if (isFirstHalfLeave && !leaveAdjusted) {
            const staticSegment = {
                type: 'static',
                startPercentage: 0,  // Occupy the first 50% of the timeline
                durationPercentage: 50,
                leaveType: 'firstHalf',
            };
            segmentsWithStaticLeave.push(staticSegment);
            previousEnd += staticSegment.durationPercentage;
            leaveAdjusted = true;
        }
        if (index > 0) {
            const prevSegment = timelineSegments[index - 1];
            const gapStart = prevSegment.startPercentage + prevSegment.durationPercentage;
            const gapEnd = segment.startPercentage;
            if (gapEnd > gapStart) {
                const breakSegment = {
                    type: 'Break',
                    startPercentage: gapStart,
                    durationPercentage: gapEnd - gapStart,
                    gapStartPercentage: gapStart,
                    gapEndPercentage: gapEnd,
                    startTime: prevSegment?.checkOutTime,
                    endTime: segment.checkInTime,
                };
                segmentsWithStaticLeave.push(breakSegment);
                previousEnd += breakSegment.durationPercentage;
            }
        }

        const adjustedSegment = { ...segment };

        if (isFirstHalfLeave) {
            adjustedSegment.startPercentage = previousEnd;
            adjustedSegment.durationPercentage = segment.durationPercentage / 2;  // Adjust for half-day leave
            previousEnd += adjustedSegment.durationPercentage;
        } else if (isSecondHalfLeave) {
            adjustedSegment.startPercentage = previousEnd;
            adjustedSegment.durationPercentage = segment.durationPercentage / 2;  // Adjust for second half of day
            previousEnd += adjustedSegment.durationPercentage;
        } else {
            adjustedSegment.startPercentage = previousEnd;
            adjustedSegment.durationPercentage = segment.durationPercentage;  // Regular session
            previousEnd += adjustedSegment.durationPercentage;
        }

        segmentsWithStaticLeave.push(adjustedSegment);
    });

    if (isSecondHalfLeave && !leaveAdjusted) {
        const staticSegment = {
            type: 'static',
            startPercentage: previousEnd,
            durationPercentage: 50,  // Occupy the second half of the timeline
            leaveType: 'secondHalf',
        };
        segmentsWithStaticLeave.push(staticSegment);
    }

    const handleMouseEnter = (type, index, left) => {
        setHoveredSegment({ type, index, left });
    };

    const handleMouseLeave = () => {
        setHoveredSegment(null);
    };
    
    return (
        <div className="h-4 w-[50%] bg-[#E5E8F2] relative overflow-visible rounded-full">
            {isFirstHalfLeave && (
                <div
                    className="absolute h-full bg-purple-400"
                    style={{
                        left: "0%",
                        width: "50%",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                    }}
                    onMouseEnter={() => handleMouseEnter("firstHalfLeave")}
                    onMouseLeave={handleMouseLeave}
                />
            )}
            {isFullDayLeave && (
                <div
                    className="absolute h-full bg-purple-400"
                    style={{
                        left: "0%",
                        width: "100%",
                        borderRadius: "8px",
                    }}
                    onMouseEnter={() => handleMouseEnter("fullDayLeave")}
                    onMouseLeave={handleMouseLeave}
                />
            )}
            {!isFullDayLeave && (
                !isFirstHalfLeave && !isSecondHalfLeave ? segmentsWithBreaks.map((segment, index) => (
                    <React.Fragment key={index}>
                        {segment.type !== 'break' && (
                            <div
                                className={`h-full absolute ${hoveredSegment?.type === "session" && hoveredSegment.index === index
                                    ? "bg-primary"
                                    : "bg-primary"
                                    }`}
                                style={{
                                    left: `${segment.startPercentage}%`,
                                    width: `${segment.durationPercentage}%`,

                                    borderTopLeftRadius: index === 0 ? '8px' : '0',
                                    borderBottomLeftRadius: index === 0 ? '8px' : '0',
                                    borderTopRightRadius: index === segmentsWithBreaks?.length - 1 ? '8px' : '0',
                                    borderBottomRightRadius: index === segmentsWithBreaks?.length - 1 ? '8px' : '0',
                                }}
                                onMouseEnter={() => handleMouseEnter("session", index, `${segment.startPercentage}%`)}
                                onMouseLeave={handleMouseLeave}
                            />
                        )}
                        {segment.type === 'break' && (
                            <div
                                className="h-full absolute bg-primary/50"
                                style={{
                                    left: `${segment.gapStart}%`,
                                    width: `${segment.gapPercentage}%`,
                                }}
                                onMouseEnter={() => handleMouseEnter("break", index, `${segment.gapStart}%`)}
                                onMouseLeave={handleMouseLeave}
                            />
                        )}
                    </React.Fragment>
                )) :
                    segmentsWithStaticLeave.map((segment, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`h-full absolute ${segment.type === 'static'
                                    ? 'bg-purple-400'
                                    : segment.type === 'Break'
                                        ? 'bg-primary/50'
                                        : 'bg-primary'}`}
                                style={{
                                    left: `${segment.startPercentage}%`,
                                    width: `${segment.durationPercentage}%`,

                                    borderTopLeftRadius: index === 0 ? '8px' : '0',
                                    borderBottomLeftRadius: index === 0 ? '8px' : '0',
                                    borderTopRightRadius: index === segmentsWithStaticLeave?.length - 1 ? '8px' : '0',
                                    borderBottomRightRadius: index === segmentsWithStaticLeave?.length - 1 ? '8px' : '0',
                                }}
                                onMouseEnter={() =>
                                    segment.type === 'static'
                                        ? handleMouseEnter("firstHalfLeave")
                                        : segment.type === 'Break' ?
                                            handleMouseEnter("Break", index, `${segment.gapStart}%`)
                                            : handleMouseEnter("session", index)
                                }
                                onMouseLeave={handleMouseLeave}
                            />
                        </React.Fragment>
                    ))
            )}
            {isSecondHalfLeave && (
                <div
                    className="absolute h-full bg-purple-400"
                    style={{
                        left: "50%",
                        width: "50%",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                    }}
                    onMouseEnter={() => handleMouseEnter("secondHalfLeave")}
                    onMouseLeave={handleMouseLeave}
                />
            )}
            {hoveredSegment && (
                <div
                    className="absolute bg-gradient-to-r from-blue-50 to-purple-50 text-xs text-gray-900 p-2 rounded-lg shadow-md border border-gray-300 z-20 mt-1"
                    style={{
                        top: "100%",
                        left: `50%`,
                        transform: "translateX(-10%)",
                        minWidth: "150px",
                    }}
                >
                    {hoveredSegment.type === "firstHalfLeave" && <span>First Half Leave</span>}
                    {hoveredSegment.type === "secondHalfLeave" && <span>Second Half Leave</span>}
                    {hoveredSegment.type === "fullDayLeave" && <span>Full Day Leave</span>}
                    {hoveredSegment.type === "session" && (
                        <>
                            <span className="font-medium text-blue-600">Clock-In:</span>
                            <span className="block">
                                {(!isFirstHalfLeave && !isSecondHalfLeave)
                                    ? `${segmentsWithBreaks[hoveredSegment.index]?.checkInTime} - ${segmentsWithBreaks[hoveredSegment.index]?.checkOutTime}`
                                    : `${segmentsWithStaticLeave[hoveredSegment.index]?.checkInTime} - ${segmentsWithStaticLeave[hoveredSegment.index]?.checkOutTime}`}
                            </span>
                        </>
                    )}

                    {hoveredSegment.type === "break" && (
                        <>
                            <span className="font-medium text-red-600">Break</span>
                            <span className="block">{`${segmentsWithBreaks[hoveredSegment.index]?.startTime} - ${segmentsWithBreaks[hoveredSegment.index]?.endTime}`}</span>
                        </>
                    )}
                    {hoveredSegment.type === "Break" && (
                        <>
                            <span className="font-medium text-red-600">Break</span>
                            <span className="block">{`${segmentsWithStaticLeave[hoveredSegment.index]?.startTime} - ${segmentsWithStaticLeave[hoveredSegment.index]?.endTime}`}</span>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default RenderTimelin



