import React, { useState } from 'react'
import { cn } from '../../lib/utils';

const Tooltip = ({
    text,
    children,
    bgColor,
    borderColor,
    toolTipClass,
    detailClass,
    arrowClass,
    arrowbg,
    position = 'top'
}) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    const positionClasses = {
        top: '-top-8 left-1/2 transform -translate-x-1/2',
        left: 'top-1/2 right-full -translate-y-1/2 mr-2',
        right: 'top-1/2 left-full -translate-y-1/2 ml-2',
        bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    };
    const arrowPositionClasses = {
        top: {
            position: 'top-full left-1/2 -translate-x-1/2',
            border: 'border-4 border-transparent border-t-primary',
            width: 'w-0 h-0'
        },
        left: {
            position: 'left-full top-1/2 -translate-y-1/2',
            border: 'border-4 border-transparent border-l-primary',
            width: 'w-0 h-0'
        },
        right: {
            position: 'right-full top-1/2 -translate-y-1/2',
            border: 'border-4 border-transparent border-r-primary',
            width: 'w-0 h-0'
        },
        bottom: {
            position: 'bottom-full left-1/2 -translate-x-1/2',
            border: 'border-4 border-transparent border-b-primary',
            width: 'w-0 h-0'
        },
    };

    return (
        <div
            className={cn('relative inline-block w-fit', toolTipClass)}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {visible && (
                <div className={cn(
                    'absolute lg:w-max md:w-[400px] w-[246px] bg-primary text-white text-xs rounded px-2 py-1 shadow-md z-10',
                    positionClasses[position],
                    bgColor,
                    detailClass
                )}>
                    {text}
                    <div
                        className={cn(
                            'absolute',
                            arrowPositionClasses[position].position,
                            arrowPositionClasses[position].border,
                            arrowPositionClasses[position].width,
                            {
                                'border-t-[var(--tooltip-bg-color)]': position === 'top',
                                'border-l-[var(--tooltip-bg-color)]': position === 'left',
                                'border-r-[var(--tooltip-bg-color)]': position === 'right'
                            },
                            arrowClass
                        )}
                        style={{
                            '--tooltip-bg-color': arrowbg,
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default Tooltip