import React from 'react'

const LeavePolicyDetails = ({ heading, detail, leaveQuota, explanation }) => {
    return (
        <div className=''>
            <h1 className='font-semibold text-3xl'>{heading}</h1>
            <p className='py-2'>{detail}</p>
            <div className='h-60 w-full flex items-center justify-center'>
                <p>No Data To Display</p>
            </div>
            <div>
                <h1 className='font-semibold text-2xl'>Leave Quota</h1>
                {leaveQuota}
            </div>
            <div className='py-4'>
                {explanation}
            </div>
        </div>
    )
}

export default LeavePolicyDetails
