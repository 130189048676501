import React, { useState, useEffect } from "react";
import Loader from "../common/ui/Loader";
import { Check, Plus, X } from 'lucide-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from "../common/ui/Button";
import API from "../services/api";
import { Input } from "../common/Input";
import DepartmentList from "../common/ui/DepartmentList";
import { toast } from "react-toastify";

const Department = () => {
    const [loader, setLoader] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newDeptName, setNewDeptName] = useState('')
    const queryClient = useQueryClient();

    const { data: department = [] } = useQuery(
        {
            queryKey: ['department'],
            queryFn: async () => {
                if (!department || department.length <= 0) {
                    setLoader(true);
                }
                const response = await API.get('/department/list');
                setLoader(false)
                return response.data?.departments || [];
            },
            enabled: true,
        }
    );

    const { mutate: addeptMutation } = useMutation({
        mutationFn: async (name) => {
            const response = await API.post(`/department/create`, { name });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['department'] });
            setNewDeptName('');
            setIsAdding(false);
            toast.success('Department Added Successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to add Department');
        },
    });

    const { mutate: updateDeptMutation } = useMutation({
        mutationFn: async ({ id, name }) => {
            const response = await API.put(`/department/update/${id}`, { name });
            return response.data;
        },
        onMutate: async (updatedDept) => {
            await queryClient.cancelQueries({ queryKey: ['department'] });
            const previousDept = queryClient.getQueryData(['department']);
            queryClient.setQueryData(['department'], (oldDepts) =>
                oldDepts.map((dept) =>
                    dept._id === updatedDept.id ? { ...dept, name: updatedDept.name } : dept
                )
            );
            return { previousDept };
        },
        onError: (error, updatedDept, context) => {
            queryClient.setQueryData(['department'], context.previousDept);
            toast.error(error.response?.data?.message || 'Failed to update department');
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['department'] });
            toast.success('department Updated Successfully');
        },
    });

    const { mutate: deleteDeptMutation } = useMutation({
        mutationFn: async (id) => {
            const response = await API.delete(`/department/delete/${id}`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['department'] });
            toast.success('department Deleted Successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to delete department');
        },
    });


    const addNewDept = () => {
        if (newDeptName.trim()) {
            addeptMutation(newDeptName.trim());
        }
    };

    const cancelAdd = () => {
        setNewDeptName('');
        setIsAdding(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addNewDept();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addNewDept();
        }
    };


    return (
        loader ? <Loader /> : (
            <section className="xxs:p-5 p-2.5">
                <div className="grid grid-cols-1 gap-2">
                    <div>
                        <div className="">
                            <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                <div className="w-1/2 px-3.5">Label</div>
                            </div>
                            <ul>
                                {department.length > 0 && department.map((dept) => (
                                    <DepartmentList
                                        key={dept._id}
                                        dept={dept}
                                        onUpdate={updateDeptMutation}
                                        onDelete={deleteDeptMutation}
                                    />
                                ))}
                                {!isAdding ? (
                                    <li
                                        className="border-t border-border py-1 cursor-pointer hover:bg-gray-100"
                                        onClick={() => setIsAdding(true)}
                                    >
                                        <div className="flex items-center gap-2 py-1.5 px-3 rounded-md">
                                            <Plus className="w-4 h-4" />
                                            <span className="text-sm text-muted">Add Department</span>
                                        </div>
                                    </li>
                                ) : (
                                    <li className="py-1">
                                        <form onSubmit={handleSubmit} className="flex items-center gap-2">
                                            <Input
                                                type="text"
                                                rootClass="w-full"
                                                value={newDeptName}
                                                onChange={(e) => setNewDeptName(e.target.value)}
                                                onKeyPress={handleKeyPress}
                                                placeholder="Enter tag label"
                                                className="py-1 px-2 border rounded-md flex-grow"
                                            />
                                            <Button
                                                className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                                                type="submit"
                                            >
                                                <Check className="h-4 w-4 text-green-500" />
                                            </Button>
                                            <Button
                                                className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
                                                onClick={cancelAdd}
                                            >
                                                <X className="h-4 w-4 text-red-500" />
                                            </Button>
                                        </form>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        )
    );
}

export default Department