import React, { useEffect, useRef } from "react";
import { ChevronDown } from "lucide-react";

const permissions = [
  { _id: "All", name: "All" },
  { _id: "View", name: "canView" },
  { _id: "Edit", name: "canEdit" },
  { _id: "Comment", name: "canComment" },
];

const DocsPermissionDropdown = ({ label, docs, isOpen, toggleDropdown, selectedPermissions, onPermissionChange, userId }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  const handleCheckboxChange = (permission) => {
    if (permission._id === "View") {
      return; // Do nothing if it's 'canView'
    }
    let updatedPermissions;

    if (permission._id === "All") {
      if (selectedPermissions.some((p) => p._id === "All")) {
        updatedPermissions = [];
      } else {
        updatedPermissions = [...permissions];
      }
    } else {
      updatedPermissions = selectedPermissions.some((p) => p._id === permission._id)
        ? selectedPermissions.filter((p) => p._id !== permission._id)
        : [...selectedPermissions, permission];

      const allSelected =
        permissions
          .filter((p) => p._id !== "All")
          .every((p) => updatedPermissions.some((up) => up._id === p._id));

      if (allSelected) {
        updatedPermissions = [...updatedPermissions, permissions.find((p) => p._id === "All")];
      } else {
        updatedPermissions = updatedPermissions.filter((p) => p._id !== "All");
      }
    }

    const structuredResponse = [
      {
        userId,
        ...permissions.reduce((acc, perm) => {
          if (perm._id !== "All") {
            acc[perm.name] =
              updatedPermissions.some((p) => p._id === perm._id) ||
              false;
          }
          return acc;
        }, {}),
      },
    ];

    onPermissionChange(structuredResponse);
  };

  const currentPermissions = docs.permissions.find(
    (perm) => perm.userId === userId
  );

  const derivedSelectedPermissions = permissions.filter((perm) => {
    if (perm._id === "All") {
      return (
        currentPermissions &&
        permissions
          .filter((p) => p._id !== "All")
          .every((p) => currentPermissions[p.name])
      );
    }
    return currentPermissions && currentPermissions[perm.name];
  });

  return (
    <div className="relative w-36" ref={dropdownRef}>
      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className="w-full px-2 py-1.5 text-left text-xs bg-white border rounded-md focus:outline-none flex items-center justify-between text-gray-600"
      >
        {label}
        <span className="float-right transform transition-transform">
          <ChevronDown className={`text-dark/70 h-4 w-4 ${isOpen ? "rotate-180" : ""}`} />
        </span>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg py-1">
          <div className="max-h-40 overflow-y-auto flex flex-col gap-1 p-1">
            {permissions.map((permission) => (
              <div key={permission._id} className="px-1">
                <label className="flex items-center text-sm cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-blue-600 flex-shrink-0"
                    checked={derivedSelectedPermissions.some((p) => p._id === permission._id)}
                    onChange={() => handleCheckboxChange(permission)}
                  />
                  <span className="ml-2 text-gray-600 flex-grow truncate text-xs">{permission._id}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocsPermissionDropdown;
