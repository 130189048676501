import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../services/api";
import { cn, getColorFromName } from "../lib/utils";
import { colorMap } from "../staticData/data";
import WeekPicker from "../common/ui/WeekPicker";
import NoDataUI from "../common/NoDataUI";
import Loader from "../common/ui/Loader";

const TimeSheet = () => {
  const [loader, setLoader] = useState(false);
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  // const startDate = moment().startOf('isoWeek');
  // const endDate = moment(startDate).endOf('isoWeek');
  const [selectedWeek, setSelectedWeek] = useState(moment().startOf("isoWeek").toDate());

  const getWeekDays = (date) => {
    const startOfWeek = moment(date).startOf("isoweek");
    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.clone().add(i, "days").format("ddd, MMM D")
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true)
      const startDate = moment(selectedWeek).startOf('isoWeek').format("YYYY-MM-DD");
      const endDate = moment(selectedWeek).endOf('isoWeek').format("YYYY-MM-DD");

      const { data } = await API.get(`/report/employee-times?start=${startDate}&end=${endDate}&page=1&limit=1`);
      setTimeSheetData(data.sort((a, b) => {
        const charA = a.name[0].toLowerCase();
        const charB = b.name[0].toLowerCase();
        return charA.localeCompare(charB);
      }));
      const startMoment = moment(startDate);
      const weekDates = [];
      for (let date = startMoment; date.isBefore(moment(endDate).add(1, "days")); date.add(1, "days")) {
        weekDates.push(date.format("ddd, MMM D"));
      }
      setWeekDates(weekDates);
      setLoader(false)
    };
    fetchData();
  }, [selectedWeek]);
  

  return (
    loader ? <Loader /> : (
      <div className="xxs:p-5 p-2.5 h-full">
        <WeekPicker selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} />
        <div className="mt-3">
          <div className="overflow-x-auto">
            <div className="min-w-[992px]">
              <div className="header w-full flex bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md">
                <div className="flex-[2] p-2 text-[#475467]">People ({timeSheetData.length})</div>
                {getWeekDays(selectedWeek).map((date, index) => (
                  <div key={index} className="flex-1 text-center p-2 text-[#475467]">
                    {date}
                  </div>
                ))}
                <div className="flex-1 text-center p-2 text-[#475467]">Total</div>
              </div>
              <div className="body w-full xxs:h-[calc(100vh-210px)] h-[calc(100vh-188px)] overflow-auto">
                {timeSheetData?.length ? timeSheetData?.map((user, index) => {
                  const initial = user?.name?.charAt(0)?.toLowerCase();
                  const backgroundColor = getColorFromName(user?.name)
                  return (
                    <div key={user.userId} className="flex w-full hover:bg-primary/5 cursor-pointer border-b border-border">
                      <div className="flex-[2] p-1 flex items-center gap-2">
                        <div className="w-6 h-6 rounded-full flex items-center capitalize justify-center text-white text-[10px]" style={{ backgroundColor: `${backgroundColor}` }}>
                          {user.name
                            ?.split(' ')
                            .slice(0, 2)
                            .map((name) => name.slice(0, 1).toUpperCase())}
                        </div>
                        <div>
                          <h1 className="font-medium text-xs text-gray-800">{user.name}</h1>
                        </div>
                      </div>
                      {weekDates.map((date, index) => {
                        const formattedDate = moment(date, "ddd, MMM D").format("YYYY-MM-DD");
                        const getHours = user?.dailyTimes.find((obj) => obj?.date === formattedDate);
                        const time = getHours?.time.split(" ")[0].replace("h", '')
                        return (
                          <div key={index} className="flex-1 text-center flex p-1 items-center justify-center text-xs">
                            <div className={cn(`px-1.5 py-1 w-full rounded-md ${getHours?.time ? time < 8 ? 'text-red-800 font-semibold' : 'text-green-500' : ''}`)}>
                              {getHours ? getHours.time : '0h 0m'}
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex-1 text-center flex p-1 items-center justify-center text-xs font-semibold">
                        <div className={cn("text-primary w-full px-1.5 py-1 rounded-md", {
                          "bg-transparent text-dark": user.totalTime === "0h 0m"
                        })}>
                          {user.totalTime}
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div className="h-full flex items-center justify-center">
                    <NoDataUI title={"Oops, No Data Found!"} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default TimeSheet;
