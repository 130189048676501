import React from 'react'

const IconGoldMedal = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9931 15.7279C15.4967 15.7279 18.337 12.8876 18.337 9.38396C18.337 5.88031 15.4967 3.04004 11.9931 3.04004C8.48944 3.04004 5.64917 5.88031 5.64917 9.38396C5.64917 12.8876 8.48944 15.7279 11.9931 15.7279Z" fill="#CF8321" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5579 10.8419L12.3626 13.2276C13.1215 13.5194 13.1406 14.1112 13.216 14.7879C13.3085 15.6215 13.3531 16.574 13.6322 17.601C13.956 18.7892 14.4095 19.5629 14.9622 20.125C15.6475 20.8215 16.5093 21.2053 17.6466 21.5592C17.7162 21.5799 17.7911 21.5513 17.8299 21.4899C17.8676 21.4277 17.8594 21.348 17.8081 21.2954C17.3205 20.8032 16.943 20.2683 16.8879 19.6602C17.3415 19.771 18.1379 19.7322 18.8369 19.4626C18.8986 19.4365 18.9373 19.3742 18.9326 19.3071C18.929 19.2406 18.883 19.1839 18.8185 19.1652C18.1204 18.9699 17.5606 18.8014 17.146 18.3798C16.8307 18.0594 16.6177 17.5973 16.4335 16.9197C16.1165 15.755 16.1519 14.6915 15.9933 13.8186C15.7514 12.4819 15.1797 11.4652 13.5579 10.8419Z" fill="url(#paint0_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4421 10.8419L11.6374 13.2276C10.8785 13.5194 10.8594 14.1112 10.784 14.7879C10.6915 15.6215 10.6469 16.574 10.3678 17.601C10.044 18.7892 9.59045 19.5629 9.03775 20.125C8.35252 20.8215 7.49074 21.2053 6.35336 21.5592C6.28379 21.5799 6.20886 21.5513 6.17011 21.4899C6.13242 21.4277 6.14063 21.348 6.19194 21.2954C6.67948 20.8032 7.05701 20.2683 7.11207 19.6602C6.65851 19.771 5.86214 19.7322 5.16306 19.4626C5.10138 19.4365 5.06274 19.3742 5.06741 19.3071C5.07101 19.2406 5.11703 19.1839 5.1815 19.1652C5.87959 18.9699 6.43945 18.8014 6.85397 18.3798C7.16929 18.0594 7.38232 17.5973 7.56653 16.9197C7.88353 15.755 7.84812 14.6915 8.00673 13.8186C8.24861 12.4819 8.82035 11.4652 10.4421 10.8419Z" fill="url(#paint1_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2059 2.35316C13.5462 2.44623 13.7478 2.79736 13.6564 3.13822C13.2333 4.75059 11.9932 9.37873 11.9932 9.37873L10.3523 3.25584C10.8616 2.64606 11.6267 2.25781 12.4825 2.25781C12.7326 2.25781 12.9753 2.29093 13.2059 2.35316Z" fill="url(#paint2_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5307 2.68701C9.87193 2.59747 10.222 2.80079 10.3134 3.14165C10.7532 4.74955 11.9933 9.37769 11.9933 9.37769L7.51073 4.89556C7.6469 4.11284 8.11535 3.39407 8.85655 2.96614C9.07312 2.8411 9.29982 2.74845 9.5307 2.68701Z" fill="url(#paint3_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.51524 4.81465C6.76599 4.56648 7.17085 4.56751 7.42037 4.81705C8.60518 5.98963 11.9932 9.37766 11.9932 9.37766L5.87017 7.73729C5.59674 6.99135 5.64304 6.13465 6.07097 5.39345C6.19601 5.17688 6.34602 4.98329 6.51524 4.81465Z" fill="url(#paint4_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.96766 8.16591C5.06073 7.82562 5.41186 7.62408 5.75272 7.71543C7.36509 8.1385 11.9932 9.37861 11.9932 9.37861L5.87034 11.0195C5.26056 10.5102 4.87231 9.74516 4.87231 8.88931C4.87231 8.63923 4.90543 8.39657 4.96766 8.16591Z" fill="url(#paint5_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.302 11.8414C5.21246 11.5001 5.41578 11.15 5.75665 11.0587C7.36454 10.6189 11.9927 9.37882 11.9927 9.37882L7.51056 13.8613C6.72783 13.7252 6.00906 13.2567 5.58113 12.5155C5.45609 12.2989 5.36344 12.0722 5.302 11.8414Z" fill="url(#paint6_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.43037 14.8558C7.1822 14.6051 7.18323 14.2002 7.43277 13.9507C8.60535 12.7659 11.9934 9.37788 11.9934 9.37788L10.353 15.5009C9.60707 15.7744 8.75037 15.7281 8.00918 15.3001C7.7926 15.1751 7.59901 15.0251 7.43037 14.8558Z" fill="url(#paint7_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7804 16.4047C10.4401 16.3116 10.2386 15.9604 10.3299 15.6196C10.753 14.0072 11.9931 9.37908 11.9931 9.37908L13.634 15.502C13.1247 16.1118 12.3597 16.5 11.5038 16.5C11.2537 16.5 11.0111 16.4669 10.7804 16.4047Z" fill="url(#paint8_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4556 16.0688C14.1144 16.1584 13.7643 15.9551 13.673 15.6142C13.2332 14.0063 11.9931 9.37817 11.9931 9.37817L16.4756 13.8603C16.3394 14.643 15.871 15.3618 15.1298 15.7897C14.9132 15.9148 14.6865 16.0074 14.4556 16.0688Z" fill="url(#paint9_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4713 13.9412C17.2206 14.1894 16.8157 14.1884 16.5662 13.9388C15.3814 12.7662 11.9934 9.3782 11.9934 9.3782L18.1164 11.0186C18.3898 11.7645 18.3435 12.6212 17.9156 13.3624C17.7906 13.579 17.6406 13.7726 17.4713 13.9412Z" fill="url(#paint10_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0187 10.5919C18.9256 10.9322 18.5745 11.1337 18.2336 11.0424C16.6212 10.6193 11.9931 9.3792 11.9931 9.3792L18.116 7.73828C18.7258 8.24757 19.114 9.01265 19.114 9.86851C19.114 10.1186 19.0809 10.3612 19.0187 10.5919Z" fill="url(#paint11_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6843 6.91449C18.7739 7.25572 18.5706 7.60583 18.2297 7.69715C16.6218 8.13694 11.9937 9.37704 11.9937 9.37704L16.4758 4.89452C17.2585 5.03069 17.9773 5.49914 18.4052 6.24034C18.5302 6.45691 18.6229 6.68361 18.6843 6.91449Z" fill="url(#paint12_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5562 3.90001C16.8044 4.15075 16.8033 4.55562 16.5538 4.80513C15.3812 5.98995 11.9932 9.37798 11.9932 9.37798L13.6336 3.25494C14.3795 2.9815 15.2362 3.02781 15.9774 3.45574C16.194 3.58078 16.3876 3.73078 16.5562 3.90001Z" fill="url(#paint13_linear_581_1313)" />
            <path d="M11.9933 14.7761C14.9712 14.7761 17.3852 12.3621 17.3852 9.38415C17.3852 6.40625 14.9712 3.99219 11.9933 3.99219C9.01538 3.99219 6.60132 6.40625 6.60132 9.38415C6.60132 12.3621 9.01538 14.7761 11.9933 14.7761Z" fill="url(#paint14_linear_581_1313)" />
            <path d="M11.9934 14.1801C14.6422 14.1801 16.7895 12.0328 16.7895 9.38402C16.7895 6.73519 14.6422 4.58789 11.9934 4.58789C9.34456 4.58789 7.19727 6.73519 7.19727 9.38402C7.19727 12.0328 9.34456 14.1801 11.9934 14.1801Z" fill="url(#paint15_linear_581_1313)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7192 6.60973C11.7673 6.5011 11.8744 6.43066 11.9935 6.43066C12.1125 6.43066 12.2197 6.5011 12.2678 6.60973C12.5495 7.24762 12.9473 8.14791 12.9473 8.14791C12.9473 8.14791 13.926 8.24761 14.6199 8.31854C14.738 8.33045 14.8382 8.4113 14.8749 8.5244C14.9116 8.63699 14.8779 8.761 14.7896 8.84037C14.2697 9.30564 13.5366 9.96188 13.5366 9.96188C13.5366 9.96188 13.744 10.9237 13.8913 11.6052C13.9161 11.7213 13.8704 11.8413 13.7742 11.9113C13.678 11.9812 13.55 11.9877 13.4468 11.9281C12.8442 11.5774 11.9935 11.0829 11.9935 11.0829C11.9935 11.0829 11.1428 11.5774 10.5401 11.9281C10.4369 11.9877 10.309 11.9812 10.2127 11.9113C10.1165 11.8413 10.0709 11.7213 10.0957 11.6052C10.243 10.9237 10.4503 9.96188 10.4503 9.96188C10.4503 9.96188 9.71721 9.30564 9.19737 8.84037C9.10908 8.761 9.07535 8.63699 9.11205 8.5244C9.14876 8.4113 9.24896 8.33045 9.36701 8.31854C10.061 8.24761 11.0396 8.14791 11.0396 8.14791C11.0396 8.14791 11.4374 7.24762 11.7192 6.60973Z" fill="url(#paint16_radial_581_1313)" />
            <defs>
                <linearGradient id="paint0_linear_581_1313" x1="14.2476" y1="13.853" x2="16.8876" y2="19.6599" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E70002" />
                    <stop offset="1" stop-color="#FF696A" />
                </linearGradient>
                <linearGradient id="paint1_linear_581_1313" x1="9.75241" y1="13.853" x2="7.11242" y2="19.6599" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E70002" />
                    <stop offset="1" stop-color="#FF696A" />
                </linearGradient>
                <linearGradient id="paint2_linear_581_1313" x1="10.6429" y1="4.88077" x2="13.6407" y2="4.88962" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint3_linear_581_1313" x1="8.57488" y1="6.15749" x2="11.1754" y2="4.66628" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint4_linear_581_1313" x1="7.42271" y1="8.29808" x2="8.92925" y2="5.70637" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint5_linear_581_1313" x1="7.49527" y1="10.7289" x2="7.50412" y2="7.73116" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint6_linear_581_1313" x1="8.77248" y1="12.7972" x2="7.28127" y2="10.1966" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint7_linear_581_1313" x1="10.9138" y1="13.9484" x2="8.3221" y2="12.4418" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint8_linear_581_1313" x1="13.3434" y1="13.877" x2="10.3457" y2="13.8682" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint9_linear_581_1313" x1="15.4115" y1="12.5984" x2="12.8109" y2="14.0896" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint10_linear_581_1313" x1="16.5639" y1="10.4578" x2="15.0573" y2="13.0495" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint11_linear_581_1313" x1="16.4911" y1="8.0289" x2="16.4822" y2="11.0266" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint12_linear_581_1313" x1="15.2138" y1="5.95867" x2="16.7051" y2="8.55922" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint13_linear_581_1313" x1="13.0728" y1="4.80748" x2="15.6645" y2="6.31402" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint14_linear_581_1313" x1="2.24994" y1="2.25016" x2="21.7501" y2="21.7503" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <linearGradient id="paint15_linear_581_1313" x1="8.3403" y1="3.98532" x2="16.1978" y2="13.574" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CF8321" />
                    <stop offset="0.5" stop-color="#F9F295" />
                    <stop offset="1" stop-color="#CF8321" />
                </linearGradient>
                <radialGradient id="paint16_radial_581_1313" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5622 6.2871) rotate(64.475) scale(7.55731)">
                    <stop stop-color="#FF9629" />
                    <stop offset="1" stop-color="#732300" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default IconGoldMedal