import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Infinity } from "lucide-react";

const LeaveDetails = ({ showAccured, data, unpaid, paid, userData, color, consumedColor }) => {

    const GraphPlaceholderPie = ({ data }) => {
        const labelColorMapping = {
            Available: color,
            Consumed:consumedColor , 
        };

        const [hasData, setHasData] = useState(false);
        const [series, setSeries] = useState([]);
        const [options, setOptions] = useState({
            chart: {
                width: 380,
                type: "donut",
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size: "65%",
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: "Leave Details",
                                fontSize: "14px",
                                fontWeight: "medium",
                                color: "#1F2937",
                            },
                        },
                    },
                },
            },
            labels: [],
            states: {
                hover: {
                    filter: {
                        type: "darken",
                        value: 0.1,
                    },
                    borderWidth: 0,
                    borderColor: "transparent",
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        });

        useEffect(() => {
            if (data) {
                let mappedLabels = [];
                let mappedSeries = [];
                let mappedColors = [];
                let totalCount = 0;

                if (data.Available > 0) {
                    mappedLabels = ["Available"];
                    mappedSeries = [data.Available];
                    mappedColors = [labelColorMapping["Available"]];
                    totalCount = data.Available;
                } else if (data.Consumed > 0) {
                    mappedLabels = ["Consumed"];
                    mappedSeries = [data.Consumed];
                    mappedColors = [labelColorMapping["Consumed"]];
                    totalCount = data.Consumed;
                }

                setHasData(totalCount > 0);
                setSeries(mappedSeries);

                setOptions((prevOptions) => ({
                    ...prevOptions,
                    labels: mappedLabels,
                    colors: mappedColors,
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return `${val} days`;
                            },
                        },
                    },
                    plotOptions: {
                        ...prevOptions.plotOptions,
                        pie: {
                            ...prevOptions.plotOptions.pie,
                            donut: {
                                ...prevOptions.plotOptions.pie.donut,
                                labels: {
                                    ...prevOptions.plotOptions.pie.donut.labels,
                                    total: {
                                        ...prevOptions.plotOptions.pie.donut.labels.total,
                                        label: mappedLabels[0] || "No Data",
                                    },
                                },
                            },
                        },
                    },
                }));
            }
        }, [data]);

        return (
            <div className="min-h-48 flex items-center justify-center">
                {hasData ? (
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        width={220}
                    />
                ) : (
                    <p className="text-center text-gray-500">No leave data available.</p>
                )}
            </div>
        );
    };


    return (
        <div className="pt-2 flex flex-col gap-2">
            <GraphPlaceholderPie showAccured={showAccured} data={data} />
            <div className="grid grid-cols-2 border-t border-gray-200">
                <div className="col-span-full grid grid-cols-2 border-b border-gray-200">
                    <div className="border-e border-gray-200 px-3 py-1.5">
                        <p className="text-sm font-medium text-gray-400">Available</p>
                        <p className="text-sm font-medium text-gray-800">
                            {unpaid ? (
                                <Infinity className="h-4 w-4" />
                            ) : paid ? (
                                userData?.leaveBalance || 0
                            ) : (
                                userData?.compOffs || 0
                            )}{" "}
                            {!unpaid && "days"}
                        </p>
                    </div>
                    <div className="px-3 py-1.5">
                        <p className="text-sm font-medium text-gray-400">Consumed</p>
                        <p className="text-sm font-medium text-gray-800">{data?.Consumed || 0} days</p>
                    </div>
                </div>
                <div className="col-span-full grid grid-cols-2">
                    {showAccured && (
                        <div className="border-e border-gray-200 px-3 py-1.5">
                            <p className="text-sm font-medium text-gray-400">
                                Accrued So Far
                            </p>
                            <p className="text-sm font-medium text-gray-800">
                                {12} days
                            </p>
                        </div>
                    )}
                    <div className="px-3 py-1.5">
                        <p className="text-sm font-medium text-gray-400">Annual Quota</p>
                        <p className="text-sm font-medium text-gray-800">
                            {unpaid ? (
                                <Infinity className="h-4 w-4" />
                            ) : paid ? (
                                12
                            ) : (
                                <Infinity className="h-4 w-4" />
                            )}{" "}
                            {paid && "days"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeaveDetails;
