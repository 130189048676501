import React, { useEffect, useState } from "react";
import IconInfo from "../../common/svgIcons/IconInfo";
import IconPad from "../../common/svgIcons/IconPad";
import IconPanel from "../../common/svgIcons/IconPanel";
import IconBag2 from "../../common/svgIcons/IconBag2";
import IconFolder from "../../common/svgIcons/IconFolder";
import IconStar from "../../common/svgIcons/IconStar";
import IconList from "../../common/svgIcons/IconList";
import SteperItem from "./SteperItem";
import { Button } from "../../common/ui/Button";
import ProjectInfoSection from "./ProjectInfoSection";
import PlatformSection from "./PlatformSection";
import PanelSection from "./PanelSection";
import ToolsLibrariesSection from "./ToolsLibrariesSection";
import ModuleSection from "./ModuleSection";
import FeatureSection from "./FeatureSection";
import TaskPlanningSection from "./TaskPlanningSection";
import API from "../../services/api";
import ProjectSetupSection from "./ProjectSetupSection";
import IconProjectSetup from "../../common/svgIcons/IconProjectSetup";
import KickOffSection from "./KickOffSection";

const steps = [
  {
    id: 1,
    icon: <IconInfo />,
    name: "Information",
  },
  {
    id: 2,
    icon: <IconPad />,
    name: "Platform Suggestion",
  },
  {
    id: 3,
    icon: <IconBag2 />,
    name: "Tools & Libraries",
  },
  {
    id: 4,
    icon: <IconPanel />,
    name: "Panel",
  },
  {
    id: 5,
    icon: <IconFolder />,
    name: "Modules",
  },
  {
    id: 6,
    icon: <IconStar />,
    name: "Features",
  },
  {
    id: 7,
    icon: <IconList />,
    name: "Task Planning",
  },
  {
    id: 8,
    icon: <IconProjectSetup />,
    name: "Project Setup",
  },
  {
    id: 9,
    icon: <IconProjectSetup />,
    name: "Project Kick Off",
  },
];

const ProjectData = ({ projectId }) => {
  const [step, setStep] = useState(steps[0].id);
  const [isLoading, setIsLoading] = useState(false)
  const [projectInfo, setProjectInfo] = useState({})

  const fetchProjectInfo = async () => {
    try {
      if (projectInfo.length === 0) {
        setIsLoading(true);
      }
      const { data } = await API.get(`/project/projectDetail/${projectId}`);
      setProjectInfo(data?.data?.project);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching project info", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectInfo();
    }
  }, [projectId]);

  const checkStepPermission = (stepId) => {
    switch (stepId) {
      case 2:
        return { isYellow: !projectInfo?.platform, isGreen: !!projectInfo?.platform };
      case 3:
        return { isGreen: !!projectInfo?.platform && !!projectInfo?.tools, isYellow: !!projectInfo?.platform && !projectInfo?.tools };
      case 4:
        return { isGreen: !!projectInfo?.tools && !!projectInfo?.panels, isYellow: !!projectInfo?.tools && !projectInfo?.panels };
      case 5:
        return { isGreen: !!projectInfo?.panels && !!projectInfo?.module, isYellow: !!projectInfo?.panels && !projectInfo?.module };
      case 6:
        return { isGreen: !!projectInfo?.module && !!projectInfo?.features, isYellow: !!projectInfo?.module && !projectInfo?.features };
      case 7:
        return { isGreen: !!projectInfo?.features && Array.isArray(projectInfo?.tasks) && projectInfo.tasks.length > 0, isYellow: !!projectInfo?.features && (!Array.isArray(projectInfo?.tasks) || projectInfo.tasks.length === 0) };
      case 8:
        return { isGreen: Array.isArray(projectInfo?.tasks) && projectInfo.tasks.length > 0 && !!projectInfo?.setup, isYellow: Array.isArray(projectInfo?.tasks) && projectInfo.tasks.length > 0 && !projectInfo?.setup };
      case 9:
        return { isGreen: !!projectInfo?.setup && !!projectInfo?.kickoff, isYellow: !!projectInfo?.setup && !projectInfo?.kickoff };
      default:
        return { isGreen: true, isYellow: false };
    }
  };


  return (
    <>
      <div className="xxs:p-5 p-2.5 flex flex-col h-full overflow-hidden">
        <div className="flex gap-2.5 h-full">
          <div className="rounded-md border border-gray-100 bg-gray-50 p-2 w-[300px] h-fit flex-shrink-0">
            <ul className="flex flex-col gap-1.5 ps-10">
              {steps.map((steped, index) => (
                <SteperItem
                  key={steped.id}
                  isLast={index === steps?.length - 1}
                  icon={steped.icon}
                  name={steped.name}
                  id={steped.id}
                  isActive={step}
                  setStep={setStep}
                  isStepClickable={checkStepPermission(steped.id)}
                />
              ))}
            </ul>
          </div>
          <div className="flex-grow flex flex-col">
            {step === 1 && <ProjectInfoSection projectId={projectId} fetchProjctInfo={projectInfo} isLoading={isLoading} />}
            {step === 2 && <PlatformSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 4 && <PanelSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 3 && <ToolsLibrariesSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 5 && <ModuleSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 6 && <FeatureSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 7 && <TaskPlanningSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 8 && <ProjectSetupSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
            {step === 9 && <KickOffSection projectId={projectId} fetchProjectInfo={fetchProjectInfo} projectInfo={projectInfo} isLoading={isLoading} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectData;
