import React, { useEffect, useState } from 'react'
import IconInfo from '../../common/svgIcons/IconInfo'
import ReactApexChart from 'react-apexcharts'
import Tooltip from '../../common/ui/Tooltip';

const ConsumedLeave = ({ result }) => {

    const labelColorMapping = {
        'Paid': '#98B433', // Light green
        'Unpaid': '#64C3D1', // Light blue
        'CompsOff': '#2E8B57', // Medium green (SeaGreen)
        'Floater': '#4682B4', // Medium blue (SteelBlue)
    };

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        chart: {
            width: 380,
            type: 'donut',
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: '65%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Total',
                            fontSize: '14px',
                            fontWeight: 'semibold',
                            color: '#000',
                        },
                    },
                },
            },
        },
        labels: [],
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.10,
                },
                borderWidth: 0,
                borderColor: 'transparent',
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    });

    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        if (result) {
            const mappedLabels = [];
            const mappedSeries = [];
            const mappedColors = [];
            let totalCount = 0;

            Object.keys(result).forEach(key => {
                const count = result[key].count;
                totalCount += count;
                const color = labelColorMapping[key];
                mappedLabels.push(key);
                mappedSeries.push(count);
                mappedColors.push(color);
            });

            setHasData(totalCount > 0);
            setSeries(mappedSeries);

            setOptions(prevOptions => ({
                ...prevOptions,
                labels: mappedLabels,
                colors: mappedColors,
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return `${val}`;
                        },
                    },
                },
            }));
        }
    }, [result]);

    return (
        <div className="col-span-3 bg-white p-4 border border-secondary rounded-md">
            <div className="card-header pb-2 border-b border-secondary flex items-center justify-between">
                <h4 className="text-sm">Consumed Leave Types</h4>
                <Tooltip
                    position="top"
                    toolTipClass="cursor-pointer"
                    text="See consumed leave type by consumption percentage/share."
                    bgColor="bg-white"
                    arrowbg="#5461d6"
                    borderColor="border-t-[#5461d6]"
                    detailClass="text-black max-w-80"
                >
                    <span><IconInfo className="h-5 w-5 text-gray-500" /></span>
                </Tooltip>
            </div>
            <div className='w-full h-full flex items-center justify-center'>
                {hasData ? (
                    <ReactApexChart options={options} series={series} type="donut" width={300} />
                ) : (
                    <div className=''>
                        <p className="text-center text-gray-500">No leave data available.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConsumedLeave;
