import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { CheckIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import AssigneePopover from '../../common/ui/AssigneePopover';
import DueDate from '../../common/ui/DueDate';
import EstimatedTimePopover from '../../common/ui/EstimatedTimePopover';
import PriorityDropdown from '../../common/ui/PriorityDropdown';
import Title from '../../common/ui/Title';
import API from '../../services/api';
import IconTime from '../../common/svgIcons/IconTime';
import { statusType } from '../../staticData/data';
import DeleteModal from '../modal/DeleteModal';
import TaskDetailModal from '../modal/TaskDetailModal';
import { cn } from '../../lib/utils';
import moment from 'moment';
import TagItem from '../../common/TagItem';

const TaskListBoardItem = ({ type, task, projectDetails, sprintDetails, fetchCurrentTask, estimateTime = true, tags, isArchive, isRecent, isReport }) => {
    const queryClient = useQueryClient();
    const [taskOpen, setTaskOpen] = useState(false);
    const [taskTags, setTaskTags] = useState(task?.tags || []);
    

    const { mutate: updateDueDateMutation } = useMutation({
        mutationFn: async (dueDate) => {
            const response = await API.patch(`/task/${task._id}/due-date`, { dueDate });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            toast.success('Due date changed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to update due date');
        }
    });

    const { mutate: updatePriorityMutation } = useMutation({
        mutationFn: async (priority) => {
            const response = await API.patch(`/task/${task._id}/priority`, { priority });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
            toast.success('Priority changed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to update priority');
        }
    });

    const { mutate: removePriorityMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.patch(`/task/${task._id}/remove-priority`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
            toast.success('Priority removed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to remove priority');
        }
    });

    const { mutate: addEstimatedTimeMutation } = useMutation({
        mutationFn: async (estimatedHours) => {
            const response = await API.patch(`/task/${task._id}/estimated-hours`, { estimatedHours });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
            toast.success('Estimated time added successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to add Estimated time');
        }
    });

    const { mutate: removeEstimatedTimeMutation } = useMutation({
        mutationFn: async (estimatedHours) => {
            const response = await API.patch(`/task/${task._id}/clear-estimated-time`);
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks'] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
            toast.success('Estimated time removed successfully');
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || 'Failed to remove Estimated time');
        }
    });

    const { mutate: statusChangeTaskMutation } = useMutation({
        mutationFn: async (newStatus) => {
            const response = await API.patch(`/task/${task._id}`, { status: newStatus });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            toast.success('Status Changed successfully');
        },
        onError: (error) => {
            toast.error('Failed to Change Status');
        }
    });
    const { mutate: updateTitleMutation } = useMutation({
        mutationFn: async (title) => {
            const response = await API.patch(`/task/${task?._id}/title`, { title });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] });
            queryClient.invalidateQueries({ queryKey: ['recentTasks'] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
            toast.success('Task Updated successfully');
        },
        onError: (error) => {
            console.error('Task update failed:', error);
            toast.error('Failed to update task');
        }
    });

    const { mutate: updateDescriptionMutation } = useMutation({
        mutationFn: async (description) => {
            const response = await API.patch(`/task/${task._id}/details`, { description });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['tasks', sprintDetails._id] });
            if (isArchive) {
                queryClient.invalidateQueries({ queryKey: ['archived-task'] });
            }
        },
        onError: (error) => {
            toast.error("Failed to update description");
        }
    });
    const isDueDatePassed = moment(task.dueDate).isBefore(moment(), 'day');
    const isCompleted = () => {
        return task?.status === 'Done' || task?.status === 'Complete' || task?.status === 'Closed';
    }
    return (
        <>
            <div className="overflow-hidden w-full relative cursor-pointer" onClick={() => setTaskOpen(true)}>
                <div className="truncate flex-grow">
                    <h6 className="font-semibold text-sm truncate">{task.title}</h6>
                </div>
                <ul className='list-none flex items-center gap-0.5 flex-wrap py-1'>
                    {taskTags?.map(tag => (
                        <TagItem
                            tagClassName='text-[10px]'
                            className="!border-0 py-0"
                            key={tag._id}
                            tag={tag}
                            isSelected={taskTags?.some((selectedTag) => selectedTag._id === tag._id)}
                            isListMode={false}
                            close={false}

                        />
                    ))}
                </ul>
                <div className="flex items-center justify-between">
                    <AssigneePopover
                        topTipPosition={"right"}
                        isArchive={isArchive}
                        isReport={isReport}
                        assignedTo={task?.assignedTo}
                        members={projectDetails?.team}
                        taskId={task?._id}
                        showReportTo={false}
                    />
                    {isCompleted() ? (
                        <span className="inline-flex items-center text-xs font-semibold space-x-1 py-1 px-1.5 rounded-lg mt-2 bg-gray-200 text-gray-600">
                            <IconTime className="w-4 h-4" />
                            <span>{moment(task.dueDate).format("DD MMM")}</span>
                        </span>
                    ) : (
                        <span
                            className={clsx("inline-flex items-center text-xs font-semibold space-x-1 py-1 px-1.5 rounded-lg mt-2", {
                                'bg-red-200 text-red-600': isDueDatePassed,
                                'bg-gray-200 text-gray-600': !isDueDatePassed,
                            })}
                        >
                            <IconTime className="w-4 h-4" />
                            <span>{moment(task.dueDate).format("DD MMM")}</span>
                        </span>
                    )}
                </div>
            </div>

            <TaskDetailModal
                isArchive={isArchive}
                isReport={isReport}
                modalType='Task'
                tagList={tags}
                menu={true}
                projectDetails={projectDetails}
                sprintDetails={sprintDetails}
                fetchCurrentTask={fetchCurrentTask}
                open={taskOpen}
                handleRemovePriority={removePriorityMutation}
                setOpen={setTaskOpen}
                task={task}
                statusType={statusType}
                handleStatusChange={statusChangeTaskMutation}
                handlePriority={updatePriorityMutation}
                timers={'timers'}
                member={projectDetails?.team}
                handleDueDate={updateDueDateMutation}
                addEstimatedTime={addEstimatedTimeMutation}
                removeEstimatedTime={removeEstimatedTimeMutation}
                updateTitle={updateTitleMutation}
                updateDescription={updateDescriptionMutation}
            />
        </>
    );
};

export default TaskListBoardItem;
