import React, { useEffect, useState } from "react";
import LabelChart from "./LabelChart";
import TimeSheetChart from "./TimeSheetChart";
import LiveTimer from "../../common/LiveTimer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import { useAuth } from "../../context/AuthContext";
import moment from "moment";
import AssigneePopover from "../../common/ui/AssigneePopover";
import { getColorFromName } from "../../lib/utils";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/ui/Button";
import ConfirmationModal from "../modal/ConfirmationModal";
import { toast } from "react-toastify";
import Tooltip from "../../common/ui/Tooltip";
import CurrentTimmer from "../../common/ui/CurrentTimmer";
import Holiday from "./Holiday";
import BirthdayAndWorkAnniversaries from "./BirthdayAndWorkAnniversaries";
import ApplyLeaveModel from "../modal/ApplyLeaveModel";
import TodayLeave from "../../common/TodayLeave";
import PaidLeaveSection from "./PaidLeaveSection";
import TaskStatusSection from "./TaskStatusSection";
import TimeSheetSection from "./TimeSheetSection";
import RecentList from "./RecentList";

const SecondWelcomeBanner = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  const handleAttendanceAction = async (isCheckIn) => {
    try {
      const endpoint = isCheckIn ? "/timer/check-in" : "/timer/check-out";
      const response = await API.post(endpoint);

      if (response?.data) {
        fetchAttendanceInfo();
        queryClient.invalidateQueries(["attendance"]);
        queryClient.invalidateQueries(["Check-info"]);
      }
    } catch (error) {
      console.error(
        `Error during ${isCheckIn ? "check-in" : "check-out"}:`,
        error
      );
      toast.error(
        error?.response?.data?.message ||
          `Error during ${isCheckIn ? "check-in" : "check-out"}`
      );
    }
  };

  const fetchWeeklyTask = async () => {
    try {
      const { data } = await API.get(`/auth/users/${user?._id}/weekly-tasks`);
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchTodayLeaveList = async () => {
    try {
      const { data, loading } = await API.get(`/leaves/today`);
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: todayLeave } = useQuery({
    queryKey: ["todays-leave"],
    queryFn: fetchTodayLeaveList,
  });

  useEffect(() => {
    fetchAttendanceInfo();
  }, []);

  const fetchAttendanceInfo = async () => {
    try {
      const response = await API.get("/timer/clock-in-info");
      if (response.data?.data) {
        const todayRecords = response.data?.data;
        const lastRecord = todayRecords[todayRecords.length - 1];
        setIsCheckedIn(lastRecord && !lastRecord.checkOutTime);
      }
    } catch (error) {
      console.error("Error fetching attendance info:", error);
    }
  };
  const { data: info } = useQuery({
    queryKey: ["todays-checkIn"],
    queryFn: fetchAttendanceInfo,
  });

  const handleAttandance = async () => {
    handleAttendanceAction(!isCheckedIn);
  };

  return (
    <div>
      <div className="task-list-wrapper">
        <div className="grid md:grid-cols-12 gap-4 mb-4">
          <CurrentTimmer
            setModelOpen={setModelOpen}
            isCheckedIn={isCheckedIn}
          />
          <div className="xl:col-span-3 md:col-span-6 col-span-1 bg-white p-4 rounded-lg border border-secondary">
            <TodayLeave title="On Leave Today" />
          </div>
          <div className="xl:col-span-3 md:col-span-6 col-span-1 bg-white p-4 pb-0 rounded-lg border border-secondary">
            <PaidLeaveSection />
          </div>
          <Holiday />
        </div>
        <div className="grid md:grid-cols-7 grid-cols-12 gap-4">
          <div className="2xl:col-span-5 xl:col-span-4 col-span-12 xl:order-1 order-2 ">
            <RecentList />
          </div>
          <div className="2xl:col-span-2 xl:col-span-3 col-span-12 flex flex-col gap-4 xl:order-2 order-1">
            <BirthdayAndWorkAnniversaries />
            <TaskStatusSection />
            <TimeSheetSection />
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={`Are you sure you want to ${
          isCheckedIn ? "Check Out" : "Check In"
        }?`}
        open={modelOpen}
        setOpen={setModelOpen}
        handleConfirm={handleAttandance}
      />
      <ApplyLeaveModel
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        userData={user}
      ></ApplyLeaveModel>
    </div>
  );
};

export default SecondWelcomeBanner;
