import React, { useEffect, useState } from "react";
import { getColorFromName } from "../../lib/utils";
import DocsSwitch from "./DocsSwitch";
import DocsPermissionDropdown from "./DocsPermissionDropdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import API from "../../services/api";

const permissions = [
  { _id: "All", name: "All" },
  { _id: "View", name: "canView" },
  { _id: "Edit", name: "canEdit" },
  { _id: "Comment", name: "canComment" },
];

const ShareUserDocsItem = ({ member, docs, dropdownId, openDropdown, setOpenDropdown }) => {
  const queryClient = useQueryClient()
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [structuredPermissions, setStructuredPermissions] = useState({
    userId: member?._id,
    canView: true,
    canEdit: false,
    canComment: false,
  });

  useEffect(() => {
    const userPermissions = docs.permissions.find((perm) => perm.userId === member._id);
    if (userPermissions) {
      setStructuredPermissions({
        userId: member?._id,
        canView: true,
        canEdit: userPermissions.canEdit,
        canComment: userPermissions.canComment,
      });
    }
  }, [docs, member]);
  const toggleDropdown = () => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const { mutate: handlePermissionChange } = useMutation({
    mutationFn: async (permissions) => {
      const response = await API.patch(`docs/${docs?._id}/permissions`, { permissions: permissions });
      return response.data;
    },
    onSuccess: () => {
      toast.success('Permission Assigned successfully')
      queryClient.invalidateQueries({ queryKey: ["userDoc-list"] })
      queryClient.invalidateQueries({ queryKey: ["archive-list"] })
      queryClient.invalidateQueries({ queryKey: ["docs-list"] })
    },
    onError: (error) => {
      console.error("Error assigning permission", error);
      toast.error(error.response?.data?.message || "Error assigning permission")
    },
  });

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1.5">
        <div
          className="h-6 w-6 text-white rounded-full flex items-center justify-center font-medium uppercase text-xs"
          style={{
            background: `${getColorFromName(member.name)}`,
          }}
        >
          {member.name
            .split(" ")
            .slice(0, 2)
            .map((name) => name.slice(0, 1).toUpperCase())
            .join("")}
        </div>
        <p className="text-[#667085] text-sm">{member.name}</p>
      </div>
      <div className="flex items-center gap-2">
        <DocsPermissionDropdown
          label="Select Permissions"
          isOpen={openDropdown === dropdownId}
          toggleDropdown={toggleDropdown}
          selectedPermissions={permissionsToCheckboxState(structuredPermissions)}
          onPermissionChange={handlePermissionChange}
          userId={member?._id}
          docs={docs}
        />
        <DocsSwitch isInDocs={true} userId={member._id} docs={docs} />
      </div>
    </div>
  );
};

// Helper to map structured permissions to checkbox state
const permissionsToCheckboxState = (structuredPermissions) => {
  return Object.entries(structuredPermissions)
    .filter(([key, value]) => value === true)
    .map(([key]) => permissions.find((perm) => perm.name === key));
};

export default ShareUserDocsItem;
