import React from "react";
import logo from "../../assets/images/New_Logo_1.png";
import blank_logo from "../../assets/images/blank_logo.png";
import Kites from "../../assets/json/kites.json";
import Kite from "../../assets/json/kite.json";
import handflag from "../../assets/json/flag/hand_flag.json";
import flag_blloon from "../../assets/json/flag/flag_x.json";
import flag from "../../assets/json/flag/indianFlag.json";
import Lottie from "react-lottie-player";

const LoaderMain = () => {
  return (
    <div className="w-[10%] relative">
      {process.env.REACT_APP_THEAM === "kite" ? (
        <>
          <img src={logo} alt="" className="w-full" />
          <div className="w-full flex">
            <Lottie
              loop
              animationData={Kites}
              play
              style={{ width: 180, height: 100 }}
              className="absolute -top-10 -left-[90px] -z-[1]"
            />
            <Lottie
              loop
              animationData={Kite}
              play
              style={{ width: 180, height: 60 }}
              className="absolute -top-5 -right-20 -z-[1]"
            />
          </div>
        </>
      ) : process.env.REACT_APP_THEAM === "flag" ? (
        <>
          <img src={logo} alt="" className="w-full" />
          <div className="w-full flex">
            {/* <Lottie
              loop
              animationData={handflag}
              play
              style={{ width: 100, height: 100 }}
              className="absolute -top-[34px] -left-[47px] -z-[1]"
            /> */}
            <Lottie
              loop
              animationData={flag}
              play
              style={{ width: 110, height: 110 }}
              className="absolute -top-[58px] -left-[18px] -z-[1]"
            />
          </div>
        </>
      ) : (
        <img src={logo} alt="" className="w-full" />
      )}
    </div>
  );
};

export default LoaderMain;
