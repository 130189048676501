import React from 'react'
import DocumentList from '../components/documents'

const Documents = () => {
  return (
    <DocumentList />
  )
}

export default Documents
