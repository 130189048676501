import React, { useEffect, useState } from "react";
import moment from "moment";
import AttendanceListItem from "./AttendanceListItem";
import { monthList } from "../../staticData/data";
import { Button } from "../../common/ui/Button";
import { generateYearList } from "../../lib/utils";
import clsx from "clsx";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import API from "../../services/api";
import { useQuery } from '@tanstack/react-query';
import Loader from "../../common/ui/Loader";
import { useLocation } from "react-router-dom";

const getDatesForMonth = (year, month) => {
  const startDate = moment(`${year}-${month}-01`);
  const endDate = moment().isSame(startDate, "month")
    ? moment()
    : startDate.clone().endOf("month");
  const dates = [];
  for (let date = startDate; date <= endDate; date.add(1, "days")) {
    dates.push(date.clone().format("YYYY-MM-DD"));
  }
  return dates;
};

const AttendanceList = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectYear, setSelectYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [loader, setLoader] = useState(false)
  const [attendanceData, setAttendanceData] = useState([]);

  const location = useLocation();

  const userId = location?.state?.userId;
  const userName = location?.state?.userName;

  const yearList = generateYearList(currentYear - 2, currentYear);

  const datesForSelectedMonth = getDatesForMonth(selectYear, selectedMonth);

  const sortedDates = datesForSelectedMonth.sort((a, b) =>
    moment(b).diff(moment(a))
  );

  const handleYearChange = (newYear) => {
    setSelectYear(newYear);
    if (parseInt(newYear) === currentYear) {
      setSelectedMonth(currentMonth);
    } else {
      setSelectedMonth(12);
    }
  };


  const fetchAttendance = async (year, month) => {
    const { data } = await API.get(`/timer/report/monthly?month=${month}&year=${year}&userId=${userId}`);
    const today = moment().startOf('day');
    return data?.data
      ?.filter((item) => moment(item.date).isSameOrBefore(today))
      .sort((a, b) => moment(b.date).diff(moment(a.date))) || [];
  };

  const { data: attendanceDatas, isLoading } = useQuery({
    queryKey: ["attendance", selectYear, selectedMonth],
    queryFn: () => fetchAttendance(selectYear, selectedMonth),
    keepPreviousData: true,
  });
  // useEffect(() => {
  //   fetchAttandance();
  // }, [selectedMonth, selectYear]);

  return (
    <div className="xxs:p-5 p-2.5 flex flex-col h-full overflow-hidden">
      {userId && userId !== 'undefined' ? <h1 className="text-base font-semibold text-dark/80 pb-2">{userName}'s Attandance</h1> : null}
      <div className="flex items-center justify-end gap-2 pb-5">
        <div className="flex items-center gap-2">
          {monthList?.map((month) => {
            const isFutureMonth =
              parseInt(selectYear) === currentYear && month.id > currentMonth;

            return (
              <Button
                key={month.id}
                className={clsx(
                  "text-primary hover:text-white shadow-none",
                  selectedMonth == month.id
                    ? "bg-primary text-white"
                    : "bg-[#e3e4f5] text-primary",
                  isFutureMonth && "opacity-50 cursor-not-allowed"
                )}
                onClick={() => !isFutureMonth && setSelectedMonth(month.id)}
                disabled={isFutureMonth}
              >
                {month.name}
              </Button>
            );
          })}
        </div>
        <Listbox value={selectYear} onChange={handleYearChange}>
          <ListboxButton
            className={clsx(
              "relative block rounded-lg bg-white/5 border border-border text-left text-sm/6 w-[150px] p-2 py-1.5",
              "focus:outline-none"
            )}
          >
            {selectYear}
            <span className="absolute right-3 top-1/2 -translate-y-1/2">
              <ChevronDownIcon className="size-4" />
            </span>
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            transition
            className={clsx(
              "w-[150px] rounded-xl shadow-custom border border-white/5 bg-white p-1",
              "transition duration-100 ease-in"
            )}
          >
            {yearList?.map((year) => (
              <ListboxOption
                key={year.id}
                value={year.value}
                className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5"
              >
                <div className="flex items-center gap-1.5">
                  <div className="text-xs font-medium">{year.name}</div>
                </div>
                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>
      </div>

      <div className="flex-grow overflow-hidden">
        <div className="h-full">
          <div className="h-full flex flex-col">
            <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
              <div className="w-[15%] px-3.5">Date</div>
              <div className="w-[40%]"> Attendance Visual </div>
              <div className="w-[15%]">Effective Hours</div>
              <div className="w-[15%]">Gross Hours</div>
              <div className="w-[15%]">Break Duration</div>
              <div className="w-[10%]">Arrival</div>
              <div className="w-[5%]">Log</div>
            </div>
            <div className="h-full flex-grow overflow-y-auto">

              {isLoading ? <div className="h-full flex items-center justify-center"><Loader /></div> :
                attendanceDatas && attendanceDatas.length > 0 ? (
                  attendanceDatas.map((item, index) => (
                    <AttendanceListItem
                      key={index}
                      date={item?.date || '-'}
                      effectiveHours={item?.effectiveHours || '-'}
                      grossHours={item?.grossHours || '-'}
                      arrival={item?.arriveTime || '-'}
                      log={item?.sessions}
                      isOnLeave={item?.isOnLeave}
                      attendanceData={item}
                    />
                  ))
                ) : (
                  <div className={`body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm font-medium hover:bg-primary/5 relative justify-center items-center`}>
                    <p className="text-xl font-bold text-gray-400 text-center mt-10 mb-10">No Attendance Found</p>
                  </div>

                )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AttendanceList;
