import React from 'react'

const IconRightArrow = (props) => {
    return (
        <span>
            <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M20.25 12L13.5 18.75V5.25L20.25 12Z" fill="currentColor" />
                <path d="M20.7806 11.4695L14.0306 4.71948C13.9257 4.61447 13.792 4.54294 13.6465 4.51396C13.5009 4.48497 13.35 4.49982 13.2129 4.55664C13.0758 4.61345 12.9586 4.70967 12.8762 4.83312C12.7938 4.95656 12.7499 5.10168 12.75 5.2501V11.2501H3.75C3.55109 11.2501 3.36032 11.3291 3.21967 11.4698C3.07902 11.6104 3 11.8012 3 12.0001C3 12.199 3.07902 12.3898 3.21967 12.5304C3.36032 12.6711 3.55109 12.7501 3.75 12.7501H12.75V18.7501C12.7499 18.8985 12.7938 19.0436 12.8762 19.1671C12.9586 19.2905 13.0758 19.3868 13.2129 19.4436C13.35 19.5004 13.5009 19.5152 13.6465 19.4862C13.792 19.4573 13.9257 19.3857 14.0306 19.2807L20.7806 12.5307C20.8504 12.4611 20.9057 12.3784 20.9434 12.2873C20.9812 12.1963 21.0006 12.0987 21.0006 12.0001C21.0006 11.9015 20.9812 11.8039 20.9434 11.7129C20.9057 11.6218 20.8504 11.5391 20.7806 11.4695ZM14.25 16.9398V7.06041L19.1897 12.0001L14.25 16.9398Z" fill="currentColor" />
            </svg>
        </span>
    )
}

export default IconRightArrow