import React from "react";
import { getColorFromName } from "../../lib/utils";

const EmployeeCard = ({ employee }) => {
  const toTitleCase = (str) => {
    if (!str) return "-";
    return str
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); 
  };

  return (
    <div className="bg-white border border-secondary rounded-lg p-4 flex gap-2.5">
      <span
        className="font-semibold uppercase h-14 w-14 p-2 text-white rounded-full flex items-center justify-center text-2xl first:ms-0 -ms-2.5 hover:z-[9] border border-border"
        style={{
          background: `${getColorFromName(employee?.name)}`,
        }}
      >
        {employee?.name
          ?.split(" ")
          .slice(0, 2)
          .map((name) => name.slice(0, 1))}
      </span>
      <div className="">
        <h2 className="text-xl text-gray-800 font-semibold">{employee?.name}</h2>
        <p className="text-sm text-gray-800 pt-1">{toTitleCase(employee?.department?.name || '-' )}</p>
        <ul className="pt-3 flex flex-col gap-0.5">
          <li className="text-sm flex items-center gap-2.5">
            <span className="text-gray-600">Department :</span>
            <span>{employee?.department?.name || '-'}</span>
          </li>
          <li className="text-sm flex items-center gap-2.5">
            <span className="text-gray-600">Email :</span>
            <span>{employee?.email || "-"}</span>
          </li>
          <li className="text-sm flex items-center gap-2.5">
            <span className="text-gray-600">Mobile :</span>
            <span>{employee?.mobileNumber || '-'}</span>
          </li>
          <li className="text-sm flex items-center gap-2.5">
            <span className="text-gray-600">Gender :</span>
            <span>{employee?.gender || '-'}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EmployeeCard;
