import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Button } from '../../common/ui/Button';
import PasswordInput from '../../common/PasswordInput';
import API from '../../services/api';
import { toast } from 'react-toastify';
import Buttonloader from '../../common/ui/ButtonLoader';
import { useForm, Controller } from 'react-hook-form';

const ChangePasswordModal = ({ passModal, setPassModal }) => {
    const [loader, setLoader] = useState(false);

    const { control, handleSubmit, formState: { errors }, reset, getValues } = useForm();

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    useEffect(() => {
        if (!passModal) {
            reset();
        }
    }, [passModal, reset]);

    const handleChangePassword = async (data) => {
        setLoader(true);
        try {
            const obj = {
                confirmPassword: data?.confirmPass,
                newPassword: data?.newPass,
                oldPassword: data?.oldPass
            }
            const response = await API.put('/auth/change-password', obj);
            toast.success(response.data.message);
            setPassModal(false);
            reset();
        } catch (err) {
            console.error(err.message);
            toast.error(err.response?.data?.message || "An error occurred");
        } finally {
            setLoader(false);
        }
    };

    return (
        <Dialog open={passModal} as="div" className="relative z-10 focus:outline-none" onClose={() => setPassModal(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground p-4 flex justify-between border-b border-border rounded-t-xl">
                            <p className='text-xl font-bold'>Change Password</p>
                            <span className='cursor-pointer' onClick={() => setPassModal(false)}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleSubmit(handleChangePassword)}>
                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Controller
                                            name="oldPass"
                                            control={control}
                                            rules={{ required: 'Old password is required' }}
                                            render={({ field }) => (
                                                <PasswordInput
                                                    {...field}
                                                    placeholder="Old Password"
                                                />
                                            )}
                                        />
                                        {errors.oldPass && <p className="text-red-500 text-xs mt-1">{errors.oldPass.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                        <Controller
                                            name="newPass"
                                            control={control}
                                            rules={{
                                                required: 'New password is required',
                                                pattern: {
                                                    value: passwordRegex,
                                                    message: 'Password must be at least 8 characters long, with 1 uppercase letter, 1 number'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <PasswordInput
                                                    {...field}
                                                    placeholder="New Password"
                                                />
                                            )}
                                        />
                                        {errors.newPass && <p className="text-red-500 text-xs mt-1">{errors.newPass.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                        <Controller
                                            name="confirmPass"
                                            control={control}
                                            rules={{
                                                required: 'Confirm password is required',
                                                validate: value => value === getValues('newPass') || 'Passwords do not match'
                                            }}
                                            render={({ field }) => (
                                                <PasswordInput
                                                    {...field}
                                                    placeholder="Confirm Password"
                                                />
                                            )}
                                        />
                                        {errors.confirmPass && <p className="text-red-500 text-xs mt-1">{errors.confirmPass.message}</p>}
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant='outline' type="button" onClick={() => setPassModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Change Password'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default ChangePasswordModal;
