import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { useAuth } from "../../context/AuthContext";
import ApplyLeaveModel from "../modal/ApplyLeaveModel";

const PaidLeaveSection = () => {
  const { user } = useAuth();
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const GraphPlaceholderPie = ({ data }) => {
    const labelColorMapping = {
      Available: "#98B433",
    };

    const [hasData, setHasData] = useState(false);
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
      chart: {
        width: 120,
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "85%",
            labels: {
              show: true,
              total: {
                show: true,
                label: "",
                fontSize: "16px",
                fontWeight: "medium",
                color: "#1F2937",
                formatter: function () {
                  return data;
                },
              },
              value: {
                offsetY: 0,
                fontSize: "22px",
              },
            },
          },
        },
      },
      labels: [],
      states: {
        hover: {
          filter: {
            type: "none", // Disable hover effect
          },
          borderWidth: 0,
          borderColor: "transparent",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 120,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    // Inside useEffect where you're setting the options:
    useEffect(() => {
      if (data) {
        let mappedSeries = [];
        let mappedColors = [];
        let totalCount = 0;

        if (data > 0) {
          mappedSeries = [data];
          mappedColors = [labelColorMapping["Available"]];
          totalCount = data;
        }

        setHasData(totalCount > 0);
        setSeries(mappedSeries);
        setOptions((prevOptions) => ({
          ...prevOptions,
          colors: mappedColors,
        }));
      }
    }, [data]);

    return (
      <div className="flex items-center justify-center">
        {hasData ? (
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={120}
          />
        ) : (
          <div className="h-full flex items-end">
            <p className="text-center text-gray-500">
              No leave data available.
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <h1 className="text-base">Paid Leave Balance</h1>
      <div className="flex justify-between">
        <GraphPlaceholderPie data={user?.leaveBalance} />
        <div className="flex flex-col items-center justify-center gap-y-3">
          <button
            onClick={() => setIsOpen(true)}
            className="text-primary font-semibold"
          >
            Request Leave
          </button>
          <button
            onClick={() => navigate("/leave")}
            className="text-primary font-semibold"
          >
            View All Balances
          </button>
        </div>
      </div>
      <ApplyLeaveModel isOpen={isOpen} setIsOpen={setIsOpen} userData={user} />
    </>
  );
};

export default PaidLeaveSection;
