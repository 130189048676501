import React from 'react'

const IconBronzeMedal = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9931 15.7279C15.4967 15.7279 18.337 12.8876 18.337 9.38396C18.337 5.88031 15.4967 3.04004 11.9931 3.04004C8.48944 3.04004 5.64917 5.88031 5.64917 9.38396C5.64917 12.8876 8.48944 15.7279 11.9931 15.7279Z" fill="#A06335" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5579 10.8419L12.3626 13.2276C13.1215 13.5194 13.1406 14.1112 13.216 14.7879C13.3085 15.6215 13.3531 16.574 13.6322 17.601C13.956 18.7892 14.4095 19.5629 14.9622 20.125C15.6475 20.8215 16.5093 21.2053 17.6466 21.5592C17.7162 21.5799 17.7911 21.5513 17.8299 21.4899C17.8676 21.4277 17.8594 21.348 17.8081 21.2954C17.3205 20.8032 16.943 20.2683 16.8879 19.6602C17.3415 19.771 18.1379 19.7322 18.8369 19.4626C18.8986 19.4365 18.9373 19.3742 18.9326 19.3071C18.929 19.2406 18.883 19.1839 18.8185 19.1652C18.1204 18.9699 17.5606 18.8014 17.146 18.3798C16.8307 18.0594 16.6177 17.5973 16.4335 16.9197C16.1165 15.755 16.1519 14.6915 15.9933 13.8186C15.7514 12.4819 15.1797 11.4652 13.5579 10.8419Z" fill="url(#paint0_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4421 10.8419L11.6374 13.2276C10.8785 13.5194 10.8594 14.1112 10.784 14.7879C10.6915 15.6215 10.6469 16.574 10.3678 17.601C10.044 18.7892 9.59045 19.5629 9.03775 20.125C8.35252 20.8215 7.49074 21.2053 6.35336 21.5592C6.28379 21.5799 6.20886 21.5513 6.17011 21.4899C6.13242 21.4277 6.14063 21.348 6.19194 21.2954C6.67948 20.8032 7.05701 20.2683 7.11207 19.6602C6.65851 19.771 5.86214 19.7322 5.16306 19.4626C5.10138 19.4365 5.06274 19.3742 5.06741 19.3071C5.07101 19.2406 5.11703 19.1839 5.1815 19.1652C5.87959 18.9699 6.43945 18.8014 6.85397 18.3798C7.16929 18.0594 7.38232 17.5973 7.56653 16.9197C7.88353 15.755 7.84812 14.6915 8.00673 13.8186C8.24861 12.4819 8.82035 11.4652 10.4421 10.8419Z" fill="url(#paint1_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2059 2.35316C13.5462 2.44623 13.7478 2.79736 13.6564 3.13822C13.2333 4.75059 11.9932 9.37873 11.9932 9.37873L10.3523 3.25584C10.8616 2.64606 11.6267 2.25781 12.4825 2.25781C12.7326 2.25781 12.9753 2.29093 13.2059 2.35316Z" fill="url(#paint2_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5307 2.68701C9.87193 2.59747 10.222 2.80079 10.3134 3.14165C10.7532 4.74955 11.9933 9.37769 11.9933 9.37769L7.51073 4.89556C7.6469 4.11284 8.11535 3.39407 8.85655 2.96614C9.07312 2.8411 9.29982 2.74845 9.5307 2.68701Z" fill="url(#paint3_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.51451 4.81562C6.76525 4.56746 7.17012 4.56848 7.41963 4.81803C8.60445 5.9906 11.9925 9.37864 11.9925 9.37864L5.86944 7.73827C5.596 6.99232 5.64231 6.13562 6.07024 5.39443C6.19528 5.17786 6.34528 4.98427 6.51451 4.81562Z" fill="url(#paint4_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.96766 8.16591C5.06073 7.82562 5.41186 7.62408 5.75272 7.71543C7.36509 8.1385 11.9932 9.37861 11.9932 9.37861L5.87034 11.0195C5.26056 10.5102 4.87231 9.74516 4.87231 8.88931C4.87231 8.63923 4.90543 8.39657 4.96766 8.16591Z" fill="url(#paint5_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.30298 11.8394C5.21343 11.4982 5.41675 11.1481 5.75762 11.0568C7.36551 10.617 11.9937 9.37686 11.9937 9.37686L7.51153 13.8594C6.7288 13.7232 6.01003 13.2548 5.5821 12.5136C5.45707 12.297 5.36442 12.0703 5.30298 11.8394Z" fill="url(#paint6_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.43037 14.8558C7.1822 14.6051 7.18323 14.2002 7.43277 13.9507C8.60535 12.7659 11.9934 9.37788 11.9934 9.37788L10.353 15.5009C9.60707 15.7744 8.75037 15.7281 8.00918 15.3001C7.7926 15.1751 7.59901 15.0251 7.43037 14.8558Z" fill="url(#paint7_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7804 16.4047C10.4401 16.3116 10.2386 15.9604 10.3299 15.6196C10.753 14.0072 11.9931 9.37908 11.9931 9.37908L13.634 15.502C13.1247 16.1118 12.3597 16.5 11.5038 16.5C11.2537 16.5 11.0111 16.4669 10.7804 16.4047Z" fill="url(#paint8_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4544 16.0698C14.1132 16.1594 13.7631 15.956 13.6717 15.6152C13.232 14.0073 11.9919 9.37915 11.9919 9.37915L16.4744 13.8613C16.3382 14.644 15.8698 15.3628 15.1286 15.7907C14.912 15.9157 14.6853 16.0084 14.4544 16.0698Z" fill="url(#paint9_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4708 13.9402C17.2201 14.1884 16.8152 14.1874 16.5657 13.9378C15.3809 12.7653 11.9929 9.37722 11.9929 9.37722L18.1159 11.0176C18.3893 11.7635 18.343 12.6202 17.9151 13.3614C17.7901 13.578 17.6401 13.7716 17.4708 13.9402Z" fill="url(#paint10_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0187 10.5919C18.9256 10.9322 18.5745 11.1337 18.2336 11.0424C16.6212 10.6193 11.9931 9.3792 11.9931 9.3792L18.116 7.73828C18.7258 8.24757 19.114 9.01265 19.114 9.86851C19.114 10.1186 19.0809 10.3612 19.0187 10.5919Z" fill="url(#paint11_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6838 6.91547C18.7734 7.2567 18.5701 7.60681 18.2292 7.69813C16.6213 8.13792 11.9932 9.37802 11.9932 9.37802L16.4753 4.8955C17.258 5.03167 17.9768 5.50012 18.4047 6.24131C18.5298 6.45789 18.6224 6.68459 18.6838 6.91547Z" fill="url(#paint12_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5557 3.90001C16.8039 4.15075 16.8029 4.55562 16.5533 4.80513C15.3807 5.98995 11.9927 9.37798 11.9927 9.37798L13.6331 3.25494C14.379 2.9815 15.2357 3.02781 15.9769 3.45574C16.1935 3.58078 16.3871 3.73078 16.5557 3.90001Z" fill="url(#paint13_linear_581_1312)" />
            <path d="M11.9933 14.7761C14.9712 14.7761 17.3852 12.3621 17.3852 9.38415C17.3852 6.40625 14.9712 3.99219 11.9933 3.99219C9.01538 3.99219 6.60132 6.40625 6.60132 9.38415C6.60132 12.3621 9.01538 14.7761 11.9933 14.7761Z" fill="url(#paint14_linear_581_1312)" />
            <path d="M11.9934 14.1801C14.6422 14.1801 16.7895 12.0328 16.7895 9.38402C16.7895 6.73519 14.6422 4.58789 11.9934 4.58789C9.34456 4.58789 7.19727 6.73519 7.19727 9.38402C7.19727 12.0328 9.34456 14.1801 11.9934 14.1801Z" fill="url(#paint15_linear_581_1312)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7192 6.60973C11.7673 6.5011 11.8744 6.43066 11.9935 6.43066C12.1125 6.43066 12.2197 6.5011 12.2678 6.60973C12.5495 7.24762 12.9473 8.14791 12.9473 8.14791C12.9473 8.14791 13.926 8.24761 14.6199 8.31854C14.738 8.33045 14.8382 8.4113 14.8749 8.5244C14.9116 8.63699 14.8779 8.761 14.7896 8.84037C14.2697 9.30564 13.5366 9.96188 13.5366 9.96188C13.5366 9.96188 13.744 10.9237 13.8913 11.6052C13.9161 11.7213 13.8704 11.8413 13.7742 11.9113C13.678 11.9812 13.55 11.9877 13.4468 11.9281C12.8442 11.5774 11.9935 11.0829 11.9935 11.0829C11.9935 11.0829 11.1428 11.5774 10.5401 11.9281C10.4369 11.9877 10.309 11.9812 10.2127 11.9113C10.1165 11.8413 10.0709 11.7213 10.0957 11.6052C10.243 10.9237 10.4503 9.96188 10.4503 9.96188C10.4503 9.96188 9.71721 9.30564 9.19737 8.84037C9.10908 8.761 9.07535 8.63699 9.11205 8.5244C9.14876 8.4113 9.24896 8.33045 9.36701 8.31854C10.061 8.24761 11.0396 8.14791 11.0396 8.14791C11.0396 8.14791 11.4374 7.24762 11.7192 6.60973Z" fill="url(#paint16_linear_581_1312)" />
            <defs>
                <linearGradient id="paint0_linear_581_1312" x1="14.4042" y1="12.5908" x2="16.8098" y2="19.6866" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5C2F00" />
                    <stop offset="1" stop-color="#A55400" />
                </linearGradient>
                <linearGradient id="paint1_linear_581_1312" x1="9.59581" y1="12.5908" x2="7.19018" y2="19.6866" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#5C2F00" />
                    <stop offset="1" stop-color="#A55400" />
                </linearGradient>
                <linearGradient id="paint2_linear_581_1312" x1="10.3045" y1="4.14793" x2="13.8732" y2="3.96452" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint3_linear_581_1312" x1="7.9154" y1="5.69203" x2="10.9143" y2="3.74883" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint4_linear_581_1312" x1="6.61813" y1="8.22569" x2="8.24366" y2="5.04338" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint5_linear_581_1312" x1="6.76243" y1="11.0673" x2="6.57902" y2="7.49858" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint6_linear_581_1312" x1="8.30799" y1="13.4547" x2="6.36479" y2="10.4558" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint7_linear_581_1312" x1="10.8404" y1="14.7522" x2="7.65812" y2="13.1267" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint8_linear_581_1312" x1="13.6818" y1="14.6099" x2="10.1131" y2="14.7933" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint9_linear_581_1312" x1="16.0697" y1="13.0648" x2="13.0708" y2="15.008" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint10_linear_581_1312" x1="17.3672" y1="10.5302" x2="15.7417" y2="13.7125" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint11_linear_581_1312" x1="17.2239" y1="7.69051" x2="17.4073" y2="11.2592" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint12_linear_581_1312" x1="15.6788" y1="5.30017" x2="17.622" y2="8.29907" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint13_linear_581_1312" x1="13.1456" y1="4.00363" x2="16.328" y2="5.62915" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint14_linear_581_1312" x1="2.24994" y1="2.25016" x2="21.7501" y2="21.7503" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint15_linear_581_1312" x1="9.12148" y1="4.92859" x2="15.2348" y2="13.2003" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#965B31" />
                    <stop offset="0.5" stop-color="#F9AA57" />
                    <stop offset="1" stop-color="#965B31" />
                </linearGradient>
                <linearGradient id="paint16_linear_581_1312" x1="14.7293" y1="13.8528" x2="11.2203" y2="7.71619" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3D1F00" />
                    <stop offset="1" stop-color="#A55400" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IconBronzeMedal