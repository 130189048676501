import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { X } from 'lucide-react';
import React, { useState } from 'react';
import NoDataUI from '../../common/NoDataUI';
import { getColorFromName } from '../../lib/utils';
import moment from 'moment';

export default function Slider({ open, setOpen, userData, showTime = false }) {
    const [searchTerm, setSearchTerm] = useState("");

    // Filter userData based on search term
    const filteredUserData = userData?.filter((user) => {
        const lowerCaseTerm = searchTerm.toLowerCase();
        return (
            user?.name?.toLowerCase().includes(lowerCaseTerm)
        );
    });

    return (
        <div>
            {open && (
                <Transition
                    show={open}
                    as="div"
                    className="pointer-events-none max-w-[50%] fixed inset-y-0 right-0 flex pl-10"
                    enter="transition-all transform duration-900 ease-in"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition-all transform duration-900 ease-in"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog
                        open={open}
                        as="div"
                        className="pointer-events-none max-w-[50%] fixed inset-y-0 right-0 flex pl-10 z-[99]"
                        onClose={() => setOpen(false)}
                    >
                        <div className="pointer-events-auto relative w-screen max-w-1/2">
                            <DialogPanel
                                className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl transform transition-all duration-300 ease-in-out"
                            >
                                <DialogTitle
                                    as="h3"
                                    className="text-base/7 font-medium text-dark p-2 ml-4 flex justify-between border-b border-border"
                                >
                                    <p className="text-xl font-bold">Employee List</p>
                                    <span className="cursor-pointer mr-4" onClick={() => setOpen(false)}>
                                        <X className="text-dark/50" />
                                    </span>
                                </DialogTitle>
                                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                    <div className="mt-3 flex-grow overflow-hidden">
                                        <input
                                            type="text"
                                            placeholder="Search by Name"
                                            className="w-full p-2 mb-4 border border-border rounded-md outline-none"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <div className="h-full overflow-x-auto">
                                            <div className="h-full flex flex-col">
                                                <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                    <div className="w-[40%] px-2 text-sm">NAME</div>
                                                    <div className="w-[25%] text-sm">DEPARTMENT</div>
                                                    <div className="w-[20%] text-sm">JOB TITLE</div>
                                                    <div className="w-[20%] text-sm">CLOCKIN TIME</div>
                                                    {showTime && (
                                                        <div className="w-[20%] text-sm">LATE DURATION</div>
                                                    )}
                                                </div>
                                                <div className="flex-grow overflow-y-auto">
                                                    {filteredUserData?.length ? (
                                                        filteredUserData.map((user) => (
                                                            <div
                                                                key={user?.id || user?.name}
                                                                className="body w-full flex gap-2 text-muted border-b border-border cursor-pointer text-sm hover:bg-primary/5"
                                                            >
                                                                <div className="w-[45%] flex gap-2 items-center border-e border-border p-3">
                                                                    <div className="flex items-center gap-2">
                                                                        <div
                                                                            className="w-7 h-7 p-2 rounded-full flex items-center ml-2 capitalize justify-center text-white text-sm"
                                                                            style={{
                                                                                backgroundColor: `${getColorFromName(user?.name)}`,
                                                                            }}
                                                                        >
                                                                            {user?.name
                                                                                ?.split(' ')
                                                                                .slice(0, 2)
                                                                                .map((name) => name.slice(0, 1).toUpperCase())}
                                                                        </div>
                                                                        {user?.name}
                                                                    </div>
                                                                </div>
                                                                <div className="w-[30%] flex items-center font-medium border-e border-border px-2">
                                                                    {user?.department}
                                                                </div>
                                                                <div className="w-[25%] flex items-center font-medium border-e border-border">
                                                                    {user?.jobTitle}
                                                                </div>
                                                                <div className="w-[25%] flex items-center ml-4 border-e border-border capitalize">
                                                                    {moment(user?.checkInTime, 'HH:mm:ss').format('hh:mm:ss A')}
                                                                </div>
                                                                {showTime && (
                                                                    <div className="w-[25%] flex items-center ml-4 border-e border-border capitalize">
                                                                        {user?.delayDuration}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="h-full flex items-center justify-center">
                                                            <NoDataUI
                                                                subtitle={"Tasks and Reminders assigned to you will show here."}
                                                                title={"Oops, No Tasks Found!"}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </div>
                    </Dialog>
                </Transition>
            )}
        </div>
    );
}
