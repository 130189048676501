import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../common/ui/Button";
import { Copy, Pencil, Trash2 } from "lucide-react";
import { Input } from "../../common/Input";
import IconDocs from "../../common/svgIcons/IconDocs";
import { useNavigate } from "react-router-dom";
import IconLink from "../../common/svgIcons/IconLink";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../../services/api";
import { toast } from "react-toastify";
import DeleteModal from "../modal/DeleteModal";
import IconReply from "../../common/svgIcons/IconReply";

const DocsTitle = ({ docs, hoverId, setHoverId }) => {

  const inputRef = useRef();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const [localTitle, setLocalTitle] = useState();
  const [docId, setDocId] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false);

  useEffect(() => {
    setLocalTitle(docs?.title);
  }, [docs]);

  const handleTitleChange = (e) => {
    setLocalTitle(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleUpdateTitle(localTitle);
    }
    if (e.key === "Escape") {
      handleCancelEdit();
    }
  };

  const { mutate: updateTitle } = useMutation({
    mutationFn: async (newTitle) => {
      const response = await API.put(`/docs/${docId}/title`, { title: newTitle });
      return response.data;
    },
    onSuccess: () => {
      toast.success("Title updated successfully")
      setLocalTitle(docs?.title)
      queryClient.invalidateQueries({ queryKey: ["docs-list"] });
      queryClient.invalidateQueries({ queryKey: ["archive-list"] });
      queryClient.invalidateQueries({ queryKey: ["userDoc-list"] });
    },
    onError: (error) => {
      setDocId(null)
      setLocalTitle(docs?.title)
      console.error("Error Creating Document", error);
      toast.error(error.response?.data?.message || "Error updating title")
    },
  });

  const { mutate: deleteDocsMutation } = useMutation({
    mutationFn: async () => {
      if (docs?.isArchived) {
        const response = await API.patch(`/docs/${docId}/delete`);
        return response.data;
      }
      else {
        const response = await API.patch(`/docs/${docId}/archive`);
        return response.data;
      }
    },
    onSuccess: () => {
      toast.success("document deleted successfully")
      queryClient.invalidateQueries({ queryKey: ["docs-list"] });
      queryClient.invalidateQueries({ queryKey: ["archive-list"] });
      queryClient.invalidateQueries({ queryKey: ["userDoc-list"] });
    },
    onError: (error) => {
      console.error("Error deleting Document", error);
      toast.error(error.response?.data?.message || "Error deleting document")
    },
  });

  const { mutate: RestoreDocsMutation } = useMutation({
    mutationFn: async () => {
      const response = await API.patch(`/docs/${docId}/restore`);
      return response.data;
    },
    onSuccess: () => {
      toast.success("document restored successfully")
      queryClient.invalidateQueries({ queryKey: ["docs-list"] });
      queryClient.invalidateQueries({ queryKey: ["archive-list"] });
      queryClient.invalidateQueries({ queryKey: ["userDoc-list"] });
    },
    onError: (error) => {
      console.error("Error restoring Document", error);
      toast.error(error.response?.data?.message || "Error restoring document")
    },
  });

  const handleUpdateTitle = async (newTitle) => {
    updateTitle(newTitle);
    setIsEditMode(false);
    setHoverId(null);
  };

  const handleCancelEdit = () => {
    setLocalTitle(docs?.title);
    setIsEditMode(false);
    setHoverId(null);
  };

  const handleOnBlur = () => {
    if (isEditMode) {
      setIsEditMode(false);
      setHoverId(null);
    } else {
      setHoverId(null);
    }
  };

  const { mutate: DuplicateDocument } = useMutation({
    mutationFn: async () => {
      const pagesData = docs?.pages?.map(page => ({
        title: page?.title,
        content: page?.content,
      }));

      const response = await API.post(`/docs/main-doc`, { title: docs?.title + ' (Copy)' || 'docs', pages: pagesData });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["docs-list"] });
      queryClient.invalidateQueries({ queryKey: ["archive-list"] });
      queryClient.invalidateQueries({ queryKey: ["userDoc-list"] });
    },
    onError: (error) => {
      console.error("Error Creating Document", error);
      toast.error(error.response?.data?.message || "Error Creating Document")
    },
  });

  const firstPageId = docs?.pages?.[0]?._id;

  return (
    <div className="relative">
      <div className="flex items-center gap-2">
        <span>
          <IconDocs className="h-4 w-4 text-dark/90" />
        </span>
        {isEditMode ? (
          <Input
            ref={inputRef}
            id={`edit-title-${docs?._id}`}
            labelClass="!hidden"
            className="border-0 bg-transparent p-0 outline-none !ring-0"
            value={localTitle}
            onChange={handleTitleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleOnBlur}
            autoFocus
          />
        ) : (
          <p
            onClick={() => {
              if (firstPageId) {
                navigate(`/docs/${docs?._id}/${firstPageId}`);
              } else {
                navigate(`/docs/${docs?._id}`);
              }
            }}
            className="truncate max-w-[280px]"
          >
            {docs?.title}
          </p>
        )}
        <div className="flex items-center gap-1 leading-normal">
          {docs?.pages?.length > 1 &&
            <>
              <IconDocs className="h-3 w-3 text-dark/90" />
              <span className="text-xs pt-[2px]">{docs?.pages?.length || 0}</span>
            </>
          }
        </div>
      </div>
      {hoverId === docs?._id && !isEditMode && (
        <div className="absolute right-0 top-1/2 -translate-y-1/2 z-[9] flex items-center gap-1.5">
          <Button
            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              DuplicateDocument();
              toast.success('Document Duplicated Successfully')
            }}
          >
            <Copy className="h-4 w-4 text-dark/60" />
          </Button>
          <Button
            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigator.clipboard.writeText(process.env.REACT_APP_COPY_LINK + `/docs/${docs?._id}/${firstPageId}`)
              toast.success('url copied successfully')
            }}
          >
            <IconLink className="h-4 w-4 text-dark/60" />
          </Button>
          <Button
            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
            onClick={(e) => {
              setDocId(docs?._id)
              e.preventDefault();
              e.stopPropagation();
              inputRef.current?.focus();
              setIsEditMode(true);
            }}
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
            onClick={(e) => {
              setDocId(docs?._id)
              e.preventDefault();
              e.stopPropagation();
              setDeleteOpen(true);
            }}
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
          {docs?.isArchived &&
            <Button
              className="border border-border p-1 rounded-md bg-white h-auto text-dark/50 hover:bg-white"
              onClick={(e) => {
                setDocId(docs?._id)
                e.preventDefault();
                e.stopPropagation();
                setRestoreOpen(true);
              }}
            >
              <IconReply className="h-4 w-4 text-gray-600" />
            </Button>
          }
          <DeleteModal title={'Are you sure you want to delete this Document?'} open={deleteOpen} setOpen={setDeleteOpen} id={docs?._id} handleDeleteTask={deleteDocsMutation} />
          <DeleteModal title={'Are you sure you want to restore this Document?'} open={restoreOpen} setOpen={setRestoreOpen} id={docs?._id} handleDeleteTask={RestoreDocsMutation} />
        </div>
      )}
    </div>
  );
};

export default DocsTitle;
