import { Dialog, DialogPanel, DialogTitle, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'
import React, { useState } from 'react'
import NoDataUI from './NoDataUI';
import moment from 'moment';
import { Infinity } from 'lucide-react';

const LeaveHistoryDetails = ({ leaveData, Name, description, quota, Restrictions, balance, Sandwich }) => {
    const [selectedTab, setSelectedTab] = useState();
    return (
        <div className="mt-4">
            <TabGroup className="h-full flex flex-col">
                <TabList className="inline-flex gap-1 p-1 bg-primary/10 rounded-md w-fit">
                    <Tab
                        onClick={() => {
                            setSelectedTab('Balance-History');
                        }}
                        key={'Balance-History'}
                        className={({ selected }) =>
                            clsx("outline-none py-2 px-3 flex items-center gap-2.5 text-xs font-semibold rounded-md", selected ? "bg-white text-primary " : "text-gray-400")
                        }
                    >
                        Balance-History
                    </Tab>
                    <Tab
                        onClick={() => {
                            setSelectedTab('Policy');
                        }}
                        key={'Policy'}
                        className={({ selected }) =>
                            clsx(
                                "outline-none detail-section py-2 px-3 flex items-center gap-2.5 text-xs font-semibold rounded-md", selected ? "bg-white text-primary" : "text-gray-400")
                        }
                    >
                        Policy
                    </Tab>
                </TabList>
                <TabPanels className="p-6 pt-1 flex-grow overflow-y-auto max-h-screen w-full">
                    <TabPanel key={'Balance-History'} className="h-full w-full">
                        <div className="mt-3 w-full overflow-hidden border border-gray-200">
                            <div className="h-full overflow-x-auto w-full">
                                <div className={`h-full w-full flex flex-col`}>
                                    <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-2">
                                        <div className="w-[10%] px-4">Transaction date</div>
                                        <div className="w-[10%]">Change</div>
                                        <div className="w-[10%]">Balance</div>
                                        <div className="w-[50%]">Reason</div>
                                    </div>
                                    <div className="flex-grow overflow-y-auto">
                                        {leaveData && leaveData.length > 0 ? (
                                            <div className="w-full">
                                                {leaveData.map((leave, index) => (
                                                    <div
                                                        key={leave.id || index}
                                                        className="flex items-center gap-2 ml-2 text-gray-700 border-b border-gray-200 py-2 hover:bg-gray-50 cursor-pointer text-sm font-semibold last:border-0"
                                                    >
                                                        <div className="w-[10%] px-3 text-gray-800">
                                                            <div className="text-sm font-medium flex flex-col gap-1 flex-wrap">
                                                                <span className="text-gary-400">{moment(leave?.createdAt).format("DD-MMM-YYYY")}</span>
                                                            </div>
                                                        </div>
                                                        <div className="w-[10%]">
                                                            {leave?.change}
                                                        </div>
                                                        <div className="w-[10%]">{leave.type === 4 ? <Infinity className='h-4 w-4' /> : leave?.balance}</div>
                                                        <div className="w-[20%]">{leave?.reason}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="h-full">
                                                <NoDataUI textMainClass='mt-0' />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel key={'Policy'} className="h-full mb-10">
                        <h2 className='font-semibold text-lg'>{Name}</h2>
                        <p className='mt-2'>{description}</p>
                        <h2 className='font-semibold text-lg mt-2'>Leave quota & requesting for leave</h2>
                        <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                            {quota}
                        </ul>
                        <h2 className='font-semibold text-lg my-4'>Restrictions</h2>
                        <p className='font-semibold'>Restrictions for applying leave</p>
                        {Restrictions}
                        {balance &&
                            <>
                                <h2 className='font-semibold text-lg my-4'>Leave balances at the end of the year</h2>
                                {balance}
                            </>
                        }
                        {Sandwich &&
                            <>
                                <h2 className='font-semibold text-lg my-4'>Sandwich policy</h2>
                                {Sandwich}
                            </>
                        }
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    )
}

export default LeaveHistoryDetails
