import React from "react";
import { useQuery } from "@tanstack/react-query";
import API from "../services/api";
import UserProfile from "./UserProfile";

const TodayLeave = ({ title }) => {
  const fetchTodayLeaveList = async () => {
    try {
      const { data, loading } = await API.get(`/leaves/today`);
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: todayLeave } = useQuery({
    queryKey: ["todays-leave"],
    queryFn: fetchTodayLeaveList,
  });
  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex items-center justify-between">
        <h2 className="text-base">{title}</h2>
        {todayLeave?.length > 8 && <p>View All</p>}
      </div>
      <div className="time flex-grow">
        {todayLeave?.length > 0 ? (
          <div className="flex gap-3.5">
            {todayLeave.slice(0, 8).map((user, index) => (
              <div className="flex-col" key={index}>
                <UserProfile
                  userName={user?.name}
                  tooltipText={user?.name + " - " + user?.leaveType}
                  leave={true}
                  leaveType={user?.leaveType}
                />
              </div>
            ))}

            {todayLeave.length > 8 && (
              <div className="flex-col">
                <div className="relative">
                  <span className="relative font-medium uppercase h-10 w-10 p-2 text-dark rounded-full flex items-center justify-center text-sm first:ms-0 -ms-2.5 hover:z-[9] border border-border bg-gray-300">
                    +{todayLeave.length - 8}
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="bg-primary/10 p-2.5 rounded-md border border-secondary h-full">
            <p className="text-gray-500 text-sm">Everyone is working today!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TodayLeave;
