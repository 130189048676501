import React from "react";

const IconBack = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6747 4.57613C15.7796 4.68073 15.8628 4.80483 15.9196 4.94163C15.9763 5.07833 16.0056 5.22503 16.0056 5.37303C16.0056 5.52113 15.9763 5.66773 15.9196 5.80443C15.8628 5.94123 15.7796 6.06543 15.6747 6.16993L9.72245 12.1221L15.6747 18.0762C15.886 18.2875 16.0048 18.5742 16.0048 18.873C16.0048 19.1719 15.886 19.4586 15.6747 19.6699C15.4633 19.8813 15.1767 20 14.8778 20C14.5789 20 14.2923 19.8813 14.0809 19.6699L7.33089 12.9199C7.22601 12.8154 7.14279 12.6912 7.08601 12.5544C7.02923 12.4177 7 12.2711 7 12.123C7 11.975 7.02923 11.8283 7.08601 11.6916C7.14279 11.5548 7.22601 11.4307 7.33089 11.3261L14.0809 4.57613C14.1854 4.47133 14.3096 4.38803 14.4464 4.33133C14.5831 4.27453 14.7297 4.24533 14.8778 4.24533C15.0259 4.24533 15.1725 4.27453 15.3092 4.33133C15.446 4.38803 15.5702 4.47133 15.6747 4.57613Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconBack;
