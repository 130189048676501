import React from "react";
import RecentList from "./RecentList";
import WelcomeBanner from "./WelcomeBanner";
import SecondWelcomeBanner from "./SecondWelcomeBanner";
import PaidLeave from "../leave/PaidLeave";

const HomePage = () => {
  return (
    <div className="xxs:p-5 p-2.5 flex flex-col h-full">
      <div className="bg-[#F9FAFB] rounded-lg border border-secondary 2xl:p-5 p-3 h-full overflow-auto">
        <WelcomeBanner />
        <SecondWelcomeBanner />
        <RecentList />
      </div>
    </div>
  );
};

export default HomePage;
