import React, { useState } from 'react'
import IconInfo from '../../common/svgIcons/IconInfo'
import LeaveDetails from './LeaveDetails'
import LeaveHistory from '../modal/LeaveHistory';

const UnpaidLeave = ({ leaveData }) => {
    const [open, setOpen] = useState(false);
    const data = {
        Consumed: leaveData?.Unpaid?.count || 0,
        Available: 0
    }
    return (
        <div className="bg-white border border-secondary rounded-md">
            <div className="card-header p-4 pb-2 border-b border-secondary flex items-center justify-between">
                <h4 className="text-sm">Unpaid Leave</h4>
                <span onClick={() => setOpen(true)} className='cursor-pointer text-sm'>View Details</span>
            </div>
            <LeaveDetails showAccured={false} data={data} unpaid={true} color={"#64C3D1"} consumedColor={'#AEE3E9'} />
            {open && <LeaveHistory isOpen={open} setIsOpen={setOpen} defaultTab={'Unpaid'} />}
        </div>
    )
}

export default UnpaidLeave