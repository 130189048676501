import React from "react";
import LeaveListItem from "./LeaveListItem";
import NoDataUI from "../../common/NoDataUI";
import FilterItem from "../../common/FilterItem";

const LeaveHistory = ({ leaves, type, setType, setStatus, status, setStartDate, startDate, endDate, setEndDate }) => {
  return (
    <div className="border border-gray-200 mt-2">
      <FilterItem
        setStatus={setStatus} setType={setType} status={status} type={type} showDatePicker={true} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} startDate={startDate} showPending={false}
      />
      <div className="">
        <div className="mt-8">
          <div className="header w-full flex justify-between gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-2">
            <div className="w-[20%] px-3.5">LEAVE DATES</div>
            <div className="w-[20%]">LEAVE TYPE</div>
            <div className="w-[20%]">STATUS</div>
            <div className="w-[20%]">ACTION BY</div>
            <div className="w-[20%]">ACTION TAKEN ON</div>
            <div className="w-[20%]">LEAVE NOTE</div>
            <div className="w-[10%]">ACTIONS</div>
            {/* <div className="w-[60%] px-3.5">Name</div>
            <div className="w-[10%] text-center">LeaveType</div>
            <div className="w-[10%] text-center">Reason</div>
            <div className="w-[10%] text-center">TotalDays</div>
            <div className="w-[20%] text-center">Duration</div>
            <div className="w-[10%] text-center">Status</div> */}
          </div>
        </div>
        {leaves && leaves.length > 0 ? (
          <LeaveListItem leaveList={leaves}></LeaveListItem>
        ) : (
          <NoDataUI />
        )}
      </div>
    </div>
  );
};

export default LeaveHistory;
