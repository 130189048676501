import React from "react";
import LeaveList from "../components/leave/LeaveList";

const Leave = () => {
    return (
        <LeaveList />
    );
};

export default Leave;
