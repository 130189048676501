import { Dialog, DialogPanel, DialogTitle, Popover, PopoverButton } from '@headlessui/react';
import { Calendar, X } from 'lucide-react';
import React from 'react';
import Tooltip from '../../common/ui/Tooltip';
import IconUser from '../../common/svgIcons/IconUser';
import { useAuth } from '../../context/AuthContext';
import { getColorFromName } from '../../lib/utils';
import moment from 'moment';

const LeaveDetails = ({ leaveDetails, setOpen, isOpen }) => {
    const { user } = useAuth();

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/10">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel className="w-full max-w-md rounded-xl bg-white shadow-lg">
                        {/* Header */}
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground p-4 flex justify-between border-b border-border rounded-t-xl">
                            <p className='text-xl font-bold'>Leave Details</p>
                            <span className='cursor-pointer' onClick={() => setOpen(false)}><X className='text-dark/50' /></span>
                        </DialogTitle>

                        {/* Popover Section */}
                        <div className="p-4 py-0 my-4 max-h-[460px] overflow-auto">
                            <Popover>
                                <PopoverButton className="w-full text-sm font-semibold text-gray-800 flex items-start gap-4 focus:outline-none hover:text-gray-600">
                                    {/* User Avatar */}
                                    {leaveDetails?.user ? (
                                        <span
                                            className="relative font-medium uppercase h-12 w-12 text-white rounded-full flex items-center justify-center text-base"
                                            style={{
                                                background: `${getColorFromName(
                                                    leaveDetails?.user?.name
                                                )}`,
                                            }}
                                            key={leaveDetails?.user?.name}
                                        >
                                            {leaveDetails?.user?.name
                                                ?.split(' ')
                                                .slice(0, 2)
                                                .map((name) => name.slice(0, 1))
                                                .join('')}
                                        </span>
                                    ) : (
                                        <IconUser className="text-gray-400 h-12 w-12" />
                                    )}

                                    {/* Details Section */}
                                    <div className="flex flex-col items-start justify-start mt-1">
                                        <p className="text-gray-700 font-semibold text-start">
                                            {leaveDetails?.user?.name || 'User'}
                                        </p>
                                        <p className="text-gray-500 font-normal text-start">
                                            Requested by {leaveDetails?.adminRequest && leaveDetails?.adminRequest?.name ? leaveDetails?.adminRequest?.name : leaveDetails?.user?.name || 'User'} on{' '}
                                            {moment(leaveDetails?.appliedAt).format(
                                                'DD-MMM-YYYY hh:mm A'
                                            ) || 'date'}
                                        </p>
                                    </div>
                                </PopoverButton>

                                {/* Leave Days Section */}
                                {leaveDetails?.leaveDays?.length > 0 && (
                                    <div className="mt-4 space-y-3">
                                        <div
                                            className="flex items-center p-2 bg-gray-50 border border-gray-200 rounded-lg"
                                        >
                                            <div className="flex flex-col ml-2 overflow-hidden">
                                                <p className="text-gray-600 text-sm whitespace-normal break-words">
                                                    Reason: {leaveDetails?.reason || 'No reason provided'}
                                                </p>
                                                <p className="text-gray-600 text-sm">
                                                    Total Days: {leaveDetails?.totalDays || 'N/A'}
                                                </p>
                                            </div>
                                        </div>

                                        {leaveDetails?.leaveDays.map((leaveDay, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-3 p-2 bg-gray-50 border border-gray-200 rounded-lg"
                                            >
                                                <Calendar className="text-gray-400 h-5 w-5" />
                                                <div className="flex flex-col">
                                                    <p className="text-gray-700 text-sm">
                                                        {moment(leaveDay?.date).format('DD-MMM-YYYY')}
                                                    </p>
                                                    <p className="text-gray-500 text-sm">
                                                        {leaveDay?.leaveType}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Popover>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default LeaveDetails;
