import React, { useEffect, useRef, useState } from "react";
import Loader from "../../common/ui/Loader";
import { Input } from "../../common/Input";
import API from "../../services/api";

const ProjectInfoSection = ({ projectId, fetchProjctInfo, isLoading }) => {
  const inputTitleRef = useRef();
  const inputDisRef = useRef();
  const [editTitle, setEditTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const [localTitle, setLocalTitle] = useState();
  const [localDesc, setLocalDesc] = useState();

  useEffect(() => {
    if (editTitle && inputTitleRef.current) {
      inputTitleRef.current.focus();
    }
    if (editDescription && inputDisRef.current) {
      inputDisRef.current.focus();
    }
  }, [editTitle, editDescription]);

  useEffect(() => {
    setLocalTitle(fetchProjctInfo?.name);
    setLocalDesc(fetchProjctInfo?.description);
  }, [fetchProjctInfo]);

  const handleUpdate = async (field, value) => {
    try {
      const payload = { [field]: value };
      await API.patch(`/project/${projectId}`, payload);
      fetchProjctInfo();
    } catch (error) {
      console.error(`Error updating ${field}`, error);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "name") {
      setLocalTitle(value);
    } else if (field === "description") {
      setLocalDesc(value);
    }
    handleUpdate(field, value);
  };

  const handleKeyPress = (e, setEdit) => {
    if (e.key === "Escape") {
      // Reset to original values on Escape
      setLocalTitle(fetchProjctInfo?.name);
      setLocalDesc(fetchProjctInfo?.description);
      setEdit(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-primary font-semibold">Information</h3>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex items-center justify-between pb-2.5"></div>
          <div className="rounded-md border border-gray-100 bg-gray-50 p-4 flex-grow">
            <div className="body">
              <div className="flex flex-col gap-0 pb-3">
                <label className="text-gray-600 text-sm tracking-wide">
                  PROJECT TITLE
                </label>
                {editTitle ? (
                  <Input
                    ref={inputTitleRef}
                    id={`edit-title-${fetchProjctInfo?._id}`}
                    lableClass="hidden"
                    className="border-0 bg-transparent p-0 outline-none !ring-0 text-gray-900 font-semibold tracking-wide text-base"
                    value={localTitle}
                    onChange={(e) =>
                      handleInputChange("name", e.target.value)
                    }
                    onKeyPress={(e) => handleKeyPress(e, setEditTitle)}
                  />
                ) : (
                  <p
                    className="text-gray-900 font-semibold tracking-wide text-base"
                    onClick={() => setEditTitle(true)}
                  >
                    {localTitle}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-0">
                <label className="text-gray-600 text-sm tracking-wide">
                  PROJECT DESCRIPTION
                </label>
                {editDescription ? (
                  <Input
                    ref={inputDisRef}
                    id={`edit-description-${fetchProjctInfo?._id}`}
                    lableClass="hidden"
                    className="border-0 bg-transparent p-0 outline-none !ring-0 text-gray-900 font-semibold tracking-wide text-base"
                    value={localDesc}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    onKeyPress={(e) => handleKeyPress(e, setEditDescription)}
                  />
                ) : (
                  <p
                    className="text-gray-900 font-semibold tracking-wide text-base"
                    onClick={() => setEditDescription(true)}
                  >
                    {localDesc}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectInfoSection;
