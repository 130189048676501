import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import API from "../../services/api";
import TimeSheetChart from "./TimeSheetChart";

const TimeSheetSection = () => {
  const { user } = useAuth();

  const fetchWeeklyHours = async () => {
    try {
      const { data, loading } = await API.get(
        `/auth/users/${user?._id}/weekly-hours`
      );
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data: weeklyHourData, isLoading: isLoadingWeeklyHours } = useQuery({
    queryKey: ["weekly-hours"],
    queryFn: fetchWeeklyHours,
    enabled: !!user,
  });

  return (
    <div className="2xl:col-span-4 md:col-span-6 col-span-1 bg-white p-4 pb-0 rounded-lg border border-secondary">
      <TimeSheetChart data={weeklyHourData} loading={isLoadingWeeklyHours} />
    </div>
  );
};

export default TimeSheetSection;
