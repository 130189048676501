import React from 'react'
import NoDataGif from "../assets/gif/no-data.gif";
import { cn } from '../lib/utils';
import Lottie from 'react-lottie-player';
import Empty from '../assets/json/project -not-found.json';
const NoDataUI = ({ title, subtitle, className, subtitleClassName, img, textMainClass }) => {
  return (
    <div className={cn('flex flex-col items-center justify-center text-center PX-4', className)}>
      <div className="lottie-wrapper">
        {/* <img src={img ?? NoDataGif} alt="" className='max-w-[250px] w-full' /> */}
        <Lottie
          loop
          animationData={Empty}
          play
          style={{ width: 250, height: 250 }}
        />
      </div>
      <div className={cn('text-wrapper -mt-5', textMainClass)}>
        {title && <h3 className='text-xl font-semibold text-[#101828] mb-2'>{title}</h3>}
        <p className={cn('text-xs font-normal text-[#475467]', subtitleClassName)}>{subtitle}</p>
      </div>
    </div>
  )
}

export default NoDataUI