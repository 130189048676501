import React from 'react'

const SummarySection = () => {
    return (
        <div className="grid grid-cols-2 gap-5">
            <div className="border border-border p-4 rounded-lg bg-[#FCFCFD]">
                <div className="flex items-center justify-between pb-2 border-b border-border">
                    <h3>Primary Detail</h3>
                    <p className='text-primary text-sm'>Edit</p>
                </div>
                <div className="grid grid-cols-2 gap-3 pt-2">
                    <div className="">
                        <label className='text-sm text-gray-500'>First Name</label>
                        <p className='text-sm font-medium text-dark'>Nkn</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Middle Name</label>
                        <p className='text-sm font-medium text-dark'>Dnn</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Last Name</label>
                        <p className='text-sm font-medium text-dark'>Jeanette</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Display Name</label>
                        <p className='text-sm font-medium text-dark'>Thora</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Gender</label>
                        <p className='text-sm font-medium text-dark'>Female</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Date of Birth</label>
                        <p className='text-sm font-medium text-dark'>Thu Aug 12 2021 </p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Marital Status</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Blood Group</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Physically Handicapped</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Nationality</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                </div>
            </div>
            <div className="border border-border p-4 rounded-lg bg-[#FCFCFD]">
                <div className="flex items-center justify-between pb-2 border-b border-border">
                    <h3>Contact Detail</h3>
                    <p className='text-primary text-sm'>Edit</p>
                </div>
                <div className="grid grid-cols-2 gap-3 pt-2">
                    <div className="">
                        <label className='text-sm text-gray-500'>Work Email</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Personal Email</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Mobile Number</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                    <div className="">
                        <label className='text-sm text-gray-500'>Work Number</label>
                        <p className='text-sm font-medium text-dark'>-Not Set-</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummarySection