import React from "react";
import IconInfo from "../../common/svgIcons/IconInfo";
import ReactApexChart from "react-apexcharts";
import API from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import Tooltip from "../../common/ui/Tooltip";
import { useLocation } from "react-router-dom";

const Weekly = () => {
  const location = useLocation() 

  const userId = location?.state?.userId;

  const fetchweeklyData = async () => {
    try {
      const { data } = await API.get(`/leaves/weekly-count?userId=${userId}`);
      return data?.data || [];
    } catch (err) {
      console.error(err.message);
      return [];
    }
  };

  const { data: weakLeaveData } = useQuery(
    { queryKey: ['weakLeaveData'], queryFn: fetchweeklyData }
  );

  const weekOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  // Function to organize the data correctly for the chart
  const getSortedWeeklyData = () => {
    if (!weakLeaveData) return Array(7).fill(0);

    const sortedData = Array(7).fill(0); // Initialize an array of 7 days with default value 0

    // Loop through the days of the week and fill the data
    weekOrder.forEach((day, index) => {
      if (weakLeaveData[day] !== undefined) {
        sortedData[index] = weakLeaveData[day]; // Assign the value for the specific day
      }
    });

    return sortedData;
  };

  const barOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
        endingShape: "rounded",
        borderRadius: 5,
        borderRadiusApplication: 'end'
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      lines: { show: false },
      categories: [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
      ],
      labels: {
        style: {
          fontSize: "12px",
          colors: "#1F2937",
        },
      },
    },
    yaxis: {
      show: false,
    },
    colors: ["#5461D6"],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const barSeries = [
    {
      name: "Weekly Stats",
      data: getSortedWeeklyData()
    },
  ];
  return (
    <div className="col-span-3 bg-white p-4 border border-secondary rounded-md flex flex-col justify-between">
      <div className="card-header pb-2 border-b border-secondary flex items-center justify-between">
        <h4 className="text-sm">Weekly Pattern</h4>
          <Tooltip
            position="right"
            toolTipClass="cursor-pointer"
            text="This graph indicates your week-over-week pattern of leave utilization during the entire year. You can see on what days of the week you were mostly on leave, during the year."
            bgColor="bg-white"
            arrowbg="#5461d6"
            borderColor="border-t-[#5461d6]"
            detailClass="text-black max-w-96"
          >
            <IconInfo className="h-5 w-5 text-gray-500" />
          </Tooltip>
      </div>
      <div className="card-body">
        <ReactApexChart
          options={barOptions}
          series={barSeries}
          type="bar"
          height="120"
          className="monthly_chart"
        />
      </div>
    </div>
  );
};

export default Weekly;
