import { Dialog, DialogPanel, DialogTitle, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import LeaveHistoryDetails from '../../common/LeaveHistoryDetails';
import { useAuth } from '../../context/AuthContext';
import API from '../../services/api';
import { useQuery } from '@tanstack/react-query';

const date = new Date().getFullYear();

const CompsOffSection = ({ leaveData }) => (
    <div>
        <h2 className="text-lg font-semibold mb-4">Compensatory Off Details</h2>
        <LeaveHistoryDetails leaveData={leaveData} Name="CompsOff"
            description={'This leave type is used to provide additional leave for the work done by employee on off/extrawork/ etc.'}
            quota={<>
                <li className='mt-2'>You are allocated a total of 0 days of leave in a year beginning January {date} till December {date}. You can consume this leave in the same year they are accrued/credited.</li>
                <li className='mt-2'>You are allowed to have more than annual quota of leave, if you are granted additional leave manually by your management.</li>
                <li className='mt-2'>Comp Offs can be applied by self or on behalf by your manager</li>
                <li className='mt-2'>Comp Offs can be applied for both full day & half-day</li>
            </>}
            Restrictions={
                <>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>For applying a leave duration of 1 or more days, you need to apply at least 3 calendar days before of which there have to be at least 3 working days</li>
                        <li>Comp Offs requires comment while applying leave</li>
                        <li>There has to be a gap of 2 calendar days between two leave requests</li>
                        <li> Comp Offs cannot be applied when in notice period</li>
                    </ul>
                    <p className='mt-2 font-semibold'>Restrictions after joining</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You are able to consume leave 0 days after probation period. While you may not eligible to consume your leave during this period, your leave accrual happens as described in the previous section.</li>
                    </ul>
                    <p className='mt-4 font-semibold'>Past dated leave restrictions</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You are not allowed to apply past day leave. Your manager or HR manager can apply on your behalf</li>
                    </ul>
                </>
            }
            balance={
                <>
                    <p className='mt-2 font-semibold'>Leave balance at the end of the year (Dec {date})</p>
                    <p className='mt-2'>At the end of leave calendar(Dec {date}), the following policies apply,</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>If the leave balance is more than 0 days, upto 3 days will be carried forward, and 0 days will be encashed.</li>
                        <li>Negative leave balances that may have accumulated for employees who have taken leave beyond their annual quota gets nullified and balance is reset to zero</li>
                    </ul>

                </>
            }
            Sandwich={
                <>
                    <h2 className='font-semibold text-base mb-2'>Holidays during leave</h2>
                    <p>If the total number of leave exceed 0 calendar days and Holiday is right between two leave days (Holiday is sandwiched between leave days).</p>
                    <h2 className='font-semibold text-base py-2'>Weekly-offs during leave</h2>
                    <p>Weekly off day accompanying leave is treated as leave when total number of leave exceed 0 calendar days and Weekly off day is right between two leave days (Weekly off day is sandwiched between leave days).</p>
                </>
            }
        />
    </div>
);

const FloaterSection = ({ leaveData }) => (
    <div>
        <h2 className="text-lg font-semibold mb-4">Floater Leave Details</h2>
        <LeaveHistoryDetails leaveData={leaveData} Name="Floater Leave" description={'A floating holiday provides an employee with paid time off to observe a holiday'}
            quota={<>
                <li className='mt-2'>ou are allocated a total of 0 days of leave in a year beginning January {date} till December {date}. You can consume this leave in the same year they are accrued/credited.</li>
                <li className='mt-2'>Comp Offs can be applied by self or on behalf by your manager</li>
                <li className='mt-2'>Floater Leave can be applied for only full day</li>
            </>}
            Restrictions={
                <>
                    <li>For applying a leave duration of 1 or more days, you need to apply at least 0 calendar days before of which there have to be at least 0 working days</li>
                    <li>Floater Leave requires comment while applying leave</li>
                    <li>Floater Leave cannot be applied when in notice period</li>
                    <p className='mt-2 font-semibold'>Restrictions after joining</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You are able to consume leave 0 days after probation period. While you may not eligible to consume your leave during this period, your leave accrual happens as described in the previous section.</li>
                    </ul>
                    <p className='mt-4 font-semibold'>Past dated leave restrictions</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You can apply leave for a past day, but not beyond than 10 calendar days back.</li>
                    </ul>
                </>
            }
            balance={
                <>
                    <p className='mt-2 font-semibold'>Leave balance at the end of the year (Dec {date})</p>
                    <p className='mt-2'>At the end of leave calendar(Dec {date}), the following policies apply,</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>All Leave balances expire or get reset.</li>
                        <li>Negative leave balances that may have accumulated for employees who have taken leave beyond their annual quota gets nullified and balance is reset to zero</li>
                    </ul>

                </>
            }
        />
    </div>
);

const PaidSection = ({ leaveData }) => (
    <div>
        <h2 className="text-lg font-semibold mb-4">Paid Leave Details</h2>
        <LeaveHistoryDetails leaveData={leaveData} Name="Paid Leave" description={"This leave will be paid when not used"}
            quota={<>
                <li className='mt-2'>You are allocated a total of 10 days of leave in a year beginning January {date} till December {date}. You can consume this leave in the same year they are accrued/credited.</li>
                <li className='mt-2'>You are allowed to have more than annual quota of leave, if you are granted additional leave manually by your management.</li>
                <li className='mt-2'>While your annual quota is 10 days , you are not eligible to consume all of those leave right away. Leave accrue at regular intervals as defined below. Accrued leave balance are the leave available for you to consume at any time.</li>
                <li className='mt-2'>As per your leave plan, Paid Leave accrues once every month on 1 st.</li>
                <li className='mt-2'>Leave accrual starts 0 day(s) after probation ends. The leave accrual rate varies based on your tenure in the organization as defined below</li>
                <li className='ml-5 mt-2'>After 10 years from joining, 1.5 days are accrued Once every month</li>
                <li className='mt-2'>At any given time, you are limited to apply for those many leave that got accrued as of the date when a leave request is made and not on future day for which leave is applied. Ex:- If you are applying for a leave on March 01 for a leave on April 12th, leave accrued as of March 01 are considered.</li>
                <li className='mt-2'>Paid Leave can be applied by self or on behalf by your manager</li>
                <li className='mt-2'>Paid Leave can be applied for both full day & half-day</li>
            </>}
            Restrictions={
                <>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>For applying a leave duration of 1 or more days, you need to apply at least 1 calendar days before of which there have to be at least 1 working days</li>
                        <li>Paid Leave requires comment while applying leave</li>
                        <li>Paid Leave requires document proof, if leave request exceeds 1 calendar days</li>
                    </ul>
                    <p className='mt-2 font-semibold'>Restrictions after joining</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You are able to consume leave 0 days after probation period. While you may not eligible to consume your leave during this period, your leave accrual happens as described in the previous section.</li>
                    </ul>
                    <p className='mt-4 font-semibold'>Past dated leave restrictions</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You can apply leave for a past day, but not beyond than 3 calendar days back</li>
                    </ul>
                </>
            }
            balance={
                <>
                    <p className='mt-2 font-semibold'>Leave balance at the end of the year (Dec {date})</p>
                    <p className='mt-2'>At the end of leave calendar(Dec {date}), the following policies apply,</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>If the leave balance is more than 0 days, upto 3 days will be carried forward, and 0 days will be encashed.</li>
                        <li>Negative leave balances that may have accumulated for employees who have taken leave beyond their annual quota gets deducted from salary</li>
                    </ul>

                </>
            }
        />
    </div>
);

const UnpaidSection = ({ leaveData }) => (
    <div>
        <h2 className="text-lg font-semibold mb-4">Unpaid Leave Details</h2>
        <LeaveHistoryDetails leaveData={leaveData} Name="Unpaid Leave" description={'Loss of pay'}
            quota={<>
                <li className='mt-2'>You have no upper limit on available annual leave quota</li>
                <li className='mt-2'>Unpaid Leave can be applied by self or on behalf by your manager</li>
                <li className='mt-2'>Unpaid Leave can be applied for both full day & half-day</li>
            </>}
            Restrictions={
                <>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>For applying a leave duration of 1 or more days, you need to apply at least 0 calendar days before of which there have to be at least 0 working days</li>
                        <li>Unpaid Leave requires comment while applying leave</li>
                    </ul>
                    <p className='mt-2 font-semibold'>Restrictions after joining</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You are able to consume leave 0 days after joining date.</li>
                    </ul>
                    <p className='mt-4 font-semibold'>Past dated leave restrictions</p>
                    <ul style={{ listStyleType: 'disc' }} className='ml-8'>
                        <li>You can apply leave for a past day, but not beyond than 10 calendar days back.</li>
                    </ul>
                </>
            }
        />
    </div>
);

const LeaveHistory = ({ isOpen, setIsOpen, defaultTab }) => {
    const { user } = useAuth();

    const fetchLeaveDetailsHistory = async () => {
        try {
            const { data } = await API.get(`/leaves/history/${user?._id}`);
            return data?.data || [];
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };
    useEffect(() => {
        if (defaultTab) {
            const tabElement = document.querySelector(`.${defaultTab}`);
            if (tabElement) {
                setTimeout(() => {
                    tabElement.click();
                }, 0);
            }
        }
    }, [defaultTab]);
    

    const { data: leaveData } = useQuery({
        queryKey: ['leaveHistory'],
        queryFn: fetchLeaveDetailsHistory,
    });

    return (
        <div>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(false)}>
                <div className="fixed inset-0 z-10 w-screen h-screen bg-black/30">
                    <div className="flex min-h-full w-full items-center justify-center">
                        <DialogPanel className="w-screen h-screen bg-white">
                            <DialogTitle
                                as="h3"
                                className="text-base font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border"
                            >
                                <p className="text-xl font-bold py-1 px-1">Leave Details</p>
                                <span className="cursor-pointer" onClick={() => setIsOpen(false)}>
                                    <X className="text-dark/50" />
                                </span>
                            </DialogTitle>
                            <TabGroup className="h-full flex">
                                {/* Tab List */}
                                <TabList defaultChecked={'Paid'} className="flex gap-1 p-6 flex-col gap-y-1 flex-grow w-[250px]">
                                    <Tab
                                        key="CompsOff"
                                        className={({ selected }) =>
                                            clsx(
                                                'outline-none p-3 CompsOff flex items-center gap-2.5 text-xs leading-[1] font-semibold rounded-lg',
                                                selected
                                                    ? 'bg-primary/10 text-primary'
                                                    : 'text-gray-400 hover:bg-gray-100'
                                            )
                                        }
                                    >
                                        CompsOff
                                    </Tab>
                                    <Tab
                                        key="Floater"
                                        className={({ selected }) =>
                                            clsx(
                                                'outline-none p-3 Floater flex items-center gap-2.5 text-xs leading-[1] font-semibold rounded-lg',
                                                selected
                                                    ? 'bg-primary/10 text-primary'
                                                    : 'text-gray-400 hover:bg-gray-100'
                                            )
                                        }
                                    >
                                        Floater Leave
                                    </Tab>
                                    <Tab
                                        key="Paid"
                                        className={({ selected }) =>
                                            clsx(
                                                'outline-none p-3 flex Paid items-center gap-2.5 text-xs leading-[1] font-semibold rounded-lg',
                                                selected
                                                    ? 'bg-primary/10 text-primary'
                                                    : 'text-gray-400 hover:bg-gray-100'
                                            )
                                        }
                                    >
                                        Paid Leave
                                    </Tab>
                                    <Tab
                                        key="Unpaid"
                                        className={({ selected }) =>
                                            clsx(
                                                'outline-none p-3 Unpaid flex items-center gap-2.5 text-xs leading-[1] font-semibold rounded-lg',
                                                selected
                                                    ? 'bg-primary/10 text-primary'
                                                    : 'text-gray-400 hover:bg-gray-100'
                                            )
                                        }
                                    >
                                        Unpaid Leave
                                    </Tab>
                                </TabList>

                                {/* Tab Panels */}
                                <TabPanels className="w-full h-full p-6 overflow-y-auto">
                                    <TabPanel>
                                        <CompsOffSection leaveData={leaveData?.filter((leave) => leave.type === 1)} />
                                    </TabPanel>
                                    <TabPanel>
                                        <FloaterSection leaveData={leaveData?.filter((leave) => leave.type === 2)} />
                                    </TabPanel>
                                    <TabPanel>
                                        <PaidSection leaveData={leaveData?.filter((leave) => leave.type === 3)} />
                                    </TabPanel>
                                    <TabPanel>
                                        <UnpaidSection leaveData={leaveData?.filter((leave) => leave.type === 4)} />
                                    </TabPanel>
                                </TabPanels>
                            </TabGroup>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default LeaveHistory;
