import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { Calendar, X } from 'lucide-react';
import React from 'react';
import { Input } from '../../common/Input';
import { Button } from '../../common/ui/Button';
import ButtonLoader from '../../common/ui/ButtonLoader';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';

const AddHolidayModal = ({ isOpen, setIsOpen, setNewHoliday, handleSubmit, loader }) => {
    const {
        register,
        handleSubmit: handleFormSubmit,
        setValue,
        watch,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            date: null,
            description: '',
        },
    });
    

    const close = () => {
        setIsOpen(false);
        setNewHoliday({ name: '', date: '', description: '' });
        reset();
    };

    const onSubmit = (data) => {
        handleSubmit({
            name: data.name,
            date: data.date,
            description: data.description || '', // Make description optional
        });
        reset();
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4 ">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-visible"
                    >
                        <DialogTitle
                            as="h3"
                            className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border"
                        >
                            <div className="">
                                <p className="text-xl font-bold">Add Holiday</p>
                            </div>
                            <span className="cursor-pointer" onClick={() => close()}>
                                <X className="text-dark/50" />
                            </span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleFormSubmit(onSubmit)}>
                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Input
                                            placeholder="Name"
                                            {...register('name', { required: 'Name is required' })}
                                            className={`${errors.name ? 'border-red-500' : ''}`}
                                        />
                                        {errors.name && (
                                            <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>
                                        )}
                                    </div>
                                    <div className={`custome_date border mb-3 border-border bg-[#FBFBFF] rounded-md w-full relative ${errors.date ? 'border-red-500' : 'border-border'}`}>
                                        <DatePicker
                                            placeholderText="Date"
                                            dateFormat="dd-MM-yyyy"
                                            selected={watch('date')}
                                            popperPlacement="bottom-start"
                                            {...register('date', { required: 'date is required' })}
                                            onChange={(date) => setValue('date', date)}
                                            className="w-full rounded-md border px-4 py-3.5 text-sm"
                                        />
                                    </div>
                                    {errors.date && (
                                        <p className="text-red-500 text-xs my-1">{errors.date.message}</p>
                                    )}
                                    <div className="mb-3">
                                        <Input
                                            placeholder="Description"
                                            {...register('description')}
                                        />
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant="outline" type="button" onClick={() => close()}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <ButtonLoader /> : 'Add Holiday'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default AddHolidayModal;