import React, { useState } from 'react'
import IconInfo from '../../common/svgIcons/IconInfo'
import LeaveDetails from './LeaveDetails'
import LeaveHistory from '../modal/LeaveHistory';

const CompOffs = ({ userData, leaveData }) => {
  const [open, setOpen] = useState(false);

  const data = {
    Consumed: leaveData?.CompsOff?.count || 0,
    Available: userData?.compOffs || 0
  }
  return (
    <div className=" bg-white border border-secondary rounded-md">
      <div className="card-header p-4 pb-2 border-b border-secondary flex items-center justify-between">
        <h4 className="text-sm">Comp Offs</h4>
        <span onClick={() => setOpen(true)} className='cursor-pointer text-sm'>View Details</span>
      </div>
      <LeaveDetails showAccured={false} data={data} userData={userData} color={'#2E8B57'} consumedColor={'#D1FAE5'} />
      {open && <LeaveHistory isOpen={open} setIsOpen={setOpen} />}
    </div>
  )
}

export default CompOffs