import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Switch,
} from "@headlessui/react";
import { ChevronRightIcon, Search, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Input } from "../../common/Input";
import { Button } from "../../common/ui/Button";
import API from "../../services/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ShareUserDocsItem from "../documents/ShareUserDocsItem";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";

const ShareDocsModal = ({ isOpen, setIsOpen, project, docs }) => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserdata] = useState();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isPublic, setIsPublic] = useState(docs?.isPublic);
  const [isPrivate, setIsPrivate] = useState(true);

  const close = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setIsPublic(docs?.isPublic)
  }, [docs])

  const fetchUsers = async () => {
    try {
      await API.get(`/department/users/list`).then((res) => {
        setUserdata(res.data?.data);
      });
    } catch (err) {
      console.error(err.message);
    } finally {
      // setLoader(false)
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  const filteredMembers = userData?.filter((member) =>
    member.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const parseTaskContent = (content, doc, x = 10, y = 10) => {
    content.forEach((task) => {
      if (task.type === "taskItem") {
        // Render task item
        const text = task.content
          ?.find((c) => c.type === "paragraph")
          ?.content?.map((c) => c.text)
          ?.join(" ") || "Untitled Task";
        doc.text(`- [ ] ${text}`, x, y);
        y += 10;

        // Check for nested task lists
        const nestedTasks = task.content?.find((c) => c.type === "taskList");
        if (nestedTasks) {
          y = parseTaskContent(nestedTasks.content, doc, x + 10, y);
        }
      }
    });
    return y;
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();
    let yPosition = 20;
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    const textWidth = pageWidth - (margin * 2);

    // Helper for text wrapping
    const splitText = (text, fontSize) => {
      doc.setFontSize(fontSize);
      return doc.splitTextToSize(text, textWidth);
    };
    const checkNewPage = (height) => {
      if (yPosition + height > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage();
        yPosition = 20;
        return true;
      }
      return false;
    };
    const writeText = (text, indent = 0, format = {}) => {
      const { fontSize = 12, prefix = '' } = format;

      doc.setFontSize(fontSize);
      doc.setFont(undefined,);

      const wrappedText = splitText(text, fontSize);
      const textHeight = wrappedText.length * 7;

      checkNewPage(textHeight);

      wrappedText.forEach((line, index) => {
        doc.text(prefix + line, margin + indent, yPosition + (index * 7));
      });

      yPosition += textHeight + 5;
    };
    const parseContent = (content, level = 0) => {
      if (!content) return;

      if (Array.isArray(content)) {
        content.forEach(item => parseContent(item, level));
        return;
      }

      const indent = level * 10;

      switch (content.type) {
        case 'doc':
          parseContent(content.content, level);
          break;

        case 'paragraph':
          let paragraphText = '';
          let isBold = false;

          content.content?.forEach(textNode => {
            if (textNode.marks?.some(mark => mark.type === 'bold')) {
              paragraphText += textNode.text;
              isBold = true;
            } else {
              paragraphText += textNode.text;
            }
          });

          writeText(paragraphText, indent, { isBold });
          break;

        case 'bulletList':
          content.content?.forEach(item => {
            parseContent(item, level + 1);
          });
          break;

        case 'orderedList':
          content.content?.forEach((item, index) => {
            const listItem = item.content?.[0];
            if (listItem) {
              listItem.prefix = `${index + 1}. `;
              parseContent(listItem, level + 1);
            }
          });
          break;

        case 'listItem':
          const listItemContent = content.content?.[0];
          if (listItemContent) {
            listItemContent.prefix = content.prefix || '• ';
            parseContent(listItemContent, level);
          }
          break;

        case 'heading':
          const headingText = content.content?.[0]?.text || '';
          const fontSize = 20 - (content.attrs?.level * 2); // h1=18, h2=16, h3=14
          writeText(headingText, indent, { fontSize, isBold: true });
          break;

        case 'taskList':
          content.content?.forEach(item => {
            parseContent(item, level + 1);
          });
          break;

        case 'taskItem':
          const checked = content.attrs?.checked ? '[✓]' : '[ ]';
          const taskText = content.content?.[0]?.content?.[0]?.text || '';
          writeText(taskText, indent, { prefix: `${checked} ` });
          break;

        case 'codeBlock':
          const code = content.content?.[0]?.text || '';
          writeText(code, indent + 5, {
            fontSize: 10,
            prefix: '```\n',
            suffix: '\n```'
          });
          break;

        case 'blockquote':
          content.content?.forEach(item => {
            const quoteText = item.content?.[0]?.text || '';
            writeText(quoteText, indent, { prefix: '> ' });
          });
          break;

        case 'table':
          const tableContent = content.content?.map(row =>
            row.content?.map(cell => cell.content?.[0]?.text || '').join(' | ')
          ).join('\n');
          writeText(tableContent, indent);
          break;
      }
    };
    doc.setFontSize(14);
    doc.setFont(undefined);
    data.pages.forEach((page, pageIndex) => {
      if (pageIndex > 0) {
        doc.addPage();
        yPosition = 20;
      }
      writeText(page.title || "Untitled Page", 0, { fontSize: 14, isBold: false });
      parseContent(page.content);
    });
    doc.save(`${data.title || "document"}.pdf`);
  };

  const { mutate: UpdateDocsPermission } = useMutation({
    mutationFn: async () => {
      const response = await API.patch(`/docs/public-doc/${docs?._id}`);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userDoc-list"] })
      queryClient.invalidateQueries({ queryKey: ["archive-list"] })
      queryClient.invalidateQueries({ queryKey: ["docs-list"] })
      toast.success('permission Updated successfully');
    },
    onError: (error) => {
      console.error("Error Updating permission", error);
      toast.error(error.response?.data?.message || "Error Updating permission");
    },
  });
  const firstPageId = docs?.pages?.[0]?._id;
  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-10 focus:outline-none"
      onClose={() => {
        setIsOpen(false)
        setSearchQuery("")
      }}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="w-full max-w-lg rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
          >
            <DialogTitle
              as="h3"
              className="text-base/7 font-medium text-dark  px-4 py-2 flex justify-between"
            >
              <div className="">
                <p className="text-xl font-semibold">Share this Document</p>
                <p className="text-sm">Sharing all pages of <span className="underline">{docs?.title}</span> Doc</p>
              </div>
              <span className="cursor-pointer" onClick={() => {
                close()
                setSearchQuery("")
              }}>
                <X className="text-dark/50" />
              </span>
            </DialogTitle>
            <div className="px-4 pb-4">
              <div className="py-5 bg-[#F9FAFB] rounded-lg" >
                <ul className="flex flex-col gap-2.5">
                  <li className="flex items-center justify-between px-5">
                    <p className="text-sm">Share link with anyone</p>
                    <Switch
                      checked={isPublic}
                      onChange={UpdateDocsPermission}
                      className="group relative flex h-5 w-8 cursor-pointer rounded-full bg-[#98A1B2] p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary"
                    >
                      <span
                        aria-hidden="true"
                        className="pointer-events-none inline-block size-3 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-3"
                      />
                    </Switch>
                  </li>
                  {
                    isPublic &&
                    <li className="px-5">
                      <Button size='sm' onClick={async () => {
                        await navigator.clipboard.writeText(process.env.REACT_APP_COPY_LINK + `/doc/${docs?._id}/${firstPageId}`)
                        toast.success('Public Url Copied Successfully')
                      }}
                        className='w-full'>Copy Public Link</Button>
                    </li>
                  }
                  <li className="flex items-center justify-between px-5">
                    <p className="text-sm">Private Share</p>
                    <Switch
                      checked={isPrivate}
                      onChange={() => {
                        setIsPrivate(!isPrivate)
                      }}
                      className="group relative flex h-5 w-8 cursor-pointer rounded-full bg-[#98A1B2] p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary"
                    >
                      <span
                        aria-hidden="true"
                        className="pointer-events-none inline-block size-3 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-3"
                      />
                    </Switch>
                  </li>
                  {
                    isPrivate &&
                    <li className="">
                      <div className="px-5 mb-2">
                        <Input
                          placeholder="Invite by name or email"
                          sufix={<Search className="h-4 w-4 text-gray-400" />}
                          className="bg-white py-2"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div className="px-5 flex flex-col gap-3 h-[250px] overflow-auto pt-2.5">
                        {filteredMembers?.map((member, index) => (
                          <ShareUserDocsItem
                            dropdownId={index}
                            openDropdown={openDropdown}
                            setOpenDropdown={setOpenDropdown}
                            member={member}
                            docs={docs}
                          />
                        ))}
                      </div>
                    </li>
                  }
                  <li className="flex items-center justify-between px-5">
                    <p className="text-sm">Export Doc</p>
                    {/* <span className="border border-secondary text-xs px-1.5 py-0.5 rounded-md">Export</span> */}
                    <Button size='sm' className='bg-white hover:bg-transparent shadow-none border border-secondary text-gray-600' onClick={() => generatePDF(docs)}>Export</Button>
                  </li>
                </ul>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ShareDocsModal;
