import React, { useState } from "react";
import { Button } from "../../common/ui/Button";
import axios from "axios";
import Buttonloader from "../../common/ui/ButtonLoader";
import Loader from "../../common/ui/Loader";
import { X, Plus, Check } from "lucide-react";
import API from "../../services/api";

const TaskPlanningSection = ({
  fetchProjectInfo,
  projectId,
  projectInfo,
  isLoading,
}) => {
  const [taskData, setTaskData] = useState(projectInfo?.tasks || []);
  const [newTask, setNewTask] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const url = process.env.REACT_APP_FASTAPI_URL;

  const updateTasks = async (action, tasks) => {
    try {
      setButtonLoader(true);
      const { data } = await API.patch(`/project/${projectId}/Array-tasks`, {
        action,
        tasks,
      });
      setTaskData(data?.data || []);
      setButtonLoader(false);
    } catch (err) {
      console.error(`Error updating tasks with action "${action}":`, err.message);
      setButtonLoader(false);
    }
  };

  const handleAddTask = (e) => {
    e.preventDefault();
    if (newTask.trim()) {
      updateTasks("append", [newTask.trim()]);
      setNewTask("");
      setIsAdding(false);
    }
  };

  const cancelAdd = () => {
    setNewTask("");
    setIsAdding(false);
  };

  const moduleGenerate = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${url}/task_list/`, {
        project_id: projectId,
      });
      setTaskData(data?.tasks || []);
      fetchProjectInfo();
      setLoader(false);
    } catch (err) {
      console.error("Error in moduleGenerate:", err.message);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between pb-2.5">
        <h3 className="text-primary font-semibold">Task Planning</h3>
        {projectInfo?.tasks && (
          <Button onClick={() => moduleGenerate()} disabled={loader}>
            {loader ? <Buttonloader /> : "Regenerate"}
          </Button>
        )}
      </div>

      {isLoading ? (
        <Loader />
      ) : taskData.length === 0 ? (
        <div className="flex items-center justify-center h-full border border-gray-100 bg-gray-50 rounded-md">
          <Button onClick={() => moduleGenerate()} disabled={loader}>
            {loader ? <Buttonloader /> : "Generate"}
          </Button>
        </div>
      ) : (
        <div className="body flex-grow overflow-y-auto p-4 rounded-md border border-gray-100 bg-gray-50">
          <ul className="w-full list-none space-y-1">
            {taskData.map((task, index) => (
              <li
                key={index}
                className="flex items-center border-b border-gray-200 py-2 text-sm"
              >
                <span className="flex-grow text-primary">
                  {index + 1}. {task}
                </span>
                <button
                  onClick={() => updateTasks("remove", [task])}
                  className="ml-2 text-red-500 hover:text-red-700 transition duration-300"
                >
                  <X size={24} />
                </button>
              </li>
            ))}

            {!isAdding ? (
              <li
                className="cursor-pointer hover:bg-gray-100 flex items-center gap-2 px-2 py-2 rounded-md bg-blue-50 border border-blue-200"
                onClick={() => setIsAdding(true)}
              >
                <Plus className="w-6 h-6 text-blue-500" />
                <span className="text-base font-medium text-blue-600">
                  Add Task
                </span>
              </li>
            ) : (
              <li className="flex items-center gap-2 py-3 px-4">
                <form
                  onSubmit={handleAddTask}
                  className="flex items-center gap-2 w-full"
                >
                  <input
                    type="text"
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                    placeholder="Enter task label"
                    className="flex-grow py-2 px-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary text-sm"
                  />
                  <button
                    type="submit"
                    className={`p-2 text-white rounded-md  ${!newTask.trim() ? `bg-green-200 cursor-not-allowed` : `bg-green-500 hover:bg-green-600`  }`}
                    disabled={!newTask.trim()}
                  >
                    <Check size={20} />
                  </button>
                  <button
                    type="button"
                    onClick={cancelAdd}
                    className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    <X size={20} />
                  </button>
                </form>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default TaskPlanningSection;
