import { Dialog, DialogPanel, DialogTitle, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { Calendar, CheckIcon, ChevronDownIcon, ChevronRightIcon, Search, X } from 'lucide-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import { getIcon } from '../../lib/utils'
import Buttonloader from '../../common/ui/ButtonLoader'
import { Controller, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'
import { priorityTypes } from '../../staticData/data'
import API from '../../services/api'
import { useQuery } from '@tanstack/react-query'

const AddTaskModal = ({ isOpen, setIsOpen, newTask, setNewTask, handleAddTask, selectedProject, activeSprint, loader }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectProject, setSelectProject] = useState(null)
    const [selectedSprint, setSelectedSprint] = useState(null);
    useEffect(() => {
        if (activeSprint) {
            setSelectedSprint(activeSprint);
        }
    }, [activeSprint]);

    const { register, handleSubmit, reset, setValue, formState: { errors }, control } = useForm({
        defaultValues: newTask
    })
    const fetchProjectsList = async () => {
        try {
            const { data } = await API.get('/project/my-project-list');
            return data
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen, reset]);


    const { data: projects } = useQuery({
        queryKey: ['all-project'], queryFn: fetchProjectsList
    });

    const sprints = selectProject
        ? selectProject.sprints
        : projects?.find((project) => project._id === selectedProject?._id)?.sprints;

    const onSubmit = (data) => {
        const updatedData = {
            ...data,
            assignedTo: data.assignedTo ? [data.assignedTo] : [],
        };
        setNewTask({ ...newTask, ...updatedData });
        setSelectProject(null)
        setSelectedSprint(activeSprint);
        handleAddTask(updatedData);
        reset();
    };  

    const filteredMembers = selectedProject?.team?.filter((member) =>
        member?.userId?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    return (
        <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(false)}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground px-4 py-2 pb-0 flex justify-between border-b border-border">
                            <div className="">
                                <p className='text-xl font-bold'>Add Task</p>
                            </div>
                            <span className='cursor-pointer' onClick={() => setIsOpen(false)}><X className='text-dark/50' /></span>
                        </DialogTitle>
                        <div className="">
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="bg-forground px-4 flex items-center">
                                    {/* Project Dropdown */}
                                    <Controller
                                        name="projectId"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="">
                                                <Listbox
                                                    value={field.value}
                                                    onChange={(newProject) => {
                                                        field.onChange(newProject._id);
                                                        setSelectProject(newProject);
                                                        const lastSprint = newProject?.sprints?.[newProject.sprints.length - 1] || null;
                                                        setSelectedSprint(lastSprint);
                                                        setValue('sprintId', lastSprint?._id);
                                                    }}
                                                >
                                                    <ListboxButton
                                                        className={clsx(
                                                            `relative block rounded-lg text-left text-sm/6 border border-border`,
                                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                        )}
                                                    >
                                                        <h2 className="py-1 text-[#98A1B2] flex items-center gap-1 text-sm truncate">
                                                            {selectProject?.name || selectedProject?.name || "Select Project"}
                                                            {/* <ChevronDownIcon className="h-4 w-4" /> */}
                                                        </h2>
                                                    </ListboxButton>
                                                    <ListboxOptions
                                                        anchor="bottom"
                                                        className={clsx(
                                                            'w-auto z-[99] rounded-xl shadow-custom border border-white/5 bg-white [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                        )}
                                                    >
                                                        <div className="max-h-40 overflow-auto p-2 flex flex-col gap-0.5">
                                                            {projects?.length ? (
                                                                projects.map((project) => (
                                                                    <ListboxOption
                                                                        key={project._id}
                                                                        value={project}
                                                                        className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5"
                                                                    >
                                                                        <div className="flex items-center gap-1.5">
                                                                            <div className="text-sm font-medium">{project?.name}</div>
                                                                        </div>
                                                                    </ListboxOption>
                                                                ))
                                                            ) : (
                                                                <div className="text-xs text-muted px-2 py-1.5">No projects available</div>
                                                            )}
                                                        </div>
                                                    </ListboxOptions>
                                                </Listbox>
                                            </div>
                                        )}
                                    />

                                    {/* Sprint Dropdown */}
                                    <Controller
                                        name="sprintId"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex-1">
                                                <Listbox
                                                    value={field.value}
                                                    onChange={(newSprint) => {
                                                        field.onChange(newSprint)
                                                        setSelectedSprint(newSprint)
                                                    }}
                                                >
                                                    <ListboxButton
                                                        className={clsx(
                                                            `relative block w-full rounded-lg text-left text-sm/6 border border-border`,
                                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                        )}
                                                    >
                                                        <h2 className="py-1 text-[#98A1B2] flex items-center gap-1 text-sm truncate">
                                                            <span className="flex">
                                                                <ChevronRightIcon className="w-4 h-4 ml-1" />
                                                            </span>
                                                            {selectedSprint ? (
                                                                <>
                                                                    <span>{getIcon(selectedSprint?.status)}</span>
                                                                    <span className="truncate">
                                                                        {selectedSprint?.name} - {moment(selectedSprint?.startDate).format('DD/MM/YYYY')} - {moment(selectedSprint?.endDate).format('DD/MM/YYYY')}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                "No Sprint Available"
                                                            )}
                                                        </h2>
                                                    </ListboxButton>
                                                    <ListboxOptions
                                                        anchor="bottom"
                                                        className={clsx(
                                                            'w-[300px] z-[99] rounded-lg shadow-custom border border-white/5 bg-white [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                        )}
                                                    >
                                                        <div className="max-h-40 overflow-auto p-2 flex flex-col gap-0.5">
                                                            {sprints?.length > 0 ? (
                                                                sprints.map((sprint) => (
                                                                    <ListboxOption
                                                                        key={sprint._id}
                                                                        value={sprint}
                                                                        className="group text-sm text-dark bg-white flex cursor-pointer items-center gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5"
                                                                    >
                                                                        {/* <div className="flex items-center gap-1.5">
                                                                            <div className="text-sm font-medium">{sprint?.name}</div>
                                                                        </div> */}
                                                                        <>
                                                                            <span>{getIcon(sprint?.status)}</span>
                                                                            <span className="truncate">
                                                                                {sprint?.name} - {moment(sprint?.startDate).format('DD/MM/YYYY')} -
                                                                                {moment(sprint?.endDate).format('DD/MM/YYYY')}
                                                                            </span>
                                                                        </>
                                                                    </ListboxOption>
                                                                ))
                                                            ) : (
                                                                <div className="text-xs text-muted px-2 py-1.5">No sprints available</div>
                                                            )}
                                                        </div>
                                                    </ListboxOptions>
                                                </Listbox>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="p-8 py-5">
                                    <div className="mb-3">
                                        <Input placeholder='Task Title'
                                            {...register('title', { required: 'Task Title is required' })}
                                            className={`${errors.title ? 'border-red-500' : ''}`}
                                        />
                                        {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title.message}</p>}
                                    </div>
                                    <div className="mb-3">
                                        <textarea
                                            rows={3}
                                            placeholder='Description'
                                            className={`block w-full rounded-md border border-border bg-[#FBFBFF] px-4 py-3.5 text-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 `}
                                            {...register('description')}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Controller
                                            name="assignedTo"
                                            control={control}
                                            render={({ field }) => (
                                                <div>
                                                    <Listbox
                                                        value={field.value}
                                                        onChange={(newStatus) => field.onChange(newStatus)}
                                                    >
                                                        <ListboxButton
                                                            className={clsx(
                                                                `relative block w-full rounded-lg bg-[#FBFBFF] text-left text-sm/6 border border-border py-3.5 px-4`,
                                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                            )}
                                                        >
                                                            {field.value
                                                                ? selectedProject?.team?.find((user) => user.userId?._id === field.value)?.userId?.name
                                                                : <span className='text-[#a6adb7]'>Select a team member</span>}
                                                            <span className='absolute right-3 top-1/2 -translate-y-1/2'>
                                                                <ChevronDownIcon className="size-4" />
                                                            </span>
                                                        </ListboxButton>
                                                        <ListboxOptions
                                                            anchor='bottom'
                                                            className={clsx(
                                                                'w-[384px] z-[99] rounded-xl shadow-custom border border-white/5 bg-white [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                            )}
                                                        >
                                                            <div className="p-2">
                                                                <Input
                                                                    placeholder="Invite by name or email"
                                                                    sufix={<Search className='h-4 w-4 text-gray-400' />}
                                                                    className='bg-white py-2'
                                                                    value={searchQuery}
                                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="max-h-40 overflow-auto p-2 flex flex-col gap-0.5">
                                                                {filteredMembers?.length ? (
                                                                    filteredMembers?.map((user) => (
                                                                        <ListboxOption
                                                                            key={user?.userId?._id}
                                                                            value={user?.userId?._id}
                                                                            className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5"
                                                                        >
                                                                            <div className="flex items-center gap-1.5">
                                                                                <div className="text-sm font-medium">{user?.userId?.name}</div>
                                                                            </div>
                                                                            {field.value === user.userId?._id && (
                                                                                <CheckIcon className="size-4 text-dark group-data-[selected]:visible" />
                                                                            )}
                                                                        </ListboxOption>
                                                                    ))
                                                                ) : (
                                                                    <div className="text-xs text-muted px-2 py-1.5">No team members available</div>
                                                                )}
                                                            </div>
                                                        </ListboxOptions>
                                                    </Listbox>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Controller
                                            name="dueDate"
                                            control={control}
                                            render={({ field }) => (
                                                <div className='custome_date border border-border rounded-md w-full relative bg-[#FBFBFF]'>
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => field.onChange(moment(date).format("yyyy-MM-DD"))}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        dateFormat="dd MMM yyyy"
                                                        className="w-full rounded-md border px-4 py-3.5 text-sm"
                                                        placeholderText="Select date"
                                                        minDate={moment(Date.now()).format("yyyy-MM-DD")}
                                                    />
                                                    <span className='absolute end-4 top-1/2 -translate-y-1/2'>
                                                        <Calendar className='h-4 w-4 text-[#a6adb7]' />
                                                    </span>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="">
                                        <Controller
                                            name="priority"
                                            control={control}
                                            render={({ field }) => (
                                                <div>
                                                    <Listbox
                                                        value={field.value}
                                                        onChange={(newStatus) => field.onChange(newStatus)}
                                                    >
                                                        <ListboxButton
                                                            className={clsx(
                                                                `relative block w-full rounded-lg bg-[#FBFBFF] text-left text-sm/6 border border-border py-3.5 px-4`,
                                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                            )}
                                                        >
                                                            {field.value
                                                                ? field.value
                                                                : 'Select Priority'}
                                                            <span className='absolute right-3 top-1/2 -translate-y-1/2'>
                                                                <ChevronDownIcon className="size-4" />
                                                            </span>
                                                        </ListboxButton>
                                                        <ListboxOptions
                                                            anchor='bottom'
                                                            className={clsx(
                                                                'w-[384px] z-[99] rounded-xl p-2 shadow-custom border border-white/5 bg-white [--anchor-gap:var(--spacing-1)] focus:outline-none',
                                                                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                                            )}
                                                        >
                                                            {priorityTypes?.length ? (
                                                                priorityTypes?.map((type) => (
                                                                    <ListboxOption
                                                                        key={type?._id}
                                                                        value={type?.value}
                                                                        className="group text-dark bg-white flex cursor-pointer items-center justify-between gap-2 rounded-md py-1.5 px-2 select-none hover:bg-dark/5"
                                                                    >
                                                                        <div className="flex items-center gap-1.5">
                                                                            <div className="text-sm font-medium">{type?.name}</div>
                                                                        </div>
                                                                        {field.value === type?.value && (
                                                                            <CheckIcon className="size-4 text-dark group-data-[selected]:visible" />
                                                                        )}
                                                                    </ListboxOption>
                                                                ))
                                                            ) : (
                                                                <div className="text-xs text-muted px-2 py-1.5">No team members available</div>
                                                            )}
                                                        </ListboxOptions>
                                                    </Listbox>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="py-4 px-6 border-t border-border flex items-center justify-end gap-2">
                                    <Button variant='outline' type="button" onClick={() => setIsOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Create Task'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default AddTaskModal