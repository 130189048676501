import React, { useState } from "react";
import { getColorFromName } from "../../lib/utils";
import * as Tooltip from "@radix-ui/react-tooltip";

const TeamCalendar = ({
  teamMembers,
  initialMonth,
  initialYear,
  weeklyOffDays,
  currentMonth,
  currentYear,
  setCurrentMonth,
  setCurrentYear,
  userData,
  holidays,
}) => {
  // const [currentMonth, setCurrentMonth] = useState(initialMonth);
  // const [currentYear, setCurrentYear] = useState(initialYear);

  // Calculate the number of days in the current month
  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate(); // Get the last day of the month
  };

  const dates = Array.from(
    { length: getDaysInMonth(currentMonth, currentYear) },
    (_, i) => i + 1
  );

  // Get the day name (e.g., Mo, Tu, We) for a specific date
  const getDayName = (date) => {
    const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const dayIndex = new Date(currentYear, currentMonth, date).getDay();
    return dayNames[dayIndex];
  };

  // Handle month navigation
  const handleMonthChange = (direction) => {
    const newDate = new Date(currentYear, currentMonth + direction);
    setCurrentMonth(newDate.getMonth());
    setCurrentYear(newDate.getFullYear());
  };

  // Check if a day is a weekly off
  const isWeeklyOff = (day) => {
    const dayIndex = new Date(currentYear, currentMonth, day).getDay(); // Get the day index (0-6)
    return weeklyOffDays.includes(dayIndex); // Check if it's a weekly off
  };

  const isLeaveDay = (date, member) => {
    const formattedDate = new Date(currentYear, currentMonth, date)
      .toISOString()
      .split("T")[0];

    return member.dates.some((leaveEntry) => {
      const leaveDate = new Date(leaveEntry.date);
      return (
        leaveDate.getFullYear() === currentYear &&
        leaveDate.getMonth() === currentMonth &&
        leaveDate.getDate() === date
      );
    });
  };

  const isHoliday = (date) => {
    return holidays?.some((holiday) => {
      const holidayDate = new Date(holiday.date);
      return (
        holidayDate.getFullYear() === currentYear &&
        holidayDate.getMonth() === currentMonth &&
        holidayDate.getDate() === date
      );
    });
  };

  const isLeaveType = (date, member) => {
    const leaveEntries = member.dates.filter((leaveEntry) => {
      const leaveDate = new Date(leaveEntry.date);
      return (
        leaveDate.getFullYear() === currentYear &&
        leaveDate.getMonth() === currentMonth &&
        leaveDate.getDate() === date
      );
    });

    return leaveEntries.map((entry) => entry.leaveType)[0];
  };

  const isleaveStatus = (date, member) => {
    const leaveEntries = member.dates.filter((leaveEntry) => {
      const leaveDate = new Date(leaveEntry.date);
      return (
        leaveDate.getFullYear() === currentYear &&
        leaveDate.getMonth() === currentMonth &&
        leaveDate.getDate() === date
      );
    });

    return leaveEntries.map((entry) => entry.status)[0];
  };

  return (
    <div className="p-6 bg-white rounded-lg border border-secondary">
      {/* Team Calendar Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left border-collapse">
          {/* Table Head */}
          <thead>
            <tr>
              {/* Team Member Header */}
              <th className="p-1 px-0 text-gray-600 border-b z-10">
                <div className="flex items-center gap-2.5">
                  <button
                    onClick={() => handleMonthChange(-1)}
                    className="h-5 w-5 bg-primary/80 text-white rounded hover:bg-primary flex items-center justify-center text-xs"
                  >
                    ❮
                  </button>
                  <h2 className="text-lg text-dark/80 font-normal">
                    {new Date(currentYear, currentMonth).toLocaleString(
                      "default",
                      {
                        month: "long",
                      }
                    )}{" "}
                    {currentYear}
                  </h2>
                  <button
                    onClick={() => handleMonthChange(1)}
                    className="h-5 w-5 bg-primary/80 text-white rounded hover:bg-primary flex items-center justify-center text-xs"
                  >
                    ❯
                  </button>
                </div>
              </th>
              {/* Dates with Day Names */}
              {dates.map((date) => (
                <th
                  key={date}
                  className={`text-xs text-center border-b w-[32px]`}
                >
                  <p
                    className={`text-xs text-center w-fit font-normal mx-auto`}
                  >
                    {getDayName(date)}
                  </p>
                </th>
              ))}
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {userData?.map((member) => (
              <tr key={member.name} className="bg-white hover:bg-gray-100 py-1">
                {/* Team Member Column */}
                <td className="p-1 px-0 flex items-center gap-2">
                  <span
                    className="font-medium uppercase h-6 w-6 p-2 text-white rounded-full flex items-center justify-center text-xs first:ms-0 -ms-2.5 hover:z-[9] border border-border"
                    style={{
                      background: `${getColorFromName(member.name)}`,
                    }}
                  >
                    {member.name
                      ?.split(" ")
                      .slice(0, 2)
                      .map((name) => name.slice(0, 1))}
                  </span>
                  <div className="text-xs">{member.name}</div>
                </td>
                {/* Date Cells */}
                {dates?.map((date) => {
                  const isCurrentDayLeave = isLeaveDay(date, member);
                  const isPreviousDayLeave = isLeaveDay(date - 1, member);
                  const isNextDayLeave = isLeaveDay(date + 1, member);
                  const isCurrentDayWeeklyOff = isWeeklyOff(date);
                  const isPreviousDayWeeklyOff = isWeeklyOff(date - 1);
                  const isNextDayWeeklyOff = isWeeklyOff(date + 1);
                  const isCurrentDayHoliday = isHoliday(date);
                  const isPreviousDayHoliday = isHoliday(date - 1);
                  const isNextDayHoliday = isHoliday(date + 1);
                  const leaveType = isLeaveType(date, member);
                  const leaveStatus = isleaveStatus(date, member);

                  console.log("leaveStatus", leaveStatus);

                  return (
                    <td
                      key={date}
                      className={`text-xs text-center w-[32px] px-0 overflow-hidden`}
                    >
                      <Tooltip.Provider>
                        <Tooltip.Root delayDuration={0}>
                          <Tooltip.Trigger asChild>
                            <p
                              className={`text-xs text-center w-5 h-5 flex items-center justify-center mx-auto ${
                                isCurrentDayWeeklyOff
                                  ? `bg-[#fbc02d] text-white rounded-full z-[1] relative after:absolute after:h-full after:w-full after:bg-[#fbc02d66] after:-z-[1] ${
                                      getDayName(date) === "Sa"
                                        ? isPreviousDayLeave ||
                                          isPreviousDayHoliday // Check if Friday is a leave day
                                          ? "before:absolute before:h-full before:w-full before:bg-[#fbc02d66] before:-z-[1] before:-left-1/2 after:left-1/2"
                                          : "after:left-1/2"
                                        : getDayName(date) === "Su"
                                        ? isNextDayLeave || isNextDayHoliday // Check if Monday is a leave day
                                          ? "before:absolute before:h-full before:w-full before:bg-[#fbc02d66] before:-z-[1] before:left-1/2 after:right-1/2"
                                          : "after:right-1/2"
                                        : ""
                                    }`
                                  : isCurrentDayHoliday
                                  ? `bg-[#98b433] text-white rounded-full z-[1] relative ${
                                      isPreviousDayLeave && isNextDayLeave
                                        ? "after:absolute after:h-full after:w-full after:bg-[#98b43366] after:-z-[1] after:left-1/2 before:absolute before:h-full before:w-full before:bg-[#98b43366] before:-z-[1] before:right-1/2"
                                        : isPreviousDayLeave &&
                                          isNextDayWeeklyOff
                                        ? "after:absolute after:h-full after:w-full after:bg-[#98b43366] after:-z-[1] after:left-1/2 before:absolute before:h-full before:w-full before:bg-[#98b43366] before:-z-[1] before:right-1/2"
                                        : (isNextDayLeave ||
                                            isNextDayHoliday) &&
                                          (isPreviousDayWeeklyOff ||
                                            isPreviousDayHoliday ||
                                            isPreviousDayLeave)
                                        ? "after:absolute after:h-full after:w-full after:bg-[#98b43366] after:-z-[1] after:left-1/2 before:absolute before:h-full before:w-full before:bg-[#98b43366] before:-z-[1] before:right-1/2"
                                        : isPreviousDayLeave
                                        ? "after:absolute after:h-full after:w-full after:bg-[#98b43366] after:-z-[1] after:left-[-50%]"
                                        : isNextDayLeave || isNextDayHoliday
                                        ? "after:absolute after:h-full after:w-full after:bg-[#98b43366] after:-z-[1] after:right-[-50%]"
                                        : isPreviousDayWeeklyOff ||
                                          isPreviousDayHoliday
                                        ? "before:absolute before:h-full before:w-full before:bg-[#98b43366] before:-z-[1] before:-left-1/2"
                                        : isNextDayWeeklyOff
                                        ? "after:absolute after:h-full after:w-full after:bg-[#98b43366] after:-z-[1] after:right-[-50%]"
                                        : ""
                                    }`
                                  : isCurrentDayLeave
                                  ? `text-white rounded-full z-[1] relative ${
                                      isPreviousDayLeave && isNextDayLeave
                                        ? `after:absolute after:h-full after:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "after:bg-[#64c3d166]"
                                              : "after:bg-[#c2b89d66]"
                                          } after:-z-[1] after:left-1/2 before:absolute before:h-full before:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "before:bg-[#64c3d166]"
                                              : "before:bg-[#c2b89d66]"
                                          } before:-z-[1] before:right-1/2`
                                        : (isPreviousDayLeave ||
                                            isPreviousDayHoliday) &&
                                          (isNextDayWeeklyOff ||
                                            isNextDayHoliday)
                                        ? `after:absolute after:h-full after:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "after:bg-[#64c3d166]"
                                              : "after:bg-[#c2b89d66]"
                                          } after:-z-[1] after:left-1/2 before:absolute before:h-full before:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "before:bg-[#64c3d166]"
                                              : "before:bg-[#c2b89d66]"
                                          } before:-z-[1] before:right-1/2`
                                        : (isNextDayLeave ||
                                            isNextDayHoliday) &&
                                          (isPreviousDayWeeklyOff ||
                                            isPreviousDayHoliday)
                                        ? `after:absolute after:h-full after:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "after:bg-[#64c3d166]"
                                              : "after:bg-[#c2b89d66]"
                                          } after:-z-[1] after:left-1/2 before:absolute before:h-full before:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "before:bg-[#64c3d166]"
                                              : "before:bg-[#c2b89d66]"
                                          } before:-z-[1] before:right-1/2`
                                        : isPreviousDayLeave
                                        ? `after:absolute after:h-full after:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "after:bg-[#64c3d166]"
                                              : "after:bg-[#c2b89d66]"
                                          } after:-z-[1] after:left-[-50%]`
                                        : isNextDayLeave ||
                                          isNextDayWeeklyOff ||
                                          isNextDayHoliday
                                        ? `after:absolute after:h-full after:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "after:bg-[#64c3d166]"
                                              : "after:bg-[#c2b89d66]"
                                          } after:-z-[1] after:right-[-50%]`
                                        : isPreviousDayWeeklyOff
                                        ? `before:absolute before:h-full before:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "before:bg-[#64c3d166]"
                                              : "before:bg-[#c2b89d66]"
                                          } before:-z-[1] before:-left-1/2`
                                        : isPreviousDayHoliday
                                        ? `after:absolute after:h-full after:w-full ${
                                            leaveType !== "Unpaid"
                                              ? "after:bg-[#64c3d166]"
                                              : "after:bg-[#c2b89d66]"
                                          } after:-z-[1] after:right-[50%]`
                                        : ""
                                    }`
                                  : ""
                              }`}
                            >
                              <span
                                className={`h-full w-full rounded-full flex items-center justify-center overflow-hidden relative after:absolute after:h-full after:bottom-0  ${
                                  isCurrentDayLeave &&
                                  !isCurrentDayHoliday &&
                                  !isCurrentDayWeeklyOff
                                    ? leaveType !== "Unpaid"
                                      ? `bg-[#64c3d166] z-[1] text-white after:bg-[#64c3d1] after:-z-[1] ${
                                        leaveStatus === 'First Half' ? 'after:w-1/2 after:left-0' : leaveStatus === 'Second Half' ? 'after:w-1/2 after:right-0' : 'after:w-full after:left-0'
                                      }`
                                      : `bg-[#c2b89d66] z-[1] text-white after:bg-[#c2b89d] after:-z-[1] ${
                                        leaveStatus === 'First Half' ? 'after:w-1/2 after:left-0' : leaveStatus === 'Second Half' ? 'after:w-1/2 after:right-0' : 'after:w-full after:left-0'
                                      }`
                                    : "bg-transparent"
                                }`}
                              >
                                {date}
                              </span>
                            </p>
                          </Tooltip.Trigger>
                          {(isCurrentDayWeeklyOff || isCurrentDayLeave) &&
                            !isCurrentDayHoliday && (
                              <Tooltip.Portal>
                                <Tooltip.Content
                                  align="center"
                                  side="top"
                                  className="bg-muted  rounded-md p-1 text-xs font-semibold text-white max-sm:hidden z-[99]"
                                  sideOffset={5}
                                >
                                  <span className="me-1">
                                    {isCurrentDayWeeklyOff
                                      ? "Weekly Off - full day"
                                      : `${leaveType} Leave - ${leaveStatus}`}
                                  </span>
                                  <Tooltip.Arrow className="TooltipArrow fill-muted" />
                                </Tooltip.Content>
                              </Tooltip.Portal>
                            )}
                        </Tooltip.Root>
                      </Tooltip.Provider>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center gap-5 justify-end pt-2.5">
        <div className="flex items-center gap-2.5">
          <span className="inline-block h-2.5 w-2.5 bg-[#64c3d1] rounded-full"></span>
          <p className="text-xs">Paid Leave</p>
        </div>
        <div className="flex items-center gap-2.5">
          <span className="inline-block h-2.5 w-2.5 bg-[#c2b89d] rounded-full"></span>
          <p className="text-xs">Unpaid Leave</p>
        </div>
        <div className="flex items-center gap-2.5">
          <span className="inline-block h-2.5 w-2.5 bg-[#fbc02d] rounded-full"></span>
          <p className="text-xs">Weekly off</p>
        </div>
        <div className="flex items-center gap-2.5">
          <span className="inline-block h-2.5 w-2.5 bg-[#98b433] rounded-full"></span>
          <p className="text-xs">Holiday</p>
        </div>
      </div>
    </div>
  );
};

export default TeamCalendar;
