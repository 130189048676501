import React, { useState } from 'react'
import API from '../../services/api';
import { useQuery } from '@tanstack/react-query';
import { groupTasksByStatus } from '../../lib/utils';
import { statusType } from '../../staticData/data';
import clsx from 'clsx';
import TaskListItem from '../dashboard/TaskListItem';
import NoDataUI from '../../common/NoDataUI';
import Loader from '../../common/ui/Loader';
import { useAuth } from '../../context/AuthContext';

const RecentList = () => {
    const {user} = useAuth()
    const [loader, setLoader] = useState(false);

    const fetchTasks = async () => {
        try {
            const { data } = await API.get(`/task/my-tasks/today`);
            return groupTasksByStatus(data);
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: sortedTasks, isLoading } = useQuery(
        { queryKey: ['recentTasks'], queryFn: fetchTasks },
    );

    return (
        <>
            <div className='p-5'>
                <h2 className='text-lg font-semibold'>Today's Tasks</h2>
                {isLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                        <Loader />
                    </div> : (
                        <div className={`mt-3`}>
                            {
                                sortedTasks?.length ? sortedTasks?.map((sortedTask) => {
                                    const theme = statusType.find((status) => status.name === sortedTask.status)
                                    return (
                                        <>
                                            <div className="mb-2.5">
                                                <button
                                                    className={clsx(
                                                        `relative text-white capitalize font-semibold  inline-block text-xs px-1 py-1 rounded-md border`,
                                                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25 '
                                                    )}
                                                    style={{ background: `${theme?.bgColor}`, color: `${theme?.color}`, borderColor: `${theme?.color}` }}
                                                >
                                                    <div className='flex items-center gap-1'>
                                                        {theme?.icon}
                                                        {sortedTask.status}
                                                    </div>
                                                </button>
                                                <div className="overflow-x-auto">
                                                    <div className='min-w-[800px]'>
                                                        <div className="mt-2">
                                                            <div className="header w-full flex gap-2 bg-primary/5 border border-[#ECEDFF] text-primary font-semibold text-sm rounded-md py-1.5">
                                                                <div className="w-[50%] px-3.5">Name</div>
                                                                <div className="w-[16%]">Due Date</div>
                                                                <div className="w-[16%]">Time Estimate</div>
                                                                <div className="w-[16%]">Priority</div>
                                                            </div>
                                                        </div>
                                                        {
                                                            sortedTask?.tasks.map((task) => {
                                                                return (
                                                                    <TaskListItem userRol={task?.projectId?.team?.filter(member => member.userId === user._id)?.map(member => member?.role)[0]} isRecent={true} type={true} fetchCurrentTask={'fetchCurrentTask'} user={task} task={task} key={task._id} sprintDetails={task?.sprintId} projectDetails={task?.projectId} />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }) :
                                    <div className="h-full flex items-center justify-center">
                                        <NoDataUI subtitle={"Tasks and Reminders assigned to you will show here."} title={"Oops, No Tasks Found!"} />
                                    </div>
                            }
                        </div>
                    )}

            </div>
        </>
    )
}

export default RecentList