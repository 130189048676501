import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";
import { useAuth } from "../../context/AuthContext";
import API from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import LiveTimer from "../LiveTimer";

const CurrentTimmer = ({ setModelOpen, isCheckedIn }) => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update the time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const formattedDate = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const { user } = useAuth();

  const fetchTodaysHour = async () => {
    try {
      const { data } = await API.get('/auth/user/today-hours');
      return data
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data } = useQuery({
    queryKey: ['todays-hour'], queryFn: fetchTodaysHour
  });

  const isActiveTimer = data?.data?.activeTimer
    const totalSeconds = data?.data?.totalSeconds
    const duration = moment.duration(totalSeconds, 'seconds');
    const formattedDuration = `${duration.hours()}h : ${duration.minutes()}m : ${duration.seconds()}s`;

  return (
    <div className="xl:col-span-3 md:col-span-6 bg-white text-dark rounded-lg p-4 border border-secondary">
      <div className="flex justify-between items-center">
        <span className="font-medium">Time Today - {formattedDate}</span>
        <button
          className="text-sm underline"
          onClick={() => navigate("/attendance")}
        >
          View All
        </button>
      </div>
      <div className="time mt-4">
        <h4 className='text-sm font-light'>Today's Task Time</h4>
        {
          isActiveTimer ? (
            <LiveTimer className="text-gray-600 text-2xl font-semibold cursor-default whitespace-nowrap" initialElapsedSeconds={totalSeconds} />
          ) : <h6 className={'text-gray-600 text-2xl font-semibold cursor-default whitespace-nowrap'}>{formattedDuration}</h6>
        }
      </div>
      <div className="mt-4">
        <p className="text-sm font-light">CURRENT TIME</p>
        <div className="flex items-center justify-between">
          <h1 className="text-gray-600 text-2xl font-semibold mt-1">{formattedTime}</h1>
          <Button
            size="sm"
            className={`font-semibold ${
              isCheckedIn && "bg-red-500 hover:bg-red-500"
            }`}
            onClick={() => setModelOpen(true)}
          >
            {isCheckedIn ? "Check Out" : "Check In"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrentTimmer;