import React from 'react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import IconTrack from './svgIcons/IconTrack';
import NoDataUI from './NoDataUI';
import { groupTasksByStatus } from '../lib/utils';
import { useQuery } from '@tanstack/react-query';
import API from '../services/api';
import { statusType } from '../staticData/data';
import clsx from 'clsx';
import { useQueryClient } from '@tanstack/react-query';
import { CheckIcon, Play } from 'lucide-react';
import { toast } from 'react-toastify';
import { Button } from './ui/Button';
import Tooltip from './ui/Tooltip';

const TodayTaskDropdown = () => {
    const queryClient = useQueryClient()
    const fetchTasks = async () => {
        try {
            const { data } = await API.get(`/task/my-tasks/today`);
            return groupTasksByStatus(data);
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: sortedTasks } = useQuery(
        { queryKey: ['recentTasks'], queryFn: fetchTasks },
    );
    const startTimer = async (id) => {
        try {
            await API.post('/timer/start', { taskId: id });
            toast.success('tracking started');
            queryClient.invalidateQueries({ queryKey: ['todays-hour'], refetchType: 'all' });
            queryClient.invalidateQueries({ queryKey: ['todays-hour'] });
        } catch (err) {
            console.error(err.message);
            toast.error(err?.response?.data?.message);
        }
    };

    const startCommonTimer = async (id) => {
        try {
            await API.post('/timer/common-start', { taskId: id });
            toast.success('tracking started');
            queryClient.invalidateQueries({ queryKey: ['todays-hour'], refetchType: 'all' });
            queryClient.invalidateQueries({ queryKey: ['todays-hour'] });
        } catch (err) {
            console.error(err.message);
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <Menu>
            <MenuButton>
                <Tooltip detailClass='top-10 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Timer' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                    <div className="flex items-center rounded-full text-sm">
                        <IconTrack className="w-6 h-6 mt-2 text-gray-500" />
                    </div>
                </Tooltip>
            </MenuButton>
            <MenuItems
                transition
                anchor="bottom end"
                className="w-72 !max-h-[350px] flex flex-col gap-1 p-2 origin-top-right rounded-xl border-2 border-border bg-white shadow-lg transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
            >
                <MenuItem>
                    <div className="flex items-center gap-2 pb-1.5">
                        <Button size='sm' onClick={() => startCommonTimer(process.env.REACT_APP_MEETING_ID)} className={"font-semibold max-sm:h-6 w-full"}>Meeting</Button>
                        <Button size='sm' onClick={() => startCommonTimer(process.env.REACT_APP_GENERAL_ID)} className={"font-semibold max-sm:h-6 w-full"}>General</Button>
                    </div>
                </MenuItem>
                {sortedTasks && sortedTasks?.length > 0 ? sortedTasks.map((sortedTask) => {
                    const theme = statusType.find((status) => status.name === sortedTask.status)
                    return (
                        sortedTask?.tasks.map((task) => (
                            <MenuItem key={task._id}>
                                <div
                                    key={task._id}
                                    className="flex items-start gap-2 p-2 py-1 rounded-md border border-border hover:bg-border"
                                >
                                    <span>
                                        {theme?.icon}
                                    </span>
                                    <div className="flex-grow flex items-center justify-between gap-3 overflow-hidden ">
                                        <div className="flex flex-col overflow-hidden">
                                            <p className='text-sm font-semibold truncate'>{task?.title}</p>
                                            <p className='text-xs truncate'>{task.projectId?.name}</p>
                                        </div>
                                        <div className="flex items-center gap-1.5">
                                            <div className="flex items-center justify-center rounded-full bg-primary h-4 w-4 cursor-pointer" onClick={() => startTimer(task?._id)}>
                                                <Play className="h-2 w-2 text-white fill-white" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </MenuItem>

                        ))
                    )
                })
                    :
                    <div>
                        <NoDataUI textMainClass='m-0' />
                    </div>
                }
            </MenuItems>
        </Menu >
    )
}

export default TodayTaskDropdown
