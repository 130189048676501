import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const ProtectedRoute = ({ children, auth }) => {
    const [user, setUser] = useState(null);
    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
        const encryptedUser = localStorage.getItem("user");
        if (encryptedUser) {
            try {
                const bytes = CryptoJS.AES.decrypt(encryptedUser, process.env.REACT_APP_SECRET_KEY);
                const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                if (!decryptedData) {
                    localStorage.removeItem("token");
                    setUser(null);
                } else {
                    setUser(decryptedData);
                }
            } catch (error) {
                console.error("Failed to decrypt user data:", error.message);
                localStorage.removeItem("user");
                setUser(null);
            }
        } else {
            setUser(null);
        }
        setIsProcessing(false);
    }, []);

    if (isProcessing) {
        return null;
    }


    if (!user) {
        localStorage.removeItem("token");
        return <Navigate to="/login" replace />;
    }

    if (!auth) {
        return children;
    }

    if (user?.role !== "admin" && user?.role !== "human_resources") {
        return <Navigate to="/home" replace />;
    }

    return children;
};

export default ProtectedRoute;
    