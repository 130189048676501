import React from "react";
import SummaryDetail from "../components/teamSummary";

const TeamSummary = () => {
  return (
    <div className="xxs:p-5 p-2.5 flex flex-col h-full">
      <SummaryDetail />
    </div>
  );
};

export default TeamSummary;
