import React from "react";

const IconPanel = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M12.5 5.625V16.875L7.5 14.375V3.125L12.5 5.625Z"
        fill="currentColor"
      />
      <path
        d="M17.8844 3.88212C17.8095 3.82378 17.7223 3.78326 17.6295 3.76364C17.5366 3.74401 17.4405 3.74579 17.3484 3.76884L12.5727 4.96259L7.77969 2.56571C7.64614 2.4991 7.49317 2.48248 7.34844 2.51884L2.34844 3.76884C2.21323 3.80264 2.09319 3.88066 2.00741 3.9905C1.92163 4.10035 1.87502 4.23572 1.875 4.37509V15.6251C1.87501 15.7201 1.89667 15.8138 1.93833 15.8991C1.97998 15.9845 2.04054 16.0592 2.1154 16.1176C2.19025 16.1761 2.27745 16.2167 2.37035 16.2364C2.46326 16.2561 2.55943 16.2544 2.65156 16.2313L7.42734 15.0376L12.2203 17.4345C12.3073 17.4774 12.403 17.4998 12.5 17.5001C12.5511 17.5001 12.602 17.4938 12.6516 17.4813L17.6516 16.2313C17.7868 16.1975 17.9068 16.1195 17.9926 16.0097C18.0784 15.8998 18.125 15.7645 18.125 15.6251V4.37509C18.125 4.28003 18.1034 4.18622 18.0617 4.10079C18.02 4.01537 17.9593 3.94058 17.8844 3.88212ZM8.125 4.13602L11.875 6.01102V15.8641L8.125 13.9891V4.13602ZM3.125 4.86337L6.875 3.92587V13.8868L3.125 14.8243V4.86337ZM16.875 15.1368L13.125 16.0743V6.11337L16.875 5.17587V15.1368Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconPanel;
