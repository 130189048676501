import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/ui/Loader";
import NoDataUI from "../../common/NoDataUI";
import API from "../../services/api";
import { Button } from "../../common/ui/Button";
import ApplyLeaveModel from "../modal/ApplyLeaveModel";

import { Link, useLocation } from "react-router-dom";
import ConsumedLeave from "./ConsumedLeave";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import CompOffs from "./CompOffs";
import PaidLeave from "./PaidLeave";
import UnpaidLeave from "./UnpaidLeave";
import PendingLeave from "./PendingLeave";
import LeaveHistory from "./LeaveHistory";
import { useAuth } from "../../context/AuthContext";
import LeavePolicy from "../modal/LeavePolicy";


const LeaveList = () => {
    const { user } = useAuth()
    const [loader, setLoader] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState("Except Pending");
    const [leavePolicyOpen, setLeavePolicyOpen] = useState(false);
    const [type, setType] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // Pagination States
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    // const user = JSON.parse(localStorage.getItem("user"));

    const location = useLocation()

    const userId = location?.state?.userId;

    const fetchAllLeaves = async () => {
        try {
            const { data } = userId && userId !== 'undefined' ? await API.get(
                `/leaves/leaves/${userId}?status=${status}&type=${type}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=5}`) : await API.get(
                    `/leaves/leaves/${user?._id}?status=${status}&type=${type}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=5}`)
            setTotalPages(data?.totalPages || 1)
            return data?.data;
        } catch (err) {
            setLoader(false);
            console.error(err.message);
            return [];
        } finally {
            setLoader(false);
        }
    };

    const { data: leaveData } = useQuery({
        queryKey: ['userLeave', status, type, startDate, endDate, currentPage],
        queryFn: fetchAllLeaves,
        keepPreviousData: true,
    });

    const { data: pendingLeaveData, isLoading } = useQuery({
        queryKey: ["pendingLeaves", user?._id],
        queryFn: async () => {
            const { data } = userId && userId !== 'undefined' ? await API.get(`/leaves/leaves/${userId}?status=Pending`) : await API.get(`/leaves/leaves/${user?._id}?status=Pending`);
            return data?.data || [];
        },
    });

    const OtherLeaves = () => {
        if (leaveData && leaveData.length > 0) {
            return leaveData.filter((leave) => leave?.status !== "Pending");
        }
    };
    const fetchUser = async () => {
        try {
            const { data } = userId && userId !== 'undefined' ? await API.get(`/auth/user/${userId}`) : await API.get(`/auth/get-profile`);
            return data;
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: userData } = useQuery(
        { queryKey: ['userProfile', user?._id], queryFn: fetchUser }
    );

    const fetchLeaveTypeSummary = async () => {
        try {
            const { data } = userId && userId !== 'undefined' ? await API.get(`/leaves/type-summary?userId=${userId}`) : await API.get(`/leaves/type-summary`);
            return data?.data || [];
        } catch (err) {
            console.error(err.message);
            return [];
        }
    };

    const { data: leaveTypeData } = useQuery(
        { queryKey: ['typeSummary', user?._id], queryFn: fetchLeaveTypeSummary }
    );

    return (
        <>

            {
                loader ? <Loader /> :
                    <div className="xxs:p-5 p-2.5 flex flex-col h-full">
                        {/* {!loader && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '700px' }}>
                            <Loader />
                        </div>
                        } */}
                        {userId && userId !== 'undefined' ? <h1 className="text-base font-semibold text-dark/80 pb-2">{userData?.name}'s Leave</h1> : null}
                        <div className="bg-[#F9FAFB] rounded-lg border border-secondary 2xl:p-5 p-3 h-full overflow-auto">
                            {/* Pending Leave Section */}
                            <div className="mb-5">

                                <h2 className="text-base font-semibold text-dark/80 pb-2">Pending Request</h2>
                                <div className="grid grid-cols-7 gap-3 items-start">
                                    <PendingLeave leaveData={pendingLeaveData} />
                                    <div className="col-span-2 bg-white p-4 border border-secondary rounded-md flex items-center">
                                        <div className="w-full flex items-center justify-between gap-2.5">
                                            <div className="flex flex-col gap-0.5">
                                                <Link to="#" className="text-primary text-sm" onClick={() => setLeavePolicyOpen(true)}>
                                                    Leave Policy Explanation
                                                </Link>
                                            </div>
                                            <Button
                                                size="sm"
                                                className={"font-semibold max-sm:h-6"}
                                                onClick={() => setIsOpen(true)}
                                            >
                                                Request Leave
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* My Leave Stats */}
                            <div className="mb-5">
                                <h2 className="text-base font-semibold text-dark/80 pb-2">My Leave Stats</h2>
                                <div className="grid grid-cols-12 gap-3">
                                    <Weekly />
                                    <ConsumedLeave result={leaveTypeData} />
                                    <Monthly />
                                </div>
                            </div>

                            {/* Leave Balance */}
                            <div className="mb-5">
                                <h2 className="text-base font-semibold text-dark/80 pb-2">Leave Balance</h2>
                                <div className="grid grid-cols-3 gap-3">
                                    <CompOffs userData={userData} leaveData={leaveTypeData} />
                                    <PaidLeave userData={userData} leaveData={leaveTypeData} />
                                    <UnpaidLeave leaveData={leaveTypeData} />
                                </div>
                            </div>

                            <div className="mb-4 bg-white p-4 border border-gray-200 rounded-lg">
                                <p>Other Leave Types Available : Floater Leave ({userData?.floaterLeave || 0} days)</p>
                            </div>
                            <div className="mb-5">
                                <h2 className="text-base font-semibold text-dark/80 pb-2">Leave History</h2>
                                <LeaveHistory
                                    leaves={OtherLeaves()}
                                    setStatus={setStatus}
                                    setType={setType}
                                    type={type}
                                    status={status}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    setStartDate={setStartDate}
                                    startDate={startDate}
                                />
                            </div>

                            {/* Pagination Controls */}
                            <div className="flex justify-between items-center mt-4">
                                <Button
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                >
                                    Previous
                                </Button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <Button disabled={currentPage === totalPages} onClick={() => setCurrentPage((prev) => prev + 1)}>Next</Button>
                            </div>
                        </div>
                    </div>
            }
            <LeavePolicy isOpen={leavePolicyOpen} setIsOpen={setLeavePolicyOpen}></LeavePolicy>
            <ApplyLeaveModel isOpen={isOpen} setIsOpen={setIsOpen} userData={userData}></ApplyLeaveModel>
        </>
    );
};

export default LeaveList;
