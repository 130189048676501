import React, { useEffect, useRef, useState } from 'react';
import { getColorFromName } from '../../lib/utils';
import moment from 'moment';
import { Button } from '@headlessui/react';
import Tooltip from '../../common/ui/Tooltip';
import { ArrowDownLeft, ArrowUpRight, Eye, Pencil } from 'lucide-react';
import RenderTimelin from './RenderTimelin';
import EditAttendanceModal from '../modal/EditAttendanceModal';

function UserAttendanceItemList({ userData, date }) {
    const {
        name,
        isOnLeave,
        leaveInfo,
        sessions = [],
        effectiveHours,
        grossHours,
        arriveTime,
    } = userData;

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const popupRef = useRef(null);

    const formattedDate = date.toISOString().split('T')[0];
    const momentDate = moment(date, 'YYYY-MM-DD');

    const isWeekend = momentDate.isValid() && (momentDate.day() === 0 || momentDate.day() === 6);

    const calculateBreakDuration = (effectiveHours, grossHours) => {
        const toMilliseconds = (time) => {
            const [h, m, s] = (time.match(/(\d+)h\s*(\d+)m\s*(\d+)s/) || []).slice(1).map(Number);
            return (h || 0) * 3600000 + (m || 0) * 60000 + (s || 0) * 1000;
        };

        const effectiveMs = toMilliseconds(effectiveHours);
        const grossMs = toMilliseconds(grossHours);

        if (grossMs > effectiveMs) {
            const diffMs = grossMs - effectiveMs;
            const hours = Math.floor(diffMs / 3600000);
            const minutes = Math.floor((diffMs % 3600000) / 60000);
            const seconds = Math.floor((diffMs % 60000) / 1000);

            return `${hours.toString().padStart(2, "0")}h ${minutes
                .toString()
                .padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`;
        } else {
            return "-";
        }
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div
            className={`w-full flex text-muted border-b  text-sm font-medium relative ${isOnLeave && !isWeekend && leaveInfo?.[0]?.type === 'Full Day'
                ? 'bg-[#E6E6FA]'
                : isWeekend && !sessions.length
                    ? 'bg-[#c2b89d33]'
                    : 'bg-transparent'
                }`}
        >
            <div className="w-[25%] flex gap-2 items-center p-2">
                <div className="flex items-center gap-2">
                    <div
                        className="w-6 h-6 rounded-full flex items-center justify-center text-white text-xs capitalize"
                        style={{ backgroundColor: `${getColorFromName(name)}` }}
                    >
                        {name
                            ?.split(' ')
                            .slice(0, 2)
                            .map((part) => part.charAt(0).toUpperCase())
                            .join('')}
                    </div>
                    {name || '-'}
                </div>
                <span
                    className={`text-[10px] font-medium px-1 py-[3px] rounded-sm leading-none ${isOnLeave && !isWeekend
                        ? "bg-primary/70 text-white"
                        : isWeekend && !sessions.length
                            ? "bg-[#f5deb3] text-[#6C6C80]"
                            : ""
                        }`}
                >
                    {!isWeekend && isOnLeave ? (
                        <div className="relative">
                            <Tooltip
                                detailClass='top-5 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Leave' bgColor={'bg-primary'} borderColor={'border-t-primary'}
                            >
                                <span>LEAVE</span>
                            </Tooltip>
                        </div>
                    ) : isWeekend && !sessions.length ? (
                        <div className="relative">
                            <Tooltip
                                detailClass='top-5 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' text='Weekly-Off' bgColor={'bg-primary'} borderColor={'border-t-primary'}
                            >
                                <span>W-OFF</span>
                            </Tooltip>
                        </div>
                    ) : (
                        ""
                    )}
                </span>
            </div>

            <div className="w-[40%] flex items-center ">
                <RenderTimelin
                    sessions={sessions}
                    leaveInfo={leaveInfo}
                    date={formattedDate}
                />
            </div>

            <div className="w-[15%] flex items-center ">
                {effectiveHours || '-'}
            </div>
            <div className="w-[15%] flex items-center ">
                {grossHours || '-'}
            </div>
            <div className="w-[15%] flex items-center ">
                {effectiveHours && grossHours
                    ? calculateBreakDuration(effectiveHours, grossHours)
                    : "-"}
            </div>
            <div className="w-[10%] flex items-center ">
                {arriveTime || '-'}
            </div>

            <div className="w-[5%] flex items-center p-2">
                <Button
                    className="p-0 h-5 w-6 shadow-none rounded-md bg-transparent border border-primary hover:bg-primary group"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsPopupOpen((prev) => !prev);
                    }}
                >
                    <Tooltip text="Attandance Log"
                        detailClass='top-8 bg-[#475467]' arrowClass='top-[unset] bottom-full rotate-180 border-t-[#475467]' toolTipClass='' bgColor={'bg-primary'} borderColor={'border-t-primary'}>
                        <Eye className="h-4 w-4 text-primary group-hover:text-white" />
                    </Tooltip>
                </Button>

                {isPopupOpen && (
                    <div
                        ref={popupRef}
                        className="absolute right-0 top-full mt-2 w-56 bg-white shadow-lg rounded-lg p-2.5 z-10 border border-secondary"
                    >
                        <div className="bg-gray-200 p-2 rounded-t-lg flex items-center justify-between">
                            <div className="flex flex-col">
                                <p className="text-sm text-gray-700 font-semibold">
                                    General Shift ({momentDate.format('MMM DD, ddd')})
                                </p>
                                <p className="text-sm text-gray-500">9:00 AM - 6:00 PM</p>
                            </div>
                            <button
                                className="text-red-500 hover:text-red-700 mb-4"
                                aria-label="Edit"
                                onClick={() => setIsEdit(true)}
                            >
                                <Tooltip
                                    detailClass="top-8 bg-[#475467]"
                                    arrowClass="top-[unset] bottom-full rotate-180 border-t-[#475467]"
                                    toolTipClass=""
                                    text="Edit"
                                    bgColor={'bg-primary'}
                                    borderColor={'border-t-primary'}
                                >
                                    <div className="flex items-center rounded-full text-sm">
                                        <Pencil className="h-4 w-4" />
                                    </div>
                                </Tooltip>
                            </button>
                        </div>


                        <div className="border-b border-gray-300 my-2"></div>

                        {sessions.length > 0 ? (
                            <ul className="mt-2 flex flex-col gap-1.5">
                                {sessions.map((entry, index) => (
                                    <li key={index} className="flex items-center justify-between">
                                        {entry.checkInTime && (
                                            <span className="flex items-center gap-1">
                                                <ArrowDownLeft className="text-green-400 h-4 w-4" />
                                                {entry.checkInTime}
                                            </span>
                                        )}
                                        {entry.checkOutTime && (
                                            <span className="flex items-center gap-1">
                                                <ArrowUpRight className="text-red-400 h-4 w-4" />
                                                {entry.checkOutTime}
                                            </span>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-sm text-gray-400 mt-2">No Attendance Found</p>
                        )}
                    </div>
                )}
            </div>
            <EditAttendanceModal
                date={date}
                open={isEdit}
                user={userData}
                setOpen={setIsEdit}
                sessions={sessions}
            />
        </div>
    );
}

export default UserAttendanceItemList;
