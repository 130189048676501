import React, { useEffect, useState } from "react";
import { Button } from "../../common/ui/Button";
import axios from "axios";
import Editor from "../editor/editor";
import Buttonloader from "../../common/ui/ButtonLoader";
import Loader from "../../common/ui/Loader";
import { toast } from "react-toastify";
import API from "../../services/api";

const PlatformSection = ({
  fetchProjectInfo,
  projectId,
  projectInfo,
  isLoading,
}) => {
  const [loader, setLoader] = useState(false);
  const [content, setContent] = useState(projectInfo?.platform || "");
  const [savingStatus, setSavingStatus] = useState('Saved');
  
  const url = process.env.REACT_APP_FASTAPI_URL;

  useEffect(() => {
    if (projectInfo?.platform) {
      setContent(projectInfo.platform);
      setSavingStatus("Saved");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (!content) return;

    if (content === projectInfo?.platform) {
      setSavingStatus("Saved");
      return;
    }
    setSavingStatus('Unsaved');
    const debounceTimeout = setTimeout(async () => {
      try {
        const payload = { platform: content };
        await UpdatePlatform(payload);
        setSavingStatus('Saved');
      } catch (error) {
        console.error("Error in Update", error);
        setSavingStatus('Error in Saved');
      }
    }, 1500);
    return () => clearTimeout(debounceTimeout);
  }, [content]);

  const UpdatePlatform = async (platform) => {
    try {
      await API.patch(`/project/${projectId}`, platform);
      fetchProjectInfo();
    } catch (error) {
      console.error("Error in Update", error);
    }
  };

  const moduleGenarate = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${url}/platform_suggestion/`, {
        project_id: projectId,
      });
      if (data?.platform_suggestions) {
        setContent(data.platform_suggestions);
      }
      fetchProjectInfo();
      setLoader(false);
      return data;
    } catch (err) {
      console.error("Error in moduleGenarate:", err.message);
      setLoader(false);
      return [];
    }
  };

  return (
    <>
      <div className="flex items-center justify-between pb-2.5">
        <h3 className="text-primary font-semibold">Platform Suggestions</h3>
        {content && (
          <Button onClick={() => moduleGenarate()} disabled={loader}>
            {loader ? <Buttonloader /> : "Regenerate"}
          </Button>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="body flex-grow rounded-md border border-gray-100 bg-gray-50 p-4 px-0 w-full overflow-y-auto flex flex-col">
            {content && <div className="flex items-center justify-end px-4 mb-5">
              <div className="p-1.5 px-4 bg-primary/20 text-xs font-semibold rounded-md inline-block">
                {savingStatus}
              </div>
            </div>}
            <div className="h-full overflow-auto flex-grow">
              {content ? (
                <Editor key={content} initialValue={content ?? ""} onChange={setContent} />
              ) : (
                <div className="h-full flex items-center justify-center">
                <Button onClick={() => moduleGenarate()} disabled={loader}>
                  {loader ? <Buttonloader /> : "Generate"}
                </Button>
              </div>
              )}
            </div>
          </div>


        </>
      )}
    </>
  );
};

export default PlatformSection;
