import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { getColorFromName } from "../../lib/utils";
import { Button } from "./Button";

const FilterDropdown = ({ list, label, isOpen, toggleDropdown, selectedValues, onSelectionChange }) => {
  const [search, setSearch] = useState("");

  const handleCheckboxChange = (item) => {
    const updatedValues = selectedValues.some((value) => value._id === item._id)
      ? selectedValues.filter((value) => value._id !== item._id)
      : [...selectedValues, item];
    onSelectionChange(updatedValues);
  };

  const handleClearAll = () => {
    onSelectionChange([]);
  };
  return (
    <div className="relative w-40">
      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className="w-full px-2 py-1.5 text-left text-sm bg-white border rounded-md focus:outline-none flex items-center justify-between text-gray-600"
      >
        {label}
        <span className="float-right transform transition-transform">
          <ChevronDown className={`text-dark/70 h-4 w-4 ${isOpen ? 'rotate-180' : ''}`} />
        </span>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg py-1">
          {/* Search Bar */}
          <div className="px-1">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              className="w-full text-sm px-2 py-1 border rounded-md focus:outline-none"
            />
          </div>

          {/* Options */}
          <div className="max-h-40 overflow-y-auto flex flex-col gap-1 p-2">
            <div className="">
            </div>
            {list
              ?.filter((item) =>
                item?.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((item, index) => (
                <div key={index} className="">
                  <label className="flex items-center text-sm cursor-pointer">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600 rounded flex-shrink-0"
                      checked={selectedValues.some((value) => value._id === item._id)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    {
                      label === 'Tags' ?
                        <span className="ml-2 text-white p-0.5 px-1 text-xs rounded-md" style={{ background: getColorFromName(item?.name) }}>{item?.name}</span> :
                        <span className="ml-2 text-gray-700 flex-grow truncate">{item?.name}</span>
                    }
                  </label>
                </div>
              ))}
              <label className="flex items-center w-full">
                <Button size={'sm'} onClick={handleClearAll} className="w-full bg-primary/10 shadow-none text-primary font-semibold hover:bg-primary/15">Clear All</Button>
              </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
