import React, { useState, useEffect } from 'react';
import { TimelineData, TimelineActionToId } from '../../staticData/data';
import { useAuth } from '../../context/AuthContext';
import API from '../../services/api';

const TimelineSection = () => {
    const [timelineData, setTimelineData] = useState([]);
    const { user } = useAuth();

    const formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString('en-GB') : 'N/A';
    };
    const processTimelineData = (apiData) => {
        const groupedByYear = {};
    
        TimelineData.forEach((item) => {
            item.events.forEach((event) => {
                let matchingDataList = [];
    
                if (event.id === 1) {
                    matchingDataList.push({ action: TimelineActionToId[1], timestamp: user?.createdAt });
                } else {
                    matchingDataList = apiData.data
                        .filter((data) => {
                            switch (data.action) {
                                case TimelineActionToId[5]:
                                    return event.id === 5;
                                case TimelineActionToId[2]:
                                    return event.id === 2;
                                case TimelineActionToId[4]:
                                    return event.id === 4;
                                case TimelineActionToId[3]:
                                    return event.id === 3;
                                case TimelineActionToId[6]:
                                    return event.id === 6;
                                default:
                                    return false;
                            }
                        })
                        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                }
    
                matchingDataList.forEach((matchingData) => {
                    const eventYear = new Date(matchingData.timestamp).getFullYear();
                    if (!groupedByYear[eventYear]) {
                        groupedByYear[eventYear] = [];
                    }
                    let value = "N/A";
                    let date = formatDate(matchingData.timestamp);
    
                    switch (matchingData.action) {
                        case TimelineActionToId[5]:
                            value = `${formatDate(matchingData.details?.newNoticePeriod?.startDate)} to ${formatDate(matchingData.details?.newNoticePeriod?.endDate)}`;
                            break;
                        case TimelineActionToId[2]:
                            value = "Probation End Date Updated";
                            date = formatDate(matchingData.details?.newProbationEndDate);
                            break;
                        case TimelineActionToId[4]:
                            value = `New Salary: ${matchingData.details?.newSalary || "N/A"}`;
                            break;
                        case TimelineActionToId[3]:
                            value = "User Confirmed";
                            break;
                        case TimelineActionToId[1]:
                            value = "User Joined";
                            break;
                        case TimelineActionToId[6]:
                            value = "Final Settlement Completed";
                            break;
                        default:
                            break;
                    }
    
                    groupedByYear[eventYear].push({
                        ...event,
                        value,
                        date,
                    });
                });
            });
        });
    
        const sortedTimelineData = Object.keys(groupedByYear)
            .sort((a, b) => a - b) // Sort years in ascending order
            .map((year) => ({
                year,
                events: groupedByYear[year], // Events are already sorted by timestamp
            }));
    
        return sortedTimelineData;
    };
    

    const fetchUser = async () => {
        try {
            const { data } = await API.get(`/auth/users/${user?._id}/timeline`);
            const processedData = processTimelineData(data);
            setTimelineData(processedData);
        } catch (err) {
            console.error("Error in Get Timeline", err.message);
            setTimelineData([]);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <div className="border border-border p-4 rounded-lg bg-[#FCFCFD]">
            <div className="flex items-center justify-between pb-2 border-b border-border">
                <h3>Timeline</h3>
            </div>
            <div className="pt-2">
                {timelineData.map((timeline, idx) => (
                    <div key={idx} className="mb-5">
                        <div className="bg-primary/10 text-primary py-1.5 px-2 inline-flex text-sm rounded-md font-semibold mb-3.5">
                            {timeline.year}
                        </div>
                        <div className="flex flex-col gap-10">
                            {timeline.events.map((data, eventIdx) => (
                                <div
                                    key={eventIdx} 
                                    className={`
                                        flex items-start gap-2 relative 
                                        ${timeline.events.length > 1 ?
                                            'after:absolute after:start-4 after:top-0 last:after:top-[unset] last:after:bottom-full after:h-full after:w-[2px] after:bg-gray-400' : ''
                                        }
                                        before:absolute before:start-4 before:bottom-full before:h-full before:w-[2px] before:bg-gray-400 
                                        first:before:h-0 last:before:h-0 
                                    `}
                                >
                                    <div className="bg-gray-100 rounded-full p-2 z-[1]">
                                        {data.icon}
                                    </div>
                                    <div>
                                        <h4 className="text-sm">{data.name}</h4>
                                        <p className="text-gray-500 text-sm">
                                            {data.date || "No Date Available"}
                                        </p>
                                        {data.value && (
                                            <div className="bg-primary/10 text-primary py-1.5 px-2 rounded-md text-sm font-semibold mt-1">
                                                {data.value} 
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TimelineSection;
