import React, { useState, useEffect } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { X } from 'lucide-react'
import { Input } from '../../common/Input'
import { Button } from '../../common/ui/Button'
import Buttonloader from '../../common/ui/ButtonLoader'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../services/api'

function EditAttendanceModal({ open, setOpen, sessions, loader, date, user }) {
    const [updatedSessions, setUpdatedSessions] = useState(sessions || [])
    const queryClient = useQueryClient();

    const handleSessionChange = (index, field, value) => {
        const updatedData = [...updatedSessions]
        updatedData[index][field] = value
        setUpdatedSessions(updatedData)
    }

    const addNewSession = () => {
        setUpdatedSessions([
            ...updatedSessions,
            { checkInTime: '', checkOutTime: '' }
        ])
    }

    const removeSession = (index) => {
        setUpdatedSessions(updatedSessions.filter((_, i) => i !== index))
    }

    const close = () => {
        setOpen(false)
    }

    const formattedDate = date.toISOString().slice(0, 10);
    const { mutate: UpdateAttendanceMutation } = useMutation({
        mutationFn: async () => {
            const response = await API.put(`timer/attendance?date=${formattedDate}`, { attendanceData: updatedSessions, userId: user?.userId });
            return response.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["UserattendanceData"] });
            toast.success('Updated successfully');
            setOpen(false)
        },
        onError: (error) => {
            console.error("Error Updating Attendance", error);
            toast.error(error.response?.data?.message || "Error Updating Attendance");
        },
    });

    useEffect(() => {
        setUpdatedSessions(sessions)
    }, [sessions])

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent page refresh
        UpdateAttendanceMutation(); // Call the mutation
    }

    return (
        <Dialog open={open} as="div" className="relative z-10 focus:outline-none" onClose={close}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-white backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-hidden"
                    >
                        <DialogTitle as="h3" className="text-base/7 font-medium text-dark bg-forground px-4 py-2 flex justify-between border-b border-border">
                            <div>
                                <p className="text-xl font-bold">Edit Attendance</p>
                            </div>
                            <span className="cursor-pointer" onClick={close}>
                                <X className="text-dark/50" />
                            </span>
                        </DialogTitle>
                        <div className="body">
                            <form onSubmit={handleSubmit}>
                                <div className="px-5 py-3 flex flex-col gap-2.5 max-h-[350px] overflow-auto">
                                    {updatedSessions.map((session, index) => (
                                        <div key={index} className="flex justify-between items-center gap-1.5">
                                            <div className="flex flex-col w-full gap-0.5">
                                                <label className="text-sm text-gray-500">Check-in Time:</label>
                                                <Input
                                                    placeholder="Check-in Time"
                                                    value={session.checkInTime}
                                                    onChange={(e) => handleSessionChange(index, 'checkInTime', e.target.value)}
                                                    className='px-2.5 py-1.5 text-gray-600'
                                                />
                                            </div>
                                            <div className="flex flex-col w-full gap-0.5">
                                                <label className="text-sm text-gray-500">Check-out Time:</label>
                                                <Input
                                                    placeholder="Check-out Time"
                                                    value={session.checkOutTime}
                                                    onChange={(e) => handleSessionChange(index, 'checkOutTime', e.target.value)}
                                                    className='px-2.5 py-1.5 text-gray-600'
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => removeSession(index)}
                                                className="text-red-500 mt-3 text-sm font-medium"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={addNewSession}
                                        className="text-blue-500 mt-2 text-sm font-medium flex items-center gap-2"
                                    >
                                        <span>+</span> Add Session
                                    </button>
                                </div>
                                <div className="border-t border-border flex items-center justify-end gap-2 px-5 py-3">
                                    <Button size='sm' variant="outline" type="button" onClick={close}>
                                        Cancel
                                    </Button>
                                    <Button size='sm' type="submit" disabled={loader}>
                                        {loader ? <Buttonloader /> : 'Save Changes'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default EditAttendanceModal
