import React from 'react'

const IconBagTick = (props) => {
    return (
    <svg {...props} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M16.6803 5.11998V14.72C16.6803 14.8897 16.6129 15.0525 16.4929 15.1725C16.3728 15.2926 16.2101 15.36 16.0403 15.36H1.96031C1.79057 15.36 1.62779 15.2926 1.50776 15.1725C1.38774 15.0525 1.32031 14.8897 1.32031 14.72V5.11998C1.32031 4.95024 1.38774 4.78746 1.50776 4.66743C1.62779 4.54741 1.79057 4.47998 1.96031 4.47998H16.0403C16.2101 4.47998 16.3728 4.54741 16.4929 4.66743C16.6129 4.78746 16.6803 4.95024 16.6803 5.11998Z" fill="currentColor" />
        <path d="M16.0397 3.84H12.8397C12.8397 2.82157 12.4351 1.84485 11.715 1.12471C10.9948 0.40457 10.0181 0 8.99969 0C7.98126 0 7.00454 0.40457 6.2844 1.12471C5.56426 1.84485 5.15969 2.82157 5.15969 3.84H1.95969C1.62021 3.84 1.29464 3.97486 1.05459 4.2149C0.814544 4.45495 0.679688 4.78052 0.679688 5.12V14.72C0.679688 15.0595 0.814544 15.385 1.05459 15.6251C1.29464 15.8651 1.62021 16 1.95969 16H16.0397C16.3792 16 16.7047 15.8651 16.9448 15.6251C17.1848 15.385 17.3197 15.0595 17.3197 14.72V5.12C17.3197 4.78052 17.1848 4.45495 16.9448 4.2149C16.7047 3.97486 16.3792 3.84 16.0397 3.84ZM8.99969 1.28C9.67864 1.28 10.3298 1.54971 10.8099 2.02981C11.29 2.5099 11.5597 3.16105 11.5597 3.84H6.43969C6.43969 3.16105 6.7094 2.5099 7.18949 2.02981C7.66959 1.54971 8.32073 1.28 8.99969 1.28ZM16.0397 14.72H1.95969V5.12H16.0397V14.72Z" fill="currentColor" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4213 7.15642C12.2533 7.16014 12.0915 7.22039 11.9621 7.32741L7.40218 10.9891L5.8618 8.93432C5.80292 8.85587 5.72917 8.78975 5.64475 8.73981C5.56033 8.68987 5.4669 8.65703 5.3698 8.6432C5.2727 8.62936 5.17382 8.63486 5.07882 8.65924C4.98381 8.68362 4.89454 8.72641 4.8161 8.78529C4.73765 8.84417 4.67158 8.91798 4.62164 9.0024C4.5717 9.08682 4.53887 9.18019 4.52504 9.2773C4.51121 9.3744 4.51664 9.47332 4.54102 9.56833C4.5654 9.66333 4.60825 9.75257 4.66714 9.83101L6.6697 12.5011C6.72976 12.5812 6.80532 12.6484 6.89188 12.6987C6.97845 12.749 7.07425 12.7814 7.17358 12.794C7.27292 12.8065 7.37376 12.799 7.47011 12.7717C7.56646 12.7445 7.65636 12.6981 7.73444 12.6355L12.8969 8.49153C13.0205 8.395 13.1106 8.26198 13.1544 8.11136C13.1982 7.96074 13.1934 7.80011 13.1408 7.65235C13.0881 7.50459 12.9903 7.37715 12.8611 7.28813C12.732 7.19911 12.5781 7.15303 12.4213 7.15642Z" fill="currentColor" />
    </svg>
    
  )
}

export default IconBagTick